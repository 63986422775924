
import React, { useEffect, useState } from "react";
import GenerateField from "./GenerateField";
import { API_GET } from "../../services/api.service";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  };
  

function UserDropdown(props) {
    const { user, id, label, handleChange } = props;

    const [allUsers, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const getCampaignUsers = async () => {

        if (user && user.admin && user.admin != 'undefined') {
        let userInfo = await API_GET(
            `register/UserInfoByUserName?username=${user.admin}`
        );
        if (userInfo.success && userInfo.data.all_members) {

            let _options = [];
            userInfo.data.all_members.forEach((element) => {
                _options.push({
                    key: element.username,
                    value: element.username+' ('+element.first_name+')',
                });
            });

            setAllUsers(_options);
        }
        }
    };

    useEffect(() => {
        getCampaignUsers();
    }, []);

    const onChange = (e) => {
        let name = e.target.name;
        setSelectedUser(e.target.value);
        handleChange(e.target.value)
    };

    return (

        <GenerateField

            value={selectedUser}
            onChange={onChange}
            defaultValue={"Select"}
            id={id}
            name={id}
            label={label ? label : id}
            type="checkbox_list"
            keyValuePair={allUsers}
        />


    );
}

export default withStyles(style)(UserDropdown);