import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Menu from "@material-ui/core/Menu";
import Button from "components/CustomButtons/Button.jsx";
import { API_POST } from "../../services/api.service";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import IconButton from "@material-ui/core/IconButton";
import { AccountCircleOutlined, AccountCircleRounded, ArrowDropDownCircleRounded, PersonPinCircle, RadioButtonCheckedRounded, TrendingUpOutlined } from "@material-ui/icons";
const TimeLine = ({ ...props }) => {


  const { classes, color, logo, image, logoText, routes, user } = props;
  const dateObj = window.moment(new Date());
  let format = {
    currentStatus:null,
    firstLoginTime:null,
    breakCount:0,
    logoutTime:0,
    loginCount:0,
    logoutCount:0,
    previousStatus:null,
    date:dateObj.format('YYYY-MM-DD'),
    loginTime:0,
    breakTime:0,
    createdAt:dateObj,
    lastUpdate:dateObj,
  }
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [formatData, setFormatData] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {

    if(anchorEl){

      setAnchorEl(false);
    }else{

      setAnchorEl(true);
    }

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTodayRecord = async () => {

   
    if(!user.admin){
      return null;
    }
    // today record
    let data = await API_POST("action/getdata", {
      root: "timeTraker",
      con: {date:dateObj.format('YYYY-MM-DD'),admin: user && user.admin,user:user && user.username },
    });
    if(data.data.length){
  
      setFormatData(data.data[0]);
      return data.data[0];
    }
    return null;
      
  };

  const calculateTime = async (obj) =>{


    if(obj.currentStatus == 'LOGIN'){
      let time = dateObj.diff(obj.lastUpdate,'seconds');
      obj.loginTime = obj.loginTime ? obj.loginTime + time : time; 

    }else if(obj.currentStatus == 'LOGOUT'){
      let time = dateObj.diff(obj.lastUpdate,'seconds');
      obj.logoutTime = obj.logoutTime ? obj.logoutTime + time : time; 

    }else{
      let time = dateObj.diff(obj.lastUpdate,'seconds');
      obj.breakTime = obj.breakTime ? obj.breakTime + time : time;     
    }
    obj.lastUpdate = dateObj;
    await  setRecord(obj);
    return true;

  }

  const loginChange = async (autoTrigger = false) => {

    let body = {};
    body = {...format};
    let currentData = await getTodayRecord();  
    if(!currentData){
      body.firstLoginTime = dateObj;
    }else{
      body = currentData;
    }

 
    let history = currentData && currentData.history ? currentData.history : [];
    history.push({
      status: 'Active',
      actionOn:dateObj,
      elapsedTime : (currentData && currentData.lastUpdate) ? (new Date() - new Date(currentData.lastUpdate)) / 1000 : 0,
      lastAction: currentData ? currentData.lastUpdate : dateObj,
      lastStatus: currentData ? currentData.currentStatus : 'Active'
    })
    body.history = history;
    body.currentStatus = 'Active';
    body.lastUpdate = dateObj;
    await setRecord(body);


    return true;
   
  };

  
  const BreakChange  = async (autoTrigger = false) => {

    if(!formatData){
      alert("Please Login First");
      return true;
    }

  
    let body = {};
    body = {...format};
    let currentData = await getTodayRecord();  
    if(!currentData){
      body.firstLoginTime = dateObj;
    }else{
      body = currentData;
    }
    let history = currentData && currentData.history ? currentData.history : [];
    history.push({
      status: 'Break',
      actionOn:dateObj,
      elapsedTime : currentData.lastUpdate ? (new Date() - new Date(currentData.lastUpdate)) / 1000 : 0,
      lastAction: currentData ? currentData.lastUpdate : null,
      lastStatus: currentData ? currentData.currentStatus : null
    })
    body.history = history;
    body.currentStatus = 'Break';
    body.lastUpdate = dateObj;
    await setRecord(body);






    return true;
  };

  const LogoutChange = async (autoTrigger = false) => {

    if(!formatData){
      alert("Please Login First");
      return true;
    }
 
    let body = {};
    body = {...format};
    let currentData = await getTodayRecord();  
    if(!currentData){
      body.firstLoginTime = dateObj;
    }else{
      body = currentData;
    }
    let history = currentData && currentData.history ? currentData.history : [];
    history.push({
      status: 'Offline',
      actionOn:dateObj,
      lastAction: currentData ? currentData.lastUpdate : null,
      elapsedTime : currentData.lastUpdate ? (new Date() - new Date(currentData.lastUpdate)) / 1000 : 0,
      lastStatus: currentData ? currentData.currentStatus : null
    })
    body.history = history;
    body.currentStatus = 'Offline';
    body.logoutTime = dateObj;
    body.lastUpdate = dateObj;
    await setRecord(body);

    return true;
  };

  useEffect(async () => {
    await getTodayRecord();

    //Implementing the setInterval method
   

    //Clearing the interval
   // return () => clearInterval(interval);
}, [user]);


const interval = setInterval( async () => {
  //let currentData = await getTodayRecord();  
  if(formatData){
    if(formatData.currentStatus == 'Active'){
      // await loginChange(true);
    }else if(formatData.currentStatus == 'Offline'){
      // await LogoutChange(true);
    }else{
      // await BreakChange(true);
    }

  }
}, 900000);


  const setRecord = async (body) => {

     let formatDatatmp = {};
     formatDatatmp.admin = user && user.admin;
     formatDatatmp.user = user && user.username;

     
     formatDatatmp = {...body};
     if(formatDatatmp._id){
      delete formatDatatmp._id;
     }
    let response = await API_POST("action/upsert", {
      root: "timeTraker",
      body: formatDatatmp,
      querydata: {date:dateObj.format('YYYY-MM-DD'),admin: user && user.admin , user : user && user.username  },
    });

    getTodayRecord();
    setAnchorEl(false);
  };


  const isMobile=(window.innerWidth <= 760);



  let currentstatus = formatData ? formatData.currentStatus == 'Active' ?  'LogedIn'
  : formatData.currentStatus == 'Break' ? 'Break' :
  formatData.currentStatus == 'Offline' ? 'Offline' : null
  : 'Status'

  let currentcolor = formatData ? formatData.currentStatus == 'Active' ?  'green'
  : formatData.currentStatus == 'Break' ? 'orange' :
  formatData.currentStatus == 'Offline' ? 'red' : null
  : 'gray'


  let commonStyle={
    fontSize: "auto",
    position: "fixed",
    right: "5px",
    zIndex: "10000" ,
    cursor:"pointer",
   
    borderTopRightRadius:'37px',
    borderBottomRightRadius:'37px',
    borderTopLeftRadius:'37px',
    borderBottomLeftRadius:'37px'  

  }

  let statusStyle= {
  
    bottom: "20px",
    width:'auto',
    height:'60px',
    background:'rgb(48 41 33)',
    ...commonStyle
   }

  let buttonStyle={...commonStyle,
    bottom : "75px",
    width:'60px',
    height:'60px',
    background:'green',
    color:"white",
    boxShadow: "0px 0px 10px green",
    border: "0px"
  }

  
  let buttonStyle1={...commonStyle,
    bottom : "139px",
    width:'60px',
    height:'60px',
    background:'orange',
    color:"white",
    boxShadow: "0px 0px 10px orange",
    border: "0px"
  }

  
  let buttonStyle2={...commonStyle,
    bottom : "203px",
    width:'60px',
    height:'60px',
    background:'red',
    color:"white",
    boxShadow: "0px 0px 10px red",
    border: "0px"
  }

  return (
    <div>

<div style={{...statusStyle}}  onClick={handleClick}>
       
  <RadioButtonCheckedRounded  title="Status" style={{fontSize:'60px',color:currentcolor}}/>


</div>




      <div style={anchorEl ? {display:"block"} : {display:"none"}}>
          <button onClick={loginChange} style={{...buttonStyle}}>Log-In</button>
          <button onClick={BreakChange} style={{...buttonStyle1}}>In-Break</button>
          <button  onClick={LogoutChange} style={{...buttonStyle2}}>Day-Off</button>
      </div>

 { /*

<span
        onClick={handleClick}
      >
        {
          formatData ? formatData.currentStatus == 'LOGIN' ?  <PanoramaFishEyeIcon style={{ color: "green",backgroundColor:'green',borderRadius:'100%' }}/> 
          : formatData.currentStatus == 'BREAK' ? <PanoramaFishEyeIcon style={{ color: "orange",backgroundColor:'orange',borderRadius:'100%' }}/> :
          formatData.currentStatus == 'LOGOUT' ? <PanoramaFishEyeIcon style={{ color: "red",backgroundColor:'red',borderRadius:'100%' }}/> : null
          : <PanoramaFishEyeIcon style={{ color: "gray",backgroundColor:'gray',borderRadius:'100%' }}/>
        }
        
      </span>
*/

 }
      

       

          


    </div>
  );
};

TimeLine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(sidebarStyle)(TimeLine);
