import React, { useState } from "react";
//import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import iconsStyle from "assets/jss/material-dashboard-react/views/iconsStyle.jsx";

import TextField from '@material-ui/core/TextField';

import { Button, ButtonGroup } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.jsx";

//import { FormControl } from "@material-ui/core";

function CallbackControl(props) {
  const { onChangeCallback, Title } = props;
  const [selectedCallback, setSelectedCallback] = useState('');

  const onChange = (e) => {
    //console


    if(e.target.name=='Callback'){

      var now = new Date();
      var selected= new Date(e.target.value);
      if(selected < now){

        setSelectedCallback('');
        return false;
      }
    }
    setSelectedCallback(e.target.value);
    onChangeCallback(e.target.value);

  }

  const onTenMin = (e) => {
    var date = addDate(10);
    setSelectedCallback(date);
    onChangeCallback(date);
  }
  const onOneHour = (e) => {
    var date = addDate(60);
    setSelectedCallback(date);
    onChangeCallback(date);
  }
  const onTomorrow = (e) => {
    var date = addDate(24 * 60);
    setSelectedCallback(date);
    onChangeCallback(date);
  }

  const addDate = (duration) => {
    
    return window.moment().add(duration, 'minutes').format("YYYY-MM-DDTHH:mm")
  }



  return (
    <>
      <GridContainer justify="flex-end">
        <GridItem xs={12} md={12} sm={12}>
          <ButtonGroup
            size="small"
            fullWidth="true"
            color="info"
            aria-label="extra-small outlined button group"
          >
            <Button onClick={onTenMin}>10 Mins</Button>

            <Button onClick={onOneHour}>1 Hour</Button>

            <Button onClick={onTomorrow}>Tomorrow</Button>
          </ButtonGroup>
        </GridItem>
        <GridItem xs={12} md={12} sm={12}>

          <CustomInput
            labelText={Title}
            id="Callback"
            labelProps={{ shrink: true }}
            formControlProps={{
              fullWidth: true,
              multiline: true
            }}

            inputProps={{
              required: true,
              value: selectedCallback,
              defaultValue: selectedCallback,
              name: "Callback",
              type: 'date_time',
              inputProps:{
              // only needs the first 16 characters in the date string
              min: new Date().toISOString().slice(0, 16),
             },
              onChange: onChange
            }}
          />

        </GridItem>



      </GridContainer>
    </>
  )
}


export default withStyles(iconsStyle)(CallbackControl);
