import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import {
  InviteeStatus,
} from "../../services/api.service";


import { Dialog, DialogTitle, DialogContent, TextField } from "@material-ui/core";

function SubscriberInvitees(props) {
  const { user, sendApprove, sentEmail } = props;
  const [data, setData] = useState([]);
  const [openChangeExpiry, setOpenChangeExpiry] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});



  const columns = [
    
    {
      name: "User",
      selector: "username",
      width: "270px",
    },
    {
      name: "Invited At",
      cell: (row) => (
        row.sent ? window.moment(row.sent).fromNow() : "NA"
      )
    },
    {
      name: "Status",
      cell: (row) => (
        InviteeStatus[row.status] 
      ),
    },
   
    {
      name: "",
      cell: (row) => (
        <Button color="danger" size="sm" onClick={(e) => props.sentEmail(e, row)}>
         Send
        </Button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <Button color="success" size="sm" onClick={(e) => props.sendApprove(e, row)}>
         Approve
        </Button>
      ),
    },
   
  ];

  


  useEffect(() => {
    if (props.ActiveUsers && props.ActiveUsers.length > 0) {
      setData(props.ActiveUsers);
    }
  }, [props.ActiveUsers]);

 


  return (
    <>


      <Card>
        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
              Invitees
                <span style={{ float: "right", fontSize: "15px" }} />
              </h3>
            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DataTableExtensions columns={columns} data={data || []} export={true} print={false} >
              <DataTable columns={columns} data={data || []} pagination striped highlightOnHover pointerOnHover />
            </DataTableExtensions>
          </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default SubscriberInvitees;
