import { Grid } from '@material-ui/core';
import React from 'react';


export default class ErrorBoundary extends React.Component {
    constructor(props) {
      debugger;
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    static getDerivedStateFromError = error => {
        return { hasError: true };
      };
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message

        console.log("error catch",error,errorInfo)
        try {
            let Details = "";
            this.setState({
                hasError: true,
                error: error,
                errorInfo: errorInfo,
                Details: Details,
            });
        }
        catch (e) {
            console.log("error",e)
            this.setState({
                hasError: true,
                error: error,
                errorInfo: errorInfo,
                Details: ''
            });
        }
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.hasError) {
            // Error path
            return (
                <Grid item xs={12}>
                    <section>
                        <div>
                            <div>
                                {this.props.name
                                    ? <h4>An error occurred in {this.props.name}, please try again later</h4>
                                    : <h4>An error occurred, please try again later</h4>
                                }
                                <b>{this.state.Details}</b>
                                <details style={{ whiteSpace: 'pre-wrap' }}>
                                    <summary>{this.state.error && this.state.error.toString()}</summary>
                                    <p>{this.state.errorInfo && this.state.errorInfo.componentStack.substring(0,200)}</p>
                                </details>
                            </div>
                        </div>
                    </section>
                </Grid>
            );
        }
        //render children
        return this.props.children;
    }
}
