import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { API_POST } from "../../services/api.service";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//import Checkbox from "../../components/Checkbox/Checkbox";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CallbackControl from "../../views/Forms/CallbackControl";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function CallBackChange(props) {
  const {
    IsCallBackChange,
    Campaigns,
    allProspects,
    selectedRows,
    user,
    onChange,
    IsShowDate,
  } = props;
  const [callback, setCallback] = useState(null);
  const [InputType, setInputType] = useState("");
  const [options] = useState(Campaigns);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));

  let notification = {
    open: false,
    message: "",
    color: "success",
  };
  const [Notify, setNotify] = useState(notification);

 
  const handleCallBackChange = (Type, InputType, selectedProspects) => {
   
    let Isupdated = true;
    Isupdated = InputType ? true : alert("Please select Date");

    if (!Isupdated) return;

    if (Type === "selectedAll" && Isupdated) {
      let result = window.confirm("Do you want to update all prospects");
      Isupdated = result ? result : false;
      Isupdated && updateProspectDetails("updateMany", selectedProspects);
    } else {
      Isupdated = true;
      Isupdated && updateProspectDetails("update", selectedProspects);
    }
    Isupdated &&
      setNotify({
        open: true,
        message: "Data Change successfully.",
        color: "success",
      });

    if (onChange) {
      onChange();
    }

    setTimeout(function() {
      props.handleClose();
      props.RemoveAllChecks(false);
    }, 3000);
  };

  const updateProspectDetails = async (url, selectedProspects) => {
    let registerRequest;
    let body = [
       {callback },
    ];
    registerRequest = await API_POST("action/updateProspectDetails", {
      selectedProspects,
      body,
      updatedType: url,
    });

    if (registerRequest && registerRequest.status == 200) {
    //props.loadFollowUpData();
   // props.RemoveAllChecks();
    }
  };


  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <GridContainer>
        <Dialog open={IsCallBackChange} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Change Callback
          </DialogTitle>

          <DialogActions>
            <GridContainer>
              
              <GridItem xs={12} sm={12} md={12}>
             
                  <CallbackControl
                    onChangeCallback={(e) => setCallback(e)}
                    Title="Schedule Callback"
                  />
               
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
              <br />
                <Button
                  size="sm"
                  onClick={() => {
                    handleCallBackChange("selected", callback, selectedRows);
                  }}
                  color="primary"
                >
                  Selected - {selectedRows.length > 0 ? selectedRows.length : 0}
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    handleCallBackChange("selectedAll", callback, allProspects);
                  }}
                  color="primary"
                >
                  Selected All - {props.totalRowCount ? props.totalRowCount : 0}
                </Button>
                <Button size="sm" onClick={props.handleClose} color="primary">
                  Close
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </>
  );
}

CallBackChange.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(CallBackChange);
