/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "../../services/api.service";
import Typography from '@material-ui/core/Typography';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';


import Card from "components/Card/Card.jsx";
import EmailTemplateList from '../CommTemplates/EmailTemplateList';
import SMSTemplateList from '../CommTemplates/SMSTemplateList';
import WhatsAppTemplateList from '../CommTemplates/WhatsAppTemplateList';
import EmailConfiguration from "./EmailConfiguration";
import SMSSetupList from "../SMSSetup/SMSSetupList";
import WhatsAppSetupList from "../WhatsAppSetup/WhatsAppSetupList";
import CallingSetupList from "../CallingSetup/CallingSetupList";
import NotificationPage from "./Notification";
import BasicSettings from "./BasicSettings";


    class ConfigurationPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {


            activeTab: 0,
            cid: ""
        };
    }

    componentWillMount() {

        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab');
        this.setState({ activeTab: parseInt(tab) });
    }



    handleChange = (event, value) => {
        const { history } = this.props;
        this.setState({ activeTab: value });
        history.push('?tab=' + value)
    };

    render() {
        const { user } = this.props;


        return (

            <GridContainer>


                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title=""
                        TabProps={{
                            onChange: this.handleChange,
                        }}
                        selectedValue={this.state.activeTab}
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Email Config",
                                tabContent: (
                                    <ErrorBoundary name="EmailTemplates">
                                        <EmailConfiguration
                                            user={user}
                                            history={this.props.history}
                                        />
                                    </ErrorBoundary>
                                ),
                            },
                            {
                                tabName: "SMS Config",
                                tabContent: (
                                    <ErrorBoundary name="SMSTemplates">
                                        <SMSSetupList user={user}
                                                      history={this.props.history}></SMSSetupList>

                                    </ErrorBoundary>
                                ),
                            },
                            {
                                tabName: "WhatsApp Config",
                                tabContent: (
                                    <ErrorBoundary name="EmailTemplates">
                                        <WhatsAppSetupList user={user}
                                                              history={this.props.history}></WhatsAppSetupList>
                                    </ErrorBoundary>
                                ),
                            },
                            {
                                tabName: "Calling Setup",
                                tabContent: (
                                    <ErrorBoundary name="EmailTemplates">
                                        <CallingSetupList user={user}
                                                          history={this.props.history}></CallingSetupList>
                                    </ErrorBoundary>
                                ),
                            },
                            {
                                tabName: "Daily Report",
                                tabContent: (
                                    <ErrorBoundary name="EmailTemplates">
                                        <NotificationPage user={user}
                                                          history={this.props.history}></NotificationPage>
                                    </ErrorBoundary>
                                ),
                            },
                            {
                                tabName: "Basic Settings",
                                tabContent: (
                                    <ErrorBoundary name="EmailTemplates">
                                        <BasicSettings user={user}
                                                          history={this.props.history}></BasicSettings>
                                    </ErrorBoundary>
                                ),
                            },


                        ]}
                    />
                </GridItem>
            </GridContainer>

        );
    }
}
export default withStyles(dashboardStyle)(ConfigurationPage);
