import React, { useEffect, useState } from "react";
import PropTypes, { any } from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import iconsStyle from "assets/jss/material-dashboard-react/views/iconsStyle.jsx";
import Muted from "components/Typography/Muted.jsx";
import { API_GET, API_POST } from "../../services/api.service";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "components/Card/Card.jsx";

const style = {
  disposition: {
    display: 'inline-block',
    width: '100%'
  },
  dispositionActions: {
    float: 'right'
  }
};


function Forms(props) {

  const { classes, user } = props;
  
  let frmVal = {
    custom_field_name: '',
    field_type: '',
    field_options: '',
    required: false,
    admin: user && user.admin,
  }


  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(frmVal);
  const [customFields, setCustomFields] = useState([]);
  const [count, setCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");

  const handleClickOpen = () => {
    setMode("Add");
    setFormValues(frmVal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadCustomFields = async (e) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata",
        {
          root: 'custom_forms_new',
          con: { admin: user && user.admin },
          cols: {}
        });
      console.log(registerRequest);


      if (registerRequest && registerRequest.status == 200) {

        setCustomFields(registerRequest && registerRequest.data);

      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }

  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    }
    else if (e._isAMomentObject) {
      formValue[props] = e.format()
    }
    else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  }



  const handleAddField = async (e) => {
    e.preventDefault();
    let formValue = formValues;
    formValue['admin'] = user && user.username;
    formValue['createdOn'] = new Date();

    let registerRequest;
    try {
      registerRequest = await API_POST("action/addRecord",
        {
          root: 'custom_forms_new',
          body: formValue
        });
      console.log(registerRequest);


      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadCustomFields(e);
        handleClose();

      }
    } catch (error) {
      console.log(error)
    }


  }

  const handleEditField = async (e) => {
    e.preventDefault();
    debugger;
    let formValue = formValues;
    let _id = formValues._id;
    formValue['admin'] = user && user.username;
    formValue['updatedOn'] = new Date();
    delete formValue["_id"];

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update",
        {
          root: 'custom_forms_new',
          body: formValue,
          querydata: { _id }
        });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadCustomFields(e);
        handleClose();

      }
    } catch (error) {
      console.log(error)
    }
  }
  const editSubRecord = async (item) => {
    setMode("Edit");
    setFormValues(item);
    setOpen(true);
  }



  useEffect(() => {

  }, [count]);

  useEffect(() => {
    debugger;
    if (user) {
      loadCustomFields();
    }
  }, [user]);



  return (
    <Card>
      <GridContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{mode} Form Details</DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Field Name *"
                  id="custom_field_name"
                  error={errors && errors.custom_field_name}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: formValues && formValues.custom_field_name,
                    name: "custom_field_name",
                    onChange: handleChange,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Field Type *"
                  id="field_type"
                  error={errors && errors.field_type}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: formValues && formValues.field_type,
                    name: "field_type",
                    type: "select",
                    onChange: handleChange,
                    options: [
                      {
                        key: "text",
                        value: "Simple Text",
                      },
                      {
                        key: "radio",
                        value: "Sub Form",
                      },
                      {
                        key: "text_area",
                        value: "Text Area",
                      },
                      {
                        key: "number",
                        value: "Number",
                      },
                      {
                        key: "currency",
                        value: "Currency",
                      },
                      {
                        key: "select",
                        value: "Select Dropdown",
                      },
                      {
                        key: "checkbox",
                        value: "Check Box",
                      },
                      {
                        key: "date_time",
                        value: "Date Time",
                      },
                    ],
                  }}
                />
              </GridItem>

              {(formValues && formValues.field_type === "select") ||
              (formValues && formValues.field_type === "radio") ? (
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Field Options *"
                    id="field_options"
                    error={errors && errors.field_options}
                    labelProps={{ shrink: true }}
                    formControlProps={{
                      fullWidth: true,
                      multiline: true,
                    }}
                    inputProps={{
                      required: true,
                      value: formValues && formValues.field_options,
                      name: "field_options",
                      type: "textarea",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
              ) : (
                ""
              )}

              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Required Field "
                  id="required"
                  error={errors && errors.required}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: false }}
                  inputProps={{
                    checked: formValues && formValues.required,
                    name: "required",
                    type: "checkbox",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} />
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={mode === "Add" ? handleAddField : handleEditField}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="white">
              <h3>
                List Form Options
                <Button
                  variant="outlined"
                  color="info"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={handleClickOpen}
                >
                  + Add
                </Button>
              </h3>
            </CardHeader>
            <CardBody>
              <p>
                {customFields.map((item, index) => {
                  return (
                    <li key={index} className={classes.disposition}>
                      <span>{item.custom_field_name}</span>
                      &nbsp;
                      <Button size="sm" color="success">
                        {" "}
                        {item.field_type}
                      </Button>
                      {item.required ? (
                        <Button size="sm" color="danger">
                          {" "}
                          Required
                        </Button>
                      ) : null}
                      <div className={classes.dispositionActions}>
                        <Button
                          color={"transparent"}
                          justIcon={true}
                          simple={false}
                          aria-label="Edit"
                          className={classes.buttonLink}
                          onClick={(e) => editSubRecord(item)}
                        >
                          <i class="fa fa-pencil-square-o" aria-hidden="true" />
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="white">
              <h3>Live Preview</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {customFields.map((item, index) => {
                  return (
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText={item.custom_field_name}
                        id={item._id}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{
                          shrink:
                            ["radio", "checkbox"].indexOf(item.field_type) > -1
                              ? false
                              : true,
                        }}
                        customOptions={item.field_options}
                        inputProps={{
                          required: item.required,
                          name: item._id,
                          type: item.field_type,
                        }}
                      />
                    </GridItem>
                  );
                })}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

Forms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Forms);
