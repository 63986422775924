import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts

// import { Chart } from "react-google-charts";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons


// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import _ from "underscore";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { API_GET } from "../../services/api.service";
import Box from "./Box";
import TotalTalkTime from "./TotalTalkTime";
import CallDetailAWChart from "./CallDetailAWChart";
import CallTracker from "../Reports/CallTracker";




class CallDetailWise extends React.Component {

  state = {
    value: 0,
    aggData: null,
    activityData: [['Task', 'per Day']],
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: new Date().setHours(23, 59, 59, 0),

  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  componentWillMount() {
    const { user } = this.props;
    if (user) {
      // this.loadCampaign();
    }
  }

  loadCampaign = async () => {
    const { user } = this.props;
    let registerRequest;
    try {
      debugger;



      if (user && user.admin) {

        registerRequest = await API_GET(
          "action/DashboardData?admin=" + user.admin + "&username=" + user.username + "&role=" + user.role + '&sdt=' + window.moment(this.state.startDate).format('YYYY-MM-DDTHH:mm') + '&edt=' + window.moment(this.state.endDate).format('YYYY-MM-DDTHH:mm')
        );
        //debugger;
        if (registerRequest && registerRequest.status == 200) {

          if (registerRequest.data) {
            this.setState({ aggData: registerRequest.data });
            localStorage.setItem("dashboardData", JSON.stringify(registerRequest.data))
          }

        }
      }
    } catch (error) {
      console.log(error);
    }
  };




  render() {
    const { user } = this.props;
    const { startDate, endDate, isReload } = this.props;
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    console.log("isReload", isReload);



    const totalCallDetails = {
      startDate: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }
    const totalCallRecording = {
      endDate: {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    }

    if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {

      totalCallDetails['caller'] = user && user.username
      totalCallRecording['user'] = user && user.username
    }


    return (
      <div>
        {/* <GridContainer>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Total Calls"}
              icon={"fa fa-compress fa-4x"}
              root="CallDetails"
              color="success"
              query={{ ...totalCallDetails, type: { $ne: 'null' } }}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Total Incoming"}
              icon={"fa fa-arrow-down fa-4x"}
              root="CallDetails"
              color="primary"
              query={{ ...totalCallDetails, type: "INCOMING" }}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Total Outgoing"}
              icon={"fa fa-arrow-up fa-4x"}
              root="CallDetails"
              color="success"
              query={{ ...totalCallDetails, type: "OUTGOING" }}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Total Missed"}
              icon={"fa fa-retweet fa-4x"}
              root="CallDetails"
              color="danger"
              query={{ ...totalCallDetails, type: "MISSED" }}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <TotalTalkTime
              label={"Total TalkTime"}
              icon={"fa fa-volume-control-phone fa-4x"}
              root="CallDetails"
              color="success"
              sum="duration"
              query={{ ...totalCallDetails }}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
          <GridItem xs={12} sm={4} md={2}>

            <Box
              label={"Total Recordings"}
              icon={"fa fa-microphone fa-4x"}
              root="CallRecording"
              color="success"
              query={totalCallRecording}
              user={user}
              small={true}
              isReload={isReload}
            />

          </GridItem>
        </GridContainer> */}

        <CallTracker
          user={user}
          startDate={startDate}
          endDate={endDate}
          isReload={isReload}
          configSettings={this.props.configSettings}
        />

        {/* <GridContainer>

          <GridItem xs={12} sm={12} md={12}>

            <CallDetailAWChart
              query={{ startDate: new Date(startDate), endDate: new Date(endDate) }}
              user={user}
            />

          </GridItem>

        </GridContainer> */}

      </div>
    );
  }
}

CallDetailWise.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(CallDetailWise);
