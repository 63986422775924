import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import Filters from "../Schedule/Filters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
//import { Snackbar } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";




function SMSTemplateList(props) {
  const { user } = props;
  const [data, setData] = useState([]);
  const countPerPage = 10;
  const [IsRefresh, setIsRefresh] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });


  const columns = [
    {
      name: "Name",
      selector: "name",
    },

    {
      name: "IsActive",
      cell: (row) => <span>{row.isActive ? 'Yes' : 'No'}</span>,
    },
    {
      name: "Created On",
      cell: (row) => (
        <span>
          {row.createdOn &&
            window.moment(row.createdOn).format("YYYY-MM-DD HH:mm")}
        </span>
      ),
    },
    {
      name: "createdBy",
      selector: "createdBy",
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          size="sm"
          title="Edit Template"
          color="info"
          onClick={() => {
            handleEdit(row);
          }}>
          Edit
        </Button>
      ),
    },
  ];

  const handleEdit = (row) => {
    props.history.push("/admin/SMSTemplate/" + row._id);
  };
  const loadSMSTemplateListData = async () => {
    debugger;

    if (!user || (user && !user.admin)) {
      return;
    }


    let registerRequest = await API_POST("action/getdata", {
      root: "Templates",
      con: { admin: user && user.admin, type: 'sms' },
      cols: {},
    });
    debugger;
    setData(registerRequest.data || []);
    setIsRefresh(true);
  };






  useEffect(() => {

    loadSMSTemplateListData();


  }, [IsRefresh]);

  const handleAdd = async () => {
    debugger;
    const { history } = props;
    history.push("/admin/SMSTemplate");
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Card>
        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                SMS Templates
                <span style={{ float: "right", fontSize: "15px" }}>
                  <GridItem  style={{float: "left", marginTop: "10px"}}>
                      <InfoBox name={'Templates.SmsTemplates'} />
                  </GridItem>
                  <Button
                    size="sm"
                    title="Add Template"
                    color="info"
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    <i className="fa fa-plus" /> &nbsp; Add Template
                  </Button>
                </span>
              </h3>
            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DataTable
              columns={columns}
              data={data || []}
              highlightOnHover
              pagination

              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}

            />
          </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default SMSTemplateList;
