import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_POST } from "../../services/api.service";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

//import ViewDisposition from "./ViewDisposition";
//import "./Style/Disposition.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Snackbar } from "@material-ui/core";

import TagInput from "components/Tags/TagInput";
import ViewTags from "./ViewTags";
import InfoBox from "../../components/InfoIcon/InfoIcon";

const style = {
  Tags: {
    display: "inline-block",
    width: "100%",
  },
  
  actions: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#00a65a",
    borderRadius: "10px",
  },
  frmFields: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#3c8dbc",
    borderRadius: "10px",
  },
};

function Tags(props) {
  const { classes, user } = props;

  let frmVal = {
    tag_value: "",    
    admin: user && user.admin,
    createdOn: new Date
  };
  const [formValues, setFormValues] = useState(frmVal);
  const [count, setCount] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [tags, setTags] = useState([]);
  
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: 'success'
  });


  const handleClickOpen = () => {
    setMode("Add");    
    setFormValues(frmVal);
    setOpen(true);
  };

  
  const handleClickEditOpen = (item) => {
    setMode("Edit");    
    setFormValues(item);
    setOpen(true);
  };


  
  const handleClose = () => {
    setOpen(false);
  };

  
  const loadTags = async (e) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "tags",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        setTags(registerRequest.data);
        
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  

  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleTags = async (e) => {
    e.preventDefault();

    let formValue = formValues;
    formValue["admin"] = user && user.admin;
    formValue["createdOn"] = new Date();

    let registerRequest;
    try {
      registerRequest = await API_POST("action/addRecord", {
        root: "tags",
        body: formValue,
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadTags(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const handleEditTags = async () => {
 
    debugger;
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];
    
    if(! await tagUse(formValues)){
         return true;
    }

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "tags",
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadTags();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  useEffect(() => {}, [count]);

  useEffect(() => {
    if (user) {      
      loadTags();
    }
  }, [user]);

  const selectfrmField = (_id) => {
    let returnVal = {};
    if (customFields && customFields.length > 0) {
      customFields.forEach((element) => {
        if (element._id == _id) returnVal = element;
      });
    }
    return returnVal;
  };


  const tagUse = async(item) =>{
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "prospects",
        con: {admin:user && user.admin,tags:{$elemMatch:{tag_value:item.tag_value}}},
      });

      if (registerRequest && registerRequest.status == 200) {
        if(registerRequest.data && registerRequest.data.length){
          alert("Tag is in use");
          return false;
        }
        return true;

      }else{
        return true;
      }


    } catch (error) {
      console.log(error);
      return false;
      //registerRequest = response;
    }

  }

  const deleteRecord = async (item) => {

    
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    formValue["isActive"] = false;
    delete formValue["_id"];

    console.log(_id);

    let registerRequest;
    try {
       
      if(! await tagUse(item)){
        return true;
      }

      registerRequest = await API_POST("action/update", {
        root: "tags",
        body: {isActive:false},
        querydata: { admin:user && user.admin,_id:item._id },
      });

      if (registerRequest && registerRequest.status == 200) {
        loadTags();
      }


    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }

  };

  const restoreRecord = async (item) => {

    
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    formValue["isActive"] = false;
    delete formValue["_id"];

    let registerRequest;
    try {
       
      if(! await tagUse(item)){
        return true;
      }

      registerRequest = await API_POST("action/update", {
        root: "tags",
        body: {isActive:true},
        querydata: { admin:user && user.admin,_id:item._id },
      });

      if (registerRequest && registerRequest.status == 200) {
        loadTags();
      }


    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }

  };

  const tagNode = (item) => {
    return (
      <li className={classes.Tags}>
        <span>
          <i class="fa fa-minus" aria-hidden="true" />
          &nbsp;
          {item.tag_value} ( {item._id} )      
        </span>
        <span style={{float:"right"}}>
        {
          <Button
          color={"transparent"}
          justIcon={true}
          simple={false}
          aria-label="Edit"
          onClick={(e) => handleClickEditOpen(item)}
          >
          <i class="fa fa-pencil-square-o" aria-hidden="true" />
          </Button>
         }
         {

                     item.isActive === false ? (
                      <Button size="sm" color="primary" onClick={(e)=>restoreRecord(item)}>
                        {" "}
                        Restore
                      </Button>     

                    ):(
                      <Button size="sm" color="danger" onClick={(e)=>deleteRecord(item)}>
                        {" "}
                        Delete
                      </Button>     
                    )                              
         }
        </span>        
      </li>
    );
  };

  const TagList = (Tags) => {
    return (
      <ul style={{ paddingLeft: "15px" }}>
        {Tags.map((item, index) => {
          return tagNode(item);
        })}
      </ul>
    );
  };

  return (
    <>
    <Snackbar
    place="tr"
    color={notify.color}
    message={notify.message}
    open={notify.open}
    closeNotification={() => setNotify({ open: false })}
    close
  />
    <GridContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {mode} Tag
          <GridItem style={{ float: "right", marginTop: "10px" }}>
            <InfoBox name={'Form.Tags.Add'} />
          </GridItem>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Tag Value *"
                id="tag_value"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.tag_value,
                  name: "tag_value",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            
            
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={mode === "Add" ? handleTags : handleEditTags}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <GridItem xs={12} sm={6} md={6}>
        <Card>
          <CardHeader color="white">
            <h3>
              List Tags
              <Button
                variant="outlined"
                color="info"
                size="sm"
                style={{ float: "right" }}
                onClick={handleClickOpen}
              >
                + Add
              </Button>
              <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Forms.Tags'} />
              </GridItem>
            </h3>
          </CardHeader>
          <CardBody>
            {tags && tags.length > 0
              ? TagList(tags)
              : null}
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={6}>
        <ViewTags
          user={user}   
        />
       
      </GridItem>
    </GridContainer>
    </>
  );
  
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Tags);
