/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "../../services/api.service";
import Typography from '@material-ui/core/Typography';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';


import Card from "components/Card/Card.jsx";
import EmailTemplateList from './EmailTemplateList';
import SMSTemplateList from './SMSTemplateList';
import WhatsAppTemplateList from './WhatsAppTemplateList';


class Templates extends React.Component {

  constructor(props) {

    super(props);
    this.state = {


      activeTab: 0,
      cid: ""
    };
  }

  componentWillMount() {

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    this.setState({ activeTab: parseInt(tab) });
  }



  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTab: value });
    history.push('?tab=' + value)
  };

  render() {
    const { user } = this.props;


    return (

      <GridContainer>


        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            TabProps={{
              onChange: this.handleChange,
            }}
            selectedValue={this.state.activeTab}
            headerColor="info"
            tabs={[
              {
                tabName: "Email Templates",
                tabContent: (
                  <ErrorBoundary name="EmailTemplates">
                    <EmailTemplateList
                      user={user}
                      history={this.props.history}
                    />
                  </ErrorBoundary>
                ),
              },
              {
                tabName: "SMS Templates",
                tabContent: (
                  <ErrorBoundary name="SMSTemplates">
                    <SMSTemplateList
                      user={user}
                      history={this.props.history}>
                    </SMSTemplateList>
                  </ErrorBoundary>
                ),
              },
              {
                tabName: "WhatsApp Templates",
                tabContent: (
                  <ErrorBoundary name="EmailTemplates">
                    <WhatsAppTemplateList user={user}
                      history={this.props.history}></WhatsAppTemplateList>
                  </ErrorBoundary>
                ),
              },

            ]}
          />
        </GridItem>
      </GridContainer>

    );
  }
}
export default withStyles(dashboardStyle)(Templates);
