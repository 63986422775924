import React, { useRef, useEffect, useState } from 'react';
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_POST } from "../../services/api.service";

import SettingsIcon from "@material-ui/icons/Settings";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "components/Snackbar/Snackbar.jsx";

function EmailConfiguration(props) {
  const { classes, user } = props;

  let frmVal = {

    mailing_method: "gmail",
    smtp_server: "smtp.gmail.com",
    smtp_port: "587",
    smtp_username: "",
    smtp_password: "",
    secure_connection: "ssl",
    connection_timeout: "30",
    testemailid: "",
    configName: "email",
    admin: user && user.admin,
  };


  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(frmVal);
  const [customFields, setCustomFields] = useState([]);
  const [count, setCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pageLoad = async () => {
    let registerRequest;
    try {
      if (user && user.admin) {
        registerRequest = await API_POST("action/getdata", {
          root: "Configuration",
          con: { configName: "email", admin: user && user.admin },
          cols: {},
        });
        if (
          registerRequest &&
          registerRequest.status == 200 &&
          registerRequest.data &&
          registerRequest.data.length > 0
        ) {
          setFormValues(registerRequest.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const handleAddField = async (e) => {
    debugger;
    e.preventDefault();
    let formValue = formValues;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];
    try {
      let registerRequest = await API_POST("action/upsert", {
        root: "Configuration",
        body: formValue,
        querydata: { configName: "email", admin: user && user.admin },
      });

      console.log(registerRequest);

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(formValue);
        //loadCustomFields(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendMail = async (e) => {
    e.preventDefault();
    let formValue = formValues;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();

    try {
      let registerRequest = await API_POST("email/sendEmailAPI", {
        querydata: { configName: "email", admin: user && user.admin },
        subject: "Test mail subject",
        mailbody: "Test mail body"
      });
      debugger;
      console.log(registerRequest);
      if (registerRequest && registerRequest.success) {
        setNotify({
          open: true,
          message: "Email send successfully.",
          color: "success",
        });
        //handleClose();
      }
      else {
        setNotify({
          open: true,
          message: registerRequest.messages,
          color: "danger",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      pageLoad();
    }
  }, [user]);

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      {["manager", "owner"].indexOf(user && user.role) > -1 && (
        <Button
          size="sm"
          color="transparent"
          justIcon
          title="Email Configuration"
          onClick={() => {
            setOpen(true);
          }}
        >
        </Button>
      )}

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Mailing Method *"
                id="mailing_method"
                error={errors && errors.mailing_method}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.mailing_method,
                  name: "mailing_method",
                  type: "select",
                  onChange: handleChange,
                  options: [
                    {
                      key: "smtp",
                      value: "SMTP",
                    },
                    {
                      key: "gmail",
                      value: "GMAIL",
                    },
                  ],
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="SMTP Server *"
                id="smtp_server"
                error={errors && errors.smtp_server}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.smtp_server,
                  name: "smtp_server",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="SMTP Port *"
                id="smtp_port"
                error={errors && errors.smtp_port}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.smtp_port,
                  name: "smtp_port",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="SMTP UserName *"
                id="smtp_username"
                error={errors && errors.smtp_username}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.smtp_username,
                  name: "smtp_username",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="SMTP Password *"
                id="smtp_password"
                error={errors && errors.smtp_password}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.smtp_password,
                  name: "smtp_password",
                  onChange: handleChange,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Secure Connection *"
                id="secure_connection"
                error={errors && errors.secure_connection}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.secure_connection,
                  name: "secure_connection",
                  type: "select",
                  onChange: handleChange,
                  options: [
                    {
                      key: "none",
                      value: "None",
                    },
                    {
                      key: "ssl",
                      value: "SSL",
                    },
                    {
                      key: "starttls",
                      value: "STARTTLS",
                    },
                  ],
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Connection Timeout *"
                id="connection_timeout"
                error={errors && errors.connection_timeout}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.connection_timeout,
                  name: "connection_timeout",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Test Email ID *"
                id="testemailid"
                error={errors && errors.testemailid}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                  defaultValue: formValues && formValues.testemailid,
                  name: "testemailid",
                  onChange: handleChange,
                }}
              />
            </GridItem>


            <GridItem xs={12} sm={12} md={12} />
          </GridContainer>

          <h7>
                Please do not use Two-Factor authentication.
          </h7>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddField} color="primary">
            Save
          </Button>
          <Button
            onClick={sendMail}
            color="primary"
          >
            Test
          </Button>

    </>
  );
}

export default EmailConfiguration;
