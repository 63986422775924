import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts

// import { Chart } from "react-google-charts";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons


// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";


import _ from "underscore";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { API_GET } from "../../services/api.service";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ProspectWise from "./ProspectWise";
import CallDetailWise from "./CallDetailWise";
import CallTracker from "../Reports/CallTracker";
import ErrorBoundary from "../../hoc/ErrorBoundary/ErrorBoundary";
import {Tab, Tabs} from "@material-ui/core";
import Box from "./Box";
import InfoBox from "./InfoBox";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";




class Dashboard extends React.Component {

  state = {
    value: 0,
    aggData: null,
    activityData: [['Task', 'per Day']],
    startDate: (new Date()).setHours(0, 0, 0, 0),
    endDate: (new Date()).setHours(23, 59, 59, 0),
    isReload: 0,
    activeTab: 0,
  };



  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTab: value });
    history.push('?tab=' + value)
  };



  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    this.setState({ activeTab: parseInt(tab) });
  }


  


  render() {
    const { user,persona,configSettings } = this.props;
    console.log("Dashboard user", user)
    const { startDate, endDate, isReload } = this.state;
   

    const followupQuery = {

      admin:user && user.admin

    }
    const callDetails = {

      admin:user && user.admin

    }

    return (
      <div>
        <GridContainer>
         
          <GridItem xs={12} sm={12} md={12}>

            <CustomInput
              style="dashboard"
              id="startdate"
              labelProps={{ shrink: true }}
              formControlProps={{
                fullWidth: true,
                multiline: true
              }}

              inputProps={{
                type: 'date_range',
                initialDateRange: {
                  startDate: this.state.startDate,
                  endDate: this.state.endDate
                },
                maxDate: new Date(),
                onChange: (e) => {
                  console.log(e)
                  this.setState({ startDate: e.startDate, endDate: e.endDate });
                },
                showFetch: true,
                onClick: () => {
                  this.setState({ isReload: isReload + 1 });
                  

                }
              }}
            />
            

          </GridItem>

         {/*

          <Card>
            <CardHeader>
              <h3> Storage Information </h3>
              <p> 1 Record takes 1500 Byte</p>
          <GridContainer>
          <GridItem xs={12} sm={4} md={2}>
              <InfoBox
                  label={"Total Prospects"}
                  icon={"fa-solid fa-database fa-4x"}
                  root="prospects"
                  color="info"
                  query={{ ...followupQuery }}
                  user={user}
                  small={false}
                  isReload={isReload}
              />
          </GridItem>
          <GridItem xs={12} sm={4} md={2}>
            <InfoBox
                label={"Total Calldetails"}
                icon={"fa-solid fa-database fa-4x"}
                root="CallDetails"
                color="info"
                query={{ ...followupQuery }}
                user={user}
                small={false}
                isReload={isReload}
            />
          </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <InfoBox
                  label={"Total Call History"}
                  icon={"fa-solid fa-database fa-4x"}
                  root="CallHistory"
                  color="info"
                  query={{ ...followupQuery }}
                  user={user}
                  small={false}
                  isReload={isReload}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <InfoBox
                  label={"Total Schedule History"}
                  icon={"fa-solid fa-database fa-4x"}
                  root="ScheduleHistory"
                  color="info"
                  query={{ ...followupQuery }}
                  user={user}
                  small={false}
                  isReload={isReload}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <InfoBox
                  label={"Total Call Recordings"}
                  icon={"fa-solid fa-database fa-4x"}
                  root="CallRecording"
                  color="info"
                  query={{ ...followupQuery }}
                  user={user}
                  small={false}
                  isReload={isReload}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <InfoBox
                  label={"Total Recording Size"}
                  icon={"fa-solid fa-database fa-4x"}
                  root="CallRecording"
                  calculate={true}
                  color="info"
                  query={{ ...followupQuery }}
                  user={user}
                  small={false}
                  isReload={isReload}
              />
            </GridItem>
          </GridContainer>
            </CardHeader>
          </Card>

*/}



          <GridItem xs={12} sm={12} md={12}>

        {

        persona && persona == 'Dialling' ?
            <>

           <CustomTabs
              title=""
              TabProps={{
                onChange: this.handleChange,
              }}
              selectedValue={this.state.activeTab}
              headerColor="info"
              tabs={[
                {
                  tabName: "Call Detail Wise",
                  tabContent: (
                    <ErrorBoundary name="CallDetailWise">
                      <CallDetailWise
                        user={user}
                        configSettings = {configSettings}

                        startDate={startDate}
                        endDate={endDate}
                        isReload={isReload}
                      />
                    </ErrorBoundary>
                  ),
                }


              ]}


            />
            </> :
            <>
            <CustomTabs
              title=""
              TabProps={{
                onChange: this.handleChange,
              }}
              selectedValue={this.state.activeTab}
              headerColor="info"
              tabs={[
                {
                  tabName: "Call Detail Wise",
                  tabContent: (
                    <ErrorBoundary name="CallDetailWise">
                      <CallDetailWise
                        user={user}
                        configSettings = {configSettings}
                        startDate={startDate}
                        endDate={endDate}
                        isReload={isReload}
                      />
                    </ErrorBoundary>
                  ),
                },
                {
                  tabName: "Prospect Wise",
                  tabContent: (
                    <ErrorBoundary name="ProspectWise">                      
                      <ProspectWise
                        user={user}                       
                        history={this.props.history}
                        startDate={startDate}
                        endDate={endDate}
                        isReload={isReload}
                      />
                    </ErrorBoundary>
                  ),
                },

                
                // {
                //   tabName: "Call Tracker",
                //   tabContent: (
                //     <ErrorBoundary name="CallDetailWise">
                //       <CallTracker
                //         user={user}                                         
                //         startDate={startDate}
                //         endDate={endDate}
                //         isReload={isReload}
                //       />
                //     </ErrorBoundary>
                //   ),
                // }

              ]}


            />

            </>


        }


          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
