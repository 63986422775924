import React, { Suspense, useRef, useEffect, useState } from "react";
import PropTypes, { array } from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";


import ErrorBoundary from "../../hoc/ErrorBoundary/ErrorBoundary";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ProspectWise from "../Dashboard/ProspectWise";
import CallDetailWise from "../Dashboard/CallDetailWise";



import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


import axios from "axios";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function CRMView(props) {

  const { user, history } = props;
  const [ admin, setAdmin ] = useState(props.admin?props.admin:props.match.params.admin);

  const [activeTab, setActiveTab] = useState(0);
  const [isReload, setIsReload] = useState(0);
 
  const startDate = (new Date()).setHours(0, 0, 0, 0);
  const endDate = (new Date()).setHours(23, 59, 59, 0);
  
  const handleChange = (event, value) => {
    const { history } = props;
    setActiveTab(value);
    history && history.push('?tab=' + value)
  };

 

  return (
    <>


      <GridContainer>
        <GridItem xs={12} sm={12} md={12} >

          <CustomTabs
            title=""
            TabProps={{
              onChange: handleChange,
            }}
            selectedValue={activeTab}
            headerColor="info"
            tabs={[
              {
                tabName: "Call Detail Wise",
                tabContent: (
                  <ErrorBoundary name="CallDetailWise">
                   
                    <CallDetailWise
                        user={{admin}}
                                         
                        startDate={startDate}
                        endDate={endDate}
                        isReload={isReload}
                      />
                  </ErrorBoundary>
                ),
              },
              {
                tabName: "Prospect Wise",
                tabContent: (
                  <ErrorBoundary name="ProspectWise">
                    
                    <ProspectWise
                        user={{admin}}                    
                        history={props.history}
                        startDate={startDate}
                        endDate={endDate}
                        isReload={isReload}
                      />
                  </ErrorBoundary>
                ),
              },


              // {
              //   tabName: "Call Tracker",
              //   tabContent: (
              //     <ErrorBoundary name="CallDetailWise">
              //       <CallTracker
              //         user={user}                                         
              //         startDate={startDate}
              //         endDate={endDate}
              //         isReload={isReload}
              //       />
              //     </ErrorBoundary>
              //   ),
              // }

            ]}
          />

        </GridItem>
      </GridContainer>

    </>
  );
}

CRMView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(CRMView);
