import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { searchVal } from "../../store/Actions/actions";
import { connect } from "react-redux";
import Clock from "../Clock/Clock";
import history from "../../history";
import "./Styles/Navbar.css";
import { ButtonGroup } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { getToken,removeToken,setToken } from "../../services/Common";
import TimeLine from "./TimeLine";

const { REACT_APP_SERVER_URL } = process.env;

class SearchBox extends React.Component {
  state = {
    open: false,
    profilePopupOpen: false,
    batteryCss: "fa fa-battery-0",
    time: "00:00:00",
    amPm: "am",
    SearchBox: "",
    isMobile: (window.innerWidth <= 760),
    blink:false
  };

  userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));



  handleToggle = () => {
    this.setState((state) => ({ open: !state.open, profilePopupOpen: false }));
  };

  handleToggleProfile = () => {
    this.setState((state) => ({
      profilePopupOpen: !state.profilePopupOpen,
      open: false,
    }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false, profilePopupOpen: false });
  };

  logout = async () => {
    const { history } = this.props;
    //localStorage.removeItem("token");
    //
    removeToken();
    localStorage.clear();
    
    history.push("/auth/login-page");
    
    try {
      window.logout.performClick();
    }
    catch (e) { }
  };

  async componentDidMount() {

    setInterval( ()=> {
      this.setState({
        blink: !this.state.blink
      });
    },1000);

    let that = this;
    let isBatterySupported = "getBattery" in navigator;
    if (!isBatterySupported) {
      console.log("Battery not supported");
    } else {
      let batteryPromise = navigator.getBattery();
      batteryPromise.then(batteryCallback);

      function batteryCallback(batteryObject) {
        printBatteryStatus(batteryObject);

        batteryObject.addEventListener("chargingchange", function (event) {
          printBatteryStatus(batteryObject);
        });
        batteryObject.addEventListener("levelchange", function (event) {
          printBatteryStatus(batteryObject);
        });
      }
      function printBatteryStatus(batteryObject) {
        // console.log("IsCharging", batteryObject.charging);
        // console.log("Percentage", batteryObject.level);
        // console.log("charging Time", batteryObject.chargingTime);
        // console.log("DisCharging Time", batteryObject.dischargingTime);

        if (batteryObject.charging) {
          that.setState({ batteryCss: "fa fa-battery-4" });
        } else {
          if (batteryObject.level < 0.25) {
            that.setState({ batteryCss: "fa fa-battery-0" });
          } else if (batteryObject.level < 0.5) {
            that.setState({ batteryCss: "fa fa-battery-1" });
          } else if (batteryObject.level < 0.75) {
            that.setState({ batteryCss: "fa fa-battery-2" });
          } else if (batteryObject.level < 1.0) {
            that.setState({ batteryCss: "fa fa-battery-3" });
          }
        }
      }
    }

    //this.getTime()
  }

  getTime() {
    const takeTwelve = (n) => (n > 12 ? n - 12 : n),
      addZero = (n) => (n < 10 ? "0" + n : n);

    setInterval(() => {
      let d, h, m, s, t, amPm;

      d = new Date();
      h = addZero(takeTwelve(d.getHours()));
      m = addZero(d.getMinutes());
      s = addZero(d.getSeconds());
      t = `${h}:${m}:${s}`;

      amPm = d.getHours() >= 12 ? "pm" : "am";

      this.setState({
        time: t,
        amPm: amPm,
      });



    }, 1000);
  }

  handleChange = (e) => {
    this.setState({ SearchBox: e.target.value });
  };

  getProspects = (e) => {
    if (e && e.key === "Enter") {
      this.getProspectDetails();
    }
  };
  getProspectDetails = (e) => {
    if (this.state.SearchBox) {
      this.props.setSearchValToRedux(this.state.SearchBox);
      this.hanldeSwitchPage();
    }
    // this.setState({SearchBox:""});
  };

  hanldeSwitchPage = () => {
    const { user } = this.props;
    const { history } = this.props;
    let query;
    query = {
      // $or: [
      //   { phone: { $regex: this.state.SearchBox } },
      //   { firstName: { $regex: this.state.SearchBox } },
      // ],
      $and: [
        //{ admin: user && user.admin },
        {
          //$or: [{ IsActive: { $exists: false } }, { IsActive: true }],
          phone: this.state.SearchBox,
        },
      ],
    };

    let search = btoa(JSON.stringify(query));

    history.push({
      pathname: `/admin/ListProspects`,
      search: `Search=${search}`,
    });
  };

  handleChnagePass = () => {
    const { history } = this.props;
    history.push("/admin/ChangePassword");
  };



  render() {
    const { classes, user } = this.props;


    const { open, profilePopupOpen, batteryCss, time, amPm,isMobile,blink } = this.state;

    return (
      <div className="navbar__search">
        {/* <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={false}
          simple={!(window.innerWidth > 959)}
          aria-label="Time"
          className={classes.buttonLink}
        >
          <b aria-hidden="true">{time} {amPm}</b>
        </Button> */}
        <GridContainer>
          <GridItem xs={8} sm={8} md={8}>
            <CustomInput
              formControlProps={{
                className: classes.margin + " ",
                style: { width: '100%' }
              }}
              inputProps={{
                placeholder: "Search for MobileNo and press enter",
                onChange: (event) => this.handleChange(event),
                onKeyDown: this.getProspects,
                inputProps: {
                  "aria-label": "Search1",
                },
                value: this.state.SearchBox,
              }}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <Button color="white" aria-label="edit" justIcon={true} round>
              <Search onClick={() => this.getProspectDetails()} />
            </Button>
          
          </GridItem>

      

          {
            user && user.role=='owner' && !isMobile && blink?
            (
             <>
               <GridItem xs={2} sm={12} md={2}>
                 <a href="/admin/support" >
                   <Button  size="sm" color="primary" style={{fontSize:'18px',width:'150px',height:'40px',color:"white",background:'red',fontWeight:'bold'}}>
                    Raise a Ticket
                   </Button>
                 </a>
               </GridItem>
             </>
            ):null

          }



          {/* <GridItem xs={2} sm={2} md={2}> */}


            {/* <Button
              color={"transparent"}
              justIcon={true}

              aria-label="Battery"
              className={classes.buttonLink}
            >
              <i className={batteryCss} aria-hidden="true" />
            </Button> */}

          {/* </GridItem> */}
        </GridContainer>
        {/* <div className={`${classes.searchWrapper} search__bar`}>


        </div> */}
        {/* 
        <Clock />        */}



        {/*        
       
        <div className={classes.manager}>
          <Button
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Person"
            aria-owns={profilePopupOpen ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleProfile}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          
        </div> */}
      </div>
    );
  }
}
const mapstatetoProps = (state) => {
  return {};
};

//add dispatch to props
const mapDispatchtoProps = (dispatch) => {
  return {
    setSearchValToRedux: (value) =>
      dispatch(searchVal({ SearchBoxVal: value })),
  };
};

//export default withStyles(headerLinksStyle)(HeaderLinks);

export default connect(
  mapstatetoProps,
  mapDispatchtoProps
)(withStyles(headerLinksStyle)(SearchBox));
