import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import InfoIcon from "@material-ui/icons/Info";

const InfoBox = ({ name }) => {
  const [informationLink, setInformationLink] = useState('');

  // Retrieve information link from local storage based on the provided name
  useEffect(() => {
    const infoLinkData = JSON.parse(localStorage.getItem('infoLinkData'));
    if (infoLinkData && infoLinkData[name]) {
      setInformationLink(infoLinkData[name].infoLink);
    }
  }, [name]);

  return (
    <>
      { informationLink && (
        <Box display="flex" alignItems="center">
          <InfoIcon color="error" fontSize="small" />
          <Typography variant="body1" style={{ color: 'red', display: 'flex', alignItems: 'center', fontSize: '13px' }}>
            <a href={informationLink} target='_blank' style={{ color: 'red' }}>
              Information
            </a>
          </Typography>
        </Box>
      )}
    </>
  );
};
export default InfoBox;
