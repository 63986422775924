import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Face from "@material-ui/icons/Face";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneIcon from "@material-ui/icons/Phone";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import registerPageStyle from "assets/jss/material-dashboard-react/views/registerPageStyle.jsx";
import logo from "assets/img/logo-dark.png";
//import login from "assets/img/login.jpeg";
import { API_GET, API_POST } from "../../services/api.service";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//import history from 'history.js';

const { REACT_APP_SERVER_URL } = process.env;

class RegisterPage extends React.Component {
  constructor(props) {
    const searchParams = new URLSearchParams(document.location)
    let referIdParse = props.location.search.split("=");
    let refer;
    if (referIdParse[0] = "?referId") {
      refer = referIdParse[1];
    }
    console.log("searchParams", searchParams, refer)
    super(props);
    this.state = {
      setNotify: {
        open: false,
        message: null,
        color: "success",
      },
      referId: refer,
      checked: [],
      enteredOtp: null,
      errors: {},
      isVerified: false,
      CompanySizeVal: "",
      companysourceVal: "",
      leadsourceVal: [],
      otpShow: false,
      otpPhone: null,
      otpValue: null,
      formValues: {},
      otpSend: false,
      otpVerified: false,
      otpResendEnable: true,
      fieldMessage: {
        phone: { msg: '', type: '' },
        email: { msg: '', type: '' },
      },
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      CompanySize: [
        { key: "< 5 employees", value: "< 5 employees" },
        { key: "5 - 20 employees", value: "5 - 20 employees" },
        { key: "20 - 50 employees", value: "20 - 50 employees" },
        { key: "50 - 250 employees", value: "50 - 250 employees" },
        { key: "> 250 employees", value: "> 250 employees" },
      ],
      steps: [
        'Registration',
        'User Details',
      ],
      SignupSource: [
        { key: "Facebook", value: "Facebook" },
        { key: "Linkedin", value: "Linkedin" },
        { key: "Google Search", value: "Google Search" },
        { key: "Reference", value: "Reference" },
        { key: "Marketing", value: "Marketing" },
        { key: "Sales Team", value: "Sales Team" },
      ],
      LeadSourceOption: [
        { key: "Open Database", value: "Open Database" },
        { key: "Existing Leads", value: "Existing Leads" },
        { key: "Website", value: "Website" },
        { key: "Social Media", value: "Social Media" },
        { key: "B2B Portals", value: "B2B Portals" },
        { key: "Push Marketing", value: "Push Marketing" },
        { key: "Incoming Queries", value: "Incoming Queries" },
        { key: "Customer Visits", value: "Customer Visits" },
      ],
      activeStep: 0,
    };
    document.title = "Sign up";
  }




  register = async (e) => {
    e.preventDefault();
    debugger;
    const { history } = this.props;

    const fields = ["name", "company", "email", "phone", "password", "CompanySize", "SignupSource", "ReferBy", "LeadSource"];
    const formValues = this.state.formValues;
    formValues.LeadSource = this.state.leadsourceVal && this.state.leadsourceVal.length ? this.state.leadsourceVal.toString() : "";

    debugger;


    console.log(formValues);

    if (!this.state.otpVerified) {
      this.setState({
        notify: {
          open: true,
          message: "OTP Not Verified",
          color: "danger",
        },
      });
      return false;
    }

    let result = await this.ValiditeUser(formValues.email); debugger;


    if (!result) {
      debugger;
      let registerRequest;
      try {
        if (this.state.referId) {
          formValues.ReferBy = this.state.referId;
        }
        formValues.phoneVerified = true;
        registerRequest = await axios.post(`//${REACT_APP_SERVER_URL}/register`, {
          ...formValues,
        });


        //const fields = ["name", "company", "email", "phone", "password", "CompanySize", "SignupSource", "ReferBy", "LeadSource"];

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTEsImlhdCI6MTYzOTc2MjQ5MH0.J452xx2Ld9Mz5n6VC1NRyfWeWA8yitgKIaVtZ-2o2oc'
        }

        let data = {
          company: formValues.company,
          phone: formValues.phone,
          firstName: formValues.name,
          email: formValues.email,
          password: formValues.password,
          CompanySize: formValues.CompanySize,
          SignupSource: formValues.SignupSource,
          ReferBy: this.state.referId,
          LeadSource: formValues.LeadSource,
          cid: "60290041f6a71780b4700371",
          admin: "mark@gmail.com"
        }

        debugger;
        try {
          let signupdata = await axios.post(`//${REACT_APP_SERVER_URL}/action/addProspects`, data, {
            headers: headers
          });


          // // markaccount lead
          // data.admin = 'mark@gmail.com';
          // data.cid = '60290041f6a71780b4700371';
          // await axios.post(`//${REACT_APP_SERVER_URL}/action/addProspects`, data, {
          //   headers: headers
          // });

        }
        catch (e) {
          console.log(e);
        }

        //Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTEsImlhdCI6MTYzOTc2MjQ5MH0.J452xx2Ld9Mz5n6VC1NRyfWeWA8yitgKIaVtZ-2o2oc
        //web.betyphon.in/api/action/addProspects
        //{ "company": "<company>","phone": "<phone>","firstName": "<firstName>","admin id": "<admin id>","password": "<password>","signupdate": "<signupdate>","users": "<users>","companysize": "<companysize>","signupsource": "<signupsource>","leadsource": "<leadsource>","Demo given by": "<Demo given by>","Account Expired": "<Account Expired>","feedback": "<feedback>","Total Users": "<Total Users>","Industry": "<Industry>","State": "<State>","Website": "<Website>","cid": "61bcc8f1645134002079a5df","admin": "accounts@techmet.in" }


      } catch ({ response }) {
        registerRequest = response;
      }
      const { data: registerRequestData } = registerRequest;
      if (registerRequestData.success) {
        this.setState({ activeStep: 1 });
        return history.push("/auth/login-page?msg=Congratulation ! Your account has been successfully created, Please check your email to account confirmation.");
      }
    }
    else {
      debugger;
      this.setState({
        notify: {
          open: true,
          message: "email id already exist. Please login with your credential.",
          color: "danger",
        },
      });
    }
  };


  verifyPhone = async (data) => {


    console.log("ddd", data)
    let registerRequest = await API_GET(
      `register/authenticatePhone?phone=${data}`
    );
    if (registerRequest && registerRequest.data.user) {
      this.setState({ fieldMessage: { phone: { msg: 'Phone already used in the system', type: 'danger' } } });
      return true;

    } else {
      this.setState({ fieldMessage: { phone: { msg: '', type: '' } } });
      return false;
    }


  }

  formSubmit = async (e) => {

    e.preventDefault();
    debugger;
    const { history } = this.props;

    const fields = ["name", "company", "email", "phone", "password", "CompanySize", "SignupSource", "ReferBy", "LeadSource"];
    const formElements = e.target.elements;
    debugger;
    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value.trim(),
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    this.setState({ formValues: formValues });
    let result = await this.ValiditeUser(formValues.email);

    if (result) {
      this.setState({
        notify: {
          open: true,
          message: "Email/Username already used in the system",
          color: "danger",
        }
      });
      return true;

    }
    let verifyPhone = await this.verifyPhone(formValues);
    if (verifyPhone) {
      this.setState({
        notify: {
          open: true,
          message: "Phone already used in the system",
          color: "danger",
        }
      });
      return true;
    }
    this.otpToogle();
    this.sendOtp();

  }


  ValiditeUser = async (value) => {

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value.match(mailformat)) {
      return false;
    }

    let registerRequest;
    registerRequest = await API_GET(
      `register/authenticateUser?user=${value}`
    );
    if (registerRequest && registerRequest.data.user) {
      this.setState({
        errors: { ...this.state.errors, username: true },
        fieldMessage: { email: { msg: 'Email/User Already Exist', type: 'danger' } }
      });
      return true;
    } else {
      this.setState({
        errors: { ...this.state.errors, username: false },
        fieldMessage: { email: { msg: '', type: 'primary' } }
      });
      return false;
    }
  };

  otpToogle = () => {

    const { otpShow } = this.state;

    if (otpShow) {
      this.setState({
        otpShow: false,
      });
    } else {
      this.setState({
        otpShow: true,
      });
    }


  }


  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleLogin = async (e) => {
    const { history } = this.props;
    return history.push("/auth/login-page");
  };
  handleChangeLeadSource = async (e) => {
    debugger;
    console.log(e.target.value);
    this.setState({ leadsourceVal: e.target.value });
    //this.setState({...this.state.formValues,LeadSource: e.target.value })
  };

  verifyOtp = async (e) => {

    let formValues = this.state.formValues;

   let res =  await API_POST("commPublic/otpProcess", {type:'verify',phone:formValues.phone,"userEntered":e.target.value});

    if (res.data.verify) {
      this.setState({
        notify: {
          open: true,
          message: "Otp Verified",
          color: "success",
        },
        fieldMessage: { phone: { msg: 'Otp Verified', type: 'primary' } },
        otpSend: false, 
        otpVerified: true, 
        errors: { ...this.state.errors, otpcode: false },
      });

    } else {
      this.setState({
        notify: {
          open: true,
          message: "Wrong Otp",
          color: "danger",
        },
        errors: { ...this.state.errors, otpcode: true }
      });

    }

  }
  setOtpNumber = async (e) => {

    let formValues = this.state.formValues;

    formValues[e.target.name] = e.target.value;

    //this.setState({formValues:formValues, otpPhone: e.target.value,otpValue:Math.floor(1000 + Math.random() * 9000) });
  }

  setForm = async (e) => {

    let formValues = this.state.formValues;

    console.log("old form data", formValues);
    console.log("new", e.target.value);
    formValues[e.target.name] = e.target.value;



    this.setState({ formValues: formValues });
    if (e.target.name == 'email') {
      this.ValiditeUser(formValues.email);
    }
    if (e.target.name == 'phone') {
      this.sendOtp();
    }

  }

  sendOtp = async () => {
    let formValues = this.state.formValues;
    if (!formValues.phone) return true;

    //alert(formValues.phone.toString().length)
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!formValues.phone.match(phoneno) || formValues.phone.toString().length < 10) {
      return true;
    }

    let verifyPhone = await this.verifyPhone(formValues.phone);
    if (verifyPhone) {
      this.setState({
        notify: {
          open: true,
          message: "Phone already used in the system",
          color: "danger",
        },
        otpSend: false,
        errors: { ...this.state.errors, phone: true }
      });
      return true;
    }
    if (!this.state.otpResendEnable) {
      this.setState({
        notify: {
          open: true,
          message: "Otp can be send after 10 sec",
          color: "danger",
        }
      });
      return true
    }

    
    let message = "BTYPHN - Dear User, Your OTP is #OTP# " ;
    

    try {
      let html = message + ' for email: ' + formValues.email;
      var mailOptions = {
        phone:formValues.phone, 
        email:formValues.email, 
        type:["sms","email","whatsapp"] ,
        from: "betyphon.crm@gmail.com",
        to: "jitander.batra@techmet.in,support@techmet.in",
        subject: "New SignUp Verification OTP",
        message,
        html
      };
      let registerRequest = await API_POST("commPublic/sendMessages", {...mailOptions});
    } catch (e) {

    }


    this.setState({
      notify: {
        open: true,
        message: "Otp has been sent",
        color: "success",
      },
      fieldMessage: { phone: { msg: 'Otp Has been sent', type: 'primary' } },
      errors: { ...this.state.errors, phone: false },
      otpSend: true,
      otpResendEnable: false,
    });

    setTimeout(function () {
      this.setState({ otpResendEnable: true });
    }.bind(this), 10000)


  }

  setUserEnterOtp = async (e) => {

    this.setState({ enteredOtp: e.target.value });
  }


  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <>
        <Snackbar
          place="right"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />


        <div className={classes.container}>
          <GridContainer sm={12} justify="center">

            <GridItem xs={12} sm={6} md={8}>

              {
                <form onSubmit={this.register}>
                  <Card className={classes[this.state.cardAnimaton]}>
                    <CardHeader
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      color="transparent"
                    >
                      {/* <h4 className={classes.cardTitle}>Register</h4> */}
                      <div className={classes.socialLine}>
                        {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                        <img src={logo} />

                      </div>
                    </CardHeader>
                    <CardBody>

                      {/* step 1 */}

                      <center>
                        <h2>Registration Form</h2>
                      </center>

                      <GridContainer direction="row" sm={12} justify="center" rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <GridItem direction="row" xs={12} sm={12} md={6} >

                          <>
                            <CustomInput
                              labelText={"Mobile Number... "}
                              id="phone"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              error={errors.phone}
                              inputProps={{
                                onChange: this.setForm,
                                disabled: (this.state.otpSend || this.state.otpVerified),
                                required: true,
                                name: "phone",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PhoneIcon className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            {this.state.otpSend &&
                              (
                                <>

                                  <CustomInput
                                    labelText="Otp Code..."
                                    id="otpCode"
                                    style={{ width: '60%' }}
                                    formControlProps={{

                                    }}
                                    error={errors.otpcode}
                                    inputProps={{
                                      onChange: this.verifyOtp,
                                      required: true,
                                      name: "otpcode",
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          ***
                                        </InputAdornment>
                                      ),
                                    }}

                                  />
                                  <a href="javascript:void(0)" className={classes.formControlClassName} onClick={(e) => this.sendOtp()}>Resent</a>
                                </>
                              )
                            }

                            <CustomInput
                              labelText="Email... "
                              id="email"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              error={errors.username}
                              inputProps={{
                                onChange: this.setForm,
                                required: true,
                                type: "email",
                                name: "email",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Email className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <span> {this.state.fieldMessage['email'] && (this.state.fieldMessage['email']['type'] == 'danger' ? (<span style={{ color: 'red' }}> {this.state.fieldMessage['email']['msg']} </span>) : (<span style={{ color: 'green' }}> {this.state.fieldMessage['email']['msg']} </span>))}  </span>
                            <CustomInput
                              labelText="Password..."
                              id="password"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              error={errors.password}
                              inputProps={{
                                onChange: this.setForm,
                                required: true,
                                name: "password",
                                type: "password",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Icon className={classes.inputAdornmentIcon}>
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <CustomInput
                              labelText="Name..."
                              id="name"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              inputProps={{
                                onChange: this.setForm,
                                required: true,
                                name: "name",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Face className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                              }}
                            />





                          </>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>

                          <>

                            <CustomInput
                              labelText="Company Name..."
                              id="company"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              inputProps={{
                                onChange: this.setForm,
                                required: true,
                                name: "company",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BusinessIcon
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <CustomInput
                              labelText="Company Size"
                              id="CompanySize"
                              label="Outlined"
                              variant="outlined"
                              formControlProps={{
                                fullWidth: true,

                              }}
                              inputProps={{
                                onChange: this.setForm,
                                required: true,
                                name: "CompanySize",
                                type: "select",
                                options: this.state.CompanySize,
                                onChange: (e) => { this.setState({ formValues: { ...this.state.formValues, CompanySize: e.target.value } }) }

                              }}
                            />



                            <GridContainer direction="row" sm={12} justify="" rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Signup Source"
                                  id="SignupSource"
                                  formControlProps={{
                                    fullWidth: true,

                                  }}
                                  inputProps={{
                                    onChange: this.setForm,
                                    required: true,
                                    name: "SignupSource",
                                    type: "select",
                                    //onChange: this.handleChange,
                                    options: this.state.SignupSource,
                                    //onChange: (e) => { this.setState({ formValues:{ ...this.state.formValues,SignupSource:e.target.value,companysourceVal:e.target.value }}) }
                                    //onChange: (e) => { this.setState({ companysourceVal: e.target.value }) }
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="ReferBy..."
                                  id="ReferBy"
                                  formControlProps={{
                                    fullWidth: true,

                                  }}
                                  inputProps={{
                                    onChange: this.setForm,
                                    required: false,
                                    name: "ReferBy",
                                    value: this.state.referId,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Face className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GenerateField
                              value={this.state.leadsourceVal}

                              onChange={this.handleChangeLeadSource}
                              id={"LeadSource"}
                              name={"LeadSource"}
                              label={"Lead Source"}
                              type="checkbox_list"
                              keyValuePair={this.state.LeadSourceOption}
                            />
                          </>
                        </GridItem>



                      </GridContainer>

                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            type="submit"
                            color="primary"

                            size="lg"
                            block
                            variant="contained"

                          >
                            Submit
                          </Button>
                        </GridItem>

                      </GridContainer>
                    </CardFooter>
                  </Card>
                </form>


              }



            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(registerPageStyle)(RegisterPage);

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      cssclass,
      type = "select",
      disabled = false,
    } = this.props;

    return (

      <CustomInput
        labelText={label}
        id

        formControlProps={{
          fullWidth: true,
          multiline: true,
        }}
        customOptions={options}
        inputProps={{
          required: false,
          value,
          defaultValue: value,
          checked: value,
          name: id,
          id: id,
          type: type,
          onChange,
          disabled,
          options: keyValuePair,
        }}
      />

    );
  }
}
