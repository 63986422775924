import Axios from 'axios';
import { createBrowserHistory } from "history";
import { getToken, removeToken, setToken } from "./Common.js";
const history = createBrowserHistory();
const { REACT_APP_SERVER_URL } = process.env;
//const  REACT_APP_SERVER_URL  = '127.0.0.1:8001/api';
export const base_url = `//${REACT_APP_SERVER_URL}/`;
let axiosInstance = Axios.create({
    baseURL: base_url,
    header: {}
});



export const API_GET = (url, headerParam, params) => {

    const token = getToken();

    return new Promise((resolve, reject) => {
        //debugger;
        let headers = {}
        if (headerParam) {
            headers = { ...headerParam }
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        axiosInstance({
            method: 'get',
            url: url,
            headers,
            params
        }).then((res) => {
            console.log(url, res);

            if (res.status && res.status == 401) {
                removeToken()
                window.location.reload();
                resolve({ status: 401, message: "Unauthorized access" });
            } else {
                resolve(res && res.data)
            }
        }, (err) => {
            //debugger
            resolve(err && err.response && err.response.data);

            if (err && err.response && err.response.status == 401) {
                removeToken()
                window.location.reload();
            }
        })
    })
}
export const API_POST = (url, data , additionToken = null) => {
    const token = getToken();
    return new Promise((resolve, reject) => {
        //debugger;
        let headers = {}
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        if(additionToken){
            headers['Authorization'] = additionToken
        }
        axiosInstance({
            method: 'post',
            url: url,
            data: data,
            headers
        }).then((res) => {
            console.log(url, res);
            if (res.status && res.status == 401) {
                removeToken()
                window.location.reload();
                resolve({ status: 401, message: "Unauthorized access" });
            } else {
                resolve(res && res.data)
            }
        }, (err) => {
            if (err && err.response && err.response.status == 401) {
                removeToken();
                window.location.reload();
            }
        })
    })
}

export const API_PUT = (url, data) => {
    const token = getToken();
    return new Promise((resolve, reject) => {
        let headers = {}
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        axiosInstance({
            method: 'put',
            url: url,
            data: data,
            headers
        }).then((res) => {
            console.log(url, res);
            if (res.status && res.status == 401) {
                removeToken();
                window.location.reload();
                resolve({ status: 401, message: "Unauthorized access" });
            } else {
                resolve(res.data)
            }
        }, (err) => {
            reject(err.response.data);
            if (err && err.response && err.response.status == 401) {
                removeToken();
                window.location.reload();
            }

        })
    })
}

export const API_DELETE = (url) => {
    const token = getToken();

    return new Promise((resolve, reject) => {
        let headers = {}
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        axiosInstance({
            method: 'delete',
            url: url,
            headers
        }).then((res) => {
            if (res.status && res.status == 401) {
                removeToken();
                window.location.reload();
                resolve({ status: 401, message: "Unauthorized access" });
            } else {
                resolve(res.data)
            }
        }, (err) => {
            reject(err.response.data);
            if (err && err.response && err.response.status == 401) {
                removeToken();
                window.location.reload();
            }

        })
    })
}

export const API_UPLOAD = (url, data) => {
    const token = getToken();
    return new Promise((resolve, reject) => {

        let headers = { 'Content-Type': 'multipart/form-data' }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        axiosInstance({
            method: 'post',
            url: url,
            data: data,
            headers
        }).then((res) => {
            console.log(url, res);
            if (res.status && res.status == 401) {
                history.push("/auth/login-page");
                resolve({ status: 401, message: "Unauthorized access" });
            } else {
                resolve(res.data)
            }
        }, (err) => {
            reject(err.response.data);
            if (err && err.response && err.response.status == 401) {
                removeToken()
                window.location.reload();
            }
        })
    })
}

export const userRoles = {
    agent: 'Tele Calling Agent',
    field_exc: 'Field Executive',
    manager: 'Manager',
    owner: 'Owner',
}

export const InviteeStatus = {
    '1': 'Pending',
    '2': 'Send',
}

export const userStatus = ["Demo account", "Paid account", "Paid expired", "Duplicate", "Internal account"];