import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// import ReactPaginate from 'react-paginate';
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import DataTableMobileStyle from "assets/jss/material-dashboard-react/components/DataTableMobileStyle.jsx";

class DataTableMobileRow extends React.Component {
  state = {
    value: 0
  };

  render() {
    const {

      columns,
      row,
    } = this.props;
    let inHeader = false;
    let inFooter = false;

    for (let index = 0; index < columns.length; index++) {
      const col = columns[index];
      if (col.inHeader) {
        inHeader = true;
      }
      if (col.inAction) {
        inFooter = true;
      }
    }


    return (
      <Card className="datatableresponsive">
        {inHeader && <CardHeader>
          <ul>
            {
              columns && columns.map((col, key) => {
                if (col.inHeader) {
                  if (col.cell) {
                    return <li><label>{col.name}</label> <span>{col.cell(row)}</span></li>
                  }
                  else {
                    return <li><label>{col.name}</label> <span>{row[col.selector]}</span></li>
                  }
                }
              })
            }
          </ul>
        </CardHeader>}
        <CardBody>
          <GridContainer>

            {
              columns && columns.map((col, key) => {
                if (!col.inHeader && !col.inAction) {
                  if (col.cell) {
                    return <GridItem xs={12} sm={6} md={3}><label>{col.name}</label> <span>{col.cell(row)}</span></GridItem>
                  }
                  else {
                    return <GridItem xs={12} sm={6} md={3}><label>{col.name}</label> <span>{row[col.selector]}</span></GridItem>
                  }
                }
              })
            }
          </GridContainer>
        </CardBody>
        {inFooter && <CardFooter>
          <ButtonGroup
            color="info"
            aria-label="small outlined button group"
          >

            {
              columns && columns.map((col, key) => {
                if (col.inAction) {
                  if (col.cell) {
                    return col.cell(row)
                  }

                }
              })
            }


          </ButtonGroup>
        </CardFooter>}
      </Card>

    )
  }
}

DataTableMobileRow.propTypes = {

  columns: PropTypes.array,
  row: PropTypes.array,

}

export default withStyles(DataTableMobileStyle)(DataTableMobileRow);
