import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import Filters from "../Schedule/Filters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

//import { Snackbar } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import EmailConfiguration from "../Configurations/EmailConfiguration";

function SMSSetupList(props) {
    const { user } = props;
    const [data, setData] = useState([]);
    const countPerPage = 10;
    const [IsRefresh, setIsRefresh] = useState(false);

    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
    });

    const columns = [
        {
            name: "Name",
            selector: "name",
        },

        {
            name: "IsActive",
            cell: (row) => <span>{row.isActive ? "Yes" : "No"}</span>,
        },
        {
            name: "Created On",
            cell: (row) => (
                <span>
                    {row.createdOn &&
                        window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
                </span>
            ),
        },
        {
            name: "createdBy",
            selector: "createdBy",
        },
        {
            name: "Action",
            cell: (row) => (
                <Button
                    size="sm"
                    title="Edit"
                    color="info"
                    onClick={() => {
                        handleEdit(row);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];

    const handleEdit = (row) => {
        props.history.push("/admin/SMSSetup/" + row._id);
    };
    const loadWebCallingListData = async () => {
        

        if (!user || (user && !user.admin)) {
            return;
        }

        let registerRequest = await API_POST("action/getdata", {
            root: "CallingSetup",
            con: { admin: user && user.admin, type: 'SMSAPI' },
            cols: {},
        });
        
        setData(registerRequest.data || []);
        setIsRefresh(true);
    };

    useEffect(() => {
        loadWebCallingListData();
    }, [IsRefresh]);

    const handleAdd = async () => {
        debugger;
        const { history } = props;
        history.push("/admin/SMSSetup");
    };

    return (
        <>
            <Snackbar
                place="tr"
                color={notify.color}
                message={notify.message}
                open={notify.open}
                closeNotification={() => setNotify({ open: false })}
                close
            />

            <Card>
                <CardHeader color="transparent">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h3>
                                SMS Setup
                                <span style={{ float: "right", fontSize: "15px" }}>
                                    <Button
                                        size="sm"
                                        title="Add SMS Api"
                                        color="info"
                                        onClick={() => {
                                            handleAdd();
                                        }}
                                    >
                                        <i className="fa fa-plus" /> &nbsp; Add Setup
                                    </Button>
                                    <Button
                                        size="sm"
                                        title="Back"
                                        color="info"
                                        onClick={() => {
                                            props.history.push("/admin/Templates?tab=1");
                                        }}
                                    >
                                          Back
                                    </Button>
                                </span>
                            </h3>
                        </GridItem>
                    </GridContainer>
                </CardHeader>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <DataTable
                            columns={columns}
                            data={data || []}
                            highlightOnHover
                            pagination
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </Card>
        </>
    );
}

export default SMSSetupList;
