import React, { Suspense, useEffect, useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";
import CustomInput from "../CustomInput/CustomInput";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";



function JsonMapping({ ...props }) {
    const {
        disable,
        mapping,
        onChange
    } = props;
    //let Count = 1;
    const [IsRefresh, setIsRefresh] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [mappingData, setMappingData] = useState(mapping);
    const toggle = () => setOpen(!open);


    // useEffect(() => {
    //     console.log("mappingData", mappingData)
    //     setMappingData(mappingData);
    // }, [mappingData]);


    const handleChangeValue = async (e, item, index) => {

        let formValue = [...mappingData];
        item[Object.keys(item)[0]] = e.target.value;
        formValue[index] = item;
        setMappingData(formValue);
        onChange(formValue);
    };
    const handleChangeKey = async (e, item, index) => {

        let formValue = [...mappingData];
        formValue[index] = renameKey(item, Object.keys(item)[0], e.target.value)
        setMappingData(formValue);
        onChange(formValue);
    };

    const renameKey = (obj, oldKey, newKey) => {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
        return obj;
    }

    return (
        <>{mappingData && mappingData.map((anObjectMapped, index) => {
            return <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                    {disable == "key" ?
                        <h5>{Object.keys(anObjectMapped)[0]}</h5> :
                        <CustomInput
                            labelText={"Hook Key"}
                            id={Object.keys(anObjectMapped)[0]}
                            labelProps={{ shrink: true }}
                            formControlProps={{
                                fullWidth: true,
                                multiline: true,
                            }}
                            inputProps={{
                                value: Object.keys(anObjectMapped)[0],
                                name: Object.keys(anObjectMapped)[0],
                                onChange: (e) => handleChangeKey(e, anObjectMapped, index),
                            }}
                        />}
                </GridItem>
                <GridItem xs={12} sm={2} md={2} ><h5>=</h5></GridItem>
                <GridItem xs={12} sm={5} md={5}>
                    {disable == "value" ?
                        <h5>{Object.values(anObjectMapped)[0]}</h5> :
                        <CustomInput
                            labelText={"System Key"}
                            id={Object.values(anObjectMapped)[0]}
                            labelProps={{ shrink: true }}
                            formControlProps={{
                                fullWidth: true,
                                multiline: true,
                            }}
                            inputProps={{
                                value: Object.values(anObjectMapped)[0],
                                name: Object.values(anObjectMapped)[0],
                                onChange: (e) => handleChangeValue(e, anObjectMapped, index),
                            }}
                        />}
                </GridItem>

            </GridContainer>
        })
        }


        </>);
}



export default withStyles(customInputStyle)(JsonMapping);

