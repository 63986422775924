import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SearchBox = {
  getQueryData: (searchBoxVal, cid, user, IsUnAssignedLeads) => {
    if (!user) {
      return;
    }
    let orCon = [
      //{ $or: [{ IsActive: { $exists: false } }, { IsActive: true }] },
    ];
  
    let con = {
      admin: user && user.admin,
    };
    if (searchBoxVal) {

      orCon.push({
        $or: [
          { phone: { $regex: searchBoxVal } },
          { firstName: { $regex: searchBoxVal } },
        ],
      });

    }
    if (cid) {
      con["cid"] = cid;
    }
    if(orCon.length>0)
      con["$and"] = orCon;
    return con;
  },
  getMultiFilterQuery: (data, cid, user, IsUnAssignedLeads) => {
    if (!user) {
      return;
    }
    let con = {
      admin: user && user.admin,
      
    };

    if (["agent", "field_exc"].indexOf(user.role) > -1 && !IsUnAssignedLeads) {
      con["assignTo"] = user.username;
    }

    if (cid) {
      con["cid"] = cid;
    }
    if (data.selectedDisposition && data.selectedDisposition.length > 0) {
      con = { ...con, disposition: { ["$in"]: data.selectedDisposition } };
    }
    con["$and"] = data;

    return con;
  },
};

//Need To check for duplicate code

// const SearchBox = {
//   getQueryData: (searchBoxVal, cid, user) => {
//     debugger;
//     let con = {};
//     let query = {};
//     let isnum = searchBoxVal && /^\d+$/.test(searchBoxVal);

//     if (user.role == "owner" || user.role == "manager") {
//       con = { admin: user && user.admin };
//     } else {
//       con = { assignTo: user && user.username };
//     }

//     if (searchBoxVal && !cid) {
//       con.queryData = isnum
//         ? {
//            // admin: user && user.admin,
//             $or: [{ phone: searchBoxVal }, { firstName: "" }],
//           }
//         : {
//           //admin: user && user.admin,
//             $or: [{ phone: "" }, { firstName: searchBoxVal }],
//           };
//     } else if (searchBoxVal && cid) {
//       con.queryData = isnum
//         ? {
//            // admin: user && user.admin,
//             cid,
//             $or: [{ phone: searchBoxVal }, { firstName: "" }],
//           }
//         : {
//            // admin: user && user.admin,
//             cid,
//             $or: [{ phone: "" }, { firstName: searchBoxVal }],
//           };
//     }
//     // } else {
//     //   if (searchBoxVal && !cid) {
//     //     con = isnum
//     //       ? {
//     //           assignTo: user && user.username,
//     //           $or: [{ phone: searchBoxVal }, { firstName: "" }],
//     //         }
//     //       : {
//     //           assignTo: user && user.username,
//     //           $or: [{ phone: "" }, { firstName: searchBoxVal }],
//     //         };
//     //   } else if (searchBoxVal && cid) {
//     //     con = isnum
//     //       ? {
//     //           assignTo: user && user.username,
//     //           cid,
//     //           $or: [{ phone: searchBoxVal }, { firstName: "" }],
//     //         }
//     //       : {
//     //           assignTo: user && user.username,
//     //           cid,
//     //           $or: [{ phone: "" }, { firstName: searchBoxVal }],
//     //         };
//     //   }
//     // }

//     return con;
//   },
// };

export default SearchBox;
