import {
  drawerWidth,
  transition,
  container,
} from "assets/jss/material-dashboard-react.jsx";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
      padding: "10px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      padding: "10px 3px",
    },
    marginTop: "36px",
    marginBottom: "5rem",
    padding: "10px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  container: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
