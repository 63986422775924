import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  API_GET,
  API_POST,
  userRoles,

  InviteeStatus,
} from "../../../services/api.service";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@material-ui/core";

function SubscriberMember(props) {
  const { user, changeUserType, changeExpiry, changeCount, changeUserStatus, changeDetails, moveRecordsToArchive } = props;
  const [Count, setCount] = useState(0);
  const [ReferBy, setrefereBy] = useState(null);
  const [phone, setPhone] = useState(null);
  const [company, setCompany] = useState(null);
  const [data, setData] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [openChangeExpiry, setOpenChangeExpiry] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openChangeUserAccount, setOpenChangeUserAccount] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [filterAcount, setFilterAccount] = useState('Paid account');
  const [selectedMember, setSelectedMember] = useState({});
  const [customerRecords, setCustomerRecords] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));

  const customerColumn = [
    {
      name: "Expire On",
      selector: "expireon",
      width: "100px",
      cell: (row) => (
        <span>
          {row.expireon && window.moment(row.expireon).format("DD-MMM-YYYY")}
        </span>
      ),

    },
    {
      name: "Company",
      selector: "company",
      width: "250px",
    },
    {
      name: "Today Active",
      cell: (row) => (
        <span>
          {row.todayActive ? 'Active' : 'Not-Active'}
        </span>
      ),
      width: "70px",
    },

    {
      name: "Name",
      selector: "name",
      width: "200px",
    },
    {
      name: "Phone",
      selector: "phone",
      width: "150px",
    },
    {
      name: "Max User",
      selector: "max_users",
      width: "70px",
    },
    {
      name: "Active User",
      selector: "activeCount",
      width: "80px",
    },
    {
      name: "Activity Count",
      selector: "todayActivityCount",
      width: "80px",

    },



    {
      name: "Refer By",
      selector: "refer_by",
      width: "200px",
    },

    {
      name: "Email",
      selector: "email",
      width: "270px",
    },

    {
      name: "Owner Id",
      selector: "id",
      width: "100px",
    },
    {
      name: "Status",
      selector: "status",
      width: "150px",
    },

  ]

  const columns = [
    {
      name: "Edit Details",
      cell: (row) => (
        row.role == "owner" ?
          <Button
            color={'success'}
            size="sm"
            onClick={(e) => {
              setOpenEdit(true);
              setSelectedMember(row);
              setrefereBy(row.refer_by);
              setPhone(row.phone);
              setCompany(row.company);
            }}
          >
            Edit
          </Button> : ""
      ),
    },
    {
      name: "Archive Prospects",
      cell: (row) => (
        row.role == "owner" && row.expired == 1 ?
          <Button
            color={'success'}
            size="sm"
            onClick={(e) => {
              moveRecordsToArchive(row.username)
            }}
          >
            Archive
          </Button> : ""
      ),
    },

    {
      name: "Action",
      grow: true,
      cell: (row) => (
        <span>


          {row.is_active && row.is_active == 1 ? (
            <Button
              color="danger"
              size="sm"
              onClick={(e) => changeUserType(e, row, 0)}
            >
              {" "}
              DeActive
            </Button>
          ) : (
            <Button
              color="success"
              size="sm"
              onClick={(e) => changeUserType(e, row, 1)}
            >
              Active
            </Button>
          )}
        </span>
      ),
    },
    {
      name: "Expired On",
      cell: (row) => (
        <Button
          color={row.expired == 0 ? 'success' : 'danger'}
          size="sm"
          onClick={(e) => {
            setOpenChangeExpiry(true);
            setSelectedMember(row);
          }}
        >
          {row.expireon && window.moment(row.expireon).format("DD-MMM-YYYY")}
        </Button>
      ),
    },
    {
      name: "User Count",
      cell: (row) => (
        row.role == "owner" ? <Button
          color="success"
          size="sm"
          onClick={(e) => {
            setOpenChangeUserAccount(true);
            setSelectedMember(row);
          }}
        >
          {row ? row.max_users : 0}
        </Button> : ""
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        row.role == "owner" ? <a
          onClick={(e) => {
            setOpenChangeStatus(true);
            setSelectedMember(row);
          }}
        >
          {row && row.status ? row.status : "change status"}
        </a> : ""
      ),
    },
    {
      name: "Owner",
      selector: "admin",
      width: "270px",
    },
    {
      name: "username",
      selector: "username",
      width: "270px",
    },
    {
      name: "Password",
      cell: (row) => <span>{row.password}</span>,
    },
    {
      name: "Role",
      selector: "role",
    },
    {
      name: "Created On",
      cell: (row) => (
        <span>
          {row.date_joined &&
            window.moment(row.date_joined).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Login",
      cell: (row) => (
        <span>
          {row.last_login &&
            window.moment(row.last_login).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Activity",
      cell: (row) => (
        <span>
          {row.last_activity &&
            window.moment(row.last_activity).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },


    {
      name: "name",
      selector: "name",
    },
    {
      name: "company",
      selector: "company",
    },
    {
      name: "phone",
      selector: "phone",
    },
    {
      name: "Company Size",
      selector: "company_size",
    },
    {
      name: "Signup Source",
      selector: "signup_source",
    },
    {
      name: "Refer By",
      selector: "refer_by",
    },
    {
      name: "Account Status",
      selector: "status",
      width: "270px",
    },
    {
      name: "Lead Source",
      cell: (row) => (
        <i title={row.lead_source}>
          {row.lead_source}
        </i>
      ),
    },
  ];

  const loadCampaigns = async () => {
    debugger;
    const { user } = props;

     let registerRequest;
    // let IsActive = this.state.IsChecked == true ? true : false;
    try {
      // registerRequest = await API_POST("action/getdata", {
      //   root: "campaigns",
      //   con: { admin: user && user.admin, IsActive },
      //   //cols: {}
      // });



      // if (registerRequest && registerRequest.status == 200) {
      //   setUserStatus(registerRequest.data);
      // }





    } catch (error) {
      console.log(error);
    }
  };
  const findActiveSubscriber = async () => {



    let getRecords = await API_POST("report/customerReport", {
      cons: {
        createdOn: {
          $gte: new Date(startDate),
          $lt: new Date(endDate),
        }
      },
      root: 'CallHistory',
      filter: { status: filterAcount }
    });



    setCustomerRecords(getRecords.data);
    // console.log(getRecords.data)



  }

  useEffect(() => {

    findActiveSubscriber()
  }, [filterAcount]);

  useEffect(() => {

    loadCampaigns()
  }, []);

  useEffect(() => {
    if (props.ActiveUsers && props.ActiveUsers.length > 0) {
      debugger;
      //findActiveSubscriber(props.ActiveUsers)
      setData(props.ActiveUsers);
    }
  }, [props.ActiveUsers]);

  const onChangeExpiry = async (e) => {
    debugger;
    console.log(selectedMember);
    setOpenChangeExpiry(false);
    changeExpiry(e, selectedMember);
  };




  const onChangeDetails = async (e) => {
    debugger;
    selectedMember.refer_by = ReferBy;
    selectedMember.phone = phone;
    selectedMember.company = company;
    console.log(selectedMember);
    setOpenEdit(false);
    changeDetails(e, selectedMember);
  };




  const onChangeCount = async (e) => {
    debugger;
    selectedMember.max_users = parseInt(Count);
    setOpenChangeUserAccount(false);
    changeCount(e, selectedMember);
  };


  const onChangeUserStatus = async (e, element) => {

    setOpenChangeStatus(false);
    debugger;
    if (await changeUserStatus(e, selectedMember, element)) {
      selectedMember.status = element;
    }
  }

  return (
    <>
      <Dialog
        onClose={() => setOpenChangeStatus(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeStatus}
      >
        <DialogTitle id="simple-dialog-title">Change User Status</DialogTitle>
        <List>
          {userStatus.map((item) => (
            <ListItem
              button
              onClick={(e) => onChangeUserStatus(e, item)}
              key={item}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <i />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Dialog
        onClose={() => setOpenChangeExpiry(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeExpiry}
      >
        <DialogTitle id="simple-dialog-title">Change User Expiry </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                id="date"
                label="Expire On"
                type="date"
                defaultValue={
                  selectedMember.expireon &&
                  window.moment(selectedMember.expireon).format("DD-MMM-YYYY")
                }
                onChange={(e) => {
                  selectedMember.expireon = e.target.value;
                }}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeExpiry}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenChangeExpiry(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setOpenChangeUserAccount(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeUserAccount}
      >
        <DialogTitle id="simple-dialog-title">Change User Count </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Count"
                id="Count"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: 4,
                  type: 'number',
                  required: true,
                  defaultValue: selectedMember && selectedMember.max_users,
                  //placeholder:'Enter Member count',
                  name: "Count",
                  //value: Count,
                  onChange: (e) => {
                    setCount(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeCount}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenChangeUserAccount(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setOpenEdit(false)}
        aria-labelledby="simple-dialog-title"
        open={openEdit}
      >
        <DialogTitle id="simple-dialog-title">Change User Details </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Refer By"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'text',
                  required: true,
                  defaultValue: selectedMember && selectedMember.refer_by,
                  //placeholder:'Enter Member count',
                  name: "refer",
                  //value: Count,
                  onChange: (e) => {
                    setrefereBy(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Phone"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'Number',
                  required: true,
                  defaultValue: selectedMember && selectedMember.phone,
                  //placeholder:'Enter Member count',
                  name: "phone",
                  //value: Count,
                  onChange: (e) => {
                    setPhone(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Company"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'text',
                  required: true,
                  defaultValue: selectedMember && selectedMember.company,
                  //placeholder:'Enter Member count',
                  name: "company",
                  //value: Count,
                  onChange: (e) => {
                    setCompany(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeDetails}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenEdit(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>



      <Card>
        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Members
                <span style={{ float: "right", fontSize: "15px" }} />
              </h3>
            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DataTableExtensions
              columns={columns}
              data={data || []}
              export={true}
              exportHeaders={true}
              print={false}
            >
              <DataTable
                columns={columns}
                data={data || []}
                pagination
                striped
                highlightOnHover
                pointerOnHover
              />
            </DataTableExtensions>
          </GridItem>
        </GridContainer>
      </Card>

      <Card>

        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Customer Realtime Data
                <span style={{ float: "right", fontSize: "15px" }} />
              </h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>


              <CustomInput
                id={"body_variable_value_"}
                labelText={"Select Account Filter"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type: "select",
                  options: [{
                    key: "Paid account",
                    value: "Paid account"
                  },
                  {
                    key: "Demo account",
                    value: "Demo account"
                  },
                  {
                    key: "Paid expired",
                    value: "Paid expired"
                  },
                  {
                    key: "Internal account",
                    value: "Internal account"
                  },
                  {
                    key: "Duplicate",
                    value: "Duplicate"
                  }


                  ],
                  value: filterAcount,
                  name: "selectFilter",
                  onChange: (e) => {
                    setFilterAccount(e.target.value)
                  },
                }}
              />

              {
                /*

                <select name="filter" value={filterAcount} onChange={setFilterAccount(this.value)}>
                  <options value={"Paid Acount"}>Paid Acount </options>
                  <options value={"Demo Acount"}>Demo Acount </options>
                  <option value={"Paid expired"}>Paid Expired </option>
                  <option value={"Duplicate"}>Duplicate </option>
                  <option value={"Internal Acount"}>Internal Acount </option>

                </select>

                */
              }


            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DataTableExtensions
              columns={customerColumn}
              data={customerRecords || []}
              export={true}
              exportHeaders={true}
              print={false}
            >
              <DataTable
                columns={customerColumn}
                data={customerRecords || []}
                pagination
                striped
                highlightOnHover
                pointerOnHover
              />
            </DataTableExtensions>
          </GridItem>
        </GridContainer>

      </Card>




    </>
  );
}

export default SubscriberMember;
