import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// core components
import DataTable from "react-data-table-component";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Chip from "@material-ui/core/Chip";
import CommonMapping from "./../CommonMapping/CommonMapping";
import InfoBox from "../../components/InfoIcon/InfoIcon";


function _99Acres(props) {
  const { classes, user, open, defaultData, IsEdit } = props;
  const [hookData, sethookData] = useState([]);
  const [Campaigns, setCampaigns] = useState([]);
  const [user_name, setuser_name] = useState("");
  const [pwd, setpwd] = useState("");
  const [api, setApi] = useState(
    "https://www.99acres.com/99api/v1/getmy99Response/OeAuXClO43hwseaXEQ/uid/"
  );
  
  
  const [InputType, setInputType] = useState("");
  
  const [MappingVal, setMappingVal] = useState([]);
  const [MappingWithMulObject, setMappingWithMulObject] = useState([]);

  const [PreviewData, setPreviewData] = useState([]);
  const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
  const [IsShowTestBtn, setIsShowTestBtn] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState(false);

  const [columns, setcolumns] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [chkResult, setChkResult] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IsTestBtnClicked, setIsTestBtnClicked] = useState(false);
  const [IsSaved, setIsSaved] = useState(false);

  const childRef = useRef();

 

  const handleClose = () => {
    props.handleClose();
  };

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (props.user) {
      loadCampaign();
    }
  }, [props.user]);

  useEffect(() => {

    if (open && defaultData && defaultData.hasOwnProperty("cid")) {
      console.log(defaultData);

      setApi(defaultData.api);
      setInputType({ id: defaultData.cid, name: defaultData.campaign });
      setMappingWithMulObject(defaultData.Mapping);

      let obj = defaultData.Mapping.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});



      setPreviewData([obj]);
      setMappingVal(obj);
      setIsShowFieldMapping(true);
      //setuser_name(defaultData.values[0].user_name);
      //setpwd(defaultData.values[1].pwd);
    }
    else {

      
      setInputType("");
      setMappingWithMulObject([]);
      setPreviewData([]);
      setMappingVal();
      setIsShowFieldMapping(false);
      // setuser_name("");
      // setpwd("");
    }
  }, [defaultData]);

  const loadCampaign = async () => {
    const { user } = props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive: true },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          setCampaigns(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTest = async () => {
    let url = api;
    let startdate = window
      .moment()
      .add(-720, "hours")
      .format("YYYY-MM-DD HH:mm:ss")
      .toUpperCase();
    let enddate = window
      .moment()
      .format("YYYY-MM-DD HH:mm:ss")
      .toUpperCase();

    var body={
      start_date :startdate,
      end_date: enddate,
      user_name,
      pwd
    }

    // url = url.replace("${startdate}", startdate);
    // url = url.replace("${enddate}", enddate);
    // url = url.replace("${user_name}", user_name);
    // url = url.replace("${pwd}", pwd);

    //api
    let data = await API_POST(`register/PostAPIData`, { url, body, xml: true });

    
    


    if (data.success) {
      if (data.data.apiData && data.data.apiData[0]) {
        setPreviewData([data.data.apiData[0]]);
        setMappingVal(data.data.apiData[0]);
        setIsTestBtnClicked(true);
        if (data.data.apiData[0] && data.data.apiData[0].Error_Message) {
          setNotify({
            open: true,
            message: data.data.apiData[0].Error_Message,
            color: "danger",
          });
        } else {
          setIsShowFieldMapping(true);
        }
      } else {
        setNotify({
          open: true,
          message: "No Recent Data Found",
          color: "success",
        });
      }
    }
  };

  const handleSave = async (e) => {
    childRef.current.handleAdd();
  };

  const handleSaveIntegration = async () => {
    let payload = {
      name: "99acres",
      admin: user && user.admin,
      campaign: InputType.name,
      cid: InputType._id,
      api: api,
      values: [
        {
          user_name: user_name,
        },
        {
          pwd: pwd,
        },
      ],
      Mapping: mapping,
      dateFormat: "DD-MMM-YYYYHH:mm:ss",
      IsActive: true
    };

    let registerRequest;
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "Integrations",
        body: payload,
        querydata: { admin: user.admin, name: "99acres" },
      });

      if (registerRequest && registerRequest.status == 200) {
        setNotify({
          open: true,
          message: "Data Save Successfully",
          color: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (IsSaved) {
      props.CallloadIntegrationList();
      handleSaveIntegration();
      setIsSaved(false);
    }
  }, [IsSaved]);

  const handleBlur = async (e) => {

    if (e.target.name == "user_name" || e.target.name == "pwd") {
      if (user_name != "" && pwd != "") { setIsShowTestBtn(true); }
      else setIsShowTestBtn(false);
    }
  };

  let col = [];
  const handlePreview = async () => {
    debugger;
    if (
      PreviewData.length == 0 &&
      !PreviewData[0].hasOwnProperty("Error_Message")
    ) {
      setNotify({
        open: true,
        message: "Please first click on test btn to show mapping.",
        color: "danger",
      });
    } else {
      let obj = {};
      for (var key in PreviewData[0]) {
        if (PreviewData[0].hasOwnProperty(key)) {
          obj = {};
          //obj = { name: key, selector: PreviewData[0][key] };
          obj = { name: key, selector: key };
          col.push(obj);
        }
      }
      debugger;
      setcolumns(col);
      setIsPreviewData(true);
    }
  };

  const bindMapping = (mapping) => {
    setChkResult(true);
    setMapping(mapping);
    setIsSaved(true);
  };

  return (
    <GridContainer>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title">99acres Setup
        <GridItem  style={{float: "right", marginTop: "10px"}}>
            <InfoBox name={'Integrations.99Acres'} />
        </GridItem>
        </DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id="Name"
                labelText={"Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: "99acres",
                  name: "99acres",
                  disabled: true,
                }}
              />

              <Autocomplete
                onChange={(event, value) => setInputType(value)}
                id="combo-box-demo"
                size="small"
                options={Campaigns}
                labelText="FieldType"
                name="autoComplete"
                value={InputType || null}
                getOptionLabel={(Campaigns) => Campaigns.name || ""}
                defaultValue="Select"
                renderTags={(value) =>
                  value.map(() => (
                    <Chip variant="outlined" label="Enter Field" />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Campaign "
                  // variant="outlined"
                  />
                )}
              />

              


              <CustomInput
                id="api"
                labelText={"POST API *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: api,
                  name: "api",
                  onChange: (e) => {
                    setApi(e.target.value);
                  },
                }}
              />

              <CustomInput
                id="user_name"
                labelText={"user_name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: user_name,
                  name: "user_name",
                  onChange: (e) => {
                    setuser_name(e.target.value);
                  },
                  onBlur: handleBlur,
                }}
              />
              <CustomInput
                id="pwd"
                labelText={"pwd"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: pwd,
                  name: "pwd",
                  onBlur: handleBlur,
                  onChange: (e) => {
                    setpwd(e.target.value);
                  },
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              {IsShowFieldMapping && (
                <CommonMapping
                  ref={childRef}
                  MappingInSingleObj={MappingVal}
                  MappingWithMulObject={MappingWithMulObject}
                  isEdit={IsEdit}
                  user={props.user}
                  bindMapping={bindMapping}
                />
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            {IsPreviewData && PreviewData && (
              <DataTable
                columns={columns}
                data={PreviewData || []}
                highlightOnHover
              />
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          {IsShowTestBtn && (
            <Button onClick={handleTest} color="primary">
              Test
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {(IsTestBtnClicked || props.IsEdit) && <Button onClick={handleSave} color="primary">
            Save
          </Button>}
          {IsTestBtnClicked && <Button onClick={handlePreview} color="primary">
            Preview
          </Button>}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

export default _99Acres;

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    debugger;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
