import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import TableCell from "@material-ui/core/TableCell";
import { API_POST } from "../../services/api.service";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CallDetail from "../Activity/CallDetail";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ViewCallDetails(props) {
  debugger;

  const { user, IsCallDetailsOpen, data,callDetailsQuery } = props;
  const [] = useState(false);
  const [] = useState({});
  const [options, setOptions] = useState({});
  let [InputType, setInputType] = useState("");
  let [filedName, setFiledName] = useState("");
  let [IsAddField, setIsAddField] = useState(false);

  let notification = {
    open: false,
    message: "",
    color: "success",
  };
  const [Notify, setNotify] = useState(notification);
  const [prospectFields, setProspectFields] = useState(data ? data : []);
  const [prospectsTableData, setProspectsTableData] = useState([]);

  let tableData = [];


  useEffect(() => {
    if (prospectFields && prospectFields.length > 0) {
      loadData();
    }
  }, [prospectFields]);

  const handleChange = (event, id) => {
    debugger;

    let checked = event.target.checked;
    let value = event.target.value;
    setProspectFields(
      prospectFields.map((row) => {
        if (row._id === id) {
          row.IsChanged = true;
          row.IsFilter =
            row.hasOwnProperty(value) && value == "IsFilter"
              ? checked
              : row.IsFilter;
          row.IsSort =
            row.hasOwnProperty(value) && value == "IsSort"
              ? checked
              : row.IsSort;
          row.IsListView =
            row.hasOwnProperty(value) && value == "IsListView"
              ? checked
              : row.IsListView;
          row.inTabView =
            row.hasOwnProperty(value) && value == "inTabView"
              ? checked
              : row.inTabView;
          row.isRequired =
            row.hasOwnProperty(value) && value == "isRequired"
              ? checked
              : row.isRequired;
        }

        return row;
      })
    );

    UpdateProspectsFields(id, value, checked);
  };

  const UpdateProspectsFields = async (FieldId, FieldName, checked) => {
    debugger;
    let registerRequest;
    let body;
    //const { user, cid } = props;
    if (FieldName == "IsFilter") {
      body = { IsFilter: checked };
    } else if (FieldName == "IsSort") {
      body = { IsSort: checked };
    } else if (FieldName == "IsListView") {
      body = { IsListView: checked };
    } else if (FieldName == "inTabView") {
      body = { inTabView: checked };
    } else if (FieldName == "isRequired") {
      body = { isRequired: checked };
    }
    try {
      registerRequest = await API_POST("action/update", {
        root: "prospect_fields",

        body,
        querydata: { _id: FieldId },
      });

      if (registerRequest && registerRequest.status == 200) {
        // setNotify({
        //   open: true,
        //   message: "Fields Value Saved successfully.",
        //   color: "success",
        // });
        console.log("Data Updated");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = () => {
    for (let index = 0; index < prospectFields.length; index++) {
      const element = prospectFields[index];
      tableData.push([
        <TableCell size="sm">{element.display_Name}</TableCell>,
        <TableCell size="sm">{element.field_type}</TableCell>,
        <TableCell size="sm">
          <input
            type="checkbox"
            name={element.display_Name}
            value={"IsFilter"}
            checked={element.IsFilter || false}
            onChange={(event) => {
              handleChange(event, element._id, element.field_name);
            }}
          />
        </TableCell>,
        // <TableCell size="sm">
        //   <input type="checkbox" onChange={handleCheckbox} checked= {item.IsSort ? 'checked':'' } value= {item.IsSort} />
        // </TableCell>,
        <TableCell size="sm">
          <input
            type="checkbox"
            name={element.display_Name}
            value={"IsSort"}
            checked={element.IsSort || false}
            onChange={(event) => {
              handleChange(event, element._id, element.field_name);
            }}
          />
        </TableCell>,
        <TableCell size="sm">
          <input
            type="checkbox"
            name={element.display_Name}
            value={"IsListView"}
            checked={element.IsListView || false}
            onChange={(event) => {
              handleChange(event, element._id, element.field_name);
            }}
          />
        </TableCell>,
        <TableCell size="sm">
          <input
            type="checkbox"
            name={element.display_Name}
            value={"inTabView"}
            checked={element.inTabView || false}
            onChange={(event) => {
              handleChange(event, element._id, element.field_name);
            }}
          />
        </TableCell>,
        <TableCell size="sm">
          <input
            type="checkbox"
            name={element.display_Name}
            value={"isRequired"}
            checked={element.isRequired || false}
            onChange={(event) => {
              handleChange(event, element._id, element.field_name);
            }}
          />
        </TableCell>,
      ]);
      setProspectsTableData(tableData);
    }
    //setProspects(result);
    //setProspectsFileds(tableFileds);
  };

  const handleAdd = () => {
    setIsAddField(true);
    setOptions(["text", "datetime", "number"]);
    //setOptions( prospectFields.length>0 ? prospectFields.map((option) => option.field_type):[]);
  };

  const handleSave = async () => {
    debugger;
    let registerRequest;
    registerRequest = await API_POST("action/addRecord", {
      root: "prospect_fields",
      body: {
        field_name: filedName,
        display_Name: filedName,
        createdOn: new Date(),
        field_type: InputType,
        isRequired: false,
        inTabView: false,
        IsSort: false,
        IsFilter: false,
        field_options: "",
        IsListView: false,
        admin: user && user.admin,
      },
    });
    if (registerRequest && registerRequest.status == 200) {
      loadData();
      setNotify({
        open: true,
        message: "Fields saved successfully.",
        color: "success",
      });
      setInputType("");
      setFiledName("");
      setIsAddField(false);
    }
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />

      <GridContainer>
        <Dialog open={IsCallDetailsOpen} aria-labelledby="form-dialog-title" maxWidth="lg">
          {/* <DialogTitle id="form-dialog-title"> Settings Details</DialogTitle> */}
         
              <DialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}
              >
                <CallDetail user={user} IsCallDetailsOpen={IsCallDetailsOpen} callDetailsQuery={callDetailsQuery}/>
                {/* <iframe src="http://localhost:3000/admin/CustActivity?tab=1" /> */}
              </DialogTitle>

             
          

         
        </Dialog>
      </GridContainer>
    </>
  );
}

ViewCallDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ViewCallDetails);
