
import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { API_POST } from "../../services/api.service";



function ReportDetails(props) {
  const { history } = props;
  const { classes, user } = props;
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (user && user.username) {
      loadData();
    }
  }, [user,endDate]);

  useEffect(() => {
    if (user && user.username) {
      loadTableData();
    }
  }, [data]);

  const handleChangeDate = (data) => {
    console.log("handleChangeUserDDL", data);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    //CreateReport(data, users)
  }


  const loadTableData = async () => {

    try{

      let query = JSON.parse(atob(props.match.params.id));
      let createdDate= {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
      let q={...query };
      if(endDate){
        delete q.date;
        q.createdOn=createdDate;
      }
    
      let registerRequest = await API_POST("action/getdata", {
        root: "userStats",
        con: q,
      });
      let d = await Promise.all( await registerRequest.data.sort(  (a, b) => a.isActiveToday > b.isActiveToday ? -1 : 1));
      setTableData(d);

    }catch(e){


    }


  }

  const loadData = async () => {
    debugger;
    let registerRequest;
    try {
        let query = JSON.parse(atob(props.match.params.id));
      registerRequest = await API_POST("action/getdata", {
        root: "userStats",
        con: { ...query },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data && registerRequest.data.length > 0) {
            setData( registerRequest.data[0]);
            // loadTableData();
           // setStartDate(new Date(subscriberData.createdOn).setHours(0, 0, 0, 0))
           // setEndDate(new Date(subscriberData.createdOn).setHours(23, 59, 59, 0))
           // let startDate=new Date(subscriberData.createdOn).setHours(0, 0, 0, 0);
           // let endDate=new Date(subscriberData.createdOn).setHours(23, 59, 59, 0);
        }else{
            registerRequest = await API_POST("action/getdata", {
                root: "userStats",
                con: {...query},
              });
              let d=registerRequest.data.sort((a, b) => a.isActiveToday > b.isActiveToday ? -1 : 1);
             // loadTableData();
              setTableData(d);
            
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  const fancyTimeFormat=(duration)=>
  {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const handleExportExcel = async (e) => {

    let data=tableData.map((v)=>{
        delete v._id;
        v.todayTalktime=fancyTimeFormat(v.todayTalktime);
        v.isActive=v.isActiveToday && v.isActiveToday > 0 ? "Active": "Not-Active";
        return v;
    })

    let exportData = ["date",
    "name",
    "isActiveToday",
    "todayCallDetails",
    "todayTalktime",
    "businessCalls",
    "connectedCalls",
    "incomingCalls",
    "outgoingCalls",
    "missedCalls",
    "totalProspect",
    "freshProspect",
    "todayCallActivity",
    "updatedProspect",
    "overdue",
    "todayRecording",
    "ActiveTime",
    "OfflineTime",
    "Total Working Hour",
    "Total Break Hour",
    "Break Count"
    ]
    data = data.map((v)=>{
      let tmp={};
      exportData.map((key)=> tmp[key] = v[key]  );
      return tmp;
    });

    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: "",
      body: {  admin: user.admin, user: user.username,static:data,page:'UserStats' },
    });

    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };
  return (
    <>
     <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
    
    <Card>
              <CardHeader color="transparent">
              
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                <h3>Report Details</h3>
                  </GridItem>

            <GridItem xs={12} sm={6} md={6}>
            <CustomInput
  style="dashboard"
  id="startdate"
  labelProps={{ shrink: true }}
  formControlProps={{
    fullWidth: true,
  }}

  inputProps={{
    style: {padding:'0px'},
    type: 'date_range',
    initialDateRange: {
      startDate: startDate,
      endDate: endDate
    },
    maxDate: new Date(),
    onChange: (e) => {
      console.log(e)
      handleChangeDate({ startDate: e.startDate, endDate: e.endDate })
      
    },
    showFetch: true,
    onClick: () => {
     

    }
  }}
/>
              
              </GridItem> 
            <GridItem xs={12} sm={2} md={2} >
                <Button
                style={{"float":"right"}}
                      size="sm"
                      color="primary"
                      justIcon
                      title="Export To Excel"
                      onClick={() => {
                        handleExportExcel();
                      }}
                    >
                        <GetAppOutlinedIcon />
                    </Button>
                    </GridItem>
                </GridContainer>
              </CardHeader>
              </Card>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="transparent">
              </CardHeader>
              
              <CardBody>
              
                <TableContainer>

                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Active Today</TableCell>
                        <TableCell>Today Calls</TableCell>
                        <TableCell>TalkTime</TableCell>

                        <TableCell>Business Calls</TableCell>
                        <TableCell>Connected Calls</TableCell>
                

                        <TableCell>Incomming Calls</TableCell>
                        <TableCell>Outgoing calls</TableCell>
                        <TableCell>Missed Calls</TableCell>

                        <TableCell>Total Prospects</TableCell>

                        <TableCell>Created Prospects</TableCell>
                        <TableCell>Activity</TableCell>
                        
                        <TableCell>Unique Prospects</TableCell>
                        <TableCell>OverDue</TableCell>
                      
                        <TableCell>Recording</TableCell>

                        <TableCell>ActiveTime</TableCell>
                        <TableCell>OfflineTime</TableCell>
                        <TableCell>Total Working Minutes</TableCell>
                        <TableCell>Total Break Minutes</TableCell>
                        <TableCell>Break Count</TableCell>




                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData &&
                        tableData.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell>{item.date}</TableCell>
                              <TableCell>{item.name} <br/> {item.user}</TableCell>
                              <TableCell>{item.isActiveToday && item.isActiveToday > 0 ? "Active": "Not-Active"}</TableCell>
                              <TableCell>{item.todayCallDetails}</TableCell>
                              <TableCell>{fancyTimeFormat(item.todayTalktime)}</TableCell>

                              <TableCell>{ typeof item.businessCalls === 'object' ? 0 : item.businessCalls }</TableCell>
                              <TableCell>{item.connectedCalls}</TableCell>
                             

 

                              <TableCell>{item.incomingCalls}</TableCell>
                              <TableCell>{item.outgoingCalls}</TableCell>
                              <TableCell>{item.missedCalls}</TableCell>

                              <TableCell>{item.totalProspect}</TableCell>
                              <TableCell>{item.freshProspect}</TableCell>



                              
                              <TableCell>{item.todayCallActivity}</TableCell>

                              <TableCell>{item.updatedProspect}</TableCell>
                              
                              <TableCell>{item.overdue}</TableCell>
                            
                              <TableCell>{item.todayRecording}</TableCell>
                              <TableCell>{window.moment(item.timeTrackingActiveTime).format("DD MM YYYY hh:mm:ss")}</TableCell>

                              <TableCell>{item.timeTrackingOfflineTime}</TableCell>
                              <TableCell>{fancyTimeFormat(item.timeTrackingTotalworkingTime)}</TableCell>
                              <TableCell>{fancyTimeFormat(item.timeTrackingTotalBreakTime)}</TableCell>
                              <TableCell>{item.timeTrackingTotalBreakCount}</TableCell>

                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
         
        </GridItem>

      </GridContainer>
    </>
  );
}

export default ReportDetails;
