/* eslint-disable */
import React from "react";
import { withRouter } from "react-router";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_GET, API_POST } from "../../services/api.service";


import Loader from "components/Loader/Loader.jsx";
import DataTable from "react-data-table-component";
import { identity } from "underscore";
import { category, subCategory, statusfilter , team } from "./constants"

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

class SupportFilter extends React.Component {
    constructor(props) {
        super(props);
        const { user,team } = this.props;

        this.state = {
            teamuser: [{ key: 'Select', value: 0 }],
            team: team,
            showLoader: true,
            editMode: false,
            _id: null,
            editId: '',
            mode: "Add",
            records: null
        };
        const defaultData = {
            category: null,
            subCategory: null,
            comment: "",
            status: "",
            adminComment: "",
            IsActive: true,
            createdOn: null,
            updatedOn: null,
            admin: user && user.admin,
            user: user && user.username,
            company: user && user.company

        };




        this.state.catoption = category;
        this.state.filter = {};
        this.state.statusOption = statusfilter;
        this.state.subcatoption = [];
        this.state.filterObj = defaultData;

    }


    categoryChange = async (e) => {

        //this.state.filterObj.category = e.target.value;
        this.setState({ filterObj: { ...this.state.filterObj, category: e.target.value } })


        let subcat = subCategory[e.target.value];
        let option = [];

        for (let i in subcat) {
            option.push({
                key: subcat[i],
                value: subcat[i]
            });
        }

        console.log(option);
        this.setState({ subcatoption: option });
        this.props.onfilter({ ...this.state.filterObj, category: e.target.value });

    }

    changesubcat = async (e) => {
        //this.state.filterObj.subCategory = e.target.value;
        this.setState({ filterObj: { ...this.state.filterObj, subCategory: e.target.value } })
        this.props.onfilter({ ...this.state.filterObj, subCategory: e.target.value });
    }

    // to stop the warning of calling setState of unmounted component
    componentDidMount() {
        const { user } = this.props;

        //alert(JSON.stringify(this.props.match));

    }

    clearFilter = async (e) => {
        this.setState({ filterObj: {} });
        this.props.onfilter({});
    }




    handleChange = async (e) => {

        let formValue = this.state.filterObj;


        if (e.target && e.target.type === "checkbox") {
            formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
            formValue[props] = e.format();
        } else {
            formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }

        console.log(formValue);
        this.setState({ filterObj: formValue });
        this.props.onfilter(formValue);
    };

    handleChangeTime = async (e) => {
        this.setState({ filterObj: { ...this.state.filterObj, startDate: e.startDate, endDate: e.endDate } });

        this.props.onfilter({ ...this.state.filterObj, startDate: e.startDate, endDate: e.endDate });
    }


    handleSave = async (e) => {

        this.handleAddNotification(e);

    };



    render() {


        const productsList = [
            {
                key: "Betyphon",
                value: "Betyphon"
            },
            {
                key: "IVR Service",
                value: "IVR Service"
            },
            {
                key: "Toll-free Service",
                value: "Toll-free Service"
            },
            {
                key: "Missed Call",
                value: "Missed Call"
            },
            {
                key: "Voice Outbound",
                value: "Voice Outbound"
            },
            {
                key: "Click2Call Service",
                value: "Click2Call Service"
            },
            {
                key: "SMS Service",
                value: "SMS Service"
            },
            {
                key: "Whatsapp Cloud API",
                value: "Whatsapp Cloud API"
            },
            {
                key: "Renewup",
                value: "Renewup"
            }
        ]

        const columnsadmin = [
            {
                name: "Date",
                selector: "createdOn",
                width: "150px"

            },
            {
                name: "scheduleTime",
                selector: "scheduleTime",
                width: "150px"

            },
            {
                name: "Company",
                selector: "company",
                width: "100px"

            },
            {
                name: "Subscriber ID",
                selector: "admin",
                width: "200px"

            },
            {
                name: "Category",
                selector: "category",
                width: "100px"

            },
            {
                name: "Concern",
                selector: "subCategory",
                width: "200px"

            },
            {
                name: "status",
                selector: "status",
                width: "100px"

            },
            {
                name: "Comment",
                selector: "comment",
                width: "300px",
                cell: (row) => (
                    <span>
                        {row.comment}  <a style={{ color: "blue" }} href={'/admin/editsupport/' + row._id} > <b> edit </b></a>
                    </span>
                ),
            },

        ]

        const columns = [
            {
                name: "Date",
                selector: "createdOn",
                width: "150px"

            },
            {
                name: "Category",
                selector: "category",
                width: "100px"

            },
            {
                name: "Concern",
                selector: "subCategory",
                width: "200px"

            },
            {
                name: "Comment",
                selector: "comment",
                width: "300px",
                cell: (row) => (
                    <span>
                        {row.comment}  <a style={{ color: "blue" }} href={'/admin/editsupport/' + row._id} > <b> edit </b></a>
                    </span>
                ),
            },
            {
                name: "Status",
                selector: "status",
                width: "180px"

            },
            {
                name: "Betyphon team",
                selector: "adminComment",
                width: "200px"

            },


        ];
        const countPerPage = 50;
        const { classes, user } = this.props;
        const {
            records,
            filterObj,
            team,
            dispositions,
            customFormFields,
            mode,
            teamuser,
            showLoader,
        } = this.state;

        return (
            <>


                {

                    !this.props.superUser ?
                        (
                            <>


                                <GridContainer pd={5} justify={"center"} rowSpacing={2}
                                    columnSpacing={2} style={{ 'padding': '21px' }} >



                                    <GridItem xs={12} sm={4} md={4}>

                                        <CustomInput
                                            labelText="Select Category"
                                            id="category"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "category",
                                                type: "select",
                                                value: this.state.filterObj.category,
                                                onChange: this.categoryChange,
                                                options: this.state.catoption
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            labelText="Select Sub Category (Concern)"
                                            id="subCategory"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "subCategory",
                                                type: "select",
                                                value: this.state.filterObj.subCategory,
                                                onChange: this.changesubcat,
                                                options: this.state.subcatoption
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            labelText="Select Status"
                                            id="status"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "status",
                                                type: "select",
                                                value: this.state.filterObj.status,
                                                onChange: this.handleChange,
                                                options: this.state.statusOption
                                            }}
                                        />
                                    </GridItem>







                                </GridContainer>

                            </>
                        ) :
                        (
                            <>



                                <GridContainer pd={5} justify={"center"} rowSpacing={2}
                                    columnSpacing={2} style={{ 'padding': '21px' }} >



                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            labelText="Select Product"
                                            id="product"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "product",
                                                type: "select",
                                                value: this.state.filterObj.product,
                                                onChange: this.handleChange,
                                                options: productsList
                                            }}
                                        />
                                    </GridItem>


                                    <GridItem xs={12} sm={4} md={4}>

                                        <CustomInput
                                            labelText="Select Category"
                                            id="category"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "category",
                                                type: "select",
                                                value: this.state.filterObj.category,
                                                onChange: this.categoryChange,
                                                options: this.state.catoption
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={4} md={4}>
                                        <CustomInput
                                            labelText="Select Sub Category (Concern)"
                                            id="subCategory"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "subCategory",
                                                type: "select",
                                                value: this.state.filterObj.subCategory,
                                                onChange: this.changesubcat,
                                                options: this.state.subcatoption
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={4} md={4}>

                                        <CustomInput
                                            labelText="Select ticketAssignedTo"
                                            id="ticketAssignedTo"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "ticketAssignedTo",
                                                type: "select",
                                                value: this.state.filterObj.ticketAssignedTo,
                                                onChange: this.handleChange,
                                                options: this.state.team
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={2} md={2}>
                                        <GenerateField
                                            value={
                                                filterObj && filterObj.admin
                                            }
                                            onChange={this.handleChange}
                                            id={"admin"}
                                            name={"admin"}
                                            label={"Subscriber Id"}
                                            type="text"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={2} md={2}>
                                        <GenerateField
                                            value={
                                                filterObj && filterObj.company
                                            }
                                            onChange={this.handleChange}
                                            id={"company"}
                                            name={"company"}
                                            label={"Company"}
                                            type="text"
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={2} md={2}>
                                        <CustomInput
                                            labelText="Select Status"
                                            id="status"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            labelProps={{ shrink: true }}
                                            inputProps={{
                                                required: false,
                                                name: "status",
                                                type: "select",
                                                value: this.state.filterObj.status,
                                                onChange: this.handleChange,
                                                options: this.state.statusOption
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={2} md={2}>
                                        <CustomInput
                                            id="startdate"
                                            labelText={""}
                                            labelProps={{ shrink: true }}
                                            formControlProps={{
                                                fullWidth: true,
                                                multiline: true,
                                            }}
                                            inputProps={{
                                                type: "date_range",
                                                initialDateRange: {
                                                    startDate: new Date(),
                                                    endDate: new Date(),
                                                },
                                                maxDate: new Date(),
                                                onChange: this.handleChangeTime,
                                                //onChange: (e) => { this.setState({ filterObj : { ...this.state.filterObj,startDate: e.startDate, endDate: e.endDate } }); }
                                                //onChange: (e) => {setStartDate(e.startDate), setEndDate(e.endDate)  }
                                            }}
                                        />

                                    </GridItem>

                                    <GridItem xs={12} sm={2} md={2}>
                                        <Button color="primary" style={{ margin: '30px' }}
                                            size="sm"
                                            onClick={this.clearFilter}>
                                            Clear

                                        </Button>

                                    </GridItem>

                                </GridContainer>

                            </>
                        )

                }






            </>
        )

    }
}

export default withStyles(styles)(SupportFilter);

class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
