import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogTitle from "@material-ui/core/DialogTitle";
//import Checkbox from "../../components/Checkbox/Checkbox";
import Chip from "@material-ui/core/Chip";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import './style.css';
import { DialogContent } from "@material-ui/core";
import { CreateBody, sendCommByTemplate } from "../../services/CommonServices";
import axios from "axios";

const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function TemplatePopup(props) {
  const {
    pid,
    user,
    type,
    prospects
  } = props;
  const [templateList, setTemplateList] = useState([]);
  const [SelectedTemplate, setSelectedTemplate] = useState(null);
  const [IsTemplate, setIsTemplate] = useState(false);
  const [customType, setCustomType] = useState(false);
  const [bodyContent, setBodyContent] = useState("<p></p>");
  const [bcc, setBcc] = useState(null);
  let notification = {
    open: false,
    message: "",
    color: "success",
  };
  const [Notify, setNotify] = useState(notification);

  const getTemplateDetails = async () => {
    debugger;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "Templates",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { admin: user.admin, type: type },
      });
    let  registerRequestIntegration = await API_POST("action/getdata", {
        root: "Integrations",
        //Change according to roleid --only visible agent and field executive
        //con: { role: user && user.role ,cid:cid },
        con: { admin: user.admin, $or:[{useInWhatsapp:true},{name: 'Interakt'}] },
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
        let d=registerRequest && registerRequest.data;
        if(registerRequestIntegration.data){
         
          registerRequestIntegration.data.map((val)=>{ val.integrationNameData=val.name;  val.name=val.integrationName;   val.apiFor=val.name; d.push(val) });
        }
        setTemplateList(d);
        //replaceTemplateandSend();
      }
    } catch (error) { }
  };

  const replaceTemplateandSend = async () => {
    debugger;

 

    if(SelectedTemplate.useInWhatsapp && SelectedTemplate.integrationNameData == 'Watti'){

    let headers = {
        'Authorization': 'Bearer '+SelectedTemplate.apiKey,
    };
   
   // alert(JSON.stringify(formValues))
    let bodyValue = SelectedTemplate.parameters;
    let payload={
        "broadcast_name": "string",
        "template_name": SelectedTemplate.templateName,
    }

    if(bodyValue.length && bodyValue[0].value){
      payload.parameters = bodyValue.map((val)=>{ if(prospects[val.value]){ val.value=prospects[val.value]; } return val; });
    }


    let url = SelectedTemplate.api.replace("${phone}", prospects.PhoneCode+prospects.phone);
    try{
      const response = await axios.post(url, payload,{ headers });
   
      setNotify({
          open: true,
          message: 'Message Sent',
          color: "success",
        });
      }catch(e){
        setNotify({
          open: true,
          message: e.message,
          color: "success",
        });
  
      }
      return true;
    }

   

    if(SelectedTemplate.integrationNameData=='Interakt'){
      const headers = { 
        'Authorization': 'Basic '+SelectedTemplate.apiKey,
    };
    let formfilter = SelectedTemplate.body.filter((v)=> v.key);
    let d=formfilter.map((val,key)=>{ return {'key':Object.keys(val)[0],'value':Object.values(val)[0]} });
    d.sort((a, b) => a.key - b.key)
    let payload={
        "countryCode": "+91",
        "phoneNumber": prospects.phone, //prospects.phone
        "callbackData": "some text here",
        "type": "Template",
        "template": {
            "name": SelectedTemplate.templateName,
            "languageCode": "en",
        }
    }

    if(SelectedTemplate.header){
      payload.template.headerValues = [SelectedTemplate.header];
    }
    if(d && d.length){
       payload.template.bodyValues = d.map((val)=> prospects[val.value] ? prospects[val.value] : `_${val.value}_` );
    }

    try{
    const response = await axios.post(SelectedTemplate.api, payload,{ headers });
 
    setNotify({
        open: true,
        message: response.data.message,
        color: "success",
      });
    }catch(e){
      setNotify({
        open: true,
        message: e.message,
        color: "success",
      });

    }



      return true;
    }


    let request = {
      email: prospects.email,
      bcc,
      SelectedTemplate,
      bodyContent,
      pid,
      phone: prospects.phone,
      PhoneCode: prospects.PhoneCode,
      prospect: prospects,
      user: user
    };

    let response = await sendCommByTemplate(request);
    if (response != 0) {
      setNotify({
        color: "success",
        message: "Message sent successfully.",
        open: true
      });
    }
    else{
      setNotify({
        color: "danger",
        message: "Message not sent please contact your administrator.",
        open: true
      });
    }
    setIsTemplate(false);

    // let registerRequest;
    // let requestPayload;
    // try {
    //   if (type == "email") {
    //     requestPayload = {
    //       commType: "email",
    //       msgObject: {
    //         to: prospects.email,
    //         bcc: bcc,
    //         subject: SelectedTemplate.subject,
    //         html: bodyContent
    //       },
    //       tid: SelectedTemplate._id,
    //       pid: pid
    //     }
    //   }
    //   if (type == "whatsapp") {
    //     requestPayload = {
    //       commType: "whatsapp",
    //       msgObject: {
    //         to: prospects.phone,
    //         text: bodyContent
    //       },
    //       tid: SelectedTemplate._id,
    //       pid: pid
    //     }

    //     try {

    //       if (SelectedTemplate.setup_id) {
    //         await sendWhatsAppAndSMS(SelectedTemplate.setup_id)
    //       }
    //       else {
    //         let wurl = "https://api.whatsapp.com/send?phone=" + (prospects.PhoneCode == 91 ? "91" : "") + prospects.phone + "&text=" + bodyContent;
    //         window.open(wurl, "_blank");
    //       }
    //     }
    //     catch (e) {

    //     }
    //   }
    //   if (type == "sms") {
    //     requestPayload = {
    //       commType: "sms",
    //       msgObject: {
    //         to: prospects.phone,
    //         text: bodyContent
    //       },
    //       tid: SelectedTemplate._id,
    //       pid: pid
    //     }

    //     try {
    //       if (SelectedTemplate.setup_id) {
    //         await sendWhatsAppAndSMS(SelectedTemplate.setup_id)
    //       }
    //       else {
    //         setNotify({
    //           color: "error",
    //           message: "SMS setup is not ready yet please contact your administrator.",
    //           open: true
    //         });
    //       }
    //       //  let wurl = "https://api.whatsapp.com/send?phone=" + (prospects.PhoneCode == 91 ? "91" : "") + prospects.phone + "&text=" + bodyContent;
    //       //          window.open(wurl, "_blank");
    //     }
    //     catch (e) {

    //     }
    //   }

    //   registerRequest = await API_POST("comm/send", requestPayload);

    //   if (registerRequest && registerRequest.status == 200) {
    //     debugger;
    //     setIsTemplate(false);
    //     //setselectedTemplate(registerRequest && registerRequest.data);
    //     setNotify({
    //       color: "success",
    //       message: "Message sent successfully.",
    //       open: true
    //     });
    //   }
    //   else {
    //     setIsTemplate(false);
    //     setNotify({
    //       color: "danger",
    //       message: registerRequest.message,
    //       open: true
    //     });
    //   }
    // } catch (error) { }
  };


  // const sendWhatsAppAndSMS = async (setup_id) => {
  //   let requestTime;
  //   let callingAPIRes;
  //   let api;

  //   let registerRequest = await API_POST("action/getdata", {
  //     root: "CallingSetup",
  //     con: { _id: setup_id },
  //     cols: {},
  //   });

  //   if (registerRequest && registerRequest.status == 200 && registerRequest.data) {
  //     let callingSetup = registerRequest.data[0];
  //     api = callingSetup.api;

  //     api = replaceAndReadyAPI(api, prospects, user, prospects.phone);

  //     requestTime = new Date();
  //     callingAPIRes = await API_GET(api);

  //     let APILog = {
  //       method: type,
  //       requestURL: api,
  //       requestPayload: {},
  //       requestTime,
  //       responseData: callingAPIRes,
  //       responseTime: new Date(),
  //       trackingId: prospects._id,
  //       user,
  //       admin: prospects.admin
  //     }
  //     await API_POST("action/addRecord", {
  //       root: "APILogs",
  //       body: APILog,
  //     });

  //     return true;
  //   }
  //   return false;

  // }




  useEffect(() => {
    if (IsTemplate) {
      getTemplateDetails();
    }
  }, [IsTemplate]);

  useEffect(() => {
    if (SelectedTemplate) {
      let body;
      if(SelectedTemplate.apiFor=='Interakt' || SelectedTemplate.integrationNameData == 'Interakt' || SelectedTemplate.useInWhatsapp){
         body = SelectedTemplate.name+" Template <br/> Template Name:"+SelectedTemplate.templateName;
         setCustomType(true);
      }else{
         body = CreateBody(SelectedTemplate, prospects);
      }
     
      setBodyContent(body);
    }
  }, [SelectedTemplate]);

  const handleTemplate = () => {
    setIsTemplate(true);
  };

  const showIcon = (type) => {
    if (type == "email") { return <i className="fa fa-envelope" /> }
    if (type == "whatsapp") { return <i className="fa fa-whatsapp" /> }
    if (type == "sms") { return <i className="fa fa-commenting" /> }
    return <></>;
  }

  return (
    <>
      {prospects && prospects.email && type == "email" &&
        <Button
          title={type}
          justIcon
          color="transparent"
          size="sm"
          onClick={handleTemplate}
        > {
            showIcon(type)
          }
        </Button>
      }
      {prospects && prospects.phone && type == "whatsapp" &&
        <Button
          title={type}
          justIcon
          color="transparent"
          size="sm"
          onClick={handleTemplate}
        > {
            showIcon(type)
          }
        </Button>
      }
      {prospects && prospects.phone && type == "sms" &&
        <Button
          title={type}
          justIcon
          color="transparent"
          size="sm"
          onClick={handleTemplate}
        > {
            showIcon(type)
          }
        </Button>
      }
      <Snackbar
        place="tr"
        color={Notify.color}
        message={Notify.message}
        open={Notify.open}
        autoHideDuration={3000}
        closeNotification={() => setNotify({ open: false })}
        close
      />



      <Dialog open={IsTemplate} maxWidth="md">
        <DialogTitle id="customized-dialog-title" onClose={() => setIsTemplate(false)}>
          Select Template
        </DialogTitle>
        <DialogContent>
          <GridContainer >
            <GridItem xs={12} sm={12} md={12}>

              <Autocomplete
                onChange={(event, value) => setSelectedTemplate(value)}
                id="combo-box-demo"
                options={templateList}
                size="small"
                labelText="SelectTemplate"
                name="autoComplete"
                value={SelectedTemplate || null}
                getOptionLabel={(templateList) => templateList.name || ""}
                defaultValue="Select"
                renderTags={(value) =>
                  value.map(() => (
                    <Chip variant="outlined" label="Enter Template" />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Template"
                    variant="outlined"
                  />
                )}
              />
              <br />
            </GridItem>
            {SelectedTemplate && type == "email" && !customType && <>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="BCC *"
                  id="bcc"

                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: bcc,
                    name: "bcc",
                    onChange: (e) => { setBcc(e.target.value) },
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                Subject : {SelectedTemplate && SelectedTemplate.subject}
              </GridItem>

            </>}

            <GridItem xs={12} sm={12} md={12} >
              <pre><div className="templateBody" dangerouslySetInnerHTML={{ __html: bodyContent }}></div></pre>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>

          <GridItem xs={12} sm={12} md={12}>
            <Button
              size="sm"
              onClick={() => {
                replaceTemplateandSend();
              }}
              color="primary"
            >

              Send
            </Button>
          </GridItem>

        </DialogActions>
      </Dialog>

    </>
  );
}

TemplatePopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(TemplatePopup);
