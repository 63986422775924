import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import UserDropdown from "../common/UserDropdown";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import Filters from "../Schedule/Filters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Snackbar } from "@material-ui/core";
import CallFilters from "../Activity/CallFilters";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import Loader from "components/Loader/Loader.jsx";
import Tags from "../Forms/Tags";
import InfoBox from "../../components/InfoIcon/InfoIcon";




const handleURL = () => {
    window.localStorage.setItem('LastURL', window.location.href);
};

const customCell = (value) => {
    let val = "";
    if (value.firstName && value.lastName) {
        val = value.firstName + " " + value.lastName;
    } else if (value.firstName) {
        val = value.firstName;
    } else {
        val = value.phone;
    }
    return <Link onClick={handleURL} to={"/admin/ViewProspect/" + value._id}>{val}</Link>;
};






function DispositionDetails(props) {
    const { user } = props;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [skip, setSkip] = React.useState(0);
    const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [open, setOpen] = useState(false);
    const [IsRefresh, setIsRefresh] = useState(false);
    const [columnslist, setColumns] = useState([]);
    const [team, setTeam] = useState([]);
    const [tag, setTag] = useState("");
    const [Query, setQuery] = useState("");
    const [TotalInfo, setTotalInfo] = useState({});
    const [disposition, setDisposition] = useState(props.disposition);
    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
    });
    const [loader, setLoader] = useState(true);
    const [backHome, setBackHome] = useState(false);
    var tempTotal = {};

    //   const columns=props.column;

    let Option = {
        "title": {
            "text": "Disposition Data"
        },
        "tooltip": {
            "pointFormat": "<span style=\"color:{point.color};\">{series.name}</span>: <b>{point.y}</b>",
            "backgroundColor": "white"
        },
        "chart": {
            "type": "pie",
            "events": {
                click: function () {
                    console.log('X: ' + this.x + ', Y: ' + this.y);
                    //call function passing this values as arguments
                }
            }
        },
        "plotOptions": {
            "series": {
                "cursor": "pointer",
                "dataLabels": {
                    "color": "#428bca",
                    "distance": -30,
                    "enabled": true,
                    "backgroundColor": "white",
                    "format": "{point.percentage:.1f}%"
                },
                "showInLegend": true,
                "allowPointSelect": true
            }
        },
        "series": [
            {
                "name": "Disposition",
                "id": "Disposition",
                "colorByPoint": true,
                "data": null,
                "events": {
                    click: function (e) {
                        updateData(e.point.options);
                        console.log(e.point.options);
                        console.log('X: ' + e.x + ', Y: ' + e.y);
                        //call function passing this values as arguments
                    }
                }
            }
        ]

    }

    let chartOption = { ...Option };





    let columns = [];
    columns.push({
        name: "User",
        cell: (row) => (
            <span>{row.username}</span>
          ),
        width: '220px',
    });

    const updateData = (data) => {

        let disId = data._id;
        //  alert(disId);
        if (disId) {
            setBackHome(true);
            loadDisposition(disId);
        }



    }

    const getColName = (selector, id = false) => {
        for (let index = 0; index < columnslist.length; index++) {
            const element = columnslist[index];
            if (element.selector == selector) {
                if (id) {
                    return element.id;
                }
                return element.name;
            }

        }
    }

    const bindChart = () => {

        let _chartOption = { ...chartOption };
        _chartOption.series[0]['data'] = null
        let obj = [];
        for (const key in TotalInfo) {
            if (Object.hasOwnProperty.call(TotalInfo, key)) {
                const element = TotalInfo[key];
                if (!isNaN(element)) {
                    obj.push({
                        "y": element,
                        "name": getColName(key),
                        "id": key,
                        "_id": getColName(key, true)
                    })
                }
            }
        }
        _chartOption.series[0]['data'] = obj


        return _chartOption;
    }

    const loadDisposition = async (id = null) => {
        debugger;
        let registerRequest;
        let addededDispositioin = [];
        setLoader(true);
        try {
            let conQuery = { admin: user && user.admin, parentId: null };
            if (id) {
                conQuery = { ...conQuery, parentId: id };
            }

            registerRequest = await API_POST("action/getdata", {
                root: "dispositions",
                con: conQuery
            });

            if (registerRequest && registerRequest.status == 200) {


                setDisposition(registerRequest && registerRequest.data);

                for (let i = 0; i < registerRequest.data.length; i++) {

                    let disposition = registerRequest.data;
                    if (!addededDispositioin.includes(disposition[i].disposition_value)) {
                        columns.push({
                            name: disposition[i].disposition_value,
                            selector: disposition[i].disposition_value,
                            id: disposition[i]._id,
                            cell: (row) => (

                                <>

                                    {
                                        //  alert(JSON.stringify(row[disposition[i].disposition_value]))
                                    }

                                    {


                                        row && <Link to={'/admin/ListProspects?query=' + btoa(JSON.stringify(row[disposition[i].disposition_value].query))}>{row[disposition[i].disposition_value].data}</Link>

                                    }

                                </>
                            ),
                        });
                        addededDispositioin.push(disposition[i].disposition_value);
                    }

                }

                setColumns(columns);



                // await loadData();

            }



        } catch (error) {
            console.log(error)
            //registerRequest = response;
        }
    }

    const loadTeam = async () => {
        console.log("user", user);
        let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${user && user.admin_id}`);
        if (userInfo.success && userInfo.data.teams_membership) {
            let team = userInfo.data.teams_membership;

            let teamData = [];

            let _options = [];
            team.forEach((element) => {

                _options.push({
                    key: element.username,
                    value: element.username,
                });
            });
            setTeam(_options);
        }
    }

    useEffect(async () => {

        loadDisposition();

        loadTeam();
    }, [user]);

    useEffect(async () => {


        loadData();



    }, [disposition, team]);


    const handleExportExcel = async (e) => {

        let Query = { ...Query, userList: team, admin: user && user.admin };
        let downloadFile = await API_POST(`action/exportDispositionWiseResult`, {
            querydata: Query,
            body: { admin: user.admin, user: user.username, page: "DispositionDetails" },
        });
        if (downloadFile.status == 200) {
            setNotify({
                open: true,
                message: "File is in process,Please check in system log section.",
                color: "success",
            });
        }
    };


    const loadData = async () => {
        let datas = [];


        var promiseRequest = new Promise(async resolve => {

            for (let u = 0; u < team.length; u++) {
                let temp = {};
                temp.username = team[u].value;


                let query = { LastCaller: team[u].value, admin: user.admin, dispositionList: disposition };
                if (selectedCampaign && selectedCampaign.length > 0) {
                    query = { ...query, cid: { $in: selectedCampaign } }
                }
                if (tag) {
                    query = { ...query, tags: { $elemMatch: { tag_value: tag } } }
                }

                if (startDate) {
                    /*                  query={...query,
                                          createdOn: {
                                              $gte: startDate,
                                              $lt: endDate,
                                          }}*/
                }
                setQuery(query);
                let dataProspect = await API_POST("action/getDispositionWiseResult", {
                    root: "prospects",
                    con: query,
                });


                if (dataProspect.data) {
                    temp = { ...temp, ...dataProspect.data };




                    for (let [key, value] of Object.entries(dataProspect.data)) {
                        tempTotal[key] = tempTotal[key] > 0 ? tempTotal[key] + value.data : value.data;

                    }

                }


                datas.push(temp);
                if (u == team.length - 1) {
                    resolve();
                }

            }


        })


        promiseRequest.then(res => {
            //  alert(JSON.stringify(columnName));
            setData(datas);
            setTotalInfo(tempTotal);
            setLoader(false);
            //   setTotalInfo()


        })

    }

    const filterData = (data) => {
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        //  setSelectedUser(data.selectedUser);
        setSelectedCampaign(data.SelectedCampaign);
        setTag(data.selectedTag);
        // setIsRefresh(true);
        // setTimeout(function(){loadCallRecordData()}, 500)
        setOpen(false);
        // handleUrl(data);
        if (data.selectedUser.length > 0) {
            let _options = [];
            data.selectedUser.forEach((element) => {
                _options.push({
                    key: element,
                    value: element,
                });
            });
            setTeam(_options);
        } else {
            loadTeam();
        }

        //  alert(JSON.stringify(data));
    };

    return (
        <>
            <Snackbar
                place="tr"
                color={notify.color}
                message={notify.message}
                open={notify.open}
                closeNotification={() => setNotify({ open: false })}
                close
            />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    
                    <span style={{ float: "right", fontSize: "15px" }}>
                    <GridItem  style={{float: "left", marginTop: "10px"}}>
                        <InfoBox name={'Report.DispositionReport'}/>
                    </GridItem>
                        {
                            backHome ? (<Button
                                size="sm"
                                title="Back Home"
                                color="info"
                                onClick={() => {
                                    loadDisposition();
                                    setBackHome(false);
                                }}> Back Home </Button>) : null
                        }

                        <Button
                            size="sm"
                            title="Filter"
                            color="info"
                            onClick={() => {
                                setOpen(true);
                            }}

                        >
                            <i className="fa fa-filter" /> &nbsp; Filter
                        </Button>




                        {["manager", "owner"].indexOf(user && user.role) > -1 && (
                            <Button
                                size="sm"
                                color="transparent"
                                justIcon
                                title="Export To Excel"
                                onClick={() => {
                                    handleExportExcel();
                                }}
                            >
                                <GetAppOutlinedIcon />
                            </Button>
                        )}
                    </span>


                </GridItem>
            </GridContainer>
            <Filters
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                IsShowField={true}
                ShowCampaign={true}
                HideMobile={true}
                showTags={true}
                filterData={filterData}
                user={user}
            />

            {TotalInfo ? (
                <>

                    <CardHeader color="transparent">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridItem>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={bindChart()}
                                        allowChartUpdate={true}
                                    />
                                </GridItem>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>


                </>
            ) : null

            }

            {loader ?
                (
                    <Loader />
                ) : null

            }



            <DataTable
                columns={columnslist}
                data={data || []}
                highlightOnHover
                striped
            />




        </>
    );
}

export default DispositionDetails;
