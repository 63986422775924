/* eslint-disable */


import axios from "axios";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import DataTable from "react-data-table-component";
import {API_GET, API_POST, API_UPLOAD} from "../../services/api.service";
import Typography from '@material-ui/core/Typography';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';

import FacebookLogin from 'react-facebook-login';
import Card from "components/Card/Card.jsx";

const { REACT_APP_FACEBOOK_APPID,REACT_APP_FACEBOOK_APPSECREAT } = process.env;
class FacebookPage extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      pageData:[],
      data:[],
      facebookAuth:[],
      activeTab: 0,
      cid: ""
    };
  }




  componentWillMount() {

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    this.setState({ activeTab: parseInt(tab) });

  }

    componentDidMount() {
        if (this.props.user) {
            this.getFacebookPages();
        }
    }


  checkFacebookLogin= async ()=>{
      const {user} = this.props;
      if (user && user.admin) {

          var registerRequest = await API_POST("action/getdata",
              {
                  root: 'Facebook_Auth',
                  con: {admin: user && user.admin},
                  cols: {}
              });

          if (registerRequest && registerRequest.status == 200) {

              this.setState({ facebookAuth: registerRequest });
              console.log(registerRequest);
          }

      }

  }


  getLongAccess= async (access) =>{

      var longAccessToken = await axios( 'https://graph.facebook.com/v13.0/oauth/access_token?grant_type=fb_exchange_token&client_id='+REACT_APP_FACEBOOK_APPID+'&client_secret='+REACT_APP_FACEBOOK_APPSECREAT+'&fb_exchange_token='+access);


      if(longAccessToken.data.access_token){
          return longAccessToken.data.access_token;
      }
      return false;
  }


    getLongPageAccess= async (pageId,access) =>{

        var longAccessToken = await axios( 'https://graph.facebook.com/v13.0/'+pageId+'&fields=access_token&access_token='+access);

        if(longAccessToken.access_token){
            return longAccessToken.access_token
        }
        return false;
    }


  getFacebookPages= async ()=>{
        const {user} = this.props;
        if (user && user.admin) {


            var pages = await API_POST("action/getdata",
                {
                    root: 'Facebook_Pages',
                    con: {admin: user && user.admin},
                    cols: {}
                });
           // this.setState({pageData:});


            let pagesListData={};
            for(let i=0;i<pages.data.length;i++){

                pagesListData[pages.data[i].id]=pages.data[i].name;

            }

      //      this.setState({pagesList:pagesListData});
            console.log(pagesListData);

            var registerRequest = await API_POST("action/getdata",
                {
                    root: 'Facebook_Form',
                    con: {admin: user && user.admin},
                    cols: {}
                });


           // alert(pagesListData);
            if (registerRequest && registerRequest.status == 200) {

                this.setState({ facebookAuth: registerRequest });


                let formData=registerRequest.data;
                for(let i=0;i<formData.length;i++) {

                    formData[i].Pagename = pagesListData[formData[i].PageId];
                }

                //console.log(formData);

                this.setState({ data: formData});
            }

        }

    }


  responseFacebook = async (response) =>{
    console.log(response);
      const {user} = this.props;
    if(! response || response.status=='unknown'){
      return false;
    }


      var FacebookAuth=response;
      FacebookAuth.isActive=1;

     var longAccess= await this.getLongAccess(response.accessToken);

     if(longAccess){

         response.accessToken=longAccess;
     }


      var PageDataRequest = await API_GET( 'https://graph.facebook.com/v13.0/'+response.userID+'/accounts?access_token='+response.accessToken);
      var pageData=PageDataRequest.data;


      FacebookAuth.admin=user && user.admin;
      FacebookAuth.createdOn= new Date();
      FacebookAuth.userID=response.userID;

      let FacebookAuthRequest = await API_POST("action/upsert",{
          root: "Facebook_Auth",
          body: FacebookAuth,
          querydata: { admin: user.admin,userID:response.userID },
      });

      if (FacebookAuthRequest && FacebookAuthRequest.status == 200) {
        await  this.handlePages(pageData,response);
      }
  }


  handlePages = async (pageData,auth) =>{

      const {user} = this.props;
      pageData.forEach(async (element) => {

          let userSubscribed=false;
          var subs = await API_POST( 'https://graph.facebook.com/v13.0/'+element.id+'/subscribed_apps', {access_token: element.access_token, subscribed_fields: ['leadgen']},);
          if(subs.success){
              userSubscribed=true;
          }



          if (subs && subs.success) {
              element.PageId = element.id;
              element.admin = user && user.admin;
              element.createdOn = new Date();
              element.createdOn = new Date();
              element.isActive = userSubscribed;
              let FacebookAuthRequest = await API_POST("action/upsert", {
                  root: "Facebook_Pages",
                  body: element,
                  querydata: {admin: user.admin, PageId: element.id},
              })


              if(FacebookAuthRequest.status==200) {
                  await this.getPageForms(element.id, element.access_token)
              }


          }

      });

     await this.getFacebookPages();

  };


  getPageForms= async (id,access) =>{
      var formDetails = await axios( 'https://graph.facebook.com/v13.0/'+id+'/leadgen_forms?access_token='+access);
      const {user} = this.props;
   //   console.log(formDetails.data.data);

      for(let i=0;i<formDetails.data.data.length;i++) {

          let formData = formDetails.data.data[i];
          formData.createdOn = new Date();
          formData.createdOn = new Date();
          formData.isActive=formData.status;
          formData.PageId=id;
          let FacebookAuthRequest = await API_POST("action/upsert", {
              root: "Facebook_Form",
              body: formData,
              querydata: {admin: user.admin, PageId: id,id:formData.id},
          })

      }

  }

      Refresh= async (e) =>{



      }

    handleLeads = async (e) =>{

        const { history } = this.props;
        history.push('/admin/FacebookLeads/' + e._id)

        return false;


    }

  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTab: value });
    history.push('?tab=' + value)
  };

  render() {
    const { user } = this.props;
    const {data}= this.state;

    const columns = [
      {
        name: "Name",
        selector: "name",
      },

        {
            name: "IsActive",
            cell: (row) => <span>{row.isActive ? "Yes" : "No"}</span>,
        },
        {
            name: "PageName",
            selector: "Pagename",
        },
        {
            name: "Created On",
            cell: (row) => (
                <span>
          {row.createdOn &&
          window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
        </span>
            ),
        },
        {
            name: "Action",
            cell: (row) => (
                <Button
                    size="sm"
                    title="View Leads"
                    color="info"
                    onClick={() => {
                       this.handleLeads(row);
                    }}
                >
                    View Leads
                </Button>
            ),
        },
    ];

    return (


          <Card>
            <CardHeader color="transparent">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                  <h3>
                    Facebook Lead Integration
                    <span style={{ float: "right", fontSize: "15px" }}>
                        <FacebookLogin
                            appId={REACT_APP_FACEBOOK_APPID}
                            autoLoad={false}
                            reAuthenticate={true}
                            returnScopes={true}
                            redirectUri={window.location.href}
                            fields="name,email,picture"
                            scope='pages_show_list,ads_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_manage_ads'
                            callback={this.responseFacebook}
                        />



                </span>
                  </h3>
                </GridItem>
              </GridContainer>
            </CardHeader>


            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <DataTable
                    columns={columns}
                    data={data || []}
                    highlightOnHover
                    pagination
                    paginationPerPage={"10"}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                />
              </GridItem>
            </GridContainer>


          </Card>



    );
  }
}
export default withStyles(dashboardStyle)(FacebookPage);
