import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { API_GET, API_POST } from "../../services/api.service";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function ProspectHistoryFilter(props) {
  let query = [];
  const { open, user, preserveQuery } = props;
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [source, setSource] = useState("")
  const [campaignOptions, setcampaignOptions] = useState([])
  const [campaign, setcampaign] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [assignTo, setAssignTo] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [disposition, setDisposition] = useState([]);
  const [dispValue , setDispValue] = useState(null)
  const [allStatus, setAllStatus] = useState([
    { key: "Inserted", value: "Inserted" },
    { key: "DuplicateData", value: "DuplicateData" },
    { key: "RepeatedData", value: "RepeatedData" },
    { key: "InvalidMobile", value: "InvalidMobile" },
    { key: "PhoneNotFound", value: "PhoneNotFound" },
    { key: "UpdateData", value: "UpdateData" },



  ]);


  const loadCamp = async () =>{

    let cmp = await API_POST(`action/getdata`, {
      root:"campaigns",
      con: {admin: user && user.admin,IsActive:true }
    });

    let _options = [];
    cmp.data.forEach((element) => {
      _options.push({
        key: element._id,
        value: element.name,
      });
    });

    setcampaignOptions(_options)

  }

  const getCampaignUsers = async () => {
    debugger;
    if (user && user.admin && user.admin != 'undefined') {
    let userInfo = await API_GET(
      `register/UserInfoByUserName?username=${user.admin}`
    );
    if (userInfo.success && userInfo.data.all_members) {

      let _options = [];
      userInfo.data.all_members.forEach((element) => {
        _options.push({
          key: element.username,
          value: element.username+ ' ('+element.first_name+')',
        });
      });

      setAllUsers(_options);
    }
    }
  };

    // Function to generate tree structure
    const  generateTree= async (records, parentId = null) => {
      let returnVal = [];
      if (records && records.length > 0) {
        let _customFields = records;
        for (let index = 0; index < _customFields.length; index++) {
          const element = _customFields[index];
          if (element.parentId == parentId) {
            element['children'] = await generateTree(records, element._id)
            returnVal.push(element)
          }
        }
      }
      return returnVal;
    }
  
    const  generateDropdownOptions= async (tree, parentHierarchy = '')=> {
      let options = '';
      let optionarray = [];
      for (let index = 0; index < tree.length; index++) {
        const node = tree[index];
        const nodeText = parentHierarchy ? `${parentHierarchy} > ${node.disposition_value}` : node.disposition_value;
        //options += `<option value="${node.id}">${nodeText}</option>`;
        optionarray.push({key:node._id,value:nodeText});
        if (node.children) {
          let tmp= await generateDropdownOptions(node.children, nodeText);
          console.log("tmp..",tmp)
          tmp && tmp.map(v=> optionarray.push({key:v.key,value:v.value}) )
       
          //options += tmp;
         
        }
      }
      return optionarray;
    }
  
  
    const loadDisposition = async () =>{
  
      let registerRequest = await API_POST("action/getdata",
      {
        root: 'dispositions',
        con: { admin: props.user && props.user.admin },
        cols: {}
      });
  
      let records = registerRequest.data
  
      // Generate the tree
      const tree = await generateTree(records);
      console.log(tree)
      // Generate dropdown options
      const dropdownOptions = await generateDropdownOptions(tree);
      
      console.log("ddddddddddd",dropdownOptions)
      setDisposition(dropdownOptions);
  
  
  }

  useEffect(()=>{
    loadCamp();
    getCampaignUsers();
    loadDisposition()
  },[user])
  
  const handleSearch = () => {
   
    let query ;
    if (SelectedStatus && SelectedStatus.length > 0) {
      query = { ...query, updateStatus: { ["$in"]: SelectedStatus } };
    }
    if(mobileNo){
      query = { ...query,phone:mobileNo };
    }

    if(startDate){
      query = { ...query,createdOn:{ $gte: startDate , $lte : endDate} };
    }
   
    if(source){
      query = { ...query,source:{ $regex: source , $options:'i'} };
    }

    if(campaign){
      query = { ...query,cid:campaign };
    }

    if(assignTo){
      query = { ...query,assignTo:assignTo };
    }

    if(dispValue){
      query = {...query,disposition: { ["$in"]: [dispValue] }}
    }



    if(SelectedStatus && SelectedStatus.length){
      query = { ...query,updateStatus:SelectedStatus };
    }

    props.filterData(query);
    props.handleClose();
  };

  return (
    <GridContainer>
      <Dialog open={open}>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
              <InfoBox name={'SystemLog.ProspectLog.Filter'} />
            </GridItem>

          <CustomInput
                  labelText="Status"
                  id="Status"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    type:'select',
                    required: true,
                    value: SelectedStatus,
                    name: "phone",
                    options: allStatus,
                    onChange: (e)=>{setSelectedStatus(e.target.value)},
                  }}
                />


          <CustomInput
                  labelText="MobileNo"
                  id="MobileNo"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    required: true,
                    value: mobileNo,
                    name: "phone",
                    onChange: (e)=>{setMobileNo(e.target.value)},
                  }}
                />

<CustomInput
                  labelText="Campaign"
                  id="Campaign"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    type:'select',
                    required: true,
                    value: campaign,
                    name: "Campaign",
                    options: campaignOptions,
                    onChange: (e)=>{setcampaign(e.target.value)},
                  }}
                />

<GenerateField
                    value={dispValue}
                    onChange={(e)=>{setDispValue(e.target.value)}}
                    id={"disposition"}
                    name={"disposition"}
                    label={"disposition"}
                    type="select"
                    keyValuePair={disposition}
                  />


<CustomInput
                  labelText="Assign To"
                  id="assignTo"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    type:'select',
                    required: true,
                    value: assignTo,
                    name: "assignTo",
                    options: allUsers,
                    onChange: (e)=>{setAssignTo(e.target.value)},
                  }}
                />




<CustomInput
                  labelText="Source"
                  id="source"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    required: true,
                    value: source,
                    name: "source",
                    onChange: (e)=>{setSource(e.target.value)},
                  }}
                />      

<GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <CustomInput
                    style="dashboard_button"
                    id="startdate"
                    labelText="Select Date"
                    labelProps={{ shrink: false }}
                    formControlProps={{
                      fullWidth: true,
                      multiline: false,
                    }}
                    inputProps={{
                      type: "date_range",
                      initialDateRange: {
                        startDate: startDate,
                        endDate: endDate,
                      },

                      showFetch: false,
                      onChange: (e) => {
                        setStartDate(e.startDate);
                        setEndDate(e.endDate);
                      },
                      onClick: () => {
                        //loadFollowUpData();
                      },
                    }}
                  />
                </GridContainer>
              </GridItem>


           
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleSearch} color="primary">
            Apply
          </Button>
        
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

ProspectHistoryFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProspectHistoryFilter);

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
