import React, { useEffect } from "react";
import "./Styles/clock.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import Button from "components/CustomButtons/Button.jsx";
import Typography from "@material-ui/core/Typography";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

function Clock() {
  const [date, setDate] = React.useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <>
      <Button color="transparent" size="sm">
        <Typography className="clock" variant="h6" component="h2">
          {date.toLocaleTimeString()}
        </Typography>
      </Button>
    </>
  );
}

export default withStyles(dashboardStyle)(Clock);
