/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

// import { Typeahead } from "react-bootstrap-typeahead";
import { API_POST, API_UPLOAD } from "../../services/api.service";
import { FormGroup } from "@material-ui/core";

const { REACT_APP_SERVER_URL } = process.env;
//import 'react-bootstrap-typeahead/css/Typeahead.css';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  searchOption: {
    background: "#ffffff 0% 0% no-repeat padding-box",
    boxShadow: "0px 6px 6px #00000029",
    zIndex: "1",
    position: "absolute",
    borderRadius: "16px",
    padding: "18px 6px 18px 12px",
    width: "100%",
    "& ul": {
      listStyleType: "none",
      maxHeight: "220px",
      overflowY: "auto",
      overflowX: "hidden",
      paddingRight: "9px",
      "& p": {
        textAlign: "left",
        font: "normal normal normal 12px/16px Roboto",
        letterSpacing: "0px",
        color: "#000000",
        marginBottom: "3px",
      },
      "& span": {
        font: "normal normal normal 12px/16px Roboto",
        letterSpacing: "0px",
        color: "#808080",
        cursor: "pointer",
      },
    },
  },
  searchExpand: {
    width: "175px!important",
    backgroundColor: "#fff",
    borderColor: "#707070",

    backgroundPosition: "95% center !important",
    WebkitBoxShadow: "0px 6px 6px #00000029",
    MozBoxShadow: "0px 6px 6px #00000029",
    BoxShadow: "0px 6px 6px #00000029",
  },
};

class CommonMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FileName: "",
      prospectsFields: [],
      headerfields: [],

      selectedFile: null,
      searchInput: "",
      SearchText: [],
      selectValue: [],
      IsShowUploadheader: false,
      mappingobj: {},
      SuggestionShow: false,
      UploadFileId: "",
      prospectFields: [],

      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  openUploadDialog = () => {
    debugger;
    let data = this.props.MappingInSingleObj;
    //let data = { name: "Nav", age: 31, city: "New York" };
    this.UploadFile(data);
  };

  handleCancel = () => {
    this.setState({ IsShowUploadheader: false });
  };
  componentDidMount() {
    if (this.props.MappingInSingleObj) {
      this.loadprospectFileds();
      if (this.props.isEdit) {
        this.setState({ selectValue: this.props.MappingWithMulObject });
      }
    }
  }

  async UploadFile(data) {
    debugger;
    let keys = Object.keys(data);
    let option = this.state.prospectFields.map((option) => option.field_name);

    this.setState({ IsShowUploadheader: true });
    this.setState({ headerfields: keys });
    this.setState({ prospectsFields: this.state.prospectFields });
  }

  handleChange = (i, val, e) => {
    console.log(e.target.innerText);

    let field = e.target.innerText;
    if (e.target.innerText === "") {
      field = e.target.value;
    }

    //this.state.selectValue = this.state.selectValue.filter((n) => !prospectsFields.includes(n));
    let IsExist = false;
    if (field === "") {
      return;
    }
    for (let index = 0; index < this.state.selectValue.length; index++) {
      const element = this.state.selectValue[index];
      if (val === Object.keys(element)[0]) {
        this.state.selectValue[index] = { [val]: field };
        IsExist = true;
        return;
      }
    }
    if (!IsExist) this.state.selectValue.push({ [val]: field });
  };

  SaveHeaderInProspectCollection = async (selectValue) => {
    debugger;
    let registerRequest;
    const { user } = this.props;
    for (let i = 0; i < selectValue.length; i++) {
      let element = selectValue[i];

      if (element != null && element != undefined && element != "") {
        registerRequest = await API_POST("action/upsert", {
          root: "prospect_fields",
          body: {
            display_Name: element,
            createdOn: new Date(),
            field_type: "text",
            isRequired: false,
            inTabView: false,
            field_options: "",
          },
          //querydata: { field_name: element, admin: user && user.admin },
          querydata: { field_name: element, admin: this.props.user.username },
        });

        if (registerRequest && registerRequest.status == 200) {
          // this.setState({
          //   notify: {
          //     open: true,
          //     message: "Fields saved successfully.",
          //     color: "success",
          //   },
          // });
        }
      }
    }
  };

  SaveMappingCollection = async () => {
    debugger;
    let registerRequest;
    console.log(this.state.selectValue);
    const { user } = this.props;

    registerRequest = await API_POST("action/update", {
      root: "filesCollection",
      body: { Mapping: this.state.selectValue },
      querydata: { _id: this.state.UploadFileId },
    });

    registerRequest = await API_POST("action/UploadExcelInDB", {
      fileId: this.state.UploadFileId,
      user: user && user.admin,
      IsCheckedCampain: this.state.IsCheckedCampain,
    });

    if (registerRequest && registerRequest.status == 200) {
      this.setState({
        notify: {
          open: true,
          message: "We are updating you data! Please wait...",
          color: "success",
        },
      });
      this.handleCancel();
    }
  };

  handleAdd = async () => {
    debugger;

    let mappingObj = this.props.MappingInSingleObj;

    // let selectValue = [];
    let selectValue = this.state.selectValue.map(
      (option) => Object.values(option)[0]
    );

    console.log("SelectedVal1", Object.keys(this.state.selectValue).length);
    console.log(
      "SelectedVal2",
      Object.keys(this.props.MappingInSingleObj).length
    );

    // if (
    //   Object.keys(this.state.selectValue).length !=
    //   Object.keys(this.props.MappingInSingleObj).length
    // ) {
      // this.setState({
      //   notify: {
      //     open: true,
      //     message: "Please fill all columns",
      //     color: "danger",
      //   },
      // });
    //   return;
    // }

    // if (
    //   this.state.selectValue.hasownproperty("phone") > -1 ||
    //   this.state.selectValue.hasownproperty("phone") > -1 ||
    //   this.state.selectValue.hasownproperty("PHONE") > -1 ||
    //   this.state.selectValue.hasownproperty("Phone") > -1 ||
    //   this.state.selectValue.hasownproperty("MOB") > -1   ||
    //   this.state.selectValue.hasownproperty("Mob") > -1 ||
    //   this.state.selectValue.hasownproperty("mob") > -1
    // ) {
    let prospectsFields = this.state.prospectsFields.map(
      (option) => option.field_name
    );

    selectValue = selectValue.filter((n) => !prospectsFields.includes(n));

    //--------------- Save headers in prospect_fields --------------------------------//
    await this.SaveHeaderInProspectCollection(selectValue);
    this.props.bindMapping(this.state.selectValue);
  };

  handleRefresh = () => {
    window.location.reload();
  };

  RebindHeader = (data, row) => {
    debugger;
    //this.state.UploadFileId

    let option = data.prospectFields.map((option) => option.field_name);
    let compareOption = data.prospectFields.map((option) =>
      option.field_name.toLowerCase()
    );

    for (let index = 0; index < data.headers.length; index++) {
      debugger;
      const element = data.headers[index];
      let compareIndex = compareOption.indexOf(element.toLowerCase());
      if (compareIndex > -1) {
        this.state.selectValue.push({ [element]: option[compareIndex] });
      }
    }
    this.setState({
      UploadFileId: row._id,
      IsShowUploadheader: true,
      headerfields: data.headers,
      prospectsFields: data.prospectFields,
    });
  };

  handleCheck = (event) => {
    let cheked = event.target.checked;
    this.setState({ IsChecked: cheked, IsCheckedCampain: cheked });
  };

  loadprospectFileds = async () => {
    debugger;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "prospect_fields",
        con: { admin: this.props.user.username },
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
        if (registerRequest.data && registerRequest.data.length > 0) {
          this.setState({ prospectFields: registerRequest.data });
          this.openUploadDialog();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { user, cid, campaignsDetails, MappingInSingleObj } = this.props;
    const options = this.state.prospectsFields.map(
      (option) => option.field_name
    );

    let compareOptions = this.state.prospectsFields.map((option) =>
      option.field_name.toLowerCase()
    );

    console.log(options,this.state.headerfields,compareOptions);

    var tableData = [];
    for (let index = 0; index < this.state.headerfields.length; index++) {
      const element = this.state.headerfields[index];
      let compareIndex = -1;
      if (this.props.isEdit) {
        let val = MappingInSingleObj[element];
        
        compareIndex = compareOptions.indexOf(val && val.toLowerCase());
      } else {
        compareIndex = compareOptions.indexOf(element.toLowerCase());
      }

      console.log("compareIndex", compareIndex);

      tableData.push([
        element,
        <FormGroup>
          <Autocomplete
            id="tags-filled"
            options={options}
            onChange={this.handleChange.bind(this, index, element)}
            onBlur={this.handleChange.bind(this, index, element)}
            value={compareIndex > -1 ? options[compareIndex] : ""}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} autoComplete="off" variant="filled" />
            )}
          />
        </FormGroup>,
      ]);
    }
    return (
      <>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          autoHideDuration={3000}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>Data</CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["File Header", "System fields"]}
                  tableData={tableData}
                />
                {/* <Button
                  color="success"
                  size="sm"
                  onClick={() => this.handleAdd()}
                >
                  Save
                </Button> */}
                {/* <Button
                  color="success"
                  size="sm"
                  onClick={() => this.handleCancel()}
                >
                  Cancel
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default withStyles(styles)(CommonMapping);
