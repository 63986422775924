import React, { Suspense, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import Loader from "components/Loader/Loader.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import JsonMapping from "components/JsonMapping/JsonMapping.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
    API_GET,
    API_POST
} from "../../services/api.service";


function AddHook(props){
    const { classes, user, open , defaultData } = props;

    const [openPopUp, setOpenPopUp] = useState(false);
    const [hookData, setHookData] = useState(null);

    
    
    useEffect(() => {
        setOpenPopUp(open)
    }, [open]);

    useEffect(() => {
        console.log("defaultData", defaultData)
        console.log("hookData", hookData)
        
        setHookData({...defaultData});
        
    }, [defaultData]);
    useEffect(() => {
        
        console.log("hookData", hookData)        
        setHookData(hookData);
        
    }, [hookData]);
    



   const handleClose = async (e) => {   
    //setOpenPopUp(false);
    props.handleClose(false);
   };     
   const handleSave = async (e) => {  
    console.log(hookData);

    hookData.admin = user && user.admin;
    let registerRequest;
    try {
        registerRequest = await API_POST("action/upsert", {
          root: "Webhooks",
          body: {api : hookData.api, method: hookData.method, values: hookData.values, Mapping: hookData.Mapping},
          querydata: { admin: hookData.admin, module: hookData.module },
          
        });
  
        if (registerRequest && registerRequest.status == 200) {
            props.handleClose(false);
            props.handleSave(1);
        }
      } catch (error) {
        console.log(error);
      }

   };     
   const handleChange = async (e) => {           
        let formValue = {...hookData};    
        if (e.target && e.target.type === "checkbox") {
          formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
          formValue[props] = e.format();
        } else {
          formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }    
        setHookData(formValue);
    };

    const handleChangeMapping = async (mapping) => {   
        console.log("mapping", mapping);

        let formValue = {...hookData};  
        formValue.Mapping = mapping;
        console.log("formValue", formValue);
        
        setHookData(formValue);
    };

    return (<GridContainer>
        <Dialog open={openPopUp} fullWidth={true} maxWidth='md'>
          
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Hook Setup
          </DialogTitle>
        
          <DialogContent>
            <GridContainer>
  
              
                <GridItem xs={12} sm={6} md={6}>
                    
                    <GenerateField
                      value={hookData && hookData.module}
                      onChange={handleChange}
                      id={"module"}                      
                      label={"Module"}  
                      disabled={true}                    
                      options={"CallDetails,CallRecording"}
                    />
  
                  <CustomInput
                    id="api"
                    labelText={"API *"}
                    labelProps={{ shrink: true }}
                    formControlProps={{
                      fullWidth: true,
                      multiline: false,
                    }}
                    inputProps={{
                            required: true,
                            value: hookData && hookData.api,
                            name: "api",
                            onChange: handleChange,
                          }}
                    
                  />

                <GenerateField
                      value={hookData && hookData.method}
                      onChange={handleChange}
                      id={"method"}                      
                      label={"Method"}                      
                      options={"GET,POST"}
                    />
                  
                </GridItem>
  
                <GridItem xs={12} sm={6} md={6}>
                    <h5>Parameters</h5>
                    <JsonMapping mapping={hookData && hookData.Mapping} disable={"value"} onChange={handleChangeMapping} ></JsonMapping>
                </GridItem>
              
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
  
          </DialogActions>
        </Dialog>
      </GridContainer>)
}


function WebHooks(props) {

    const { classes, user, module } = props;
    //const options = { appId: '3100942573473121', version: 'v10.0' };
    
    const [open, setOpen] = useState(false);
    const [selectedHook, setSelectedHook] = useState({});
    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
      });
    const [campaignList, setCampaignList] = useState([
        {        
            "admin" : "",
            "module" : "CallDetails",
            "api" : "",
            "method" : "",        
            "Mapping" : [ 
                {
                    "" : "admin"
                }, 
                {
                    "" : "caller"
                }, 
                {
                    "" : "phone"
                }, 
                {
                    "" : "startDate"
                }, 
                {
                    "" : "endDate"
                }, 
                {
                    "" : "duration"
                }, 
                {
                    "" : "type"
                }
            ]        
        }


    ]);

    const [callRecordingList, setCallRecordingList] = useState([
      {        
          "admin" : "",
          "module" : "CallRecording",
          "api" : "",
          "method" : "",        
          "Mapping" : [ 
              {
                  "" : "admin"
              }, 
              {
                  "" : "caller"
              }, 
              {
                  "" : "phone"
              }, 
              {
                  "" : "startDate"
              }, 
              {
                  "" : "endDate"
              } 
              // {
              //     "" : "user"
              // }
              // {
              //     "" : "type"
              // }
          ]        
      }


  ]);


    const loadCampaign = async (id) => {
        const { user } = props;

        let registerRequest;
        
        try {
          registerRequest = await API_POST("action/getdata", {
            root: "Webhooks",
            con: { admin: user && user.admin },
            //cols: {}
          });
    
          if (registerRequest && registerRequest.status == 200) {
            if(registerRequest.data.length>0){
                setCampaignList(registerRequest.data );
                setCallRecordingList(registerRequest.data );
            }
          }
        } catch (error) {
          console.log(error);
        }
      };


    useEffect(() => {

        // window.fbAsyncInit = function () {
        loadCampaign();

    }, [user]);



    const handleAdd = (e) => {
        setOpen(true);
    }
    const handleEdit = (item) => {
        setOpen(true);
        setSelectedHook(item);
    }
    const handleDelete = (item) => {

    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSave = (val) => {
        if(val==1)
        {
            setNotify ({
                open: true,
                message: "Hook setup successfully.",
                color: "success",
              });
        }
        else{
            setNotify ({
                open: true,
                message: "something went wrong.",
                color: "danger",
              });
        }
    }

    return (<>
        <GridContainer>
        
        <Snackbar
            place="tr"
            color={notify.color}
            message={notify.message}
            open={notify.open}
            closeNotification={() => setNotify({ open: false })}
            close
        />
        <AddHook user={user} open={open} handleClose={handleClose} handleSave={handleSave} defaultData={selectedHook}></AddHook>
        <Card>
          <CardHeader color="transparent">
            <h3>
              
              Hooks
             
              {/* {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <Button
                  variant="transparent"
                  color="info"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={() => handleAdd()}
                >
                  Create Hook
                </Button>
              )} */}
              <GridItem  style={{float: "right", marginTop: "10px"}}>
                  <InfoBox name={'Integrations.Webhook'} />
              </GridItem>
            </h3>
            <p>Manage your Hooks</p>
          </CardHeader>
          <CardBody>
            <GridContainer
              justify={
                campaignList && campaignList.length == 0
                  ? "center"
                  : "flex-start"
              }
            >
              {campaignList && campaignList.length == 0 && <Loader />}

              {campaignList.map((item, index) => {
                return (
                  <GridItem key={index} xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="white">
                        <GridContainer>
                          <GridItem sm={12} md={4} xs={12}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                            >
                              {item.module}
                            </Typography>
                          </GridItem>
                          <GridItem sm={12} md={8} xs={12}>
                            <GridContainer justify="flex-end" direction="row">
                              <ButtonGroup
                                color="info"
                                aria-label="outlined primary button group"
                              >
                               
                                {["manager", "owner"].indexOf(
                                  user && user.role
                                ) > -1 && (
                                  <Button
                                    justIcon
                                    onClick={() => handleEdit(item)}
                                  >
                                    <i class="fa fa-pencil-square-o" />
                                  </Button>
                                )}

                                {/* {["manager", "owner"].indexOf(
                                  user && user.role
                                ) > -1 && (
                                  <Button
                                    justIcon
                                    onClick={() => handleDelete(item)}
                                  >
                                    <DeleteForeverIcon />
                                  </Button>
                                )} */}
                              </ButtonGroup>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody>
                        
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              })}

            {callRecordingList.map((item, index) => {
                return (
                  <GridItem key={index} xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="white">
                        <GridContainer>
                          <GridItem sm={12} md={4} xs={12}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                            >
                              {item.module}
                            </Typography>
                          </GridItem>
                          <GridItem sm={12} md={8} xs={12}>
                            <GridContainer justify="flex-end" direction="row">
                              <ButtonGroup
                                color="info"
                                aria-label="outlined primary button group"
                              >
                               
                                {["manager", "owner"].indexOf(
                                  user && user.role
                                ) > -1 && (
                                  <Button
                                    justIcon
                                    onClick={() => handleEdit(item)}
                                  >
                                    <i class="fa fa-pencil-square-o" />
                                  </Button>
                                )}

                                
                              </ButtonGroup>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody>
                        
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </GridItem>
                );
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </>
    )
}

export default WebHooks;


class GenerateField extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {
        value,
        onChange,
        id,
        options,
        label,
        keyValuePair,
        type = "select",
        disabled = false,
      } = this.props;

      debugger;
  
      return (
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText={label}
            id
            labelProps={{ shrink: true }}
            formControlProps={{
              fullWidth: true,
              multiline: true,
            }}
            customOptions={options}
            inputProps={{
              required: false,
              value,
              defaultValue: value,
              checked: value,
              name: id,
              id: id,
              type: type,
              onChange,
              disabled,
              options: keyValuePair,
            }}
          />
        </GridItem>
      );
    }
  }
  