import React, { useEffect, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Loader from "components/Loader/Loader.jsx";

import { API_POST } from "../../services/api.service";
import TagInput from "components/Tags/TagInput";

import _ from "underscore";

const style = {

};



function ViewTags(props) {

  const { classes, user, tags, onSelectTag ,closeTagsPermission ,editTagsPermission } = props;
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);



  const onSelect = (selected) => {


    selected = [...new Map(selected.map(item =>
      [item['tag_value'], item])).values()];
    console.log("update",selected,tags);
    setSelectedTags(selected);
    onSelectTag(selected);

    if (onSelectTag) {

   
    }



  }



  const loadAllTags = async () => {

    let registerRequest;



    try {
      registerRequest = await API_POST("action/getdata",
        {
          root: 'tags',
          con: { admin: user && user.admin,$or:[{isActive:true},{isActive:{$exists:false}}] },
          cols: {}
        });

      if (registerRequest && registerRequest.status == 200) {

        let tags = registerRequest.data;
        setAllTags(tags);
      }
    } catch (error) {
      console.log(error)
    }

  }



  useEffect(() => {
    if (user) {
      loadAllTags();
    }
    //console.log("tags", tags);
  }, [user]);



  return (
    <>
      {/* {JSON.stringify(tags)}
      {JSON.stringify(allTags)} */}
      {allTags && allTags.length > 0 &&

        <TagInput
          options={allTags}
          defaultValue={tags ? tags : []}
          OptionLabel="tag_value"
          label="Choose Tag"
          placeholder="Tag"
          user={user}
          onTagsChange={(item) => onSelect(item)}
          closeTagsPermission = {closeTagsPermission}
          editTagsPermission = {editTagsPermission}
        />

      }
    </>
  );
}


export default withStyles(style)(ViewTags);