import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { API_GET, API_POST } from "../../services/api.service";
import ViewDisposition from "../Forms/ViewDisposition";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function Filters(props) {
  let query = [];

  const { open, user, preserveQuery,IsShowField ,HideMobile,ShowCampaign,showCallType,showTags} = props;
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [tagsKeys, setTagsKey] = useState([]);
  const [CampainData, setCampainData] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [currentDate, setCurrentDate] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [Campaigns, setCampaigns] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState([]);
  const [mobileNo, setMobileNo] = useState([]);
  const [callType,setCallType]=useState('');
  const [selectedTag,setSelectedTag]=useState('');
  const [callTypeOPtion,setCallTypeOption]=useState([

    {
      key:"select",
      value:"Select"
    },
      {
    key:"personal",
    value:"Personal Call"
  },
    {
      key:"business",
      value:"Business Call"
    }
  ]);

  const handleSearch = () => {
    let query = {
      startDate,
      endDate,
      mobileNo,
      selectedUser,
      SelectedCampaign,
      selectedDisposition,
      callType,
      selectedTag
    };

    props.filterData(query);
  };

  const getAllUsers = async () => {

    let fetchDatafromname=user.admin;

    if(user.role=='manager'){
      fetchDatafromname=user.username;
    }

    if(!fetchDatafromname){
      return;
    }

    let userInfo = await API_GET(
      `register/UserInfoByUserName?username=${fetchDatafromname}&manager=${user.role=='manager' ? user.id : false}`
    );
    if (userInfo.success && userInfo.data.all_members) {
      let _options = [];
      userInfo.data.all_members.forEach((element) => {
        _options.push({
          key: element.username,
          value: element.username+'('+element.first_name+')',
        });
      });

      setAllUsers(_options);
    }
  };

  const getCampaignData = async () => {
    let Request;
    Request = await API_POST("action/getdata", {
      root: "campaigns",
      con: {
        admin: user && user.admin,
        IsActive:true
      },
    });
    if (Request && Request.status == 200 && Request.data) {
      let _options = [];
      Request.data.forEach((element) => {
        _options.push({
          key: element._id,
          value: element.name,
        });
      });
      setCampainData(_options);
    }
  };

  const ReloadFilter = async () => {debugger;
    const url = new URLSearchParams(window.location.search);
    let urlParam = url.get("Search");

    if (urlParam && JSON.parse(atob(urlParam))) {debugger;
      urlParam = JSON.parse(atob(urlParam));
      //======================= Campain ==============================//
      urlParam && urlParam.cid && urlParam.cid.$in && urlParam.cid.$in.length > 0
        ? setSelectedCampaign(urlParam.cid.$in)
        : setSelectedCampaign([]);

        // urlParam && urlParam.disposition && urlParam.disposition.$in.length > 0
        // ? setSelectedDisposition(urlParam.disposition.$in)
        // : setSelectedDisposition([]);

      //====================== Users =================================//
      urlParam && urlParam.assignTo && urlParam.assignTo.$in && urlParam.assignTo.$in.length > 0
        ? setSelectedUser(urlParam.assignTo.$in)
        : setSelectedUser([]);

        urlParam.mobileNo && urlParam.mobileNo.$regex 
        ? setMobileNo(urlParam.mobileNo.$regex)
        : setMobileNo([]);  

      if (urlParam.hasOwnProperty("callback")) {
        setStartDate(urlParam.callback.$gte);
        setEndDate(urlParam.callback.$lt);
      } else if (urlParam.hasOwnProperty("createdOn")) {
        setStartDate(urlParam.createdOn.$gte);
        setEndDate(urlParam.createdOn.$lt);
      
      } else if (urlParam.hasOwnProperty("Appointment")) {
        setStartDate(urlParam.Appointment.$gte);
        setEndDate(urlParam.Appointment.$lt);
      }
    }
  };

  const handleReset = async () => {
    setSelectedUser([]);
    setSelectedCampaign([]);
    setStartDate(new Date().setHours(0, 0, 0, 0));
    setEndDate(new Date().setHours(23, 59, 59, 0));
  };

  const onSelectDisposition = async (item) => {
    console.log("Disposition", item);
    
    let obj = [];
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      obj.push(element._id);
    }
    setSelectedDisposition(obj.length > 1 ? [obj[obj.length-1]] :[obj[0]]);
  };

  const updateUser = async (camp)=>{

    let campquery = { admin: user && user.admin, cid: { $in: camp } }
    if (user && ["manager"].indexOf(user.role) > -1) {
    //  campquery = { ...campquery, $or :[ {campaignMembers: { $in: [user.username]} } ,{'createdBy': user.username }] };
    }
    var Request = await API_POST("action/getdata", {
      root: "campaignMembers",
      con: campquery,
    });
    if (Request && Request.status == 200 && Request.data) {
      let _options = [];
      let tempdata=[];
      Request.data.forEach((element) => {

        if(user && ["manager"].indexOf(user.role) > -1){

          if(user.members.includes(element.username) && ! tempdata.includes(element.username)){
            _options.push({
              key: element.username,
              value: element.username,
            });
          }
          tempdata.push(element.username);
        }else{

          if(! tempdata.includes(element.username)) {
            _options.push({
              key: element.username,
              value: element.username,
            });
          }
          tempdata.push(element.username);
        }



      });
      setAllUsers(null);
      if(_options){
        setAllUsers(_options);
      }

    }



  }

  const loadTags= async ()=>{

    
    try {
      let registerRequest;
      registerRequest = await API_POST("action/getdata",
        {
          root: 'tags',
          con: { admin: user && user.admin,$or:[{isActive:true},{isActive:{$exists:false}}] },
          cols: {}
        });

      if (registerRequest && registerRequest.status == 200) {

        let tags = registerRequest.data;
        setAllTags(tags);

        let keyPair = [];
        tags.map((v)=>{
          keyPair.push({
            key:v.tag_value,
            value:v.tag_value
          })
        })

        setTagsKey(keyPair)

      }
    } catch (error) {
      console.log(error)
    }


  }

  useEffect(() => {
    getAllUsers();
    getCampaignData();
    ReloadFilter();
    if(showTags){
      loadTags();
    }
  }, []);

  return (
    <GridContainer>
      <Dialog open={open}>
        {/* <DialogTitle id="form-dialog-title"> Filter Details</DialogTitle> */}
        <DialogContent>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Filter
            <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Activity.Activity.Filter'} />
              </GridItem>
          </DialogTitle>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
            {!IsShowField &&  <ViewDisposition
                Title="Dispositions"
                user={user}
                //selectedDisposition ={selectedDisposition}
                onSelectDisposition={(item) => onSelectDisposition(item)}
              />}
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <GridItem xs={12} sm={12} md={12}>
                { Campaigns && (!IsShowField ||  ShowCampaign) && (
                  <GenerateField
                    value={SelectedCampaign}
                    onChange={(e) => {
                      setSelectedCampaign(e.target.value);
                      updateUser(e.target.value);

                    }}
                    id={"cid"}
                    name={"cid"}
                    label={"Campaigns"}
                    type="checkbox_list"
                    keyValuePair={CampainData}
                  />
                )}
              </GridItem>

              {["manager", "owner"].indexOf(user && user.role) > -1 && (
                <GridItem xs={12} sm={12} md={12}>
                  {allUsers  && (
                    <GenerateField
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      defaultValue={"Select"}
                      id={"assignTo"}
                      name={"assignTo"}
                      labelText="Select User"
                      label="Select User"
                      type="checkbox_list"
                      keyValuePair={allUsers}
                    />
                  )}
                </GridItem>
              )}

            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <CustomInput
                  style="dashboard_button"
                  id="startdate"
                  labelText="Select Date"
                  labelProps={{ shrink: false }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: false,
                  }}
                  inputProps={{
                    type: "date_range",
                    initialDateRange: {
                      startDate: startDate,
                      endDate: endDate,
                    },

                      showFetch: false,
                      onChange: (e) => {
                        setStartDate(e.startDate);
                        setEndDate(e.endDate);
                      },
                      onClick: () => {
                        //loadFollowUpData();
                      },
                    }}
                  />

                { !HideMobile ?
                    (
                        <>
                          <CustomInput
                              labelText="MobileNo"
                              id="MobileNo"
                              labelProps={{ shrink: true }}
                              formControlProps={{
                                fullWidth: true,
                                multiline: true,
                              }}
                              inputProps={{
                                required: true,
                                value: mobileNo,
                                name: "MobileNo",
                                onChange: (e)=>{setMobileNo(e.target.value)},
                              }}
                          />
                        </>

                    ):null

                }


                { showCallType && <GenerateField
                      value={callType}
                      onChange={(e) => setCallType(e.target.value)}
                      defaultValue={"select"}
                      id={"callType"}
                      name={"callType"}
                      labelText="Select Call Type"
                      label="Select Call Type"
                      type="select"
                      keyValuePair={callTypeOPtion}
                  />


                }

                {
                  showTags && <GenerateField
                  value={selectedTag}
                  onChange={(e) => setSelectedTag(e.target.value)}
                  defaultValue={"select"}
                  id={"tags"}
                  name={"tags"}
                  labelText="Select Tags"
                  label="Select Tags"
                  type="select"
                  keyValuePair={tagsKeys}
                  />
                }


              </GridContainer>
              </GridItem>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.handleClose} color="primary">
            Close
          </Button> */}
          <Button onClick={handleSearch} color="primary">
            Apply
          </Button>
          {/* <Button onClick={handleReset} color="primary">
            Reset
          </Button> */}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Filters);

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
