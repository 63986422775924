/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import { API_POST } from "../../services/api.service";
import Typography from '@material-ui/core/Typography';
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';


import Card from "components/Card/Card.jsx";



import Subscribers from "./Subscribers";
import SubscribersSuperadmin from "./SubscribersSuperadmin"


class SubscriberMain extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      campaignList: [],
      campaignsDetails: null,
      activeTab: 0,
      cid: "",
      isMobile: (window.innerWidth <= 760),
      persona:props.persona
    };
  }

  componentWillMount() {
    // let cid = this.props.match.params.cid;
    // if (cid) {
    //   this.setState({ cid: cid });
    //   this.loadCampaign(cid);
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    this.setState({ activeTab: parseInt(tab) });
  }

  // loadCampaign = async (id) => {

  //   let registerRequest;
  //   try {
  //     registerRequest = await API_POST("action/getdata", {
  //       root: "campaigns",
  //       con: { _id: id },
  //     });

  //     if (registerRequest && registerRequest.status == 200) {
  //       //console.log(registerRequest.data);
  //       if (registerRequest.data && registerRequest.data.length > 0) {
  //         this.setState({ campaignsDetails: registerRequest.data[0] });
  //       }
  //       //this.setState({ campaignsDetails: registerRequest.data });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTab: value });
    history.push('?tab=' + value)
  };

  render() {
    const { user } = this.props;
    const cid = this.props.match.params.cid;
    const { campaignsDetails, isMobile,persona } = this.state;
    console.log("campaignsDetails", campaignsDetails);

    return (

      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <Typography gutterBottom variant="h5" component="h2">
            {campaignsDetails && campaignsDetails.name
              ? campaignsDetails.name
              : ""}
          </Typography>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>

            <CustomTabs
            title=""
            TabProps={{
              onChange: this.handleChange,
            }}
            selectedValue={this.state.activeTab}
            headerColor="info"
            tabs={[
              {
                tabName: "Subscriber Activity",
                tabContent: (
                  <ErrorBoundary name="Subscribers">
                    <Subscribers
                      user={user}
                      cid={this.state.cid}
                      {...this.props}
                    />
                  </ErrorBoundary>
                ),
              },
              {
                tabName: "Subscriber Details",
                tabContent: (
                  <ErrorBoundary name="SubscribersSuperadmin">
                    <SubscribersSuperadmin
                      user={user}
                      cid={this.state.cid}
                      {...this.props}
                    />
                  </ErrorBoundary>
                ),
              },

            ]}
          />




        </GridItem>
      </GridContainer>

    );
  }
}
export default withStyles(dashboardStyle)(SubscriberMain);
