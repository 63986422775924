import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Filters from "./Filter";
import Button from "components/CustomButtons/Button.jsx";
import Filters from "../Schedule/Filters";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

//import { Snackbar } from "@material-ui/core";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import EmailConfiguration from "../Configurations/EmailConfiguration";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import InfoBox from "../../components/InfoIcon/InfoIcon";



const { REACT_APP_SERVER_URL } = process.env;



function CallingSetupList(props) {
    const { user } = props;
    const [data, setData] = useState([]);
    const [Campaigns, setCampaigns] = useState([]);
    const [cid, setCid] = useState("");
    const [optionfield, setOptionfield] = useState([]);
    const [apiFields, setApiFields] = useState([]);
    const countPerPage = 10;
    const [IsRefresh, setIsRefresh] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [blankPhone, setBlankPhone] = useState(null);

    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
    });

    const columns = [
        {
            name: "Name",
            selector: "name",
        },

        {
            name: "IsActive",
            cell: (row) => <span>{row.isActive ? "Yes" : "No"}</span>,
        },
        {
            name: "Created On",
            cell: (row) => (
                <span>
                    {row.createdOn &&
                        window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
                </span>
            ),
        },
        {
            name: "createdBy",
            selector: "createdBy",
        },
        {
            name: "Action",
            cell: (row) => (
                <Button
                    size="sm"
                    title="Edit"
                    color="info"
                    onClick={() => {
                        handleEdit(row);
                    }}
                >
                    Edit
                </Button>
            ),
        },
    ];



    const GetAllMembersByUserId = async (userid) => {

        if (!user || (user && !user.admin)) {
            return;
        }
        let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${user && user.id}`);
        if (userInfo.success) {
            setUserInfo(userInfo.data);

            let blankPhoneList = [];
            for (let i = 0; i < userInfo.data.teams_membership.length; i++) {
                let el = userInfo.data.teams_membership[i];
                if (el.agentMobile == '' || el.agentMobile === null && el.username != user.admin) {
                    blankPhoneList.push(el.username);
                }
            }
            if (blankPhoneList.length)
                setBlankPhone(blankPhoneList);

        } else {
            setUserInfo(null);
        }
    }

    const loadFields = async () => {
        const { user } = props;
        let registerRequest;
        try {
            registerRequest = await API_POST("action/getdata", {
                root: "prospect_fields",
                con: { admin: user && user.admin },
            });
            debugger;
            if (registerRequest && registerRequest.status == 200) {
                if (registerRequest.data.length > 0) {
                    let optionfields = registerRequest.data.map((val) => { return { key: val.field_name, value: val.display_Name } })
                    setOptionfield(optionfields);

                    let apiField = [];
                    for (let index = 0; index < optionfields.length; index++) {
                        const element = optionfields[index];
                        apiField.push(`${element.key}=#${element.key}#`)

                    }
                    setApiFields(apiField);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadCampaign = async () => {
        const { user } = props;
        let registerRequest;
        try {
            registerRequest = await API_POST("action/getdata", {
                root: "campaigns",
                con: { admin: user && user.admin, IsActive: true }
            });

            if (registerRequest && registerRequest.status == 200) {
                if (registerRequest.data.length > 0) {
                    //  setCampaigns("");
                    setCampaigns(registerRequest.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleEdit = (row) => {
        props.history.push("/admin/CallingSetup/" + row._id);
    };
    const loadWebCallingListData = async () => {


        if (!user || (user && !user.admin)) {
            return;
        }

        let registerRequest = await API_POST("action/getdata", {
            root: "CallingSetup",
            con: { admin: user && user.admin, type: 'CallingAPI' },
            cols: {},
        });

        setData(registerRequest.data || []);
        setIsRefresh(true);
    };

    useEffect(() => {
        if (props.user) {
            loadCampaign();
            loadFields();
        }
    }, [props.user]);

    useEffect(() => {
        loadWebCallingListData();
        GetAllMembersByUserId();
    }, [IsRefresh]);

    const handleAdd = async () => {
        debugger;
        const { history } = props;
        history.push("/admin/CallingSetup");
    };


    return (
        <>
            <Snackbar
                place="tr"
                color={notify.color}
                message={notify.message}
                open={notify.open}
                closeNotification={() => setNotify({ open: false })}
                close
            />

            <Card>
                <CardHeader color="transparent">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h3>
                                Calling Setup
                                <span style={{ float: "right", fontSize: "15px" }}>
                                <GridItem  style={{float: "left", marginTop: "10px"}}>
                                    <InfoBox name={'Integrations.CallingSetup'}/>
                                </GridItem>
                                    <Button
                                        size="sm"
                                        title="Add WebCalling Api"
                                        color="info"
                                        onClick={() => {
                                            handleAdd();
                                        }}
                                    >
                                        <i className="fa fa-plus" /> &nbsp; Add Setup
                                    </Button>

                                </span>
                            </h3>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <br />
                            {
                                blankPhone && (
                                    <>
                                        <div style={{ background: "#cf3d3d", padding: "10px" }}>
                                            <b style={{ fontSize: "18px", color: "white" }}> Please map Phone no. with agent profile to recieve call details in Betyphon account. </b><br />
                                            {

                                                blankPhone.map(function (element) {
                                                    return <span style={{ color: "white" }}> {element}, </span>;
                                                })

                                            }

                                        </div>



                                    </>
                                )

                            }


                            <span> <b>Hangout Api: </b>  https://web.betyphon.com/api/public/hangupdata?calltime=#calltime#&calltype=#type#&recordingfile=#recpath#&trackingid=#UniqueID#&duration=#duration#&agent=#agent#&customer=#customer#&telephonySource=telephony&assignPhone=#agent#&admin={user && user.admin}</span>
                            <hr />
                            <span> <b>Inbound Lead Api: </b>
                                <Autocomplete
                                    onChange={(event, value) => setCid(value)}
                                    id="combo-box-demo"
                                    size="small"
                                    options={Campaigns}
                                    labelText="FieldType"
                                    name="autoComplete"
                                    value={cid || null}
                                    getOptionLabel={(Campaigns) => Campaigns.name || ""}
                                    defaultValue="Select"
                                    renderTags={(value) =>
                                        value.map(() => (
                                            <Chip variant="outlined" label="Enter Field" />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Campaign "
                                        // variant="outlined"
                                        />
                                    )}
                                />

                                {cid && `https://web.betyphon.com/api/public/inboundleads?cid=${cid._id}&assignPhone=#assignPhone#&${apiFields.join('&')}&admin=${user && user.admin}`}
                            </span>

                        </GridItem>

                    </GridContainer>
                </CardHeader>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <DataTable
                            columns={columns}
                            data={data || []}
                            highlightOnHover
                            pagination
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </Card>
        </>
    );
}

export default CallingSetupList;
