/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { API_GET, API_POST, userRoles } from "../../services/api.service";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import _ from "underscore";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

class AddMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      activeTab: 0,
      userInfo: [],
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }
  // to stop the warning of calling setState of unmounted component

  componentDidMount() {
    this.loadPage();
  }

  loadPage = async () => {
    const { user, cid } = this.props;
    if (user && user.admin && user.admin != 'undefined') {
     await this.loadUserInfo(user.admin, cid);
    }
  };

  handleRefresh = () => {
    window.location.reload();
  };

  loadUserInfo = async (username, cid) => {
    let userInfo = await API_GET(
      `register/UserInfoByUserName?username=${username}&cid=${cid}`
    );
    if (userInfo.success) {
      this.setState({ userInfo: userInfo.data });
    } else {
      this.setState({ userInfo: null });
    }
  };

  AddRemoveMemberInCampain = async () => {
    debugger;
    let registerRequest;
    const { userInfo } = this.state;
    const { user } = this.props;
    let val ;
    
     //val =Type == 1 ? _.pluck(userInfo.campaignsMembers, "username"): _.pluck(userInfo.teams_membership, "username");
     val = _.pluck(userInfo.campaignsMembers, "username");
    
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "campaigns",
        body: {
          campaignMembers: val
        },
        querydata: { _id: this.props.cid },
        
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.userInfo.campaignsMembers !== this.state.userInfo.campaignsMembers) {
     
  //   }
  // }
  
 

  handleAdd = async (val) => {
    let registerRequest;
    const { user } = this.props;
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "campaignMembers",
        body: {
          role: val.role,
          createdOn: new Date(),
          createdBy: user.username,
          updatedOn: new Date(),
          updatedBy: user.username,
          IsActive: true,
        },
        querydata: { cid: this.props.cid, username: val.username  ,admin: user.admin},
      });

      if (registerRequest && registerRequest.status == 200) {
        //this.setState({ campaignList: registerRequest.data });
        
        this.setState({
          notify: {
            open: true,
            message: "Move Member successfully",
            color: "success",
          },
        });

        await  this.loadPage();
        this.AddRemoveMemberInCampain();
        console.log("Save Data in mongo");

        //Add member in campian Collection
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  ChkAssignedLead = async (username) => {
    const { user, cid } = this.props;
    let Request;
    Request = await API_POST("action/getCount", {
      root: "prospects",
      con: {
        cid,
        assignTo: username,
        admin: user && user.admin,
        IsActive:true
      },
    });
    if (Request && Request.status == 200 && Request.data > 0) {
      return false;
    } else {
      return true;
    }
  };

  handleRemove = async (val) => {
    let registerRequest;
    const { user } = this.props;
    try {
      let result = window.confirm("Are you sure you want to remove member ?");
      if (result) {
        let IsRemove = await this.ChkAssignedLead(val.username);
        if (IsRemove) {
          registerRequest = await API_POST("action/update", {
            root: "campaignMembers",
            body: {
              updatedBy: user.username,
              IsActive: false,
            },
            querydata: { _id: val._id },
          });

          if (registerRequest && registerRequest.status == 200) {
            //this.setState({ campaignList: registerRequest.data });
           
            this.setState({
              notify: {
                open: true,
                message: "Move Member successfully",
                color: "success",
              },
            });
            await this.loadPage();
            this.AddRemoveMemberInCampain();
            
            console.log("Remove Data in mongo");
          }
        } else {
          this.setState({
            notify: {
              open: true,
              message: "Please change the assignment before remove member. ",
              color: "danger",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { userInfo } = this.state;
    var tableData = [];
    var AllUsertableData = [];
    if (userInfo && userInfo.teams_membership) {
      for (let index = 0; index < userInfo.teams_membership.length; index++) {
        const element = userInfo.teams_membership[index];
        tableData.push([
          element.username,
          <Button
            color="success"
            size="sm"
            onClick={() => this.handleAdd(element)}
          >
            <AddRoundedIcon />
            Add
          </Button>,
        ]);
      }
    }

    if (userInfo && userInfo.campaignsMembers) {
      for (let item = 0; item < userInfo.campaignsMembers.length; item++) {
        const _val = userInfo.campaignsMembers[item];
        AllUsertableData.push([
          _val.username,
          userRoles[_val.role],
          _val.defaultHandler,
          _val.prospects,
          _val.last_login ? window.moment(_val.last_login).fromNow() : "NA",
          <Button
            color="danger"
            size="sm"
            onClick={() => this.handleRemove(_val)}
          >
            <ClearRoundedIcon />
            Remove
          </Button>,
        ]);
      }
    }

    return (
      <>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer>
          <GridItem xs={12} sm={5} md={5}>
            <Card>
              <CardHeader>
                <GridItem  style={{float: "right"}}>
                    <InfoBox name={'Campaigns.ViewMore.Memebers'} />
                </GridItem>
                <h4>Add Members</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  tableHead={["User", ""]}
                  tableData={tableData}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={7} md={7}>
            <Card>
              <CardHeader>
                <h4>Campaign Members</h4>
                <p>New employees on 15th September, 2016</p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  tableHead={[
                    "User",
                    "Role",
                    "Default Handler",
                    "Prospects",
                    "Last Login",
                    "",
                  ]}
                  tableData={AllUsertableData}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default withStyles(styles)(AddMember);
