import React from "react";
import {
  API_GET,
  API_POST,
  userRoles,
  InviteeStatus,
} from "../../../services/api.service";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "components/Table/Table.jsx";
import Loader from "components/Loader/Loader.jsx";
import SubscriberMember from "./SubscriberMember";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class Subscribers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: null,
      userInfo: null,
      AllActiveUsers: null,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      openchangemanager: false,
      openchangerole: false,
      selectedMember: null,
    };
    this.changeUserType = this.changeUserType.bind(this);
    this.changeExpiry = this.changeExpiry.bind(this);
    this.changeDetails = this.changeDetails.bind(this);
    this.changeCount = this.changeCount.bind(this);

    this.userRoles = ["manager", "agent", "field_exc"];

  }

  async componentDidMount() {
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);
    //Page Authorization
    if (getSessionRequest && getSessionRequest.success) {
      this.setState({ user: getSessionRequest.userInfo });

      this.loadUserInfo(getSessionRequest.userInfo.id);
      this.GetAllUsers(getSessionRequest.userInfo.username);
    }
  }

  async loadUserInfo(userid) {
    let userInfo = await API_GET(`register/SubscribersList?id=${userid}`);
    if (userInfo.success) {
      this.setState({ userInfo: userInfo.data });
    } else {
      this.setState({ userInfo: null });
    }
  }

  async GetAllUsers(username) {
    let userInfo = await API_GET(`register/GetAllUsers?username=${username}`);
    if (userInfo.success) {
      this.setState({ AllActiveUsers: userInfo.data });
    } else {
      this.setState({ AllActiveUsers: null });
    }
  }



  async sentEmail(e, element) {
    debugger;
    e.preventDefault();
    try {
      let request = await API_GET(
        `email/sendCode?user_id=${element.to_user_id}`
      );
      console.log(request);
      if (request && request.success) {
        this.setState({
          notify: {
            open: true,
            message: request.messages.success,
            color: "success",
          },
        });
        this.loadUserInfo(this.state.user.id);
      }
    } catch ({ response }) {
      console.log(response);
    }
  }
  async sendApprove(e, element) {
    debugger;
    e.preventDefault();
    try {
      let request = await API_GET(`email/verify?code=${element.code}`);
      console.log(request);
      this.loadUserInfo(this.state.user.id);
      if (request && request.success) {
        this.setState({
          notify: {
            open: true,
            message: request.messages.success,
            color: "success",
          },
        });

      } else {
        this.setState({
          notify: {
            open: true,
            message: request.messages.error,
            color: "danger",
          },
        });
      }
    } catch ({ response }) {
      console.log(response);
    }
  }

  async changeUserType(e, element, Type) {
    debugger;
    let registerRequest = await API_POST("register/changeUserState", {
      Type: Type,
      id: element.id,
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        notify: {
          open: true,
          message: "Save Successfully",
          color: "success",
        },
      });
      this.GetAllUsers(this.state.user.username);
    }
  }


  async moveRecordsToArchive(admin) {
    debugger;
    let registerRequest = await API_GET(`action/MoveRecordsToArchive?admin=${admin}`)
   

    if (registerRequest && registerRequest.data) {
      this.setState({
        notify: {
          open: true,
          message: "Save Successfully",
          color: "success",
        },
      });
      this.GetAllUsers(this.state.user.username);
    }
  }

  async changeExpiry(e, selectedMember) {
    debugger;
    console.log(selectedMember);

    let registerRequest = await API_POST("register/changeExpiry", {
      ...selectedMember
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        notify: {
          open: true,
          message: "Expiry changed Successfully.",
          color: "success",
        },
      });
      this.GetAllUsers(this.state.user.username);
    }
  }

  async changeDetails(e, selectedMember) {
    debugger;
    console.log(selectedMember);

    let registerRequest = await API_POST("register/changeRefer", {
      ...selectedMember
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        notify: {
          open: true,
          message: "User updated Successfully.",
          color: "success",
        },
      });
      this.GetAllUsers(this.state.user.username);
    }
  }

  async changeCount(e, selectedMember) {
    debugger;

    let registerRequest = await API_POST("register/changeCount", {
      ...selectedMember
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        notify: {
          open: true,
          message: "Member count changed Successfully.",
          color: "success",
        },
      });
      this.GetAllUsers(this.state.user.username);
    }
  }

  async changeUserStatus(e, selectedMember, newVal) {
    debugger;
    let member = selectedMember;
    if (!window.confirm("Are you sure! you wanna change status to " + member.status)) {
      return false;
    }
    try {
      let registerRequest = await API_POST("register/changeUserStatus", {
        id: member.id,
        status: newVal,
      });
      console.log(registerRequest);
      if (registerRequest && registerRequest.data.update) {
        this.GetAllUsers(this.state.user.username);
        this.setState({
          notify: {
            open: true,
            message: "Status changed Successfully.",
            color: "success",
          },
        });
        
      }
    } catch ({ response }) {
      //registerRequest = response;
    }
    return true;
  }


  render() {
    const { classes, name, email } = this.props;
    const { errors, user, userInfo, AllActiveUsers } = this.state;

    // if (!userInfo) {
    //   return <Loader />;
    // }

    let Invitees = null;
    if (userInfo && userInfo.invitees) {
      var InviteestableData = [];
      for (let index = 0; index < userInfo.invitees.length; index++) {
        const element = userInfo.invitees[index];

        InviteestableData.push([
          element.username,
          InviteeStatus[element.status],
          element.sent ? window.moment(element.sent).fromNow() : "NA",
          <Button color="danger" onClick={(e) => this.sentEmail(e, element)}>
            Send
          </Button>,
          element.status === 2 ? (
            <Button
              color="success"
              onClick={(e) => this.sendApprove(e, element)}
            >
              Approve
            </Button>
          ) : null,
        ]);
      }
      Invitees = (
        <Table
          tableHeaderColor="primary"
          tableHead={["User", "Status", "Invited At", "", ""]}
          tableData={InviteestableData}
        />
      );
    }

    let ActiveUsers = null;
    if (AllActiveUsers) {
      var ActiveUserstableData = [];
      for (let index = 0; index < AllActiveUsers.length; index++) {
        const element = AllActiveUsers[index];

        ActiveUserstableData.push([
          element.admin,
          element.username,
          element.date_joined
            ? window.moment(element.date_joined).fromNow()
            : "NA",
          element.last_login
            ? window.moment(element.last_login).fromNow()
            : "NA",
          element.expiry ? window.moment(element.expiry).fromNow() : "NA",
          element.password,
          element.is_active && element.is_active == 1 ? (
            <Button
              color="danger"
              onClick={(e) => this.changeUserType(e, element, 0)}
            >
              {" "}
              DeActive
            </Button>
          ) : (
            <Button
              color="success"
              onClick={(e) => this.changeUserType(e, element, 1)}
            >
              Active
            </Button>
          ),
        ]);
      }
      ActiveUsers = (
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "Owner",
            "User",
            "Created On",
            "Last Login",
            "Expired On",
            "Password",
          ]}
          tableData={ActiveUserstableData}
        />
      );
    }

    return (
      <div>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
       

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {/* <Card> */}
            {/* <CardHeader color="transparent">
                <h3>Members</h3>
              </CardHeader> */}
            {/* <CardBody> */}
            {/* {!userInfo ? <Loader /> : null} */}
            {/* {ActiveUsers} */}
            <SubscriberMember
              ActiveUsers={AllActiveUsers}
              changeUserType={this.changeUserType.bind(this)}
              changeExpiry={this.changeExpiry.bind(this)}
              moveRecordsToArchive={this.moveRecordsToArchive.bind(this)}
              changeCount={this.changeCount.bind(this)}
              changeUserStatus={this.changeUserStatus.bind(this)}
              changeDetails={this.changeDetails.bind(this)}
              user={this.state.user}
            />
            {/* </CardBody> */}
            {/* </Card> */}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Subscribers);
