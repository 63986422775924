import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CommonMapping from "./../CommonMapping/CommonMapping";
import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";

import FacebookLogin from 'react-facebook-login';

import {
    API_GET,
    API_POST
} from "../../services/api.service";


function FacebookLead(props) {


    const { classes, user, open, defaultData, IsEdit } = props;
    const [hookData, sethookData] = useState([]);
    const [Campaigns, setCampaigns] = useState([]);
    const [mobileInput, setmobileInput] = useState("");
    const [api, setApi] = useState(
        "https://mapi.indiamart.com/wservce/enquiry/listing/GLUSR_MOBILE/${mobile}/GLUSR_MOBILE_KEY/${key}/Start_Time/${startdate}/End_Time/${enddate}/"
    );
    const [InputType, setInputType] = useState([]);
    const [keyInput, setKeyInput] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [MappingVal, setMappingVal] = useState([]);
    const [MappingWithMulObject, setMappingWithMulObject] = useState(null);

    const [PreviewData, setPreviewData] = useState([]);
    const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
    const [IsShowTestBtn, setIsShowTestBtn] = useState(false);
    const [IsPreviewData, setIsPreviewData] = useState(false);
    const [auth, setauth] = useState(false);

    const [columns, setcolumns] = useState([]);
    const [mapping, setMapping] = useState(null);
    const [chkResult, setChkResult] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [IsTestBtnClicked, setIsTestBtnClicked] = useState(false);
    const [IsSaved, setIsSaved] = useState(false);
    const [count, setCount] = useState(0);
    const [Lead, setLead] = useState([]);

    const [pages, setpages] = useState(null);
    const [forms, setforms] = useState(null);

    const { REACT_APP_FACEBOOK_APPID,REACT_APP_FACEBOOK_APPSECREAT } = process.env;

    const [PageList,setPageList]=useState(null);
    const [FormList,setFormList]=useState(null);
    const [campaignList,setCampaignList]=useState(null);
    const handleClose = () => {
        props.handleClose();
    };

    const handleSaveIntegration = async () => {

        let add={};
            add['pageName']=PageList[InputType.page].name;
            add['FormName']=FormList[InputType.form].name;
            add['ConnectedAccount']=auth.name;

            let registerRequest;
            registerRequest = await API_POST("action/getdata", {
                root: "Integrations",
                con: { admin: user.admin, name: "Facebook", IsActive: true,FormId: InputType.form },
            });

            if (registerRequest && registerRequest.status == 200 && !IsEdit) {
                if (registerRequest.data.length > 0) {
                    alert("Selected Form is Already used in Different Integration, Please Select Other Form Name or disable Previous Integration.");
                    return true;
                }
            
            }

        let payload = {
            name: "Facebook",
            admin: user && user.admin,
            campaign: InputType.campaign,
            FacebookId:InputType.FacebookId,
            Mapping: mapping,
            cid: InputType.cid,
            PageId:InputType.page,
            FormId:InputType.form,
            Message:'',
            IsActive: true,
            additionalInfo:add
        };

    
        try {
            registerRequest = await API_POST("action/upsert", {
                root: "Integrations",
                body: payload,
                querydata: { admin: user.admin, name: "Facebook","FacebookId":InputType.FacebookId,campaign: InputType.campaign,PageId: InputType.page,FormId:InputType.form },
            });

            if (registerRequest && registerRequest.status == 200) {


                await SaveRecord(payload);

                setNotify({
                    open: true,
                    message: "Data Save Successfully",
                    color: "success",
                });
            }
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };


    const handleChange= (e) =>{
        InputType[e.target.name]=e.target.value;
        if(e.target.name==='campaign'){
            InputType['cid']=campaignList[e.target.value]._id;
        }
        setInputType(InputType);
        setCount(count+1);
    }

    const loadCampaign = async () => {
        const { user } = props;
        let registerRequest;
        let col = [];
        try {
            registerRequest = await API_POST("action/getdata", {
                root: "campaigns",
                con: { admin: user && user.admin, IsActive: true },
            });

            if (registerRequest && registerRequest.status == 200) {
                if (registerRequest.data.length > 0) {
                    let formlist={};
                    for(let i=0;i<registerRequest.data.length;i++){
                        formlist[registerRequest.data[i]['name']]=registerRequest.data[i];
                       let obj = { key: registerRequest.data[i]['name'], value: registerRequest.data[i]['name'] };
                        col.push(obj);
                    }

                    setCampaignList(formlist);
                    setCampaigns(col);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    useEffect( async ()=>{



    },[InputType]);

    useEffect(async () => {

        if (open && defaultData && defaultData.hasOwnProperty("FacebookId")) {


            let obj = defaultData.Mapping.reduce(function (result, current) {
                return Object.assign(result, current);
            }, {});



            setMappingVal(obj);
            setMappingWithMulObject(obj);
            setMapping(defaultData.Mapping);

            setInputType({ ...defaultData,page:defaultData.PageId,form:defaultData.FormId });
            setIsShowFieldMapping(true);
            setKeyInput([]);
            setShowSave(true);

            await getFacebookPages();
            await FacebookForm(defaultData.PageId);




        }else{

            setInputType([]);
            setMappingWithMulObject([]);
            setMappingVal();
            setpages(null);
            setforms(null);
            setIsShowFieldMapping(false);
            setKeyInput([]);


        }

    }, [defaultData]);

    useEffect(() => {

        loadCampaign();

    }, [user]);
    const childRef = useRef();

   const getLongAccess= async (access) =>{

        var longAccessToken = await axios( 'https://graph.facebook.com/v13.0/oauth/access_token?grant_type=fb_exchange_token&client_id='+REACT_APP_FACEBOOK_APPID+'&client_secret='+REACT_APP_FACEBOOK_APPSECREAT+'&fb_exchange_token='+access);


        if(longAccessToken.data.access_token){
            return longAccessToken.data.access_token;
        }
        return false;
    }


   const getFacebookPages= async ()=>{
        const {user} = props;
        if (user && user.admin) {

            var pages = await API_POST("action/getdata",
                {
                    root: 'Facebook_Pages',
                    con: {admin: user && user.admin,AuthId:auth.userID},
                    cols: {}
                });
            // this.setState({pageData:});

            let pagesListData={};
            let col=[];
            for(let i=0;i<pages.data.length;i++){

                pagesListData[pages.data[i].id]=pages.data[i];
                let obj = { key: pages.data[i]['id'], value: pages.data[i]['name'] };
                col.push(obj);
            }

            setPageList(pagesListData);
            setpages(col);
            return true;
        }

    }

    const FacebookForm = async (id) =>{

       var registerRequest = await API_POST("action/getdata",
                {
                    root: 'Facebook_Form',
                    con: {admin: user && user.admin,PageId:id,AuthId:auth.userID},
                    cols: {}
                });


            // alert(pagesListData);
            if (registerRequest && registerRequest.status == 200) {
                let formlist={};
                let col=[];
                let formData=registerRequest.data;
                for(let i=0;i<formData.length;i++) {
                    formlist[formData[i].id]=formData[i];
                    let obj = { key: formData[i]['id'], value: formData[i]['name'] };
                    col.push(obj);
                }
                setforms(col);
                setFormList(formlist);
            }

    }


   const  responseFacebook = async (response) =>{
        console.log(response);
        const {user} = props;
        if(! response || response.status=='unknown'){
            return false;
        }


        var FacebookAuth=response;
        FacebookAuth.isActive=1;

        try {
            var longAccess = await getLongAccess(response.accessToken);

            if (longAccess) {

                response.accessToken = longAccess;
            }


            var PageDataRequest = await API_GET('https://graph.facebook.com/v13.0/' + response.userID + '/accounts?access_token=' + response.accessToken);
            var pageData = PageDataRequest.data;


            FacebookAuth.admin = user && user.admin;
            FacebookAuth.createdOn = new Date();
            FacebookAuth.userID = response.userID;

            let FacebookAuthRequest = await API_POST("action/upsert", {
                root: "Facebook_Auth",
                body: FacebookAuth,
                querydata: {admin: user.admin, userID: response.userID},
            });

            if (FacebookAuthRequest && FacebookAuthRequest.status == 200) {
                InputType['FacebookId'] = FacebookAuth.id;
                setInputType(InputType);
                await handlePages(pageData, response);
            }
        }catch (e) {
            setNotify({
                open: true,
                message: "Unable to get Data",
                color: "danger",
            });

        }


    }


   const  handlePages = async (pageData,auth) =>{

        const {user} = props;
        pageData.forEach(async (element) => {

            let userSubscribed=false;
            var subs = await API_POST( 'https://graph.facebook.com/v13.0/'+element.id+'/subscribed_apps', {access_token: element.access_token, subscribed_fields: ['leadgen']},);
            if(subs.success){
                userSubscribed=true;
            }

            if (subs && subs.success) {
                element.PageId = element.id;
                element.admin = user && user.admin;
                element.createdOn = new Date();
                element.createdOn = new Date();
                element.isActive = userSubscribed;
                element.AuthId=auth.userID;
                let FacebookAuthRequest = await API_POST("action/upsert", {
                    root: "Facebook_Pages",
                    body: element,
                    querydata: {admin: user.admin, PageId: element.id},
                })

                setauth(auth);

                if(FacebookAuthRequest.status==200) {
                    await getPageForms(element.id, element.access_token,auth)
                }


            }

        });

        await getFacebookPages();

    };


    const  getPageForms= async (id,access,auth) =>{
        var formDetails = await axios( 'https://graph.facebook.com/v13.0/'+id+'/leadgen_forms?access_token='+access);
        const {user} = props;
        //   console.log(formDetails.data.data);

        for(let i=0;i<formDetails.data.data.length;i++) {

            let formData = formDetails.data.data[i];
            formData.createdOn = new Date();
            formData.createdOn = new Date();
            formData.isActive=formData.status;
            formData.PageId=id;
            formData.AuthId=auth.userID;
            let FacebookAuthRequest = await API_POST("action/upsert", {
                root: "Facebook_Form",
                body: formData,
                querydata: {admin: user.admin, PageId: id,id:formData.id},
            })

        }

    }


    const [notify, setNotify] = useState({
        open: false,
        message: null,
        color: "success",
    });

    const getLead = async ()=>{


        try{
            let formid = FormList[InputType.form].id;

            let pageaccess=PageList[InputType.page].access_token;
            var leadDetails = await axios( 'https://graph.facebook.com/v13.0/'+formid+'/leads?access_token='+pageaccess);


            let data=[];
            let col=[];
            let previewData=[];

            for(let i=0;i<leadDetails.data.data[leadDetails.data.data.length-1].field_data.length;i++){
                data[leadDetails.data.data[leadDetails.data.data.length-1].field_data[i].name]='';
                let named=leadDetails.data.data[leadDetails.data.data.length-1].field_data[i].name;
                let value=leadDetails.data.data[leadDetails.data.data.length-1].field_data[i].values[0];

               let obj = {};
               let prevdata={};
                obj = { name: leadDetails.data.data[leadDetails.data.data.length-1].field_data[i].name, selector: leadDetails.data.data[leadDetails.data.data.length-1].field_data[i].name };
                col.push(obj);
                prevdata['name']=named;
                prevdata['value']=value;


                previewData.push(prevdata);
              }

            if(previewData.length>0){
                let prevdata={};
                prevdata['name']='created_time';
                prevdata['value']=leadDetails.data.data[leadDetails.data.data.length-1].created_time;
                previewData.push(prevdata);

            }

            // console.log(JSON.stringify(previewData));
            setMappingVal(data);
            setMappingWithMulObject(data);
            setMapping(data);
            setcolumns(col);
            setPreviewData(previewData);

           // alert(JSON.stringify(leadDetails.data.data));

                setLead(leadDetails.data.data);
                setShowSave(true);


            console.log(data);
           //  await SaveRecord(leadDetails.data);

        }catch (e) {
            setNotify({
                color: "danger",
                message: e.message,
                open: true
            });
        }


    }


    const SaveRecord= async (integration)=>{

        var data=Lead.pop()

        try {


             if(data){

                let record = data.field_data;
                let fbLeadId = data.id;
                let prospect = {};
                prospect['createdOn'] = record.created_time;
                prospect['admin'] = integration.admin;
                prospect['cid'] = integration.cid;
                prospect['source'] = integration.name;
                prospect['priority'] = 0;
                prospect['country'] = 'IN';
                prospect['open'] = true;
                prospect['IsActive'] = true;
                prospect['InSystem'] = 'API';
                prospect['fbLeadId'] = fbLeadId;
                prospect['fbFormId'] = integration.FormId;

                for (let i = 0; i < record.length; i++) {
                    let elements = record[i];

                    for (let mi = 0; mi < integration.Mapping.length; mi++) {
                        const mapHeader = integration.Mapping[mi];
                        let mapKey = Object.keys(mapHeader)[0];
                        let mapValue = Object.values(mapHeader)[0];
                        if (elements.name === mapKey) {
                            prospect[mapValue] = elements.values[0];
                        } else {
                            prospect[elements.name] = elements.values[0];
                        }
                    }
                }



                var Request = await API_POST("action/getdata",
                    {
                        root: 'prospects',
                        con: {"fbLeadId": prospect.fbLeadId, admin: prospect.admin,fbFormId:integration.FormId},
                        cols: {}
                    });


                if (Request && Request.status == 200) {


                    if(Request.data.length){
                       return true;
                    }

                 }


                await API_POST("action/upsert", {
                    root: "prospects",
                    body: prospect,
                    querydata: {"fbLeadId": prospect.fbLeadId, admin: prospect.admin},
                });


                /*  let checkfblead = await db.collection('prospects')
                      .find({ "fbLeadId": prospect.fbLeadId,admin:prospect.admin })
                      .toArray();


                  if(checkfblead.length>0){
                      continue;
                  }

                  await registerRepo.addprospects(prospect);*/

            }

        }catch (e) {
            alert(e.message);
        }

    }

    useEffect(() => {
        if (IsSaved) {
           props.CallloadIntegrationList();
            handleSaveIntegration();
            setIsSaved(false);
        }
    }, [IsSaved]);
    const handleSave = async (e) => {
        childRef.current.handleAdd();
    };
    const bindMapping = (map) => {
        setChkResult(true);

        for(let i=0;i<map.length;i++){

           let value=Object.values(map[i])[0];
           if(value==''){
               map[i][Object.keys(map[i])[0]]=Object.values(mapping[i])[0];
           }
        }

        setMapping(map);

        setIsSaved(true);
    };
    return (<>
            <GridContainer>
                <Snackbar
                    place="tr"
                    color={notify.color}
                    message={notify.message}
                    open={notify.open}
                    closeNotification={() => setNotify({ open: false })}
                    close
                />
                <Dialog open={open} fullWidth={true} maxWidth="xl">
                    <DialogTitle id="customized-dialog-title">Facebook Setup
                    <GridItem  style={{float: "right", marginTop: "10px"}}>
                        <InfoBox name={'Integrations.Facebook'} />
                    </GridItem>
                    </DialogTitle>

                    <DialogContent>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>


                                <GridItem xs={12} sm={12} md={12}>
                                    <span>
                                        <FacebookLogin
                                            appId={REACT_APP_FACEBOOK_APPID}
                                            autoLoad={false}
                                            reAuthenticate={true}
                                            returnScopes={true}
                                            redirectUri={window.location.href}
                                            fields="name,email,picture"
                                            scope='pages_show_list,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_manage_ads,business_management'
                                            callback={responseFacebook}
                                        />
                                   </span>
                                </GridItem>


                                <CustomInput
                                    id="Name"
                                    labelText={"Name"}
                                    labelProps={{ shrink: true }}
                                    formControlProps={{
                                        fullWidth: true,
                                        multiline: false,
                                    }}
                                    inputProps={{
                                        required: true,
                                        value: "Facebook",
                                        name: "Facebook",
                                        disabled: true,
                                    }}
                                />


                                <GenerateField
                                    value={
                                        InputType && InputType.campaign
                                    }
                                    onChange={(event) => handleChange(event)}
                                    id={"campaign"}
                                    name={"campaign"}
                                    label={"Select Campaign"}
                                    type="select"
                                    keyValuePair={Campaigns}
                                />

                                {
                                    pages && pages ?(<GenerateField
                                            value={
                                                InputType && InputType.page
                                            }
                                            onChange={(event) =>{ handleChange(event); FacebookForm(event.target.value)} }
                                            id={"page"}
                                            name={"page"}
                                            label={"Select Page"}
                                            type="select"
                                            keyValuePair={pages}
                                        />):null

                                }

                                {
                                    forms && forms ?(<GenerateField
                                            value={
                                                InputType.form && InputType.form
                                            }
                                            onChange={(event) => handleChange(event)}
                                            id={"form"}
                                            name={"form"}
                                            label={"Select Form"}
                                            type="select"
                                            keyValuePair={forms}
                                        />) :null

                                }

                                {
                                    forms && forms ?( <>
                                        <Button onClick={getLead} color="primary">
                                            Get Lead
                                        </Button>
                                        <br/>

                                   </> ) :null

                                }

                                {MappingVal && PreviewData && (
                                    <h3> Lead Data </h3>
                                )
                                }
                                {MappingVal && PreviewData && (



                                    PreviewData.map((val)=>{

                                        return (<GridItem xs={12} sm={12} md={12}>
                                            <p> <b>{val.name}</b> <span> {val.value}</span></p>


                                        </GridItem>)

                                    })

                                )}

                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                {MappingVal && MappingWithMulObject && (
                                    <CommonMapping
                                        ref={childRef}
                                        MappingInSingleObj={MappingVal}
                                        MappingWithMulObject={MappingWithMulObject}
                                        isEdit={IsEdit}
                                        user={props.user}
                                        bindMapping={bindMapping}
                                    />
                                )}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>


                        </GridContainer>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>


                        {
                            showSave  ? <Button onClick={handleSave} color="primary">
                            Save
                            </Button>
                            :null

                        }



                    </DialogActions>
                </Dialog>
            </GridContainer>
        </>
    )
}

export default FacebookLead;

class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        debugger;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
