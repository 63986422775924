import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// core components
import DataTable from "react-data-table-component";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { API_POST } from "../../services/api.service";
import Chip from "@material-ui/core/Chip";
import CommonMapping from "./../CommonMapping/CommonMapping";

function CommonFloor(props) {
  const { classes, user, open, defaultData, IsEdit } = props;
  const [hookData, sethookData] = useState([]);
  const [Campaigns, setCampaigns] = useState([]);
  const [idInput, setIdInput] = useState("");
  const [api, setApi] = useState(
    "https://www.commonfloor.com/agent/pull-leads/v1?id=${id}&key=${key}&start=${startdate}&end=${enddate}"
  );
  
  const [apiVersion,setApiVersion]= useState(1);
  const [InputType, setInputType] = useState("");
  const [keyInput, setKeyInput] = useState([]);
  const [MappingVal, setMappingVal] = useState([]);
  const [MappingWithMulObject, setMappingWithMulObject] = useState([]);

  const [PreviewData, setPreviewData] = useState([]);
  const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
  const [IsShowTestBtn, setIsShowTestBtn] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState(false);

  const [columns, setcolumns] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [chkResult, setChkResult] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IsTestBtnClicked, setIsTestBtnClicked] = useState(false);
  const [IsSaved, setIsSaved] = useState(false);

  const childRef = useRef();

  const handleClose = () => {
    props.handleClose();
  };

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (props.user) {
      loadCampaign();
    }
  }, [props.user]);

  useEffect(() => {

    if (open && defaultData && defaultData.hasOwnProperty("cid")) {
      console.log(defaultData);

      //setApi(defaultData.api);
      setInputType({ id: defaultData.cid, name: defaultData.campaign });
      setMappingWithMulObject(defaultData.Mapping);

      let obj = defaultData.Mapping.reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});


      setPreviewData([obj]);
      setMappingVal(obj);
      setIsShowFieldMapping(true);
      setIdInput(defaultData.values[0].id);
      setKeyInput(defaultData.values[1].key);
    }
    else {

      setInputType("");
      setMappingWithMulObject([]);
      setPreviewData([]);
      setMappingVal();
      setIsShowFieldMapping(false);
      setIdInput([]);
      setKeyInput([]);
    }
  }, [defaultData]);

  const loadCampaign = async () => {
    const { user } = props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive: true },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          setCampaigns(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTest = async () => {
    let url = api;
    let startdate = window
      .moment()
      .subtract(1, 'days')
      .format("YYYYMMDD")
      .toUpperCase();
    let enddate = window
      .moment()
      .format("YYYYMMDD")
      .toUpperCase();

    url = url.replace("${startdate}", startdate);
    url = url.replace("${enddate}", enddate);
    url = url.replace("${id}", idInput);
    url = url.replace("${key}", keyInput);



    let data = await API_POST(`register/GetAPIData`, { url });
    
    console.log('XML Data:', data);
    handleXmlData(data.data.apiData);

  };

  //api
  const handleXmlData = (xmlData) => {
        try {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xmlData, 'application/xml');
      
          const cfLeads = xmlDoc.getElementsByTagName('cf_lead');
          const convertedData = Array.from(cfLeads).map(cfLead => {
            const newObj = {};
            Array.from(cfLead.children).forEach(child => {
              newObj[child.tagName] = child.textContent;
            });
            return newObj;
          });
      
          console.log('Converted Data:', convertedData);
      
          if (convertedData && convertedData[0]) {
            setPreviewData([convertedData[0]]);
            setMappingVal(convertedData[0]);
            setIsTestBtnClicked(true);
            setIsShowFieldMapping(true);
            if (convertedData[0].Error_Message) {
              setNotify({
                open: true,
                message: convertedData[0].Error_Message,
                color: "danger",
              });
            } else {
              // Additional logic if needed
            }
          } else {
            setNotify({
              open: true,
              message: "No Recent Data Found",
              color: "success",
            });
          }
        } catch (error) {
          console.error('Error parsing XML:', error);
        }
  };

  const handleSave = async (e) => {
    childRef.current.handleAdd();
  };

  const handleSaveIntegration = async () => {
    let payload = {
      name: "CommonFloor",
      admin: user && user.admin,
      campaign: InputType.name,
      cid: InputType._id,
      api: api,
      values: [
        {
          id: idInput,
        },
        {
          key: keyInput,
        },
      ],
      Mapping: mapping,
      dateFormat: "YYYYMMDD",
      IsActive: true,
      apiVersion:apiVersion
    };

    let registerRequest;
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "Integrations",
        body: payload,
        querydata: { admin: user.admin, name: "CommonFloor" },
      });

      if (registerRequest && registerRequest.status == 200) {
        setNotify({
          open: true,
          message: "Data Save Successfully",
          color: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (IsSaved) {
      props.CallloadIntegrationList();
      handleSaveIntegration();
      setIsSaved(false);
    }
  }, [IsSaved]);

  const handleBlur = async (e) => {

    if (e.target.name == "Id" || e.target.name == "Key") {
      if (idInput != "" && keyInput != "") { setIsShowTestBtn(true); }
      else setIsShowTestBtn(false);
    }
  };

  let col = [];
  const handlePreview = async () => {
    debugger;
    if (
      PreviewData.length == 0 &&
      !PreviewData[0].hasOwnProperty("Error_Message")
    ) {
      setNotify({
        open: true,
        message: "Please first click on test btn to show mapping.",
        color: "danger",
      });
    } else {
      let obj = {};
      for (var key in PreviewData[0]) {
        if (PreviewData[0].hasOwnProperty(key)) {
          obj = {};
          //obj = { name: key, selector: PreviewData[0][key] };
          obj = { name: key, selector: key };
          col.push(obj);
        }
      }
      debugger;
      setcolumns(col);
      setIsPreviewData(true);
    }
  };

  const bindMapping = (mapping) => {
    setChkResult(true);
    setMapping(mapping);
    setIsSaved(true);
  };

  return (
    <GridContainer>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title">Common Floor Setup</DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id="Name"
                labelText={"Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: "CommonFloor",
                  name: "CommonFloor",
                  disabled: true,
                }}
              />

              <Autocomplete
                onChange={(event, value) => setInputType(value)}
                id="combo-box-demo"
                size="small"
                options={Campaigns}
                labelText="FieldType"
                name="autoComplete"
                value={InputType || null}
                getOptionLabel={(Campaigns) => Campaigns.name || ""}
                defaultValue="Select"
                renderTags={(value) =>
                  value.map(() => (
                    <Chip variant="outlined" label="Enter Field" />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Campaign "
                  // variant="outlined"
                  />
                )}
              />

              <CustomInput
                id="api"
                labelText={"API *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: api,
                  name: "api",
                  onChange: (e) => {
                    setApi(e.target.value);
                  },
                }}
              />

              <CustomInput
                id="Id"
                labelText={"Id *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: idInput,
                  name: "Id",
                  onChange: (e) => {
                    setIdInput(e.target.value);
                  },
                  onBlur: handleBlur,
                }}
              />
              <CustomInput
                id="Key"
                labelText={"Key"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: keyInput,
                  name: "Key",
                  onBlur: handleBlur,
                  onChange: (e) => {
                    setKeyInput(e.target.value);
                  },
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              {IsShowFieldMapping && (
                <CommonMapping
                  ref={childRef}
                  MappingInSingleObj={MappingVal}
                  MappingWithMulObject={MappingWithMulObject}
                  isEdit={IsEdit}
                  user={props.user}
                  bindMapping={bindMapping}
                />
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            {IsPreviewData && PreviewData && (
              <DataTable
                columns={columns}
                data={PreviewData || []}
                highlightOnHover
              />
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          {IsShowTestBtn && (
            <Button onClick={handleTest} color="primary">
              Test
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {(IsTestBtnClicked || props.IsEdit) && <Button onClick={handleSave} color="primary">
            Save
          </Button>}
          {IsTestBtnClicked && <Button onClick={handlePreview} color="primary">
            Preview
          </Button>}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

export default CommonFloor;

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    debugger;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
