import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CardFooter from "components/Card/CardFooter.jsx";
import { API_POST, API_GET } from "../../services/api.service";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      oldPassowrd: "",
      newPassowrd: "",
      UserName: "",
      IsShowMsg: false,
     
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  async componentDidMount() {
    
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);

    if (getSessionRequest && getSessionRequest.success) {
      this.setState({ "UserName": getSessionRequest.userInfo });
    }
    
  }

  savePasswordInDB = async (password) => {
    let registerRequest;

    registerRequest = await API_POST("register/ResetPassword", {
      password,
      user: this.state.UserName.username,
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({ IsShowMsg: true });

      // this.setState({
      //   notify: {
      //     open: true,
      //     message: "Password Save Successfully",
      //     color: "success",
      //   },
      // });
    } else {
      this.setState({
        notify: {
          open: true,
          message: "Something went wrong",
          color: "danger",
        },
      });
    }
  };

  savePassword = async (e) => {
    if (
      JSON.stringify(this.state.oldPassowrd) !=
      JSON.stringify(this.state.newPassowrd)
    ) {
      this.setState({
        notify: {
          open: true,
          message: "Password not match",
          color: "danger",
        },
      });
    } else {
      this.savePasswordInDB(this.state.oldPassowrd);
    }
  };

  

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>
        <Snackbar
          place="right"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.CreatePassword}>
              <Card className={classes[this.state.cardAnimaton]}>
               
                 
                {!this.state.IsShowMsg && (
                  <CardBody>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      error={errors.password || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.oldPassowrd,
                        required: true,
                        onChange: (e) => {
                          this.setState({ oldPassowrd: e.target.value });
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="Confirm password"
                      error={errors.password || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.newPassowrd,
                        required: true,
                        onChange: (e) => {
                          this.setState({ newPassowrd: e.target.value });
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                )}
                {this.state.IsShowMsg && (
                  <CardBody>{"Password Change Succesfully"}</CardBody>
                )}

                {!this.state.IsShowMsg && (
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.savePassword}
                        >
                          Save
                        </Button>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.handleLogin}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                )}
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
};

export default withStyles(styles)(ChangePassword);
