import React, { useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Loader from "components/Loader/Loader.jsx";
import { API_POST } from "../../services/api.service";


function TotalTalkTime(props) {
  const { classes, user, root, query, small, isReload, sum } = props
  const [data, setData] = React.useState(0);


  const loadData = async (e) => {
    if (!user)
      return;

    let registerRequest;
    try {
      registerRequest = await API_POST("action/getSum",
        {
          root: root,
          con: { ...query, admin: user && user.admin },
          sum: sum
        });
      console.log(registerRequest);
      debugger;

      if (registerRequest && registerRequest.status == 200) {
        if(registerRequest && registerRequest.data){
          setData(registerRequest && registerRequest.data);
        }
        
      
      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }

  useEffect(() => {
    if (user) {
      loadData();
    }
  }, [user, isReload]);


  return (<><Card>
    <CardHeader color={props.color ? props.color : 'danger'} stats icon>
      <CardIcon color={props.color ? props.color : 'danger'}>
        <i className={props.icon}></i>
      </CardIcon>
      {!small && <p className={classes.cardCategory}>{props.label}</p>}
      {!small && <h3 className={classes.cardTitle}>
        {data}
      </h3>}
      {small && <h4 className={classes.cardTitle}>
        {data && new Date(data * 1000).toISOString().substr(11, 8)}
      </h4>}
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        <a href="#pablo" onClick={(e) => e.preventDefault()}>
          {small ? props.label : "View Details"}
        </a>
      </div>
    </CardFooter>
  </Card></>)

}



export default withStyles(dashboardStyle)(TotalTalkTime);
