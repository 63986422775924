import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Face from "@material-ui/icons/Face";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneIcon from "@material-ui/icons/Phone";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import SignUpSuccessStyle from "assets/jss/material-dashboard-react/views/SignUpSuccessStyle.jsx";
import logo from "assets/img/logo-dark.png";
//import login from "assets/img/login.jpeg";

//import history from 'history.js';

const { REACT_APP_SERVER_URL } = process.env;

class SignUpSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      errors: {},
      CompanySizeVal: "",
      companysourceVal: "",
      PageType: 0,
      CompanySize: [],

      SignupSource: [],
    };
    document.title = "Sign up";
  }
  register = async (e) => {
    e.preventDefault();

    const { history } = this.props;

    const fields = [
      "name",
      "company",
      "email",
      "phone",
      "password",
      "CompanySize",
      "SignupSource",
      "ReferBy",
    ];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value.trim(),
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await axios.post(`//${REACT_APP_SERVER_URL}/register`, {
        ...formValues,
      });
    } catch ({ response }) {
      registerRequest = response;
    }
    const { data: registerRequestData } = registerRequest;
    if (registerRequestData.success) {
      return history.push("/auth/login-page");
    }

    this.setState({
      errors:
        registerRequestData.messages && registerRequestData.messages.errors,
    });
  };
  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  handleLogin = async (e) => {
    const { history } = this.props;
    return history.push("/auth/login-page");
  };

  componentDidMount() {
    debugger;
    let routeParams = window.location.pathname.split("/");
    if (routeParams && routeParams[3] == "type=1") {
      this.setState({PageType : 0});
    } else {
      this.setState({PageType : 1});
    }
  }

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.register}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="transparent"
                >
                  {/* <h4 className={classes.cardTitle}>Register</h4> */}
                  <div className={classes.socialLine}>
                    <img src={logo} />
                  </div>
                </CardHeader>
                <CardBody>
                  {this.state.PageType ==0 ? <b>Link has been expired! Please contact administrator.</b>
                   : <b>User Approved Successfully</b>}
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                      {/* <h3>Already have an account?</h3> */}
                      <Button
                        color="primary"
                        simple
                        size="lg"
                        onClick={this.handleLogin}
                        block
                      >
                        LOG iN
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SignUpSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(SignUpSuccessStyle)(SignUpSuccess);
