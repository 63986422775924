/* eslint-disable */
import React from "react";
import { withRouter } from "react-router";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_GET, API_POST } from "../../services/api.service";


import Loader from "components/Loader/Loader.jsx";
import DataTable from "react-data-table-component";
import { identity } from "underscore";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

class Release extends React.Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            teamuser: [{ key: 'Select', value: 0 }],
            showLoader: true,
            editMode:false,
            _id: null,
            editId:'',
            mode: "Add",
            records: null
        };
        const defaultData = {
            type: 'onetoone',
            user: [],
            read: 0,
            message: '',
            prospectId: null,
            IsActive: true,
            createdOn: null,
            updatedOn: null,
            admin: user && user.admin,
        };

        this.state.notificationDetails = defaultData;


    }




    // to stop the warning of calling setState of unmounted component
    componentDidMount() {
        const { user } = this.props;
        let id = this.props.match.params.id;
        if(id){
            this.setState({editMode:true});
            this.setState({editId:id});
            this.editRecord(id);
        }
        this.setState({ showLoader: false });
        if (user) {
            this.loadUsers(user.id);
            this.loadRecords(user);
        }
    }


    editRecord = async (id)=>{
        let registerRequest = await API_POST("action/getdata", {
            root: "release",
            con :{_id:id}
        });

        this.setState({ notificationDetails: registerRequest.data[0] });

    }

    loadRecords = async (user) => {
        if (!user || (user && !user.admin)) {
            return;
        }

        let registerRequest = await API_POST("action/getdata", {
            root: "release",
            con :{}
        });

        let orderData = registerRequest.data;

        orderData.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
        console.log(orderData);
        this.setState({
            records: orderData
        });

        // debugger;
        //setData(registerRequest.data || []);
        // setIsRefresh(true);

    }

    loadUsers = async (userid) => {
        if (userid) {
            let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid}`);
            if (userInfo.success) {

                if (userInfo.data.ActiveMembers) {

                    var teamuUserData = [];
                    userInfo.data.ActiveMembers.map((val, key) => {
                        if (val.is_active && val.role != "owner") {
                            var tempobj = {};
                            tempobj.key = val.email;
                            tempobj.value = val.email;
                            teamuUserData.push(tempobj);
                        }
                    })

                    // console.log(teamuUserData);



                    this.setState({
                        teamuser: teamuUserData,
                    });
                }
            } else {
                this.setState({ userInfo: null });
            }
        }

    }


    handleChange = async (e) => {

        let formValue = this.state.notificationDetails;

        if (e.target && e.target.type === "checkbox") {
            formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
            formValue[props] = e.format();
        } else {
            formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }

        console.log(formValue);
        this.setState({ notificationDetails: formValue });
    };


    handleCancel = async (e) => {
        const { user, history } = this.props;
        history.push("/admin/addnotification");
    };

    handleSave = async (e) => {

        this.handleAddNotification(e);

    };

    handleAddNotification = async (e) => {

        const { user, history } = this.props;
        let formValue = this.state.notificationDetails;
        formValue["admin"] = user && user.username;
        formValue["createdOn"] = new Date();

        let registerRequest;
        try {

    
            if(this.state.editMode){
                delete formValue._id;
                registerRequest = await API_POST("action/update", {
                    root: "release",
                    body: formValue,
                    querydata:{_id:this.state.editId}
                });
            }else{
                registerRequest = await API_POST("action/addRecord", {
                    root: "release",
                    body: formValue,
                });
            }


            

            if (registerRequest) {
                history.push("/admin/release");
            }


        } catch (error) {
            console.log(error);
        }
    };



    render() {

        const columns = [
            {
                name: "Version",
                selector: "version",
                width:"180px",
            
                cell: (row) => (
                    <span>
                      { user.is_superuser ? (<>
                        {row.version}  <a style={{color:"blue"}} href={'/admin/editrelease/'+row._id} > <b> edit </b></a>
                      </>) : (<>
                      {row.version}
                      </>)

                      }  
                   
                  </span>
                ),
            },
            {
                name: "Release Date",
                selector: "releaseDate",
                width:"180px",
                sortType: "releaseDate"

            },
            {
                name: "Message",
                cell: (row) => (
                    <span>
                    { row.message.substring(0,100) } <a style={{color:"blue"}} href={row.readmore} target="_blank"><b> ...more </b></a>
                  </span>
                ),
            },



        ];
        const countPerPage = 50;
        const { classes, user } = this.props;
        const {
            records,
            notificationDetails,
            dispositions,
            customFormFields,
            mode,
            teamuser,
            showLoader,
        } = this.state;

        return (
            <>
                <>
                    {showLoader ? <Loader /> : null}
                    {user && user.is_superuser ? (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="white">
                                        <h3>Manage Release</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>

                                            <GridItem xs={12} sm={12} md={12}>

                                            <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.version
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"version"}
                                                    name ={"version"}
                                                    label={"Version Number *"}
                                                    type="text"

                                                />
                                                 <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.releaseDate
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"releaseDate"}
                                                    label={"Release Date *"}
                                                    type="date"
                                                    name={"releaseDate"}

                                                />
                                                <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.message
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"message"}
                                                    name={"message"}
                                                    label={"Message *"}
                                                    type="textarea"
                                                    rows={20}
                                                    rowsMax={30}

                                                />

                                            </GridItem>

                                                <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.readmore
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"readmore"}
                                                    label={"ReadMore link *"}
                                                    type="input"
                                                    name={"readmore"}

                                                />

                                            

                                            



                                        </GridContainer>
                                    </CardBody>

                                    <CardFooter>
                                        <Button color="primary"
                                            size="sm"
                                            onClick={this.handleCancel}>
                                            Cancel
                                        </Button>
                                        <Button color="primary"
                                            size="sm"
                                            onClick={this.handleSave}>
                                            Save
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>

                        </GridContainer>


                    ): null}
                </>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="white">
                                <h3>View Release </h3>

                            </CardHeader>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <DataTable
                                        columns={columns}
                                        data={records || []}
                                        highlightOnHover
                                        pagination

                                        paginationPerPage={countPerPage}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                        </Card>
                    </GridItem>
                </GridContainer>



            </>
        );
    }
}

export default withStyles(styles)(Release);

class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
