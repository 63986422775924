/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { API_POST } from "../../services/api.service";
import CachedIcon from "@material-ui/icons/Cached";
import GroupIcon from "@material-ui/icons/Group";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LinkIcon from "@material-ui/icons/Link";
import BubbleChart from "@material-ui/icons/BubbleChart";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import HomeIcon from "@material-ui/icons/Home";
import AddMember from "./AddMember";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Files from "./Files";
import Home from "./Home";
import Prospects from '../Prospects/Prospects';
import ProspectsResponsive from '../Prospects/ProspectsResponsive';

import ErrorBoundary from "../../hoc/ErrorBoundary/ErrorBoundary";
import API from "./API";
import Card from "components/Card/Card.jsx";
import { Box } from "@material-ui/core";

class CampainMoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      campaignsDetails: null,
      activeTab: 0,
      cid: "",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      isMobile: (window.innerWidth <= 760)
    };
  }

  componentWillMount() {
    debugger;

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    this.setState({ activeTab: parseInt(tab) });

    let cid = this.props.match.params.cid;
    if (cid) {
      this.setState({ cid: cid });
      this.loadCampaign(cid);
    }
  }

  loadCampaign = async (id) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { _id: id },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data && registerRequest.data.length > 0) {
          this.setState({ campaignsDetails: registerRequest.data[0] });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // to stop the warning of calling setState of unmounted component

  handleRefresh = () => {
    window.location.reload();
  };
  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTab: value });
    history.push("?tab=" + value);
  };

  handleEdit = async () => {
    const cid = this.props.match.params.cid;
    const { history } = this.props;
    history.push("/admin/editcampaigns/" + cid);
  };
  handleAddProspect = async () => {
    const cid = this.props.match.params.cid;
    const { history } = this.props;
    history.push("/admin/AddProspect/" + cid);
  };

  StartCall = async () => {
    const { user } = this.props;
    const cid = this.props.match.params.cid;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/StartCall", {
        cid,
        username: user && user.username,
      });

      if (registerRequest && registerRequest.status == 200) {
        console.log(registerRequest.data);
        if (registerRequest.data) {
          //this.setState({ campaignsDetails: registerRequest.data[0] });
          //  console.log()
        }
        //this.setState({ campaignsDetails: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = async () => {
    let registerRequest;
    let IsActive = false;

    registerRequest = await API_POST("action/update", {
      root: "campaigns",
      body: { IsActive },
      querydata: {
        admin: this.props.user.admin,
        _id: this.props.match.params.cid,
      },
    });
    if (registerRequest && registerRequest.status == 200) {
      this.setState({
        notify: {
          open: true,
          message: "Campain Deactive Successfully.",
          color: "danger",
        },
      });
    }
  };

  render() {
    const { user } = this.props;
    const cid = this.props.match.params.cid;
    const { campaignsDetails , isMobile} = this.state;
    console.log("campaignsDetails", campaignsDetails);

    let tabs = [
      {
        tabName: "Home",
        tabIcon: <HomeIcon />,
        tabContent: (
          <ErrorBoundary name="Home">
            <Home
              user={user}
              cid={cid}
              {...this.props}
              campaignsDetails={campaignsDetails}
            />
          </ErrorBoundary>
        ),
      },

      {
        tabName: "Prospects",
        tabIcon: <BubbleChart />,
        tabContent: isMobile ? <ProspectsResponsive cid={cid} user={user} /> : <Prospects cid={cid} user={user} />,
      },
    ];
    if (["manager", "owner"].indexOf(user && user.role) > -1) {
      tabs.push({
        tabName: "Members",
        tabIcon: <GroupIcon />,
        tabContent: (
          <ErrorBoundary name="AddMember">
            {" "}
            <AddMember user={user} cid={this.state.cid} />
          </ErrorBoundary>
        ),
      });
      tabs.push({
        tabName: "Files",
        tabIcon: <FileCopyIcon />,
        tabContent: (
          <ErrorBoundary name="Files">
            {" "}
            <Files
              user={user}
              cid={this.state.cid}
              campaignsDetails={this.state.campaignsDetails}
            />
          </ErrorBoundary>
        ),
      });
      tabs.push({
        tabName: "API",
        tabIcon: <LinkIcon />,
        tabContent: (
          <ErrorBoundary name="API">
            <API cid={cid} user={user} showGet={true} showPost={true} />
          </ErrorBoundary>
        ),
      });
    }

    return (
      <>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />

        <Card>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Box ml={3} my={1}>
                <Typography gutterBottom variant="h6" component="h2">
                  {campaignsDetails && campaignsDetails.name
                    ? campaignsDetails.name
                    : ""}
                </Typography>
              </Box>
            </GridItem>

            <GridItem xs={12} sm={12} md={8}>
              <GridContainer justify="flex-end">
                <Box mr={3} my={1}>
                  <ButtonGroup
                    color="info"
                    aria-label="small outlined button group"
                  >


                      {

                        (campaignsDetails && !campaignsDetails.disableManualProspectCreate) && (
                        
                         <Button
                      title="Add Prospect"
                      justIcon
                      size="sm"
                      onClick={() => this.handleAddProspect()}
                    >
                       <PersonAddIcon />
                    </Button>
                        
                      )

                      }

                  


                     
                    {["manager", "owner"].indexOf(user && user.role) > -1 && (
                      <Button
                        justIcon
                        title="EDIT"
                        size="sm"
                        onClick={() => this.handleEdit()}
                      >
                        <EditIcon />
                      </Button>
                    )}

                    <Button
                      justIcon
                      title="REFRESH"
                      size="sm"
                      onClick={() => this.handleRefresh()}
                    >
                      <CachedIcon />
                    </Button>
                    {["manager", "owner"].indexOf(user && user.role) > -1 && (
                      <Button
                        justIcon
                        title="ARCHIVE"
                        size="sm"
                        onClick={() => this.handleDelete()}
                      // onClick={() => this.handleAdd()}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    )}
                  </ButtonGroup>
                </Box>
              </GridContainer>

            </GridItem>
          </GridContainer>
        </Card>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={tabs}
              selectedValue={this.state.activeTab}
              TabProps={{
                onChange: this.handleChange,
              }}
            />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default withStyles(dashboardStyle)(CampainMoreInfo);
