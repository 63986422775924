import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { API_GET, API_POST } from "../../services/api.service";
import ViewDisposition from "../Forms/ViewDisposition";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function CallFilters(props) {
  let query = [];

  const { open, user, preserveQuery, IsShowField,Recording_IsShowField ,showCallType,showCommunicationType, showCallConnectedFilter} = props;
  const [SelectedCampaign, setSelectedCampaign] = useState([]);
  const [mobileNo, setMobileNo] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [currentDate, setCurrentDate] = useState(
    new Date().setHours(0, 0, 0, 0)
  );

  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [callType,setCallType]=useState('');
  const [communicationType,setCommunicationType]=useState('select');
  const [connected,setConnected]=useState('select');
  const [callTypeOPtion,setCallTypeOption]=useState([

    {
      key:"select",
      value:"Select"
    },
    {
      key:"personal",
      value:"Personal Call"
    },
    {
      key:"business",
      value:"Business Call"
    }
  ]);

  const [connectedOption,setConnectedOption]=useState([
    {
      key:"",
      value:"Select"
    },
    {
      key:1,
      value:"Connected"
    },
    {
      key:0,
      value:"Non Connected"
    },

  ])

  const [communicationTypeOption,setcommunicationTypeOption]=useState([

    {
      key:"",
      value:"Select"
    },
    {
      key:"INCOMING",
      value:"incoming"
    },
    {
      key:"OUTGOING",
      value:"outgoing"
    },
    {
      key:"MISSED",
      value:"missed"
    }
  ]);




  const handleSearch = () => {
    debugger;
    let query = {
      startDate,
      endDate,
      selectedUser,
      SelectedCampaign,
      mobileNo,callType,
      type:communicationType,
      TotalCalls:connected
    };
debugger;
    props.filterData(query);
  };

  const getAllUsers = async () => {
    if (user && user.admin && user.admin != 'undefined') {
    let userInfo = await API_GET(
      `register/UserInfoByUserName?username=${user.admin}`
    );
    if (userInfo.success && userInfo.data.all_members) {
      let _options = [];
      userInfo.data.all_members.forEach((element) => {
        _options.push({
          key: element.username,
          value: element.username+' ('+element.first_name+')',
        });
      });

      setAllUsers(_options);
    }
    }
  };

  const ReloadFilter = async () => {
    const url = new URLSearchParams(window.location.search);
    let urlParam = url.get("Search");

    console.log(urlParam);
    if (urlParam && JSON.parse(atob(urlParam))) {
      urlParam = JSON.parse(atob(urlParam));

      //====================== Users =================================//
      
     
      urlParam.filename && urlParam.filename.$regex 
        ? setMobileNo(urlParam.filename.$regex)
        : setMobileNo([]);

        
      if (urlParam.hasOwnProperty("callback")) {
        setStartDate(urlParam.callback.$gte);
        setEndDate(urlParam.callback.$lt);
      } else if (urlParam.hasOwnProperty("createdOn")) {
        setStartDate(urlParam.createdOn.$gte);
        setEndDate(urlParam.createdOn.$lt);
      }
    }
  };

  useEffect(() => {
    getAllUsers();
    ReloadFilter();
  }, []);
// !Recording_IsShowField && //
  return (
    <GridContainer>
      <Dialog open={open}>
        {/* <DialogTitle id="form-dialog-title"> Filter Details</DialogTitle> */}
        <DialogContent>
          <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Filter
            <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Activity.CallDetails.Filter'} />
              </GridItem>
          </DialogTitle>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}> 
              {["manager", "owner"].indexOf(user && user.role) > -1 &&  (
                <GridItem xs={12} sm={12} md={12}>
                  {allUsers && (
                    <GenerateField
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      defaultValue={"Select"}
                      id={"assignTo"}
                      name={"assignTo"}
                      labelText="Select User"
                      label="Select User"
                      type="checkbox_list"
                      keyValuePair={allUsers}
                    />
                  )}
                </GridItem>
              )}

               <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <CustomInput
                    style="dashboard_button"
                    id="startdate"
                    labelText="Select Date"
                    labelProps={{ shrink: false }}
                    formControlProps={{
                      fullWidth: true,
                      multiline: false,
                    }}
                    inputProps={{
                      type: "date_range",
                      initialDateRange: {
                        startDate: startDate,
                        endDate: endDate,
                      },

                      showFetch: false,
                      onChange: (e) => {
                        setStartDate(e.startDate);
                        setEndDate(e.endDate);
                      },
                      onClick: () => {
                        //loadFollowUpData();
                      },
                    }}
                  />
                </GridContainer>
              </GridItem>

              {Recording_IsShowField &&   <GridItem xs={8} sm={8} md={8}>
                <CustomInput
                  labelText="MobileNo"
                  id="MobileNo"
                  labelProps={{ shrink: true }}
                  formControlProps={{
                    fullWidth: true,
                    multiline: true,
                  }}
                  inputProps={{
                    required: true,
                    value: mobileNo,
                    name: "name",
                    onChange: (e)=>{setMobileNo(e.target.value)},
                  }}
                />
              </GridItem>}




              { showCallType && <GenerateField
                  value={callType}
                  onChange={(e) => setCallType(e.target.value)}
                  defaultValue={"select"}
                  id={"callType"}
                  name={"callType"}
                  labelText="Select Call Type"
                  label="Select Call Type"
                  type="select"
                  keyValuePair={callTypeOPtion}
              />


              }


              {
                showCommunicationType && <GenerateField
                    value={communicationType}
                    onChange={(e) => setCommunicationType(e.target.value)}
                    defaultValue={"select"}
                    id={"communicationType"}
                    name={"communicationType"}
                    labelText="Select Call Type"
                    label="Select Communication Type"
                    type="select"
                    keyValuePair={communicationTypeOption}
                />
              }


              {
                showCallConnectedFilter && <GenerateField
                    value={connected}
                    onChange={(e) => setConnected(e.target.value)}
                    defaultValue={"select"}
                    id={"connected"}
                    name={"connected"}
                    labelText="Select Connection Type"
                    label="Select Connection "
                    type="select"
                    keyValuePair={connectedOption}
                />


              }

            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.handleClose} color="primary">
            Close
          </Button> */}
          <Button onClick={handleSearch} color="primary">
            Apply
          </Button>
          {/* <Button onClick={handleReset} color="primary">
            Reset
          </Button> */}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

CallFilters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(CallFilters);

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
