import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { styled, useTheme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide";
import useMediaQuery from '@material-ui/core/useMediaQuery';

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import dayjs from "dayjs";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { ButtonGroup, DialogActions, DialogContent } from "@material-ui/core";
import Button from "../CustomButtons/Button";
import {Typography } from "@material-ui/core";



function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    customOptions,
    style,
  } = props;
  //let Count = 1;
  const [IsRefresh, setIsRefresh] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState(inputProps.initialDateRange);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 760);
  const toggle = () => setOpen(!open);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });

  // useEffect(() => {
  //   debugger;
  //   setIsRefresh(true);
  // //  Count = Count + 1;
  //   setDateRange(inputProps.initialDateRange);
  // }, [inputProps.initialDateRange]);

  let frmcontrol = null;
  //debugger;
  let selectOption = [];
  if (customOptions) {
    customOptions.split(",").forEach((element) => {
      selectOption.push({
        key: element,
        value: element,
      });
    });
  } else if (inputProps.options) {
    selectOption = inputProps.options;
  }

  switch (inputProps.type) {
    case "radio":
      frmcontrol = (
        <RadioGroup
          id={id}
          value={inputProps.defaultValue}
          {...inputProps}
          style={{ marginTop: "40px" }}
        >
          {selectOption &&
            selectOption.map((item) => {
              return (
                <FormControlLabel
                  value={item.key}
                  control={<Radio />}
                  label={item.value}
                />
              );
            })}
        </RadioGroup>
      );

      break;
    case "checkbox":
      // frmcontrol = <Checkbox
      //   classes={{
      //     root: marginTop,

      //   }}
      //   style={{ top: '8px' }}
      //   id={id}
      //   {...inputProps}
      // />
      frmcontrol = (
        <MenuItem value={inputProps.checked}>
          <Checkbox id={id} {...inputProps} />
          <ListItemText primary={labelText} />
        </MenuItem>
      );

      break;
    case "textarea":
      frmcontrol = (
        <TextField
          classes={{
            root: marginTop,
            disabled: classes.disabled,
          }}
          id={id}
          style={{ top: "15px" }}
          multiline
          rows={inputProps.rows || 1}
          rowsMax={inputProps.rowsMax || 4}
          value={inputProps.defaultValue}
          {...inputProps}
        />
      );
      break;
    case "select":
      frmcontrol = (
        <Select
          classes={{
            root: marginTop,
            disabled: classes.disabled,
          }}
          id={id}
          value={inputProps.defaultValue}
          {...inputProps}
        >
          {selectOption &&
            selectOption.map((item) => {
              return <MenuItem value={item.key}>{item.value}</MenuItem>;
            })}
        </Select>
      );
      break;
    case "checkbox_list":
      frmcontrol = (
        <Select
          classes={{
            root: marginTop,
            disabled: classes.disabled,
          }}
          multiple
          {...inputProps}
          input={<Input />}
          id={id}
          renderValue={(selected) => handleRender(selected)}
          value={inputProps.defaultValue || inputProps.value}
        >
          {selectOption &&
            selectOption.map((item) => {
              return (
                <MenuItem key={item.key} value={item.key}>
                  <Checkbox
                    checked={
                      inputProps &&
                      inputProps.defaultValue &&
                      inputProps.defaultValue.indexOf(item.key) > -1
                    }
                  />
                    <ListItemText primary={<Typography type="body2" style={{ wordWrap:'break-word',fontSize:'15px' }}> {item.value} </Typography>} />
                </MenuItem>
              );
            })}
        </Select>
      );

      break;
    case "date_time":
      frmcontrol = (
        <TextField
          classes={{
            root: marginTop,
            disabled: classes.disabled,
          }}
          id={id}
          style={{ top: "15px" }}
          {...inputProps}
          type="datetime-local"
          value={inputProps.defaultValue}
        />
      );
      break;
    // case 'date_range':
    //   frmcontrol = <DateRangePicker
    //     open={open}
    //     toggle={toggle}

    //   />
    //   break;
    default:
      frmcontrol = (
        <Input
          classes={{
            root: marginTop,
            disabled: classes.disabled,
            
          }}
          id={id}
          {...inputProps}
        />
      );
      break;
  }


  const handleRender = (selected) => {
    let _result = [];
    selectOption.forEach((element) => {
      if (selected.indexOf(element.key) > -1) {
        _result.push(element.value);
      }
    });
    return _result.join(", ");
  };



  if (inputProps.type == "date_range") {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} justify="end">
          <br />
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <ButtonGroup className={`${style && "dashboard_button"}`}>
              <Button color="white" onClick={toggle}>
                {dayjs(dateRange.startDate).format("DD-MMM-YYYY")} -{" "}
                {dayjs(dateRange.endDate).format("DD-MMM-YYYY")}
              </Button>
              {inputProps.showFetch && (
                <Button color="info" onClick={() => inputProps.onClick()}>
                  Apply
                </Button>
              )}
            </ButtonGroup>
          </GridItem>
        </GridItem>
        <Dialog
          open={open}
          fullWidth={false}
          maxWidth="md"
        >
          <DialogContent style={{ padding: '0' }}>

            <DateRangePicker
              open={open}
              toggle={toggle}
              closeOnClickOutside={true}
              classes={{ paper: classes.paper }}
              {...inputProps}
              onChange={(range) => {
                range.startDate.setHours(0, 0, 0, 0);
                range.endDate.setHours(23, 59, 59, 0);
                setDateRange(range);
                toggle();
                inputProps.onChange(range);
              }}
            />

          </DialogContent>
          {isMobile &&
            <DialogActions>
              <h6>
                Slide right to left
              </h6>
            </DialogActions>
          }
        </Dialog>


      </GridContainer>
    );
  }

  return (
    <FormControl
      {...formControlProps}
      className={
        formControlProps.className +
        " " +
        classes.formControl +
        " " +
        (inputProps.type === "checkbox" && classes.checkbox)
      }
    >
      {labelText !== undefined && inputProps.type !== "checkbox" ? (
        <InputLabel
          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}

      {frmcontrol}

      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  style: PropTypes.string,
};

export default withStyles(customInputStyle)(CustomInput);

// export class GenerateField extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     const { value, onChange, id, options, label, keyValuePair, type = 'select', disabled = false } = this.props;

//     return <GridItem xs={12} sm={12} md={12}>
//       <CustomInput
//         labelText={label}
//         id
//         labelProps={{ shrink: true }}
//         formControlProps={{
//           fullWidth: true,
//           multiline: true
//         }}
//         customOptions={options}
//         inputProps={{
//           required: false,
//           value,
//           defaultValue: value,
//           name: id,
//           id: id,
//           type: type,
//           onChange,
//           disabled,
//           options: keyValuePair
//         }}
//       />
//     </GridItem>;
//   }
// }
