import FacebookLead from "./FacebookLead";
import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { API_POST } from "../../services/api.service";
import IndiaMart from "./IndiaMart";
import IndiaMartLogo from "assets/img/indiamartlogo.png";

import TradeIndia from "./TradeIndia";
//import IndiaMartLogo from "assets/img/indiamartlogo.png";

import JustDial from "./JustDial";
import Shiksha from "./Shiksha";
import CollegeDunia from "./CollegeDunia";
// img
import webhooks from "assets/img/integrations/webhooks.png";
import indiaMart from "assets/img/integrations/indiamart.png";
import facebook from "assets/img/integrations/fbImage.png";
import justdial from "assets/img/integrations/justdial-logo.png";
import watti_logo from "assets/img/integrations/wati-logo-1.svg";
import Interakt_logo from "assets/img/integrations/Interakt.webp";
import callingsetup_logo from "assets/img/integrations/callingsetup.png";
import shiksha from "assets/img/integrations/shiksha.png";
import collegedunia from "assets/img/integrations/collegedunia.png";
import tradeindia from "assets/img/integrations/tradeindialogo.png";
import _99acres from "assets/img/integrations/99acres.jpeg";
import ei_logo from "assets/img/integrations/ei-logo.png";
import WA_logo from "assets/img/integrations/whatsIcon.gif";
import Commonfloor_logo from "assets/img/integrations/Commonfloor.png";
import {Box, Tab, Tabs} from "@material-ui/core";


import Interakt from "./Interakt";
import Housing from "./Housing";
import Ajio from "./Ajio";
import Watti from "./Watti";

import _99Acres from "./_99Acres";
import ExportersIndia from "./ExportersIndia";
import WhatsappBetyphon from "./WhatsappBetyphon";
import CommonFloor from "./CommonFloor";

function Integrations(props) {
  const { history } = props;
  const { classes, user } = props;
  const [TotalIntegrationsList, setTotalIntegrationsList] = useState([]);
  const [openIndiaMart, setOpenIndiaMart] = useState(false);
  const [facebookLead, setfacebookLead] = useState(false);
  const [openTradeIndia, setOpenTradeIndia] = useState(false);
  const [openIntrakt,setOpenInterakt] = useState(false);
  const [openWatti,setOpenWatti] = useState(false);
  const [openExporterIndia,setOpenExporterIndia] = useState(false);
  const [whatsappBetyphon,setOpenwhatsappBetyphon] = useState(false);

  
  const [openHousing,setOpenHousing] = useState(false);
  const [openAjio,setOpenAjio] = useState(false);
  const [open_99Acres,setOpen_99Acres] = useState(false);
  const [openCommonFloor,setOpenCommonFloor] = useState(false);
  
  const [openJD, setOpenJD] = useState(false);
  const [openShikha, setOpenShikha] = useState(false);
  const [openCollegeDunia, setOpenCollegeDunia] = useState(false);
  const [IsEdit, setIsEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [active,setActive] = useState(1)
  useEffect(() => {
    if (user && user.username) {
      loadIntegrationList();
    }
  }, [user]);

  const handleClick = () => {
    setOpenIndiaMart(true);
    setIsEdit(false);
    setSelectedItem([]);
  };

  const handleFacebook=() =>{
    setfacebookLead(true);
    setIsEdit(false);
    setSelectedItem([]);
  }

  const handleClose = () => {
    setOpenIndiaMart(false);
    setOpenJD(false);
    setOpenShikha(false);
    setOpenCollegeDunia(false);
    setfacebookLead(false);
    setOpenInterakt(false)
    setOpenWatti(false)
    setOpenExporterIndia(false)
    setOpenwhatsappBetyphon(false)
    setOpenHousing(false)
    setOpenAjio(false)
    setOpen_99Acres(false)
    setOpenCommonFloor(false)
  };

  const handleClickTI = () => {
    setOpenTradeIndia(true);
    setIsEdit(false);
    setSelectedItem([]);
  };

  const handleInterakt = () => {
    setOpenInterakt(true);
    setIsEdit(false);
    setSelectedItem([]);
  };
  const handleWatti = () => {
    setOpenWatti(true);
    setIsEdit(false);
    setSelectedItem([]);
  };
  const handle_99Acres = () => {
    setOpen_99Acres(true);
    setIsEdit(false);
    setSelectedItem([]);
  };

  const handleExporterIndia = () =>{
    setOpenExporterIndia(true);
    setIsEdit(false);
    setSelectedItem([]);
  }


  const handleWhatsappBetyphon = () =>{
    setOpenwhatsappBetyphon(true);
    setIsEdit(false);
    setSelectedItem([]);
  }

  const handleHousing = () => {
    setOpenHousing(true);
    setIsEdit(false);
    setSelectedItem([]);
  };

  const handleAjio = () => {
    setOpenAjio(true);
    setIsEdit(false);
    setSelectedItem([]);
  };



  const handleCloseTI = () => {
    setOpenTradeIndia(false);
    setOpenJD(false);
    setOpenShikha(false);
    setOpenCollegeDunia(false);
  };

  const handleClickJD = () => {
    setOpenJD(true);
  };
  const handleShikshaClick = () => {
    setOpenShikha(true);
    setIsEdit(false);
    setSelectedItem([]);
  };
  
  const handleCollegeDuniaClick = () => {
    setOpenCollegeDunia(true);
    setIsEdit(false);
    setSelectedItem([]);
  };

  const handleShikshaClose = () => {
    setOpenShikha(false);
  };
  //handleShikshaClick

  const handleCommonFloor = () => {
    setOpenCommonFloor(true);
    setIsEdit(false);
    setSelectedItem([]);
  };


  useEffect(()=>{
    loadIntegrationList()
  },[active])

  const loadIntegrationList = async () => {
    debugger;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "Integrations",
        con: { admin: props.user.username,IsActive : active ? true : false },
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
        if (registerRequest.data && registerRequest.data.length > 0) {

          registerRequest.data.map((val)=>{ if(! val.campaign) val.campaign=val.templateName;  })
          setTotalIntegrationsList(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleDelete = async (item) =>{

  let  updateStatus=true;
    if(item.IsActive){
      updateStatus=false;
    }else{
      updateStatus=true;
    }

     await API_POST("action/update", {
      root: "Integrations",
      querydata: { _id: item._id },
      body: { IsActive: updateStatus },
    });

   await  loadIntegrationList();
   return true;

  }

  const handleEdit = (item) => {
    debugger;



    if (item.name == "Interakt") {
      setOpenInterakt(true);
      setIsEdit(true);
    }
    
    if (item.name == "Watti") {
      setOpenWatti(true);
      setIsEdit(true);
    }
    if (item.name == "99Acres") {
      setOpen_99Acres(true);
      setIsEdit(true);
    }

    if (item.name == "exportersIndia") {
      setOpenExporterIndia(true);
      setIsEdit(true);
    }
    


    if (item.name == "Ajio") {
      setOpenAjio(true);
      setIsEdit(true);
    }

    if (item.name == "Facebook") {
      setfacebookLead(true);
      setIsEdit(true);
    }
    if (item.name == "IndiaMart") {
      setOpenIndiaMart(true);
      setIsEdit(true);
    }
    if (item.name == "TradeIndia") {
      setOpenTradeIndia(true);
      setIsEdit(true);
    }
    if (item.name == "JustDial") {
      setOpenJD(true);
      setIsEdit(true);
    }
    if (item.name == "Shiksha") {
      setOpenShikha(true);
      setIsEdit(true);
    }
    if (item.name == "CollegeDunia") {
      setOpenCollegeDunia(true);
      setIsEdit(true);
    }
    if (item.name == "CommonFloor") {
      setOpenCommonFloor(true);
      setIsEdit(true);
    }
    setSelectedItem(item);
  };
  const Logo = (item) => {
    if (item.name == "IndiaMart") {
      return <img src={IndiaMartLogo} style={{ width: "100px" }} />;
    }
  };

  return (
    <>

      <Interakt
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openIntrakt}
        handleClose={handleClose}
      />

      <Watti
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openWatti}
        handleClose={handleClose}
      />

    <Housing  
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openHousing}
        handleClose={handleClose}
      />

      <ExportersIndia  
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openExporterIndia}
        handleClose={handleClose}
      />
 
      <WhatsappBetyphon
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={whatsappBetyphon}
        handleClose={handleClose}
      />



      <IndiaMart
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openIndiaMart}
        handleClose={handleClose}
      />
      <_99Acres
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={open_99Acres}
        handleClose={handleClose}
      />
      <FacebookLead
          user={user}
          CallloadIntegrationList={loadIntegrationList.bind(this)}
          defaultData={selectedItem}
          IsEdit={IsEdit}
          open={facebookLead}
          handleClose={handleClose}
      />

      <TradeIndia
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openTradeIndia}
        handleClose={handleCloseTI}
      />

      <JustDial
        user={user}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openJD}
        handleClose={handleClose}
      />
      <Shiksha
        user={user}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openShikha}
        handleClose={handleClose}
      />
      <CollegeDunia
        user={user}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openCollegeDunia}
        handleClose={handleClose}
      />

      <Ajio
        user={user}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openAjio}
        handleClose={handleClose}
      />

      <CommonFloor
        user={user}
        CallloadIntegrationList={loadIntegrationList.bind(this)}
        defaultData={selectedItem}
        IsEdit={IsEdit}
        open={openCommonFloor}
        handleClose={handleClose}
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="transparent">
              <h3>Market Place</h3>
            </CardHeader>
            {/* <CardBody>
              <Button
                onClick={() => {
                  history.push("/admin/webhook");
                }}
              >
                WebHooks
              </Button>
              <Button onClick={handleClick}>IndiaMart</Button>
              <Button onClick={handleClickTI}>TradeIndia</Button>
              <Button onClick={handleClickJD}>JustDial</Button>
              <Button onClick={handleShikshaClick}>Shiksha</Button>
              <Button onClick={handleCollegeDuniaClick}>CollegeDunia</Button>
            </CardBody> */}
            {/* new grid */}
            <CardBody className="App-con">
              <GridContainer>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={webhooks} className="img-responsive" />
                    <h4>WebHooks</h4>
                    <a
                      onClick={() => {
                        history.push("/admin/webhook");
                      }}
                    >
                      Integrate
                    </a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={facebook} className="img-responsive" />
                    <h4>Facebook Leads</h4>
                    <a onClick={handleFacebook}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={indiaMart} className="img-responsive" />
                    <h4>IndiaMart</h4>
                    <a onClick={handleClick}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={justdial} className="img-responsive" />
                    <h4>JustDial</h4>
                    <a onClick={handleClickJD}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={shiksha} className="img-responsive" />
                    <h4>Shiksha</h4>
                    <a onClick={handleShikshaClick}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={collegedunia} className="img-responsive" />
                    <h4>CollegeDunia</h4>
                    <a onClick={handleCollegeDuniaClick}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={tradeindia} className="img-responsive" />
                    <h4>TradeIndia</h4>
                    <a onClick={handleClickTI}>Integrate</a>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={callingsetup_logo} className="img-responsive" />
                    <h4>Calling Setup</h4>
                    <a onClick={()=>{  history.push("/admin/callingSetupList") } }>Integrate</a>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={Interakt_logo} className="img-responsive" />
                    <h4>Interakt</h4>
                    <a onClick={handleInterakt}>Integrate</a>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={watti_logo} className="img-responsive" />
                    <h4>Watti</h4>
                    <a onClick={handleWatti}>Integrate</a>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={_99acres} className="img-responsive" />
                    <h4>99acres</h4>
                    <a onClick={handle_99Acres}>Integrate</a>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={ei_logo} className="img-responsive" />
                    <h4>Exporter India</h4>
                    <a onClick={handleExporterIndia}>Integrate</a>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={WA_logo} className="img-responsive" />
                    <h4>Whatsapp Betyphon</h4>
                    <a onClick={handleWhatsappBetyphon}>Integrate</a>
                  </div>
                </GridItem>

              

{
   /*
                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={"https://cdn.spark.app/media/housingcom/image/housing.com_purple_black.png"} className="img-responsive" />
                    <h4>Housing</h4>
                    <a onClick={handleHousing}>Integrate</a>
                  </div>
                </GridItem>
   */
}



                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={"https://cdn6.aptoide.com/imgs/d/f/b/dfb0d95efaa0be188f2fc6bc3b609c55_icon.png"} className="img-responsive" />
                    <h4>Ajio</h4>
                    <a onClick={handleAjio}>Integrate</a>
                  </div>
                </GridItem>
                

                <GridItem xs={12} sm={6} md={2}>
                  <div className="channel-wrapper">
                    <img src={Commonfloor_logo} className="img-responsive" />
                    <h4>Commonfloor</h4>
                    <a onClick={handleCommonFloor}>Integrate</a>
                  </div>
                </GridItem>



              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="transparent">
                <h3>Prospect Integration</h3>
              </CardHeader>
              
              <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#265077"
                    }
                  }}
                  value={active}
                  onChange={ (e)=>setActive(active?0:1)}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
              >
                <Tab label="Inactive" />
                <Tab label="Active" />

              
              </Tabs>

              <CardBody>
                <TableContainer>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Channel Name</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Additional Info</TableCell>
                        <TableCell>Last Modified</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TotalIntegrationsList &&
                        TotalIntegrationsList.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell>{item.name.toUpperCase()}</TableCell>
                              <TableCell>{item.campaign}</TableCell>
                              <TableCell>{item.IsActive ? 'true' : 'false'}</TableCell>
                              <TableCell>{item.additionalInfo ? (
                                  <>
                                    <p> <b>Account</b>: {item.additionalInfo.ConnectedAccount} </p>
                                    <p> <b>Form Name</b>: {item.additionalInfo.FormName} </p>
                                    <p> <b>Page Name</b>: {item.additionalInfo.pageName} </p>

                                  </>
                              ) : null}</TableCell>
                              <TableCell>
                                  {window
                                    .moment(item.LastRun)
                                    .format("DD-MMM-YYYY HH:mm:ss")}
                              </TableCell>
                              <TableCell>
                                <ButtonGroup
                                  color="info"
                                  aria-label="outlined primary button group"
                                >
                                  {["manager", "owner"].indexOf(
                                    user && user.role
                                  ) > -1 && (
                                      <>
                                    <Button
                                    variant="outlined" color="primary"
                                      onClick={() => handleEdit(item)}
                                    >
                                      Edit
                                    </Button>
                                    <Button variant="outlined" color="primary"  onClick={() => {
                  history.push("/admin/APiLogs?type=integration&id="+item._id);
                }}
                >Logs</Button>
                                        {
                                          item.IsActive ?
                                              (
                                                  <Button
                                                      variant="outlined" color="danger"
                                                      onClick={() => handleDelete(item)}
                                                  >
                                                    Delete
                                                  </Button>

                                              ):(
                                                  <Button
                                                      variant="outlined" color="primary"
                                                      onClick={() => handleDelete(item)}
                                                  >
                                                    Restore
                                                  </Button>

                                              )
                                        }
                                  </>
                                  )}
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </>
  );
}

export default Integrations;
