
import {SET_SERACHVAL} from '../ActionTypes/ActionsTypes';

export const searchVal = (payload) => {
  
    return {
        type: SET_SERACHVAL,
        payload: payload
    };
};
