import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// core components
import DataTable from "react-data-table-component";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { API_POST } from "../../services/api.service";
import CommonMapping from "./../CommonMapping/CommonMapping";
import axios from "axios";
import InfoBox from "../../components/InfoIcon/InfoIcon";


function Interakt(props) {
  const { classes, user, open, defaultData, IsEdit } = props;


  const [hookData, sethookData] = useState([]);
  const [Campaigns, setCampaigns] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [phone, setPhone] = useState("");
  const [integrationName, setIntegrationName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [profileIdInput, setProfileIdInput] = useState("");
  // https://www.tradeindia.com/utils/my_inquiry.html?userid=4696867&profile_id=6260167&key=0155a1bfb1360b02af468f4424a0c4ab&from_date=2021-08-22&to_date=2021-08-23&limit=10&page_no=1
  const [api, setApi] = useState(
    "https://api.interakt.ai/v1/public/message/"
  );
  const [InputType, setInputType] = useState("");
  const [keyInput, setKeyInput] = useState([]);
  const [MappingVal, setMappingVal] = useState([]);
  const [MappingWithMulObject, setMappingWithMulObject] = useState([]);
  const [optionfield,setOptionfield] = useState([]);


  const [PreviewData, setPreviewData] = useState([]);
  const [IsShowFieldMapping, setIsShowFieldMapping] = useState(false);
  const [IsShowTestBtn, setIsShowTestBtn] = useState(false);
  const [IsPreviewData, setIsPreviewData] = useState(false);

  const [columns, setcolumns] = useState([]);
  const [mapping, setMapping] = useState([]);
  const [chkResult, setChkResult] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IsTestBtnClicked, setIsTestBtnClicked] = useState(false);
  const [IsSaved, setIsSaved] = useState(false);
  const [header, setHeader] = useState(null);

  const childRef = useRef();

  const [formValues, setFormValues] = useState([{}])

  let handleChange = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }

  let addFormFields = () => {
      setFormValues([...formValues, {   }])
    }

  let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues)
  }

  let handleSubmit = (event) => {
      event.preventDefault();
     // alert(JSON.stringify(formValues));
  }


  const handleClose = () => {
//alert(JSON.stringify(formValues));
    props.handleClose();
  };

  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  useEffect(() => {
    if (props.user) {
      loadCampaign();
    }
  }, [props.user]);

  useEffect(() => {
    setIsTestBtnClicked(true)
    if (open && defaultData && defaultData.templateName) {
      console.log(defaultData);

      setApi(defaultData.api);
     // setInputType({ id: defaultData.cid, name: defaultData.campaign });
      setIntegrationName(defaultData.integrationName);
      setPhone(defaultData.phone);
      //setCampaigns(defaultData.cid)

      setHeader(defaultData.header);
      setTemplateName(defaultData.templateName);
      setFormValues(defaultData.body);
      setApiKey(defaultData.apiKey)

    }
    else {
        setApiKey("")
      setInputType("");
      setMappingWithMulObject([]);
      setPreviewData([]);
      setMappingVal();
      setIsShowFieldMapping(false);
      setTemplateName([]);
      setProfileIdInput([]);
      setKeyInput([]);
    }
  }, [defaultData]);

  const loadCampaign = async () => {
    const { user } = props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "prospect_fields",
        con: { admin: user && user.admin },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
         //  setCampaigns("");
           setOptionfield(registerRequest.data.map((val)=>{ return { key:val.field_name,value:val.display_Name } }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleSave = async (e) => {
    setIsSaved(true);
   // childRef.current.handleAdd();
  };


  const TestApi = async () =>{
    console.log(user)

    if(!phone){
        alert('Plese enter test phone');
        return true;
    }



    const headers = {
        'Authorization': 'Basic '+apiKey,
    };
   
    let formfilter = formValues.filter((v)=> v.key);
   // alert(JSON.stringify(formValues))
    let d=formfilter.map((val,key)=>{ return {'key':Object.keys(val)[0],'value':Object.values(val)[0]} });
    d.sort((a, b) => a.key - b.key)
    let bodyValue = d.map((val)=> val.value);
    let payload={
        "countryCode": "+91",
        "phoneNumber": phone,
        "callbackData": "some text here",
        "type": "Template",
        "template": {
            "name": templateName,
            "languageCode": "en"
        }
    }
    if(header){
      payload.template.headerValues = [header];
    }
    if(bodyValue){
       payload.template.bodyValues = bodyValue;
    }

    try{
      const response = await axios.post(api, payload,{ headers });
      setNotify({
        open: true,
        message: response.data.message,
        color: "success",
      });
    }catch(e){
      setNotify({
        open: true,
        message: e.message + ' please check Details',
        color: "success",
      });
    }




  }

  const handleSaveIntegration = async () => {
    let payload = {
      name: "Interakt",
      admin: user && user.admin,
      integrationName: integrationName,
      useInWhatsapp :true,
      api: api,
      templateName:templateName,
      phone:phone,
      IsActive: true,
      apiKey:apiKey,
      header:header,
      body:formValues
    };

    let registerRequest;
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "Integrations",
        body: payload,
        querydata: { admin: user.admin, name: "Interakt" ,integrationName:integrationName  },
      });

      if (registerRequest && registerRequest.status == 200) {
        setNotify({
          open: true,
          message: "Data Save Successfully",
          color: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
    props.handleClose();
  };

  useEffect(() => {
    if (IsSaved) {
      props.CallloadIntegrationList();
      handleSaveIntegration();
      setIsSaved(false);
    }
  }, [IsSaved]);

  const handleBlur = async (e) => {

    if (e.target.name == "profile_id" || e.target.name == "userid" || e.target.name == "Key") {
      if (profileIdInput != "" && templateName != "" && keyInput != "") { setIsShowTestBtn(true); }
      else setIsShowTestBtn(false);
    }
  };

  let col = [];
  const handlePreview = async () => {
    debugger;
    if (
      PreviewData.length == 0 &&
      !PreviewData[0].hasOwnProperty("Error_Message")
    ) {
      setNotify({
        open: true,
        message: "Please first click on test btn to show mapping.",
        color: "danger",
      });
    } else {
      let obj = {};
      for (var key in PreviewData[0]) {
        if (PreviewData[0].hasOwnProperty(key)) {
          obj = {};
          //obj = { name: key, selector: PreviewData[0][key] };
          obj = { name: key, selector: key };
          col.push(obj);
        }
      }
      debugger;
      setcolumns(col);
      setIsPreviewData(true);
    }
  };

  const bindMapping = (mapping) => {
    setChkResult(true);
    setMapping(mapping);
    setIsSaved(true);
  };

  return (
    <GridContainer>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle id="customized-dialog-title">Interakt Setup
        <GridItem  style={{float: "right", marginTop: "10px"}}>
            <InfoBox name={'Integrations.Interakt'} />
        </GridItem>
        </DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                id="Name"
                labelText={"Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: "Interakt",
                  name: "Interakt",
                  disabled: true,
                }}
              />


                <CustomInput
                id="IntegrationName"
                labelText={"Integration Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: integrationName,
                  name: "integrationName",
                  onChange: (e) => {
                    setIntegrationName(e.target.value);
                  },
                  onBlur: handleBlur,
                }}
              />

              <CustomInput
                id="apiKey"
                labelText={"API KEY"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: apiKey,
                  name: "apiKey",
                  onChange: (e) => {
                    setApiKey(e.target.value);
                  },
                  onBlur: handleBlur,
                }}
              />


              <CustomInput
                id="api"
                labelText={"API *"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: api,
                  name: "api",
                  onChange: (e) => {
                    setApi(e.target.value);
                  },
                }}
              />

              <CustomInput
                id="templateName"
                labelText={"Template Name"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: templateName,
                  name: "templateName",
                  onChange: (e) => {
                    setTemplateName(e.target.value);
                  },
                  onBlur: handleBlur,
                }}
              />


                <CustomInput
                id="phone"
                labelText={"Phone (only for test)*"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: phone,
                  name: "phone",
                  onChange: (e) => {
                    setPhone(e.target.value);
                  },
                }}
              />

<CustomInput
                id="phone"
                labelText={"Header Value (optional)"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: false,
                  value: header,
                  name: "header",
                  onChange: (e) => {
                    setHeader(e.target.value);
                  },
                }}
              />


           <h3>Body Values</h3>

           { formValues.length && formValues.map((element, index) => (

            <div className="row" key={index+1}>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                id={(index+1)}
                labelText={"{{"+(index+1)+"}}"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  value: (index+1),
                  name: (index+1),
                  disabled: true,
                }}
              />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                 id={"body_variable_value_"+(index+1)}
                 labelText={"Value"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type:"select",
                  options:optionfield,
                  value: element[(index+1)],
                  name: (index+1),
                  onChange: (e) => {
                    handleChange(index, e);
                  },
                  onBlur: handleBlur,
                }}
              />
              {
                index ?
                <Button onClick={() => removeFormFields(index)} color="danger">
                Remove
              </Button>
                  : null
              }
              </GridItem>
              </GridContainer>


            </div>
          ))}

          <div className="button-section">
          <Button onClick={() => addFormFields()} color="primary">
            Add
          </Button>

          </div>




            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              {IsShowFieldMapping && (
                <CommonMapping
                  ref={childRef}
                  MappingInSingleObj={MappingVal}
                  MappingWithMulObject={MappingWithMulObject}
                  isEdit={IsEdit}
                  user={props.user}
                  bindMapping={bindMapping}
                />
              )}
            </GridItem>
          </GridContainer>
          <GridContainer>
            {IsPreviewData && PreviewData && (
              <DataTable
                columns={columns}
                data={PreviewData || []}
                highlightOnHover
              />
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {(IsTestBtnClicked || props.IsEdit) && <Button onClick={handleSave} color="primary">
            Save
          </Button>}
          {<Button onClick={TestApi} color="primary">
            Test
          </Button>}
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

export default Interakt;

class GenerateField extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      value,
      onChange,
      id,
      options,
      label,
      keyValuePair,
      type = "select",
      disabled = false,
    } = this.props;

    debugger;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          labelText={label}
          id
          labelProps={{ shrink: true }}
          formControlProps={{
            fullWidth: true,
            multiline: true,
          }}
          customOptions={options}
          inputProps={{
            required: false,
            value,
            defaultValue: value,
            checked: value,
            name: id,
            id: id,
            type: type,
            onChange,
            disabled,
            options: keyValuePair,
          }}
        />
      </GridItem>
    );
  }
}
