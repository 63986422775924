import React, { useEffect, useState } from 'react'
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
// import TableCell from "@material-ui/core/TableCell";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import { API_GET, API_POST } from "../../services/api.service";

const useStyles = makeStyles({
    tableWrapper: {
      overflowX: 'auto', // Enable horizontal scrolling
      maxWidth: '100%', // Set maximum width to 100% of parent container
    },
  });


const InformationLink = () => {

    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState([]);
    const [editedData, setEditedData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await API_POST("action/getdata", {
                root: "Information_link",
                con: {}
            });
                setData(response.data);
                console.log(response.data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const handleEditClick = (index) => {
        setEditMode((prevEditMode) => {
            const newEditMode = [...prevEditMode];
            newEditMode[index] = true;
            return newEditMode;
        });

        setEditedData((prevEditedData) => {
            const newEditedData = [...prevEditedData];
            newEditedData[index] = { ...data[index] };
            return newEditedData;
        });
    };

    const handleSaveClick = async (index) => {
        try {

            console.log('Save clicked for index:', index);
            // Make sure to include the ID in the edited data
            const originalId = data[index]._id;
            console.log(originalId)

            // Create a new object with the original _id and the edited data
            const updatedData = { ...editedData[index] };
            delete updatedData._id;

            console.log(updatedData);



            // Call API to update data on the server
            await API_POST("action/update", {
                root: "Information_link",
                body: updatedData,
                querydata: { _id: originalId }
            });

            // Update local state
            setEditMode((prevEditMode) => {
                const newEditMode = [...prevEditMode];
                newEditMode[index] = false;
                return newEditMode;
            });


            fetchData(); // Fetch updated data

            console.log('Save successful');
        } catch (error) {
            console.error('Error saving data:', error.message);
        }
    };


    const handleCancelClick = (index) => {
        setEditMode((prevEditMode) => {
            const newEditMode = [...prevEditMode];
            newEditMode[index] = false;
            return newEditMode;
        });

        setEditedData((prevEditedData) => {
            const newEditedData = [...prevEditedData];
            newEditedData[index] = null; // Reset edited data
            return newEditedData;
        });
    };

    useEffect(() => {

        fetchData();
    }, []);

    const handleInputChange = (index, fieldName, value) => {
        setEditedData((prevEditedData) => {
            const newEditedData = [...prevEditedData];
            newEditedData[index] = {
                ...newEditedData[index],
                [fieldName]: value
            };
            return newEditedData;
        });
    };

    const classes = useStyles();

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="white">
                            <h3>Information Links</h3>
                        </CardHeader>
                        <CardBody>
                        <div className={classes.tableWrapper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="sm">Page</TableCell>
                                        <TableCell size="sm">SubPage</TableCell>
                                        <TableCell size="sm">Name</TableCell>
                                        <TableCell size="sm">Info Link</TableCell>
                                        <TableCell size="sm">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item.page}
                                            </TableCell>
                                            <TableCell>
                                                {item.subPage}
                                            </TableCell>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {editMode[index] ? (
                                                    <input
                                                        type="text"
                                                        value={editedData[index].infoLink}
                                                        onChange={(e) => handleInputChange(index, 'infoLink', e.target.value)}
                                                    />
                                                ) : (
                                                    item.infoLink
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {editMode[index] ? (
                                                    <Button color="primary" onClick={() => handleSaveClick(index)}>
                                                        Save
                                                    </Button>
                                                ) : (
                                                    <Button color="primary" onClick={() => handleEditClick(index)}>
                                                        Edit
                                                    </Button>
                                                )}
                                                {editMode[index] && (
                                                    <Button color="danger" onClick={() => handleCancelClick(index)}>
                                                        Cancel
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default InformationLink