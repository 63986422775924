import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Cookies from "js-cookie";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import logo from "assets/img/logo-dark.png";
import forgetPasswordStyle from "../../assets/jss/material-dashboard-react/views/forgetPasswordStyle";
import { API_GET } from "../../services/api.service";
import { getToken,removeToken,setToken } from "../../services/Common";

const { REACT_APP_SERVER_URL } = process.env;

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      errors: {},
      userName: "",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
    document.title = "ForgetPassword";
  }

  ValiditeUser = async () => {
    debugger;
    let registerRequest;

    registerRequest = await API_GET(
      `register/authenticateUser?user=${this.state.userName}`
    );

    if (registerRequest && registerRequest.data.user) {
      return true;
    } else {
      this.setState({
        notify: {
          open: true,
          message: "User Not Exixts",
          color: "danger",
        },
      });

      return false;
    }
  };

  sendEmail = async (e) => {
    debugger;
    let result = await this.ValiditeUser();
    if (result) {
      var mailOptions = { to: this.state.userName };

      let SendEmail;
      try {
        SendEmail = await axios.post(
          `//${REACT_APP_SERVER_URL}/register/forgetPassword`,
          {
            mailOptions,
          }
        );

        debugger;
        if (SendEmail && SendEmail.data.success) {
          this.setState({
            notify: {
              open: true,
              message: "Mail Sent successfully",
              color: "success",
            },
          });
        }
      } catch ({ response }) {
        this.setState({
          notify: {
            open: true,
            message: "Something went wrong",
            color: "danger",
          },
        });
        SendEmail = response;
        console.log(response);
      }
    } else {
      this.setState({
        notify: {
          open: true,
          message: "User does not Exists",
          color: "danger",
        },
      });
    }
  };

  handleLogin = async (e) => {
    const { history } = this.props;
    return history.push("/auth/login-page");
  };

  componentDidMount() {
    //if (localStorage.getItem("token")) {
    if (getToken()) {
      const { history, user } = this.props;
      debugger;
      return history.push("/admin/dashboard");
    } else {
    }
  }

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <>
        <Snackbar
          place="right"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} />
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.forgetPassword}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="transparent"
                  >
                    {/* <h4 className={classes.cardTitle}>Log in</h4> */}
                    <div className={classes.socialLine}>
                      <img src={logo} />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      error={errors.username || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        required: true,
                        name: "username",
                        onChange: (e) => {
                          this.setState({ userName: e.target.value });
                        },
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.sendEmail}
                        >
                          Send Email
                        </Button>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.handleLogin}
                        >
                          LOG iN
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

ForgetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object,
};

export default withStyles(forgetPasswordStyle)(ForgetPassword);
