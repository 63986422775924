import React, { useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Loader from "components/Loader/Loader.jsx";
import { API_POST } from "../../services/api.service";
import {Link} from "react-router-dom";


function Box(props) {
  const { classes, user, root, query, small, isReload , propData} = props
  const [data, setData] = React.useState(propData? propData: 0);


  const loadData = async (e) => {
    
    if (!user || !query)
      return;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getCount",
        {
          root: root,
          con: { ...query, admin: user && user.admin },
        });
      console.log(registerRequest);


      if (registerRequest && registerRequest.status == 200) {

        setData(registerRequest && registerRequest.data);

      }
    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }

  useEffect(() => {
    if (user && user.admin && !propData) {
      loadData();
    }
  }, [user, isReload, query, propData]);
  
  useEffect(() => {
    setData(propData);
  }, [propData]);


  return (<>
    <Card>
    <CardHeader color={props.color ? props.color : 'danger'} stats icon>
      <CardIcon color={props.color ? props.color : 'danger'}>
        <i className={props.icon}></i>
      </CardIcon>
      {!small && <p className={classes.cardCategory}>{props.label}</p>}
      {!small && <h3 className={classes.cardTitle} >
        {data}
      </h3>}
      {small && <h4 className={classes.cardTitle}>
        {data}
      </h4>}
    </CardHeader>
    <CardFooter stats>
      <div className={classes.stats}>
        { props.url ? (
            <>
              <Link to={props.url}>
                {small ? props.label : "View Details"}
              </Link>
            </>
        ):
            (
                <>
                  <a href="#pablo" onClick={ props.clickMe ? ()=>props.clickMe(props.query) : (e) => e.preventDefault()  }>
                    {small ? props.label : "View Details"}
                  </a>
                </>
            )

        }


      </div>
    </CardFooter>
  </Card></>)

}



export default withStyles(dashboardStyle)(Box);
