import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import logo from "assets/img/logo-dark.png";
import createPasswordStyle from "../../assets/jss/material-dashboard-react/views/createPasswordStyle";
import { API_POST } from "../../services/api.service";
const { REACT_APP_SERVER_URL } = process.env;

class CreatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      oldPassowrd: "",
      newPassowrd: "",
      UserName: "",
      IsShowMsg: false,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
    document.title = "CreatePassword";
  }

  handleLogin = async (e) => {
    const { history } = this.props;
    return history.push("/auth/login-page");
  };

  savePasswordInDB = async (password) => {
    let registerRequest;

    registerRequest = await API_POST("register/ResetPassword", {
      password,
      user: this.state.UserName,
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({ IsShowMsg: true });

      // this.setState({
      //   notify: {
      //     open: true,
      //     message: "Password Save Successfully",
      //     color: "success",
      //   },
      // });
    } else {
      this.setState({
        notify: {
          open: true,
          message: "Something went wrong",
          color: "danger",
        },
      });
    }
  };

  savePassword = async (e) => {
    if (
      JSON.stringify(this.state.oldPassowrd) !=
      JSON.stringify(this.state.newPassowrd)
    ) {
      this.setState({
        notify: {
          open: true,
          message: "Password not match",
          color: "danger",
        },
      });
    } else {
      this.savePasswordInDB(this.state.oldPassowrd);
    }
  };

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = async () => {
    let registerRequest;
    let routeParams = window.location.pathname.split("/");
    let code = window.location.search.split('code=')[1];

    if (!routeParams) {
      return 0;
    }

    registerRequest = await API_POST("public/resetPasswordValidate", {
      con: { SecurityCode: code },
    });

    if (registerRequest && registerRequest.status == 200) {
      this.setState({ UserName: registerRequest.data[0].User });
    } else {
      this.setState({
        notify: {
          open: true,
          message: "UserName Not Exists",
          color: "danger",
        },
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>
        <Snackbar
          place="right"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.CreatePassword}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="transparent"
                >
                  {/* <h4 className={classes.cardTitle}>Log in</h4> */}
                  <div className={classes.socialLine}>
                    <img src={logo} />
                  </div>
                </CardHeader>
                {!this.state.IsShowMsg && (
                  <CardBody>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      error={errors.password || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.oldPassowrd,
                        required: true,
                        onChange: (e) => {
                          this.setState({ oldPassowrd: e.target.value });
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="Confirm password"
                      error={errors.password || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.newPassowrd,
                        required: true,
                        onChange: (e) => {
                          this.setState({ newPassowrd: e.target.value });
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                )}
                {this.state.IsShowMsg && (
                  <CardBody>{"Password Change Succesfully"}</CardBody>
                )}

                {!this.state.IsShowMsg && (
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.savePassword}
                        >
                          Save
                        </Button>
                        <Button
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.handleLogin}
                        >
                          Cancel
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                )}
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CreatePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object,
};

export default withStyles(createPasswordStyle)(CreatePassword);
