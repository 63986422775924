import React, { useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Loader from "components/Loader/Loader.jsx";

import { API_POST } from "../../services/api.service";
import _ from "underscore";
import { Button } from "@material-ui/core";

drilldown(Highcharts);

let Option = {
  "title": {
    "text": "Latest Responses"
  },
  "tooltip": {
    "pointFormat": "<span style=\"color:{point.color};\">{series.name}</span>: <b>{point.y}</b>",
    "backgroundColor": "white"
  },
  "chart": {
    "type": "pie",
    "events": {
      drilldown: function (e) {

        if (!e.seriesOptions) {
          let dashboardData = JSON.parse(localStorage.getItem("disposition_chart"));

          let _dispositions = _.where(dashboardData, { parentId: e.point.id });
          let obj = [];
          _dispositions.forEach(function (item) {
            if (item.count > 0) {
              obj.push([item.disposition_value, item.count])
            }
          });
          var chart = e.target;
          chart.addSingleSeriesAsDrilldown(e.point, {
            name: e.point.name,
            color: 'green',
            data: obj
          });
          chart.applyDrilldown();
        }
      }
    }
  },
  "plotOptions": {
    "series": {
      "cursor": "pointer",
      "dataLabels": {
        "color": "#428bca",
        "distance": -30,
        "enabled": true,
        "backgroundColor": "white",
        "format": "{point.percentage:.1f}%"
      },
      "showInLegend": true,
      "allowPointSelect": true
    }
  },
  "series": [
    {
      "name": "Disposition",
      "id": "Disposition",
      "colorByPoint": true,
      "data": null
    }
  ]

}



function LRChart(props) {
  const { classes, user, query } = props
  
  const [data, setData] = React.useState(null);
  
  let chartOption = { ...Option };
  
  const loadData = async (e) => {
    let registerRequest;
    try {
      if(user && user.admin){
      let con = { ...query, admin: user && user.admin, username: user && user.username, role: user && user.role, dispositionQuery: { admin: user && user.admin } };
      registerRequest = await API_POST("action/getChartInfo",
        {
          root: 'disposition',
          con: con
        });
      //console.log(registerRequest);


      if (registerRequest && registerRequest.status == 200) {
        debugger;
        localStorage.setItem('disposition_chart', JSON.stringify(registerRequest.data))
        setData(registerRequest && registerRequest.data);
      }
    }


    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }



  useEffect(() => {
    
    if (user && user.admin) {
      console.log("LRChart user", user)
      loadData();
    }
  }, [user, query]);
  

  const bindChart = () => {
    let _chartOption = { ...chartOption };
    _chartOption.series[0]['data'] = null
    let obj = [];
    let _dispositions = _.where(data, { parentId: null });
    _dispositions.forEach(function (item) {
      if (item.count > 0) {
        obj.push({
          "y": item.count,
          "drilldown": true,
          "name": item.disposition_value,
          "id": item._id,
        })
      }
    });
    _chartOption.series[0]['data'] = obj

    return _chartOption;
  }

 


  return (
    <>
      <Card chart>
        <CardHeader color="primary">
          {/* <Button onClick={() => loadData()}>Reload</Button> */}
          {data == null && <div style={{height:'400px', background: '#ffffff'}}> <Loader></Loader></div>}

          {data != null && <HighchartsReact
            highcharts={Highcharts}
            options={bindChart()}
            allowChartUpdate={true}
          />}
        </CardHeader>
        <CardBody >
         
          </CardBody>
        <CardFooter chart />
      </Card>
    </>
  );
}



export default withStyles(dashboardStyle)(LRChart);
