import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Cookies from "js-cookie";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPageStyle.jsx";
import logo from "assets/img/logo-dark.png";
import "./Styles/LoginPage.css";
import {getQueryParams, getToken, removeToken, setToken} from "../../services/Common";
// import { Snackbar } from "@material-ui/core";

import Snackbar from "../../components/Snackbar/Snackbar.jsx";
const { REACT_APP_SERVER_URL } = process.env;

class LoginPage extends React.Component {
  constructor(props) {
   
    super(props);
    this.state = {
      registerMsg:null,
      release:null,
      checked: [],
      errors: {},
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
    document.title = "Log in";
  }
  
  isMobile=(window.innerWidth <= 760); 

  login = async (e) => {
    e.preventDefault();
   
    const { history } = this.props;

    const fields = ["username", "password"];
    const formElements = e.target.elements;

    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value,
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let loginRequest;
    try {
      loginRequest = await axios.post(
        `//${REACT_APP_SERVER_URL}/login`,
        {
          ...formValues,
        },
        {}
      );
    } catch ({ response }) {
      loginRequest = response;
      console.log(response);
    }
    console.log(loginRequest);
    const { data: loginRequestData } = loginRequest;
    if (loginRequestData.success) {
      this.setState({
        notify: {
          open: false,
          message: "",
          color: "success",
        },
      });

      setToken(loginRequestData.token);
      //localStorage.setItem("token", loginRequestData.token);

      let userInfo = loginRequestData.userInfo;
      console.log(loginRequestData);

      try{

        // only for mobile Dialling permission
        if(this.isMobile && loginRequestData.permissions && loginRequestData.permissions.length && loginRequestData.permissions[0].redirect){
          return history.push(loginRequestData.permissions[0].redirect);
        }
      }catch(e){

      }

      if (userInfo.role === "agent") {
        return history.push("/admin/campaigns");
      } else {
        return history.push("/admin/dashboard");
      }
    } else {
      this.setState({
        notify: {
          open: true,
          message: loginRequestData.messages.errors.invalidEmailOrPassword,
          color: "danger",
        },
      });
    }

    this.setState({
      errors: loginRequestData.messages && loginRequestData.messages.errors,
    });
  };
  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };
  handleReg = async (e) => {
    const { history } = this.props;
    return history.push("/auth/register-page");
  };

  handleForgetPass = async (e) => {
    debugger;
    const { history } = this.props;
    return history.push("/auth/forget-password");
  };
  componentDidMount() {
    debugger;
    let routeParams = window.location.pathname.split("/");

    let message=getQueryParams("message", window.location.href);
    if(message){
      this.setState({
        notify: {
          open: true,
          message: decodeURI(message),
          color: "success",
        },
      });
    }

    let registermsg=getQueryParams('msg', window.location.href);

    if(registermsg){
      this.setState({ ...this.state,registerMsg:decodeURI(registermsg) });

    }


    if (routeParams && routeParams[3] == "Logout") {
      const { history } = this.props;
      removeToken();
      //localStorage.removeItem("token");
      //localStorage.clear();
      history.push("/auth/login-page");
      try {
        window.location.reload();
        window.logout.performClick();
      } catch (e) {}
    } else if (getToken()) {
      const { history, user } = this.props;
      debugger;
      return history.push("/admin/dashboard");
    } else {
    }
  }
  handleClosePopup = () => {
    this.setState({ ...this.state.notify, open: false });
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />



        <div className={classes.container}>



          <GridContainer sm={12} justify="">
            {
              !this.isMobile ?
              (<>
              
              <GridItem xs={12} sm={12} md={6}  style={{ marginRight: '100px', marginTop:'100px' }}>

                <iframe style={{ width:'100%',height:'398px' }} src="https://www.youtube.com/embed/E81XyemCeVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

              </GridItem>  

              </>)
              : (<>
              
              </>)

            }
        
            <GridItem xs={this.isMobile ? 12 : 2} sm={this.isMobile ? 12 : 2} md={4} justify="center" >
              <form onSubmit={this.login} style={{paddingLeft:'18% !important'}}>
                <Card
                  className={`${
                    classes[this.state.cardAnimaton]
                  } LoginPage__card`}
                >
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="transparent"
                  >
                    {
                      this.state.registerMsg && ( <p style={{color:'green',fontSize:20}}> { this.state.registerMsg} </p>)
                    }
                    {/* <h4 className={classes.cardTitle}>Log in</h4> */}
                    <div className={classes.socialLine}>
                      {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}

                      <img src={logo} />
                    </div>
                  </CardHeader>
                  <CardBody>
                    {/* <p
                    className={`${classes.textCenter} ${classes.checkboxLabel}`}
                  >
                    Or Sign in with <strong>admin@material.com</strong> and the
                    password <strong>secret</strong>{" "}
                  </p> */}
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      error={errors.username || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        required: true,
                        name: "username",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      error={errors.password || errors.invalidEmailOrPassword}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "password",
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        root:
                          classes.checkboxLabelControl +
                          " " +
                          classes.checkboxLabelControlClassName,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => this.handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={<span>Remember me</span>}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Button type="submit" color="primary" simple size="lg">
                          LOG IN
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.handleForgetPass}
                        >
                          FORGOT PASSWORD
                        </Button>
                      </GridItem>
                      <GridItem xs={8} sm={8} md={8}>
                        <p>
                          Don't have an account? Sign Up for free 3 day trial
                        </p>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button
                          type="button"
                          color="primary"
                          simple
                          size="lg"
                          onClick={this.handleReg}
                          block
                        >
                          SIGN UP
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object,
};

export default withStyles(loginPageStyle)(LoginPage);
