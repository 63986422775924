/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { API_GET } from "../services/api.service";
import { getToken, setToken } from "../services/Common";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-dark.png";

let userInfo = {};

class CRM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      user: {},
    };
  }
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  async componentDidMount() {
    if (getToken()) {
    } else {
      debugger;
      const urlParams = new URLSearchParams(this.props.location.search);
      const token = urlParams.get("token");
      if (token) {
        //localStorage.setItem("token", token);
        setToken(token);
      } else {
        const { history } = this.props;
        return history.push("/auth/login-page");
      }
    }

    for (let index = 0; index < routes.length; index++) {
      const prop = routes[index];
      if (this.activeRoute(prop.layout + prop.path)) {
        document.title = prop.name;
      }
    }

    if (navigator.platform.indexOf("Win") > -1) {
    }
    //window.addEventListener("resize", this.resizeFunction);
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);

    if (getSessionRequest && getSessionRequest.success) {
      console.log("getSessionRequest.userInfo", getSessionRequest.userInfo);
      this.setState({ user: getSessionRequest.userInfo });
      userInfo = getSessionRequest.userInfo;
      let url = window.location.href;



    }
    else {
      return history.push("/auth/login-page");
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.wrapper}>



        <Switch>
          {routes.map((prop, key) => {
            if (prop.layout === "/crm") {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={(props) => {
                    const Component = prop.component;
                    return (
                      <Component {...props} user={this.state.user} />
                    );
                  }}
                  key={key}
                />
              );
            }
          })}
        </Switch>


      </div>
    );
  }
}

CRM.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(CRM);
