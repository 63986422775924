/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import { getToken } from "../../services/Common.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/DialogTitle/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Button from "components/CustomButtons/Button.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


import API from "../Campaigns/API";

const { REACT_APP_SERVER_URL } = process.env;

class CollegeDunia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Campaigns: [],
      InputType: "",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  componentDidMount() {

    if (this.props.user) {
      this.loadCampaign();
    }
  }

  componentWillMount() {
    console.log("componentWillMount")
  }

  // to stop the warning of calling setState of unmounted component

  loadCampaign = async () => {
    const { user } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive: true },
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          this.setState({ Campaigns: registerRequest.data, InputType: this.props.defaultData });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  componentWillReceiveProps(n, o) {
    debugger;
    if (n.defaultData) {
      this.setState({
        InputType: {
          name: n.defaultData.campaign,
          _id: n.defaultData.cid,
        }
      })
    }
  }

  handleSave = async () => {
    const { user, IsEdit } = this.props;
    const { InputType } = this.state;
    let payload = {
      name: "CollegeDunia",
      admin: user && user.admin,
      campaign: InputType.name,
      cid: InputType._id
    };

    let registerRequest;
    try {
      registerRequest = await API_POST("action/upsert", {
        root: "Integrations",
        body: payload,
        querydata: { admin: user.admin, name: "CollegeDunia" },
      });

      if (registerRequest && registerRequest.status == 200) {

        this.setState({
          notify: {
            open: true,
            message: "Data Save Successfully",
            color: "success"
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  render() {
    const { user, open, defaultData, IsEdit } = this.props;
    const { Campaigns, InputType } = this.state;
    console.log(InputType);
    

    return (
      <GridContainer>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <Dialog open={open} fullWidth={true} maxWidth="md">
          <DialogTitle id="customized-dialog-title">CollegeDunia Setup
          <GridItem  style={{float: "right", marginTop: "10px"}}>
              <InfoBox name={'Integrations.CollegeDunia'} />
          </GridItem>
          </DialogTitle>

          <DialogContent>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    onChange={(event, value) => this.setState({ InputType: value })}
                    id="combo-box-demo"
                    size="small"
                    options={Campaigns}
                    labelText="FieldType"
                    name="autoComplete"
                    value={InputType || null}
                    getOptionLabel={(Campaigns) => Campaigns.name || ""}
                    defaultValue="Select"
                    renderTags={(value) =>
                      value.map(() => (
                        <Chip variant="outlined" label="Enter Field" />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Campaign "
                      // variant="outlined"
                      />
                    )}
                  />
                </GridItem>
                <br />
                <br />
                
                {InputType && InputType._id &&
                    <API cid={InputType._id} user={this.props.user}
                        showGet={false} showPost={true}
                    >

                    </API>
                  
                }

              </GridContainer>

            </GridItem>
          </DialogContent>
          <DialogActions>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                Please ask CollegeDunia team, for the above API.
                </GridItem>
            </GridContainer>
            {(InputType) && <Button onClick={this.handleSave.bind(this)} color="primary">
              Save
          </Button>}
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Cancel
          </Button>
          </DialogActions>
        </Dialog>






      </GridContainer>
    );
  }
}
export default withStyles(dashboardStyle)(CollegeDunia);
