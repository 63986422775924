import axios from "axios";
import React, { useRef, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { render } from 'react-dom'

import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST, API_UPLOAD } from "../../services/api.service";
import { copyToClipboard } from "../../services/Common";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Divider } from '@material-ui/core';


const FacebookLeads = (props) => {
    const emailEditorRef = useRef(null);

    const { classes, user, cid } = props;
    const [formid,setFormid]=useState('');
    const [pageaccess,setPageaccess]=useState('');
    const [pageId,setPageId]=useState('');
    const [data,setData]=useState([]);
    const [count,setCount]=useState(0);
    let notification = {
        open: false,
        message: "",
        color: "success",
    };
    const [Notify, setNotify] = useState(notification);

    const columns = [
        {
            name: "id",
            selector: "id",
        },
        {
            name: "Data",
            cell: (row) =>

                (
                <GridContainer>

                    {
                        row.field_data.map((val,key)=>{

                            return (
                                <>
                                <GridItem xs={6} sm={6} md={12}>
                                   {val.name} =  {val.values}
                                </GridItem>

                                </>
                            );
                        })
                    }
                </GridContainer>
                ),


        },

        {
            name: "Created On",
            cell: (row) => (
                <span>
          {row.createdOn &&
          window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
        </span>
            ),
        },

    ];

    useEffect(async () => {
        if (user) {

            const tid = props.match.params.id;
            if (tid) {
               await loadPages(tid);
              await  getData();
            }
        }
    }, [user,formid,pageId]);


    const getData= async ()=>{

        var leads = await API_POST("action/getdata",
            {
                root: 'Facebook_Leads',
                con: {admin: user && user.admin,formId:formid,pageId:pageId},
                cols: {}
            });


        if (leads && leads.status == 200) {

            setData(leads.data);

        }


    }

    const SaveRecord= async (data)=>{


        for(let i=0;i<data.data.length;i++){

            let storeData=data.data[i];
            storeData.admin = user && user.admin;
            storeData.formId = formid;
            storeData.pageId = pageId
            storeData.createdOn = new Date();
            storeData.createdOn = new Date();


            let Request = await API_POST("action/upsert", {
                root: "Facebook_Leads",
                body: storeData,
                querydata: {admin: user.admin, id: storeData.id,pageId:pageId},
            })

        }

    }

    const Refresh =  async () =>{



        try{

            var leadDetails = await axios( 'https://graph.facebook.com/v13.0/'+formid+'/leads?access_token='+pageaccess);
            await SaveRecord(leadDetails.data);

        }catch (e) {
            setNotify({
                color: "danger",
                message: e.message,
                open: true
            });
        }

        await getData();


    }

    const loadPages = async (id) =>{
        if (user) {
            var registerRequest = await API_POST("action/getdata",
                {
                    root: 'Facebook_Form',
                    con: {admin: user && user.admin, _id: id},
                    cols: {}
                });

            var PageData = await API_POST("action/getdata",
                {
                    root: 'Facebook_Pages',
                    con: {admin: user && user.admin, id: registerRequest.data[0].PageId},
                    cols: {}
                });

            setPageId(PageData.data[0].id);
            setFormid(registerRequest.data[0].id);
            setPageaccess(PageData.data[0].access_token);


        }
    }


    return (


        <>
            <Snackbar
                place="tr"
                color={Notify.color}
                message={Notify.message}
                open={Notify.open}
                autoHideDuration={3000}
                closeNotification={() => setNotify({ open: false })}
                close
            />



            <GridContainer>



            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="transparent">
                        <h3> Facebook Leads
                        </h3>
                    </CardHeader>
                    <CardBody>


                        <Button onClick={Refresh}>
                            Refresh
                        </Button>


                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <DataTable
                                    columns={columns}
                                    data={ data || []}
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={"10"}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                    }}
                                />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                    <CardFooter>

                    </CardFooter>
                </Card>
            </GridItem>


        </GridContainer>


</>
    );
};
export default withStyles(dashboardStyle)(FacebookLeads);
class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
