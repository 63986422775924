/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { API_GET, API_POST } from "../../services/api.service";

import PhoneIcon from "@material-ui/icons/Phone";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const { REACT_APP_SERVER_URL } = process.env;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      aggData: {},
      activeTab: 0,
      cid: "",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  componentDidMount() {}

  componentWillMount() {
    const { cid } = this.props;
    if (cid) {
      this.setState({ cid: cid });
      this.loadCampaign(cid);
    }
  }

  loadCampaign = async (id) => {
    let registerRequest;
    try {
      registerRequest = await API_GET("action/CampaignAggregateData?cid=" + id);

      if (registerRequest && registerRequest.status == 200) {
        //console.log(registerRequest.data);
        if (registerRequest.data) {
          this.setState({ aggData: registerRequest.data });
        }
        //this.setState({ campaignsDetails: registerRequest.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // to stop the warning of calling setState of unmounted component

  handleRefresh = () => {
    window.location.reload();
  };


  StartCall = async (type) => {
    const { user, cid, history } = this.props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/StartCall", {
        cid,
        username: user && user.username,
        type: type
      });

      if (registerRequest && registerRequest.status == 200) {
        console.log(registerRequest.data);
        if (registerRequest.data && registerRequest.data.data && registerRequest.data.message =="" ) {
          let prospect = registerRequest.data.data;
          localStorage.setItem("callingType", type);
          history.push("/admin/ViewProspect/" + prospect._id);
        }
        else if (registerRequest.data  && registerRequest.data.message !="") {
          this.setState({
            notify: {
              open: true,
              message: registerRequest.data.message,
              color: "danger",
            },
          });
          setTimeout(() => {
            history.push("/admin/campaigns/");
          }, 5000);
        }
        else {
          this.setState({
            notify: {
              open: true,
              message: "No Prospect found to call.",
              color: "danger",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  IsCallAllowed = async () => {
    const { user, cid, history } = this.props;
    let message = "";
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getCount", {
        root: "campaigns",
        con: { admin: user && user.admin, _id: cid, IsActive: true },
      });

      if (
        registerRequest &&
        registerRequest.status == 200 &&
        registerRequest.data == 1
      ) {
        //----------Check user exists in that campain------------//

        let IsUserExist;
        IsUserExist = await API_POST("action/getCount", {
          root: "campaignMembers",
          con: { admin: user && user.admin, cid, username: "username1234" },
        });
        if (IsUserExist && IsUserExist.status == 200 && IsUserExist.data != 1) {
          message = "User is not exist in Campaign";
        }
      } else {
        message = "Campaign is not active";
      }
    } catch (error) {
      console.log(error);
    }
    return message;
  };

  render() {
    const { classes } = this.props;

    const { campaignsDetails } = this.props;
    const { aggData } = this.state;

    console.log("aggData", aggData);

    return (
      <GridContainer>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
          <GridItem xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
            <InfoBox name={'Campaigns.ViewMore.Home'} />
          </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Button
            variant="outlined"
            color="transparent"
            block={true}
            // onClick={() => this.handleAdd()}
          >
            &nbsp;<b>{aggData && aggData.overdueProspectsToday}</b> &nbsp;
            overdue out of &nbsp;{" "}
            <b>{aggData && aggData.pendingProspectsToday}</b> &nbsp; pending
            followups today
          </Button>

          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Button
                variant="outlined"
                color="warning"
                block={true}
                onClick={() => this.StartCall("fresh")}
              >
                <PhoneIcon />
                Fresh Calling
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Button
                variant="outlined"
                color="danger"
                block={true}
                onClick={() => this.StartCall("overdue")}
              >
                <PhoneIcon />
                Overdue
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                variant="outlined"
                color="info"
                block={true}
                onClick={() => this.StartCall("normal")}
              >
                <PhoneIcon />
                Start Calling
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    {/* <Icon>content_copy</Icon> */}
                    <i className="fa fa-address-card fa-5x" />
                  </CardIcon>

                  <h3 className={classes.cardTitle}>
                    {aggData && aggData.totalProspects}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Total Prospects</div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    {/* <Icon>content_copy</Icon> */}
                    <i className="fa fa-user-plus fa-4x" />
                  </CardIcon>

                  <h3 className={classes.cardTitle}>
                    {aggData && aggData.freshProspects}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Fresh Prospects</div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    {/* <Icon>content_copy</Icon> */}
                    <i className="fa fa-phone fa-4x" />
                  </CardIcon>

                  <h3 className={classes.cardTitle}>
                    {aggData && aggData.pendingProspects}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>Total Follow up</div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <i className="fa fa-calendar fa-4x" />
                  </CardIcon>
                  <p className={classes.cardCategory}>Upcoming FollowUps</p>
                  <h3 className={classes.cardTitle}>
                    {aggData && aggData.upcomingProspects}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    <i className="fa fa-warning fa-4x" />
                  </CardIcon>
                  <p className={classes.cardCategory}>Overdue Followups</p>
                  <h3 className={classes.cardTitle}>
                    {aggData && aggData.overdueProspects}
                  </h3>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Workflow
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {campaignsDetails && campaignsDetails.workflow
                ? campaignsDetails.workflow
                : "None"}
            </GridItem>
          </GridContainer>
          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Telephony
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {campaignsDetails && campaignsDetails.telephony
                ? campaignsDetails.telephony
                : "None"}
            </GridItem>
          </GridContainer>

          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Prospect View Type
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {campaignsDetails && campaignsDetails.viewType
                ? campaignsDetails.viewType
                : "None"}
            </GridItem>
          </GridContainer>

          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Help Script
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {campaignsDetails && campaignsDetails.helpScript
                ? campaignsDetails.helpScript
                : "None"}
            </GridItem>
          </GridContainer>

          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Created at
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {/* {campaignsDetails && campaignsDetails.createdOn} */}
              {campaignsDetails && campaignsDetails.createdOn
                ? window
                    .moment(campaignsDetails.createdOn)
                    .format("h:mm:ss a MMMM Do YYYY")
                : "NA"}
            </GridItem>
          </GridContainer>

          <GridContainer style={{ padding: "10px" }}>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Launch time
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              {campaignsDetails && campaignsDetails.launchTime
                ? window
                    .moment(campaignsDetails.launchTime)
                    .format("h:mm:ss a ,MMMM Do YYYY")
                : "NA"}
              {/* {campaignsDetails && campaignsDetails.launchTime ? campaignsDetails.launchTime : "" } */}
            </GridItem>
          </GridContainer>

          <GridContainer>
            {/* <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Integration API (POST)
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              //{REACT_APP_SERVER_URL}/action/addProspects
            </GridItem> */}
          </GridContainer>
          {/* <GridContainer>
            <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
              Pay Load
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <p>
                {
                  `{ 
                    "phone": "<phone>",
                   "source": "<source>",
                   "cid" : "${campaignsDetails && campaignsDetails._id}",
                   "admin": "${campaignsDetails && campaignsDetails.admin}"  
                   }`
                }
              </p>
            </GridItem>
          </GridContainer> */}
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(dashboardStyle)(Home);
