import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_POST } from "../../services/api.service";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import ViewDisposition from "./ViewDisposition";
import "./Style/Disposition.css";
// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
import DeleteIcon from "@material-ui/icons/Delete";
import { Snackbar } from "@material-ui/core";
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import InfoBox from "../../components/InfoIcon/InfoIcon";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
  actions: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#00a65a",
    borderRadius: "10px",
  },
  frmFields: {
    padding: "3px 8px",
    color: "white",
    backgroundColor: "#3c8dbc",
    borderRadius: "10px",
  },
};

function Disposition(props) {
  const { classes, user } = props;

  let frmVal = {
    disposition_value: "",
    templateId: "",
    communicationType: "none",
    disposition_Message: "",
    action: [],
    form_fields: [],
    parentId: null,
    admin: user && user.admin,
  };
  const [formValues, setFormValues] = useState(frmVal);
  const [count, setCount] = useState(0);
  const [showshiftCamp, setshowshiftCamp] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [campaigns, setCampaigns] = useState(null);
  const [formOptions, setFormOptions] = useState([]);
  const [dispositions, setDispositions] = useState([]);
  const [rawDispositions, setRawDispositions] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [templates, setTemplates] = useState([]);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: 'success'
  });


  const handleClickOpen = () => {
    setMode("Add");
    frmVal.parentId = null;
    setFormValues(frmVal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadCustomFields = async (e) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "custom_forms_new",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest && registerRequest.data) {
          setCustomFields(registerRequest.data);
          let _options = [];
          registerRequest.data.forEach((element) => {
            _options.push({
              key: element._id,
              value: element.custom_field_name,
            });
          });
          setFormOptions(_options);
        }
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const loadDispositions = async (e) => {
    let registerRequest;

    try {
      registerRequest = await API_POST("action/getdata", {
        root: "dispositions",
        con: { admin: user && user.admin },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        setRawDispositions(registerRequest.data);
        let data = await getChild(registerRequest.data, null);
        setDispositions(data);
      }

      // load campaign
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin,IsActive: true },
        cols: {},
      });

      if (registerRequest && registerRequest.status == 200) {
        let options=[{key:'Default',value:'Default'}]

        registerRequest.data.map((v)=>{ options.push(  {
          key: v._id,
          value: v.name
        })} )

        setCampaigns(options);
      }

    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  const getChild = async (data, parentId) => {
    let returnVal = [];
    if (data && data.length > 0) {
      let _customFields = data;
      for (let index = 0; index < _customFields.length; index++) {
        const element = _customFields[index];
        if (element.parentId == parentId) {
          element["child"] = await getChild(data, element._id);
          returnVal.push(element);
        }
      }
    }
    return returnVal;
  };




  const getTemplate = async (type) => {
    if (type == "none") {
      return;
    }
    try {
      var data = await API_POST("action/getdata", {
        root: "Templates",
        con: { admin: user && user.admin, isActive: true, type: type },
        cols: {},
      });

      if (data && data.status == 200) {
        let returnVal = [];
        if (data && data.data.length > 0) {

          for (let index = 0; index < data.data.length; index++) {
            let element = data.data[index];
            element["key"] = element._id;
            element["value"] = element.name;
            returnVal.push(element);

          }

        }
        setTemplates(returnVal);

      }

    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  }

  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }


    if (e.target.name == 'communicationType') {
      getTemplate(e.target.value);
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleDisposition = async (e) => {
    e.preventDefault();

    let formValue = formValues;
    formValue["admin"] = user && user.admin;
    formValue["createdOn"] = new Date();

    let registerRequest;
    try {
      registerRequest = await API_POST("action/addRecord", {
        root: "dispositions",
        body: formValue,
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadDispositions(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const handleEditDisposition = async (e) => {
    e.preventDefault();
    debugger;
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];
    delete formValue["child"];

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "dispositions",
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadDispositions(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  useEffect(() => { }, [count]);

  useEffect(() => {
    if (user) {
      loadCustomFields();
      loadDispositions();
    }
  }, [user]);

  const selectfrmField = (_id) => {
    let returnVal = {};
    if (customFields && customFields.length > 0) {
      customFields.forEach((element) => {
        if (element._id == _id) returnVal = element;
      });
    }
    return returnVal;
  };

  const  getAllParents = async (records, childParentId) => {
    // Filter the records to get all parents with the matching parentId
    let parents = records.filter(record => record._id === childParentId);
  
    let allParents = [...parents];

      if (parents.length && parents[0].parentId){
          parents = records.filter(record => record._id === parents[0].parentId);
          allParents = [...allParents,...parents]
      }
    
      if (parents.length && parents[0].parentId){
          parents = records.filter(record => record._id === parents[0].parentId);
          allParents = [...allParents,...parents]
    }
      if (parents.length && parents[0].parentId){
          parents = records.filter(record => record._id === parents[0].parentId);
          allParents = [...allParents,...parents]
    }
      if (parents.length && parents[0].parentId){
          parents = records.filter(record => record._id === parents[0].parentId);
          allParents = [...allParents,...parents]
    }

    // Combine the current parents and their parents
    return allParents;
  }


  const showShiftDisp = async (item) =>{

    let showShift = true;

      let alldata = rawDispositions;
      console.log("aaaaaaaaaa",alldata)
      let parentRecord = await getAllParents(alldata,item.parentId); 
      parentRecord.map((v)=>{  if(v.shiftCampaign !='Default' ){  showShift = false; }  });

      setshowshiftCamp(showShift)

      console.log("sssssssss",item,parentRecord,showShift);

  }

  const addSubRecord = async (item) => {
    frmVal.parentId = item._id;
    await showShiftDisp(frmVal);
    setMode("Add");
    setFormValues(frmVal);
    console.log(":rrrrrrrrrrrrrrrr",frmVal)
    setOpen(true);
  };



  const editSubRecord = async (item) => {
    setMode("Edit");
    setFormValues(item);
    await showShiftDisp(item);
    console.log(":rrrrrrrrrrrrrrrr",item)
    getTemplate(item.communicationType)
    setOpen(true);
  };
  const deleteRecord = async (item) => {
    debugger;
    console.log(item._id);
    let registerRequest;
    registerRequest = await API_POST("action/deleteDisposition", {
      query: { admin: user.admin },
      body: { dispositionId: item._id },
    });
    if (registerRequest && registerRequest.status == 200) {
      if (!registerRequest.data) {
        setNotify({
          open: true,
          message: "Disposition is in use.",
          color: 'success'
        })

      }
      else {
        // setNotify({
        //   open: true,
        //   message: "Delete Successfully.",
        //   color: 'success'
        // })

        loadCustomFields();
        loadDispositions();
      }
    }
  };

  const dispositionNode = (item) => {
    return (
      <li className={classes.disposition}>
        <span>
          <i class="fa fa-minus" aria-hidden="true" />
          &nbsp;
          {item && item.disposition_value}
        </span>
        &nbsp;
        {item && item.action && item.action.length > 0 && <span className={classes.actions}>{item.action.join(", ")}
        </span>}
        &nbsp;
        {
          item && item.priority && <span className={classes.actions}> 
          {item.priority==1 && "Medium"}
          {item.priority==2 && "High"}
          </span>
        }
         &nbsp;
        {
          item && item.notifyme && <Notifications />
        }
         &nbsp;
        {
          item && item.communicationType && <span style={{ color: 'gray', fontWeight: 'bold' }}> {item.communicationType}</span>
        }
        <div className={classes.dispositionActions}>
          {item && item.action && item.action.length == 0 &&
            <Button
              color={"transparent"}
              justIcon={true}
              simple={false}
              aria-label="Edit"
              className={classes.buttonLink}
              onClick={(e) => addSubRecord(item)}
            >
              <i class="fa fa-plus-square-o" aria-hidden="true" />
            </Button>
          }

          <Button
            color={"transparent"}
            justIcon={true}
            simple={false}
            aria-label="Edit"
            className={classes.buttonLink}
            onClick={(e) => editSubRecord(item)}
          >
            <i class="fa fa-pencil-square-o" aria-hidden="true" />
          </Button>
          {item && item.child && item.child.length == 0 ? (
            <Button
              color={"transparent"}
              justIcon={true}
              simple={false}
              aria-label="Edit"
              className={classes.buttonLink}
              onClick={(e) => deleteRecord(item)}
            >
              <DeleteIcon />
            </Button>
          ) : null}
          {/* <i class="fa fa-trash-o" aria-hidden="true"></i>&nbsp; */}
        </div>

        <ul className={classes.fields}>
          {item && item.form_fields && item.form_fields.map((elem, i) => {

            return (
              <li>
                Attached fields :&nbsp;
                <span className={classes.frmFields} color="info">
                  {selectfrmField(elem).custom_field_name}
                </span>
              </li>
            );
          })}
        </ul>

        {item.child && item.child.length > 0 && dispositionList(item.child)}
      </li>
    );
  };

  const dispositionList = (dispositions) => {
    return (
      <ul style={{ paddingLeft: "15px" }}>
        {dispositions.map((item, index) => {
          return dispositionNode(item);
        })}
      </ul>
    );
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
      <GridContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {mode} Disposition
            <GridItem style={{ float: "right", marginTop: "10px" }}>
              <InfoBox name={'Form.Disposition.Add'} />
            </GridItem>
          </DialogTitle>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Disposition Value *"
                  id="disposition_value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: formValues && formValues.disposition_value,
                    name: "disposition_value",
                    onChange: handleChange,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Action"
                  id="action"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  customOptions="Callback"
                  inputProps={{
                    required: false,
                    name: "action",
                    type: "checkbox_list",
                    defaultValue: formValues && formValues.action,
                    onChange: handleChange,
                  }}
                />
              </GridItem>



              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Select Communication Type"
                  id="communicationType"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: false,
                    name: "communicationType",
                    type: "select",
                    value: formValues && formValues.communicationType,
                    onChange: handleChange,
                    options: [
                      {
                        key: "none",
                        value: "No Template"
                      },
                      {
                        key: "email",
                        value: "Email Template"
                      },
                      {
                        key: "sms",
                        value: "SMS Template"
                      },
                      {
                        key: "whatsapp",
                        value: "WhatsApp Template"
                      }
                    ],
                  }}
                />
              </GridItem>

              {formValues && (['', 'none'].indexOf(formValues.communicationType) == -1) &&
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Select Template Type"
                    id="templateId"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      required: false,
                      name: "templateId",
                      type: "select",
                      defaultValue: formValues && formValues.templateId,
                      onChange: handleChange,
                      options: templates,
                    }}
                  />
                </GridItem>
              }



              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Disposition Message *"
                  id="disposition_Message"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: true,
                    defaultValue: formValues && formValues.disposition_Message,
                    name: "disposition_Message",
                    type: "textarea",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Select Priority"
                  id="priority"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    required: false,
                    name: "priority",
                    type: "select",
                    value: (formValues && formValues.priority)||0,
                    onChange: handleChange,
                    options: [
                      {
                        key: 0,
                        value: "Default"
                      },
                      {
                        key: 1,
                        value: "Medium"
                      },
                      {
                        key: 2,
                        value: "High"
                      }
                    ],
                  }}
                />
              </GridItem>



{  showshiftCamp &&    <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Auto Shift Campaign"
                id="shiftCampaign"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  required: false,
                  name: "shiftCampaign",
                  type: "select",
                  value: formValues && formValues.shiftCampaign ? formValues.shiftCampaign : 'Default' ,
                  onChange: handleChange,
                  options: campaigns,
                }}
              />
            </GridItem>


}
          


            

            </GridContainer>
          </DialogContent>
          <DialogActions>
            <CustomInput
              labelText="Notify Me"
              id="notifyme"
              formControlProps={{
                fullWidth: true,
              }}
              labelProps={{ shrink: true }}
              inputProps={{
                required: true,
                checked: formValues && formValues.notifyme,
                name: "notifyme",
                type: "checkbox",
                onChange: handleChange,
              }}
            />
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={mode === "Add" ? handleDisposition : handleEditDisposition}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="white">
              <h3>
                List Dispositions
                <Button
                  variant="outlined"
                  color="info"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={handleClickOpen}
                >
                  + Add
                </Button>
                <GridItem style={{ float: "right", marginTop: "10px" }}>
                  <InfoBox name={'Forms.Disposition'} />
                </GridItem>
              </h3>
            </CardHeader>
            <CardBody>
              {dispositions && dispositions.length > 0 && dispositionList(dispositions)
              }
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <ViewDisposition
            dispositions={rawDispositions}
            showControls={true}
            user={user}
            Title="Live Preview"
          />
          {/* <Card>
          <CardHeader color="white">
            <h3>Live Preview</h3>
          </CardHeader>
          <CardBody>
            <GridContainer>
              

            </GridContainer>
          </CardBody>
        </Card> */}
        </GridItem>
      </GridContainer>
    </>
  );

}

Disposition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Disposition);
