import React from "react";
import axios from "axios";
import { API_GET, API_POST } from "../../services/api.service";

import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import avatar from "assets/img/faces/marc.jpg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const { REACT_APP_SERVER_URL } = process.env;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: null,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      profileData: [],
    };
    this.updateProfile = this.updateProfile.bind(this);
  }

  loadUserInfo = async (username) => {
    debugger;
    let userInfo = await API_GET(
      `register/GetProfileData?username=${username}`
    );
    debugger;
    if (userInfo.success) {
      this.setState({ profileData: userInfo.data });
    } else {
      this.setState({ profileData: null });
    }
  };

  async updateProfile(e) {
    e.preventDefault();
    debugger;

    const fields = ["firstName", "lastName", "email", "Phone"];
    // const formElements = e.target.elements;
    // let formValues = fields
    //   .map((field) => ({
    //     [field]: formElements.namedItem(field).value,
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    //var ab=this.state.profileData;
    // formValues = {
    //   username: this.state.username,
    //   id: this.state.user.id,
    //   firstName:
    //     formValues.firstName == ""
    //       ? this.state.profileData.first_name
    //       : formValues.firstName,
    //   lastName:
    //     formValues.lastname == ""
    //       ? this.state.profileData.last_name
    //       : formValues.lastName,
    //   Phone:
    //     formValues.Phone == ""
    //       ? this.state.profileData.phone
    //       : formValues.Phone,
    //   email:
    //     formValues.email == ""
    //       ? this.state.profileData.email
    //       : formValues.email,
    // };
    let formValues = this.state.profileData;
    debugger;
    try {
      let registerRequest = await API_POST("register/updateProfileData", {
        ...formValues,
      });
      if (registerRequest && registerRequest.data.update) {
        this.setState({
          notify: {
            open: true,
            message: "Data Save successfully",
            color: "success",
          },
        });
        this.loadUserInfo(this.state.user.username);
      }
    } catch ({ response }) {
      registerRequest = response;
    }
  }

  async componentDidMount() {
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);

    if (getSessionRequest && getSessionRequest.success) {
      this.setState({ user: getSessionRequest.userInfo });
    }

    this.loadUserInfo(getSessionRequest.userInfo.username);
  }
  render() {
    const { classes, name, email } = this.props;
    const { errors, user } = this.state;

    if (!user) {
      return null;
    }

    return (
      <div>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <form onSubmit={this.updateProfile}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>My Profile</h4>
                  <p className={classes.cardCategoryWhite}>
                    Complete your profile
                  </p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput
                        labelText="FirstName"
                        id="firstName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{ shrink: true }}
                        inputProps={{
                          // onChange: (e) => {
                          //   this.setState(() => ({
                          //     profileData: {
                          //       ...this.state.profileData,
                          //       first_name: e.target.value
                          //     }
                          //   }));
                          // },

                          // onChange: (e) => {
                          //   this.setState(prevState => ({
                          //     profileData: {
                          //       ...prevState.profileData,
                          //       first_name: e.target.value,
                          //     }
                          //   }));
                          // },
                          onChange: (e) => {
                            this.setState((prevState) => ({
                              profileData: {
                                ...prevState.profileData,
                                first_name: e.target.value,
                              },
                            }));
                          },
                          value:
                            this.state.profileData &&
                            this.state.profileData.first_name,
                          //this.state.profileData &&
                          //this.state.profileData.username,
                          name: "firstName",
                        }}
                      />
                      <CustomInput
                        labelText="Last Name"
                        id="lastName"
                        error={errors.name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{ shrink: true }}
                        inputProps={{
                          value:
                            this.state.profileData &&
                            this.state.profileData.last_name,

                          onChange: (e) => {
                            this.setState((prevState) => ({
                              profileData: {
                                ...prevState.profileData,
                                last_name: e.target.value,
                              },
                            }));
                          },
                          name: "lastName",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput
                        labelText="Email address"
                        id="email-address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{ shrink: true }}
                        inputProps={{
                          value:
                            this.state.profileData &&
                            this.state.profileData.email,
                          name: "email",

                          onChange: (e) => {
                            this.setState((prevState) => ({
                              profileData: {
                                ...prevState.profileData,
                                email: e.target.value,
                              },
                            }));
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput
                        labelText="Phone"
                        id="Phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelProps={{ shrink: true }}
                        inputProps={{
                          pattern: "[0-9]*",
                          maxLength: 10,
                          type: "number",

                          value:
                            this.state.profileData &&
                            this.state.profileData.phone,
                          name: "phone",

                          onChange: (e) => {
                            this.setState((prevState) => ({
                              profileData: {
                                ...prevState.profileData,
                                phone: e.target.value,
                              },
                            }));
                          },
                          //value: Count,
                          //onChange: (e) => {
                          //setCount(e.target.value);
                          //},
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="primary">
                    Update Profile
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile className="ProfileSection">
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Company Details</h4>
              </CardHeader>
              {/* <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img src={avatar} alt="..." />
                </a>
              </CardAvatar> */}
              <CardBody profile>
                {/* <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6> */}
                <h4 className={classes.cardTitle}>
                  {this.state.profileData && this.state.profileData.company}
                </h4>
                {/* <p className={classes.description}>
                  
                </p> */}
                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    FirstName:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData &&
                      this.state.profileData.first_name}
                  </GridItem>
                </GridContainer>

                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    LastName:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData && this.state.profileData.last_name}
                  </GridItem>
                </GridContainer>

                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    username:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData && this.state.profileData.username}
                  </GridItem>
                </GridContainer>

                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    Phone:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData && this.state.profileData.phone}
                  </GridItem>
                </GridContainer>

                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    Email:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData && this.state.profileData.email}
                  </GridItem>
                </GridContainer>

                <GridContainer >
                  <GridItem xs={12} sm={6} md={4} style={{ fontWeight: 500 }}>
                    Company Size:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={8}>
                    {this.state.profileData &&
                      this.state.profileData.company_size}
                  </GridItem>
                </GridContainer>
                <GridContainer >
                  <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
                  Last Call Details:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    {this.state.profileData &&
                      this.state.profileData.lastSyncData &&
                      this.state.profileData.lastSyncData.CallDetails && 
                      this.state.profileData.lastSyncData.CallDetails.length>0 && 
                      window.moment(this.state.profileData.lastSyncData.CallDetails[0].startDate).format("DD-MMM-YYYY HH:mm")
                      
                      }
                  </GridItem>
                </GridContainer>
                <GridContainer >
                  <GridItem xs={12} sm={6} md={6} style={{ fontWeight: 500 }}>
                  Last Recording:
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                  {this.state.profileData &&
                      this.state.profileData.lastSyncData &&
                      this.state.profileData.lastSyncData.CallRecording && 
                      this.state.profileData.lastSyncData.CallRecording.length>0 && 
                      window.moment(this.state.profileData.lastSyncData.CallRecording[0].startDate).format("DD-MMM-YYYY HH:mm")
                      
                      }
                  </GridItem>
                </GridContainer>

                {/* <Button color="primary" round>
                  Follow
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
};

export default withStyles(styles)(UserProfile);
