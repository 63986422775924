/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import { CopyBlock, dracula } from "react-code-blocks";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import { getToken } from "../../services/Common.js";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { Box } from "@material-ui/core";
import Prospects from "../Prospects/Prospects";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const { REACT_APP_SERVER_URL } = process.env;

class API extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      prospect_fields: [],
      activeTab: 0,
      activeTabInner:0,
      cid: "",
      notify: {
        open: false,
        message: "",
        color: "success",
      },
    };
  }

  componentDidMount() { }

  componentWillMount() {
    const { cid, user } = this.props;
    if (cid) {
      this.setState({ cid: cid });
      this.loadCampaign(cid);
      
    }
  }

  loadCampaign = async (id) => {
    const { user } = this.props;
    let registerRequest;
    try {
      debugger;
      registerRequest = await API_POST("action/getdata",
        {
          root: 'prospect_fields',
          con: { admin: user && user.admin },
          cols: {}
        });
        debugger;
      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data) {
          this.setState({ prospect_fields: registerRequest.data });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // to stop the warning of calling setState of unmounted component

  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ activeTabInner: value });
    history.push("?tab=" + value);
  };


  render() {

    const { prospect_fields } = this.state;
    const { showGet, showPost } = this.props;

    let phpapiFields = [];
    console.log("prospect_fields", prospect_fields);
    let apiFields = [];
    for (let index = 0; index < prospect_fields.length; index++) {
      const element = prospect_fields[index];
      debugger;
      apiFields.push(`"${element.field_name}": "<${element.field_name}>"`)
      phpapiFields.push(`"${element.field_name}" => "<${element.field_name}>"`)
    }
    apiFields.push(`"cid": "${this.props.cid}"`)
    apiFields.push(`"admin": "${this.props.user.admin}"`);
    phpapiFields.push(`"cid" => "${this.props.cid}"`)
    phpapiFields.push(`"admin" => "${this.props.user.admin}"`);
    
    let getApiFields = [];
    for (let index = 0; index < prospect_fields.length; index++) {
      const element = prospect_fields[index];
      debugger;
      getApiFields.push(`${element.field_name}=#${element.field_name}#`)
    }
    getApiFields.push(`cid=${this.props.cid}`)
    getApiFields.push(`admin=${this.props.user.admin}`);


    
    let tabs = [

      {
        tabName: "Overview",
        tabContent: (
          <>
         <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
              <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Campaigns.ViewMore.Api.Overview'} />
              </GridItem>
              Headers
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>              
              Authorization: Bearer {getToken()}
            </GridItem>
          </GridContainer>
        </GridItem>

          {showPost && 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
              Integration API (POST)
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {REACT_APP_SERVER_URL}/action/addProspects
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
              Pay Load
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <p>
                {`{ ${apiFields.join(',')}
                   }`}
              </p>
            </GridItem>
          </GridContainer>
        </GridItem>}
        {showGet && 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
              Integration API (GET)
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {REACT_APP_SERVER_URL}/action/addProspects?
              {/* {`?cid=${this.props.cid ? this.props.cid : ""}&admin=${this.props.user.admin ? this.props.user.admin : ""} &source=IVR&phone=#customer#&ivr_number=#dnid#&agent_number=#agent#&calltime=#calltime#&duration=#duration#&bill_sec=#agentbillsec#&status=#status#&audio=#recpath#`} */}
              {getApiFields.join('&')}
            </GridItem>
          </GridContainer>

        </GridItem>
        }
          </>
        ),
      },
      {
        tabName: "PHP Integration",
        tabContent: (
          <>
                   {showPost && 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 800 }}>
              <GridItem style={{ float: "right", marginTop: "10px" }}>
                <InfoBox name={'Campaigns.ViewMore.Api.PhpIntegration'} />
              </GridItem>
              Integration API (POST) PHP
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>

            <CopyBlock
          language="php"
          text={` <?php   
          
          $url = `+REACT_APP_SERVER_URL+`/action/addProspects;
          $data = array(\n `+phpapiFields.join(',\n')+`);
          $data_string = json_encode($data);
          $ch=curl_init($url);
          curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
          curl_setopt($ch, CURLOPT_POSTFIELDS, $data_string);
          curl_setopt($ch, CURLOPT_HEADER, true);
          curl_setopt($ch, CURLOPT_HTTPHEADER,
              array(
                  'Content-Type:application/json',
                  'Authorization: Bearer `+getToken()+`'
              )
          );

          $result = curl_exec($ch);
          curl_close($ch);
                    
          ?>`}
          codeBlock
          theme={dracula}
          showLineNumbers={false}
        />
            
            </GridItem>
          </GridContainer>
        </GridItem>}
        {showGet && 
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
              Integration PHP API (GET)
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CopyBlock
          language="php"
          text={` <?php   
          
          $url = `+REACT_APP_SERVER_URL+`/action/addProspects?`+getApiFields.join('&')+`;
          $ch=curl_init($url);
          curl_setopt($ch, CURLOPT_HEADER, true);
          curl_setopt($ch, CURLOPT_HTTPHEADER,
              array(
                  'Content-Type:application/json',
                  'Authorization: Bearer `+getToken()+`'
              )
          );
          $result = curl_exec($ch);
          curl_close($ch);
                    
          ?>`}
          codeBlock
          theme={dracula}
          showLineNumbers={false}
        />
               


            </GridItem>
          </GridContainer>

        </GridItem>
        }
          </>
        ),
      },
    ];


    return (
      <GridContainer>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={tabs}
              selectedValue={this.state.activeTabInner}
              TabProps={{
                onChange: this.handleChange,
              }}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  }
}
export default withStyles(dashboardStyle)(API);
