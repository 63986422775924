const category = [
    {
        key: "Sales",
        value: "Sales"
    },
    {
        key: "Supports",
        value: "Supports"
    },
    {
        key: "Accounts",
        value: "Accounts"
    }
];

const superCategory = [
    {
        key: "Sales",
        value: "Sales"
    },
    {
        key: "Supports",
        value: "Supports"
    },
    {
        key: "Accounts",
        value: "Accounts"
    },
    {
        key: "Story",
        value: "Story"
    },
    {
        key: "Implementation",
        value: "Implementation"
    },
    {
        key: "Bug Reported",
        value: "Bug Reported"
    },

];
const superCategorymanager = [
    {
        key: "Sales",
        value: "Sales"
    },
    {
        key: "Supports",
        value: "Supports"
    },
    {
        key: "Bug Reported",
        value: "Bug Reported"
    },

];

const subCategory = {
    "Sales": ["Increase the Users", "Know more other products", "Service Activation", "Service Renewal","Product Demo required","Extend of trial account"],
    "Supports": ["Basic Support", "support Call & Recording SYNC", "Support in data upload", "Support in User Training", "Support in Admin Training", "Support in Integration", "Support in Template Setup", "Support in Campaign", "Support in API`S"],
    "Accounts": ["Invoice required", "Other Account issue", "Users adjustment"],
    "Story": ["Service Solution"],
    "Implementation": ["Account Setup","Lead Source Integration","Training Data Upload","Training Admin & User","Existing Data Upload","Template Setup"],
    "Bug Reported": ["New Feature Required","Call and Recording not getting Synced","Data Upload Error","Report Counts Error","Prospect Discrepancy","Product Upgradations","Others"]
};

const statusfilter =[  
    {
        key: "",
        value: ""
    }, 
    {
      key: "Pending",
      value: "Pending"
    },
    {
      key: "In-Progress",
      value: "In-Progress"
    },
    {
        key: "Under Development",
        value: "Under Development"
    },
    {
        key: "In Roadmap",
        value: "In Roadmap"
    },
    {
        key: "Resolved",
        value: "Resolved"
    }

  ];



  const productsList = [
    {
        key: "Betyphon",
        value: "Betyphon"
    },
    {
        key: "IVR Service",
        value: "IVR Service"
    },
    {
        key: "Toll-free Service",
        value: "Toll-free Service"
    },
    {
        key: "Missed Call",
        value: "Missed Call"
    },
    {
        key: "Voice Outbound",
        value: "Voice Outbound"
    },
    {
        key: "Click2Call Service",
        value: "Click2Call Service"
    },
    {
        key: "SMS Service",
        value: "SMS Service"
    },
    {
        key: "Whatsapp Cloud API",
        value: "Whatsapp Cloud API"
    },
    {
        key: "Renewup",
        value: "Renewup"
    }
]
const status = [
    {
        key: "Pending",
        value: "Pending"
    },
    {
        key: "In-Progress",
        value: "In-Progress"
    },
    {
        key: "Under Development",
        value: "Under Development"
    },
    {
        key: "In Roadmap",
        value: "In Roadmap"
    },
    {
        key: "Resolved",
        value: "Resolved"
    }

];

module.exports = {
    superCategory,
    category,
    subCategory,
    statusfilter,
    productsList,
    status,
    superCategorymanager
} 