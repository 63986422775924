import FacebookLead from "../Integrations/FacebookLead";
import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

import { API_POST,API_GET } from "../../services/api.service";
import IndiaMart from "../Integrations/IndiaMart";
import IndiaMartLogo from "assets/img/indiamartlogo.png";

import TradeIndia from "../Integrations/TradeIndia";
//import IndiaMartLogo from "assets/img/indiamartlogo.png";

import JustDial from "../Integrations/JustDial";
import Shiksha from "../Integrations/Shiksha";
import CollegeDunia from "../Integrations/CollegeDunia";
// img
import webhooks from "assets/img/integrations/webhooks.png";
import indiaMart from "assets/img/integrations/indiamart.png";
import facebook from "assets/img/integrations/fbImage.png";
import justdial from "assets/img/integrations/justdial-logo.png";
import shiksha from "assets/img/integrations/shiksha.png";
import collegedunia from "assets/img/integrations/collegedunia.png";
import tradeindia from "assets/img/integrations/tradeindialogo.png";
import Interakt from "../Integrations/Interakt";
import UserDropdown from "../common/UserDropdown";
import InfoBox from "../../components/InfoIcon/InfoIcon";


import { Snackbar } from "@material-ui/core";

function ReportStats(props) {
  const { history } = props;
  const { classes, user } = props;
  const [data, setData] = useState(null);
  const [adminParameter, setAdminParameter] = useState({});
  const [adminUser, setAdminUser] = useState(null);
  const [selectedUser, setSelectedUsers] = useState(null);
  const [adminTodayData, setAdminTodayData] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate()-5));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (5 * 60 * 60 * 1000));
    return this;
}

  const handleChangeUserDDL = (users) => {
    console.log("handleChangeUserDDL", users);
    setSelectedUsers(users);
    //CreateReport(data, users)
  }

  const handleChangeDate = (data) => {
    console.log("handleChangeUserDDL", data);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    //CreateReport(data, users)
  }

  const  GetAllUsers = async(username) => {
    let userInfo = await API_GET(`register/GetAllUsers?username=${username}`);
    if (userInfo.success) {
      let mapdata= await userInfo.data.map((element)=>{ return {key: element.username,
        value: element.username+' ('+element.first_name+')'}  } )
      setAdminUser(mapdata)

    }
  }

  useEffect(async () => {

    if (user && user.username) {
      loadData();
    }

    if(user && user.is_superuser){
      loadAdmin()
    }

  }, [user,selectedUser,endDate]);


  const loadAdmin = async ()=>{

    try{

      let createdDate= {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      } 
      let q={createdOn:createdDate };
      let registerRequest = await API_POST("action/getdata", {
        root: 'userStats',
        con: q,
      });



        let parameters={};
        let allAdmins=[];
        let activeusers=0;
        let freshProspect=0;
        let callRecordings=0;
        let talktime=0;
        let incomingCalls=0;
        let overdue=0;
        let todayCallDetails=0;
        let todayCallActivity=0;
        let totalProspects=0;
        let totalUsers=0;
        let totalSubscriber=0;
        registerRequest.data.map((val)=>{
          if(val.isActiveToday && val.isActiveToday >0){
            activeusers++;  
          } 

          totalProspects+=val.totalProspect;
          incomingCalls+=val.incomingCalls;
          overdue+=val.overdue;
          todayCallDetails+=val.todayCallDetails;
          todayCallActivity+=val.todayCallActivity;
          freshProspect+=val.freshProspect;
          callRecordings+=val.todayRecording;
          talktime+=parseInt(val.todayTalktime);
          allAdmins.push(val.admin);
        });
        parameters.activeCount=activeusers;
        parameters.freshProspect=freshProspect;
        parameters.callRecordings=callRecordings;
        parameters.talktime=talktime;

        parameters.incomingCalls=incomingCalls;
        parameters.overdue=overdue;
        parameters.todayCallDetails=todayCallDetails;
        parameters.todayCallActivity=todayCallActivity;
        parameters.totalProspects=totalProspects;
        parameters.totalUsers=registerRequest.data && registerRequest.data.length
        parameters.totalSubscriber = [...new Set(allAdmins)].length;      
        setAdminParameter(parameters);

        let requestData = await API_POST("report/adminSpecificReport", {
          root: 'userStats',
          con: q,
        });
  
        setAdminTodayData(requestData.data);

    }catch(e){

    }


  }

  const fancyTimeFormat=(duration)=>
  {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const loadProspectsCount = async (admin,date) => {

    let query={"admin":admin,"$and":[{"$or":[{"IsActive":{"$exists":false}},{"IsActive":true}]}],"open":true,"IsActive":true,"createdOn":{$gt: window.moment(date).add(5.5,'hours').startOf('date').toISOString() }};
    
    let registerRequest = await API_POST('action/getPaginationData', {
      root: 'prospects',
      querydata: query,
    });

    if (registerRequest && registerRequest.status == 200) {
      if (registerRequest.data) {

      }
    }
return 0;

  }

  const loadData = async () => {
    debugger;
    let registerRequest;
    try {
    let createdDate= {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
    console.log(createdDate,startDate);   
    let root='superAdmin';
    let q; 
    let url='report/subscriber';  



    if(props.user.role=='owner' && !selectedUser){ 
        root='AdminStats';
        q={ admin: props.user.username,createdOn:createdDate };
    }else{  
        root='userStats';
        url='action/getdata';
        q={ admin: props.user.admin,user: props.user.username, createdOn:createdDate };

        if(selectedUser)
        q.user={};
        q.user.$in=selectedUser;
    }
        
    if(props.user.is_superuser && !selectedUser){
      root='superAdmin';
      url='report/subscriber'; 
      q={createdOn:createdDate}
    }
      registerRequest = await API_POST(url, {
        root: root,
        con: q,
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data && registerRequest.data.length > 0) {

            setData( registerRequest.data);
        }else{
            setData(null);
        }
      }else{
        setData(null);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleExportExcel = async () => {

    if(!data){
      return true;
    }
    let datas=data.map((v)=>{
        delete v._id;
        v.todayTalktime=fancyTimeFormat(v.todayTalktime);
        v.isActive=v.isActiveToday && v.isActiveToday > 0 ? "Active": "Not-Active";
        return v;                                      
    })

    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: "",
      body: {  admin: user.admin, user: user.username,static:datas,page:'SubscriberStats' },
    });

    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };
  return (
    <>
         <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />



  
{  user.is_superuser ?
    (

      <Card>


        <CardBody>
          
     

<h2>Admin Live Data</h2>


<GridContainer>

<Box label="Today New Signup" value={adminTodayData && adminTodayData.newSignup} />
<Box label="Today Agent Created" value={adminTodayData && adminTodayData.agentCreation} />
<Box label="Total Signup" value={adminTodayData && adminTodayData.totalSubscriber} />
<Box label="Total Agent" value={adminTodayData && adminTodayData.totalAgent} />
<Box label="Total Prospects" value={adminTodayData && adminTodayData.totalProspects} />
<Box label="Active Agents" value={adminTodayData && adminTodayData.lastActivityCount} />
<Box label="Demo Account" value={adminTodayData && adminTodayData.demoSubscriber} />
<Box label="Internal Account" value={adminTodayData && adminTodayData.internalAccount} />
<Box label="Paid Expired Account" value={adminTodayData && adminTodayData.paidExpired} />
<Box label="Paid  Account" value={adminTodayData && adminTodayData.paidSubscriber} />
<Box label="Active Subscriber" value={adminTodayData && adminTodayData.activeSubscriber} />


</GridContainer>


   </CardBody>

</Card>
    ):null
}    



  <Card>

            <CardHeader color="transparent">
           
</CardHeader>

            <CardBody className="App-con">
    
<GridContainer>
            <GridItem xs={12} sm={2} md={2}>
            <h3>Report Stats</h3>
</GridItem>
<GridItem xs={12} sm={4} md={4}>

      <UserDropdown user={user} id="users" label="My Team" handleChange={handleChangeUserDDL}  ></UserDropdown>

        </GridItem>
        <GridItem xs={12} sm={4} md={4}>

<CustomInput
  style="dashboard"
  id="startdate"
  labelProps={{ shrink: true }}
  formControlProps={{
    fullWidth: true,
    multiline: true
  }}

  inputProps={{
    type: 'date_range',
    initialDateRange: {
      startDate: startDate,
      endDate: endDate
    },
    maxDate: new Date(),
    onChange: (e) => {
      console.log(e)
      handleChangeDate({ startDate: e.startDate, endDate: e.endDate })
      
    },
    showFetch: true,
    onClick: () => {
     

    }
  }}
/>

</GridItem>

<GridItem xs={12} sm={2} md={2} >
                <GridItem  style={{float: "left", marginTop: "10px"}}>
                  <InfoBox name={'Report.MonthlyReport'} />
                </GridItem>
                <Button
                style={{"float":"right"}}
                      size="sm"
                      color="primary"
                      justIcon
                      title="Export To Excel"
                      onClick={() => {
                        handleExportExcel();
                      }}
                    >
                        <GetAppOutlinedIcon />
                    </Button>
                    </GridItem>
</GridContainer>
</CardBody>
</Card>



      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
       






          <Card>
            <CardHeader color="transparent">
           
</CardHeader>

            <CardBody className="App-con">
              <GridContainer>

                {
                    data && data.map((val)=>{
                        return (
                            <>
                    <GridItem xs={12} sm={6} md={2}>

                     {
                         user.is_superuser ? 
                         (
                          <div className="channel-wrapper">
                          <GridItem sm={12} md={12} xs={12}>
                            <b className="campaigns-title">Date: {val.date} </b>
                            <span>{val.user}</span>
                            <hr/>
                          </GridItem>


                          <Typography variant="caption" display="block" >
                           Active User: {val.activeUser}
                          </Typography>
                          <Typography variant="caption" display="block" >
                           New Prospects: {val.freshProspect}
                          </Typography>
                      
                          <Typography variant="caption" display="block" >
                           Subscriber: {val.activeSubscriber}
                          </Typography>

                          <Typography variant="caption" display="block" >
                           Number of Calls: {val.todayCallActivity}
                          </Typography>
                          <Typography variant="caption" display="block" >
                           Prospect Touched: {val.updatedProspect}
                          </Typography>
                          <Typography variant="caption" display="block" >
                           Call Recording: {val.todayRecording}
                          </Typography>
                   
                          <hr/>
                          <a
                      onClick={() => {
                        history.push("/admin/ReportSubscriberWise/"+btoa(JSON.stringify({date:val.date})));
                      }}
                    >
                        See Details
                  </a>
                           </div>
                         ):
                         (
                          <div className="channel-wrapper">
                          <GridItem sm={12} md={12} xs={12}>
                            <b className="campaigns-title">Date: {val.date} </b>
                            <span>{val.user}</span>
                            <hr/>
                          </GridItem>
                          
                   


                          <Typography variant="caption" display="block" >
                           Today Calls: {val.todayCallDetails}
                          </Typography>

                          <Typography variant="caption" display="block" >
                           Business Calls: { typeof val.businessCalls === 'object' ? 0 :val.businessCalls}
                          </Typography>

                          
                          <Typography variant="caption" display="block" >
                           Connected Calls: {val.connectedCalls}
                          </Typography>

                          <Typography variant="caption" display="block" >
                           Activity: {val.todayCallActivity}
                          </Typography>
                          <Typography variant="caption" display="block" >
                            All Talk-time: {fancyTimeFormat(val.todayTalktime)}
                          </Typography>
                          <Typography variant="caption" display="block" >
                          Recording: {val.todayRecording}
                          </Typography>

                          {
                            val.isActiveToday ? (
                              <Typography variant="caption" display="block" >
                              Active Users: {val.activeUser}
                             </Typography>
                            ): <Typography variant="caption" display="block" >
                            Active Users: 0
                           </Typography>
                          }
                         <Typography variant="caption" display="block" >
                          Last Updated: {val.updatedOn}
                          </Typography>
                          <hr/>
                          <a
                      onClick={() => {
                        history.push("/admin/ReportDetails/"+btoa(JSON.stringify({date:val.date,admin:val.admin})));
                      }}
                    >
                        See Details
                  </a>
                           </div>
                         )

                     }



                </GridItem>
                            
                            </>
                        )



                    })
                    
                }



              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </>
  );
}

function Box(props){

  return (<>
  
<GridItem xs={12} sm={2} md={2}>
<Card>
<CardHeader>
<b><h6>{props.label}</h6> </b>
{ <h4>
    {props && props.value }
</h4>}
</CardHeader>
</Card>
</GridItem>

  </>)


}

export default ReportStats;
