import React, { } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Prospects from './Prospects';
import ProspectsResponsive from './ProspectsResponsive';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


function ListProspects(props) {
  const { user } = props;
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 760);


  if (isMobile) {
    return (
      <ProspectsResponsive user={user} />
    );
  }
  else {
    return (
      <Prospects user={user} />
    );
  }
}

ListProspects.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListProspects);
