
import React, { useEffect, useState } from "react";
// @material-ui/core components
// core components
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { API_POST } from "../../services/api.service";



function ReportSubscriberWise(props) {
  const { history } = props;
  const { classes, user } = props;
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });



  useEffect(() => {
    if (user && user.username) {
      loadTableData();
    }
  }, [user,endDate]);

  const handleChangeDate = (data) => {
    console.log("handleChangeUserDDL", data);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    //CreateReport(data, users)
  }


  const loadTableData = async () => {

    try{

      let query = JSON.parse(atob(props.match.params.id));
      let createdDate= {
        $gte: new Date(startDate),
        $lt: new Date(endDate),
      }
      let q={...query };
      if(endDate){
        delete q.date;
        q.createdOn=createdDate;
      }
      let registerRequest = await API_POST("report/adminSubscriberWise", {
        root: "userStats",
        con: q,
      });
      let d = await Promise.all( await registerRequest.data.sort(  (a, b) => a.isActiveToday > b.isActiveToday ? -1 : 1));
      setTableData(d);

    }catch(e){


    }


  }


  
  const fancyTimeFormat=(duration)=>
  {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const handleExportExcel = async (e) => {

    let data=tableData.map((v)=>{
        delete v._id;
        v.todayTalktime=fancyTimeFormat(v.todayTalktime);
        v.isActive=v.isActiveToday && v.isActiveToday > 0 ? "Active": "Not-Active";
        return v;
    })

    let downloadFile = await API_POST(`action/ExportExcel`, {
      querydata: "",
      body: {  admin: user.admin, user: user.username,static:data,page:'UserStats' },
    });

    if (downloadFile.status == 200) {
      setNotify({
        open: true,
        message: "File is in process,Please check in system log section.",
        color: "success",
      });
    }
  };
  return (
    <>
     <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
    
    <Card>
              <CardHeader color="transparent">
              
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} >
                <h3>Report Details</h3>
                  </GridItem>

            <GridItem xs={12} sm={6} md={6}>
            <CustomInput
  style="dashboard"
  id="startdate"
  labelProps={{ shrink: true }}
  formControlProps={{
    fullWidth: true,
  }}

  inputProps={{
    style: {padding:'0px'},
    type: 'date_range',
    initialDateRange: {
      startDate: startDate,
      endDate: endDate
    },
    maxDate: new Date(),
    onChange: (e) => {
      console.log(e)
      handleChangeDate({ startDate: e.startDate, endDate: e.endDate })
      
    },
    showFetch: true,
    onClick: () => {
     

    }
  }}
/>
              
              </GridItem> 
            <GridItem xs={12} sm={2} md={2} >
                <Button
                style={{"float":"right"}}
                      size="sm"
                      color="primary"
                      justIcon
                      title="Export To Excel"
                      onClick={() => {
                        handleExportExcel();
                      }}
                    >
                        <GetAppOutlinedIcon />
                    </Button>
                    </GridItem>
                </GridContainer>
              </CardHeader>
              </Card>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="transparent">
              </CardHeader>
              
              <CardBody>
              
                <TableContainer>

                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>SignUpId</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Active Today</TableCell>
                        <TableCell>Members</TableCell>
                        <TableCell> Subscriber Status</TableCell>
                        <TableCell> Company Name</TableCell>
                        <TableCell> Phone</TableCell>
                        <TableCell> Refer By</TableCell>
                        <TableCell> Creayed Date</TableCell>
                        <TableCell> Expiry Date</TableCell>

                        <TableCell>Today Calls</TableCell>
                        <TableCell>TalkTime</TableCell>

                        <TableCell>Business Calls</TableCell>
                        <TableCell>Connected Calls</TableCell>
                

                        <TableCell>Incomming Calls</TableCell>
                        <TableCell>Outgoing calls</TableCell>
                        <TableCell>Missed Calls</TableCell>

                        <TableCell>Total Prospects</TableCell>

                        <TableCell>Created Prospects</TableCell>
                        <TableCell>Activity</TableCell>
                        
                        <TableCell>Unique Prospects</TableCell>
                        <TableCell>OverDue</TableCell>
                      
                        <TableCell>Recording</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData &&
                        tableData.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell>{item.date}</TableCell>
                              <TableCell>{item.subscriberStatus.id}</TableCell>
                              <TableCell>                          
                                <a
                      onClick={() => {
                        history.push("/admin/ReportDetails/"+btoa(JSON.stringify({date:item.date,admin:item.admin})));
                      }}>{item.name} <br/> {item.user}</a>
                    </TableCell>
                              <TableCell>{item.isActiveToday && item.isActiveToday > 0 ? "Active": "Not-Active"}</TableCell>
                              
                              <TableCell>{item.memeberCount}</TableCell> 
                              <TableCell>{item.subscriberStatus.status}</TableCell>
                              <TableCell>{item.subscriberStatus.company}</TableCell>
                              <TableCell>{item.subscriberStatus.phone}</TableCell>
                              <TableCell>{item.subscriberStatus.refer_by}</TableCell>
                              <TableCell>{item.subscriberStatus.date_joined}</TableCell>
                              <TableCell>{item.subscriberStatus.expireon}</TableCell>


                              <TableCell>{item.todayCallDetails}</TableCell>
                             
                              <TableCell>{fancyTimeFormat(item.todayTalktime)}</TableCell>

                              <TableCell>{ typeof item.businessCalls === 'object' ? 0 : item.businessCalls }</TableCell>
                              <TableCell>{item.connectedCalls}</TableCell>
                             

 

                              <TableCell>{item.incomingCalls}</TableCell>
                              <TableCell>{item.outgoingCalls}</TableCell>
                              <TableCell>{item.missedCalls}</TableCell>

                              <TableCell>{item.totalProspect}</TableCell>
                              <TableCell>{item.freshProspect}</TableCell>



                              
                              <TableCell>{item.todayCallActivity}</TableCell>

                              <TableCell>{item.updatedProspect}</TableCell>
                              
                              <TableCell>{item.overdue}</TableCell>
                            
                              <TableCell>{item.todayRecording}</TableCell>

                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
         
        </GridItem>

      </GridContainer>
    </>
  );
}

export default ReportSubscriberWise;
