import React, { useEffect, useState } from "react";
import PropTypes, { any } from "prop-types";
import serialize from "form-serialize";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import CountryMaster from "../common/CountryMaster";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import './Style/Dialling.css'
import { BackspaceSharp, CallRounded } from "@material-ui/icons";
import { CreateBody, sendCommByTemplate, replaceAndReadyAPI } from "../../services/CommonServices";

const style = {
    input:{
        width:'700px'
    },
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};


const myTheme = {
    header: {
      primaryColor: '#263238',
      secondaryColor: '#f9f9f9',
      highlightColor: '#FFC107',
      backgroundColor: '#607D8B',
    },
    body: {
      primaryColor: '#263238',
      secondaryColor: '#32a5f2',
      highlightColor: '#FFC107',
      backgroundColor: '#f9f9f9',
    },
    panel: {
      backgroundColor: '#CFD8DC'
    }
  };

function Dialling(props) {
  const { classes, user, IsShowInCallDetail } = props;

  let frmVal = {
    source: "",
    assignTo: "",
    country: "",
    phone:props &&   props.data &&  props.data.phone
  };

  const [values, setValues] = useState('');
  const [customFields, setCustomFields] = useState([]);
  const [campaignMembers, setCampaignMembers] = useState([]);
  const [btnDissable, setbtnDissable] = useState(false);
  const [count, setCount] = useState(0);
  const [settings, setSettings] = useState(null);

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });


  const handleClickOpen = (v) => {

    console.log(v)
    setValues(values+v);
    
  };

  useEffect(() => {
    if (user) {
      loadsettings();
    }
  }, [user]);

  const handleClickremove = () => {

    setValues(values.slice(0, -1));
    
  };
 
  const handleClosePopup = () => {
    setNotify({ ...notify, open: false });
  };

  const CallingAPI = async ( id, phone) => {
    debugger;

    // console.log(prospect, currentCampaign, phone)
    let requestTime;
    let callingAPIRes;
    let api;
    let registerRequest = await API_POST("action/getdata", {
      root: "CallingSetup",
      con: { _id: id },
      cols: {},
    });

    if (registerRequest && registerRequest.status == 200) {

      //console.log(registerRequest);
      if (registerRequest && registerRequest.data) {

        let callingSetup = registerRequest.data[0];
        api = callingSetup.api;

        api = replaceAndReadyAPI(api, null, user, phone);

        requestTime = new Date();


        if (!callingSetup.request_type || callingSetup.request_type == 'get') {

          callingAPIRes = await API_GET(api);

          setNotify({
            open: true,
            message: "Call is initiated",
            color: "success",
          });

        } else {


          let msisdnlist = [{
            "phoneno": phone,
            "agentno": user.phone,
            "TrackerID": 123456,
            "admin": user.admin
          }]
          // console.log("bbbbbbbbbbbbbbbb",{ ...callingSetup,msisdnlist:msisdnlist });
          //   let callingAPIRes = await API_POST(api, {
          //   body: { ...callingSetup,msisdnlist:msisdnlist },
          // });

          let response = await API_POST("comm/postRequest", {
            url: api, body: { ...callingSetup, msisdnlist: msisdnlist }
          });

          callingAPIRes = response.data;

          setNotify({
            open: true,
            message: response.data.error_code ? response.data.message : "Call is initiated",
            color: response.data.error_code ? "danger" : "success",
          });



        }

        let message = [];
 

      }

      let APILog = {
        method: 'CallingAPI',
        requestURL: api,
        requestPayload: {},
        requestTime,
        responseData: callingAPIRes,
        responseTime: new Date(),
        trackingId: phone,
        diallingCall: true,
        user,
        admin: user.admin
      }

      await API_POST("action/addRecord", {
        root: "APILogs",
        body: APILog,
      });


    }

  }


  const loadsettings = async () =>{
    let registerRequest = await API_POST("action/getdata", {
      root: "Configuration",
      con: { configName: "basic", admin: user && user.admin },
      cols: {},
    });
    if (
      registerRequest &&
      registerRequest.status == 200 &&
      registerRequest.data &&
      registerRequest.data.length > 0
    ) {
      setSettings(registerRequest.data[0]);
    }
  }


  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        onClose={handleClosePopup}
        autoHideDuration={3000}
      />
     

           

            <div class="container">
  <div class="firstinput">
    <div id="output">{values}
    </div> 

 
    <div class="back" onClick={(e)=>handleClickremove()}>   <BackspaceSharp onClick={(e)=>handleClickremove()} />
    </div>
    </div>
 
  <div class="row">
    <div class="digit" id="one" onClick={(e)=>handleClickOpen(1)}>1</div>
    <div class="digit" id="two" onClick={(e)=>handleClickOpen(2)}>2
      
    </div>
    <div class="digit" id="three" onClick={(e)=>handleClickOpen(3)}>3
    
    </div>
  </div>
  <div class="row">
    <div class="digit" id="four" onClick={(e)=>handleClickOpen(4)}>4
     
         </div>
    <div class="digit" id="five" onClick={(e)=>handleClickOpen(5)}>5
    
    </div>
    <div class="digit" onClick={(e)=>handleClickOpen(6)}>6
    
    </div>
  </div>
  <div class="row">
    <div class="digit" onClick={(e)=>handleClickOpen(7)}>7
   
    </div>
    <div class="digit" onClick={(e)=>handleClickOpen(8)}>8
     
    </div>
    <div class="digit" onClick={(e)=>handleClickOpen(9)}>9
    
    </div>
  </div>
  <div class="row">
    <div class="digit" onClick={(e)=>handleClickOpen('*')}>*
   
    </div>
    <div class="digit" onClick={(e)=>handleClickOpen('0')}>0
     
    </div>
    <div class="digit" onClick={(e)=>handleClickOpen('#')}>#
    
    </div>
  </div>
  <div class="row">

    {
      settings && settings.diallingSettingEnableIvr && settings.diallingSettingIvr ? 
      <>
            <div class="digit call">
        <a  onClick={() => {
        CallingAPI(
          settings.diallingSettingIvr,
          values
        )
      }}>  
      <div id=""><i class="fa fa-phone phoneIcon" aria-hidden="true"></i></div> </a> </div>
      </> :  <> <div class="digit call"><a href={"tel:"+values} > <div id=""><i class="fa fa-phone phoneIcon" aria-hidden="true"></i></div> </a></div> </>
   
    }
    
  </div>
</div>



 

   <br/>
   <br/>

   { 
    open && (
        <center>
        <a href={'tel:'+values}>
       <div className="iii">
       <CallRounded/>
       </div>
       </a>
     
      </center>
    )

   }


    </>
  );
}

Dialling.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Dialling);
