import { SET_SERACHVAL, SET_CAMPAIGN_ID } from "../ActionTypes/ActionsTypes";

const intialState = {
  searchVal: "",
  campaignId: "",
};

const setSearchVal = (state = intialState, Action) => {
  let { payload } = Action;
  return {
    ...state,
    payload,
  };
};
const ReduxReducer = (state = intialState, Action) => {
  switch (Action.type) {
    case SET_SERACHVAL:
      return setSearchVal(state, Action);
    case SET_CAMPAIGN_ID:
      return {
        ...state,
        campaignId: Action.payload,
      };
    default:
      return state;
  }
};

export default ReduxReducer;