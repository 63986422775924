import React, { useEffect, useState } from "react";
import PropTypes, { any } from "prop-types";
import serialize from "form-serialize";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { API_GET, API_POST } from "../../services/api.service";
import CountryMaster from "../common/CountryMaster";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import InfoBox from "../../components/InfoIcon/InfoIcon";


const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

function AddProspect(props) {
  const { classes, user, IsShowInCallDetail } = props;

  let frmVal = {
    source: "",
    assignTo: "",
    country: "",
    phone:props &&   props.data &&  props.data.phone
  };

  const [formValues, setFormValues] = useState(frmVal);
  const [customFields, setCustomFields] = useState([]);
  const [campaignMembers, setCampaignMembers] = useState([]);
  const [btnDissable, setbtnDissable] = useState(false);
  const [count, setCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [IsShowSave, setIsShowSave] = useState(true);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const [IsShowField, setIsShowField] = useState(false);
  const [InputCampaign, setInputCampaign] = useState("");
  const [Campaigns, setCampaigns] = useState([]);

  const handleClickOpen = () => {
    setMode("Add");
    setFormValues(frmVal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePopup = () => {
    setNotify({ ...notify, open: false });
  };

  const loadCustomFields = async (e) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "prospect_fields",
        con: { admin: user && user.admin },
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
        setCustomFields(registerRequest && registerRequest.data);
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  const loadCampaign = async () => {
    debugger;
    const { user } = props;
    let registerRequest;
    try {
      let con = { admin: user && user.admin, IsActive: true } 
      if (user && ["field_exc", "agent"].indexOf(user.role) > -1) {
        con = {...con, campaignMembers: user.username}
      }

      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: con,
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          setCampaigns(registerRequest.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadCampaignMembers = async (e) => {
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaignMembers",
        con: { cid: props.match.params.cid },
      });

      if (registerRequest && registerRequest.status == 200) {
        let _options = [];
        registerRequest.data.forEach((element) => {
          _options.push({
            key: element.username,
            value: element.username,
          });
        });
        setCampaignMembers(_options);
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  const handleChange = async (e,multi=false) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    }
    if (multi) {
      formValue[e.target.name] = e.target.value && e.target.value.length ? e.target.value.toString() : '';
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleCancelProspect = (e) => {
    if (!IsShowInCallDetail) {
      e.preventDefault();
      document.getElementsByName("frmProspect")[0].reset();
      window.history.back();
    } else {
      props.CloseAddProspectPopup();
    }
  };

  const IsExistsProspect = async (phone, admin) => {
    let registerRequest;

    try {
      // registerRequest = await API_POST("action/getdata", {
      //   root: "prospects",

      //   con: { phone, admin, IsActive: true }
      // });
      let body = {
        limit: 0,
        skip: 0,
        sort: { createdOn: -1 },
      };
      phone = phone.replace(/[^\d]/g,'');
      let con = { phone, admin, IsActive: true };
      registerRequest = await API_POST("action/getPaginationDataActivity", {
        root: "prospects",
        querydata: con,
        body: body,
      });

      if (registerRequest && registerRequest.status == 200) {
        debugger;
        return registerRequest.data.activityData;
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const GetMongoData = async (collection, query) => {
    debugger;
    const { user } = props;
    let registerRequest;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: collection,
        con: query,
      });

      if (registerRequest && registerRequest.status == 200) {
        if (registerRequest.data.length > 0) {
          return registerRequest.data;
        }
      }
    } catch (error) {
      return null;
    }
  };

  const SetPidInCallDetails = async (phone) => {
    if(phone.length>10){
      phone = phone.substr(phone.length - 10)
    }
    let query = {
      admin: user && user.admin,
      phone: { $regex: ".*" + phone + ".*" },
    };
    let request = await GetMongoData("prospects", query);
    debugger;
    if (request && request.length > 0) {
      let registerRequest = await API_POST("action/updateMany", {
        root: "CallDetails",
        body: { pid: request[0]._id, _v: 1 },
        querydata: {
          admin: user.admin,
          phone: { $regex: ".*" + phone + ".*" },
        },
      });
      if (registerRequest && registerRequest.status == 200) {
        setNotify({
          open: true,
          message: "Save Successfully",
          color: "success",
        });

        if(IsShowInCallDetail){
          props.CloseAddProspectPopup()
        }
      }
    }
  };

  const handleAddField = async (e) => {
    e.preventDefault();
    debugger;
    let frm = serialize(document.getElementsByName("frmProspect")[0], {
      hash: true,
      empty: true,
      disabled: true,
    });
   setbtnDissable(true);
    console.log(formValues);
    let formValue = frm;
    formValue["admin"] = user && user.admin;
    formValue["createdBy"] = user && user.username;
    formValue["createdOn"] = new Date();
    formValue["open"] = true;
    formValue["IsActive"] = true;

    formValue["cid"] = !IsShowField
      ? props.match.params.cid
      : InputCampaign._id;

    let registerRequest;

    try {
      registerRequest = await API_POST("action/addProspects", {
        ...formValue,
      });
      console.log(registerRequest);
      if (registerRequest && registerRequest.status == 200) {
        setFormValues(formValue);
        setNotify({
          open: true,
          message: "Prospect Added Successfully",
          color: "success",
        });

        if (!IsShowField) {
          const cid = props.match.params.cid;
          const { history } = props;
          setTimeout(function(){
            history.push("/admin/CampainMoreInfo/" + cid);
          },2000);
        //
        } else {

          SetPidInCallDetails(formValue.phone);
          console.log("Save Data");
        }


      }else{
        setNotify({
          open: true,
          message: registerRequest.error,
          color: "danger",
        });
      }
    } catch (error) {
      console.log(error);

    }
    setbtnDissable(false);
    return false;
  };

  const handleEditField = async (e) => {
    e.preventDefault();
    debugger;
    let formValue = formValues;
    let _id = formValues._id;
    formValue["admin"] = user && user.admin;
    formValue["updatedBy"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];

    let registerRequest;
    try {
      registerRequest = await API_POST("action/update", {
        root: "prospects",
        body: formValue,
        querydata: { _id },
      });

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(frmVal);
        loadCustomFields(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editSubRecord = async (item) => {
    setMode("Edit");
    setFormValues(item);
    setOpen(true);
  };

  useEffect(() => {
    debugger;
    if (user && customFields.length == 0) {
      loadCustomFields();
      loadCampaignMembers();
      loadCampaign();
    }
  }, [user]);

  useEffect(() => {
    debugger;
    if (IsShowInCallDetail) {
      setIsShowField(true);
    }
  }, [IsShowInCallDetail]);

  const handleBlur = async (e) => {
    debugger;
    if (e.target.name == "phone") {
      let response = await IsExistsProspect(e.target.value, user.admin);

      if (response.length > 0) {
        setNotify({
          open: true,
          message:
            "MobileNo is already exists in '" +
            response[0].CampainData[0].name +
            "' Campaign and prospect is assign to " +
            response[0].assignTo,
          color: "danger",
        });
        setIsShowSave(false);
        return;
      } else {
        setIsShowSave(true);
      }
    }
  };

  const setCallCountry = (Input) => {
    setFormValues({ ...formValues, country: Input.ISO2 });
  };

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        onClose={handleClosePopup}
        autoHideDuration={3000}
      />
      <form name="frmProspect" onSubmit={handleAddField}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="white">
                <h3>Create Prospect
                <GridItem  style={{float: "right", marginTop: "10px"}}>
                    <InfoBox name={'Campaigns.CreateProspect'} />
                </GridItem>
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {customFields.map((item, index) => {
                     let type=item.field_type;
                     let option=item.selectOptions;
                     let value=formValues && formValues[item.field_name];
                      if(item.display_Name == 'Repeat'){
                        return null;
                      }
    
                    return (
                      <GridItem xs={12} sm={12} md={12}>
                        {item.IsEditable && (
                          <>

                          {
                            type == 'select' ? (
                              <>
                      <CustomInput
                        labelText={item.display_Name}
                        id={item.field_name}
                        formControlProps={{
                          fullWidth: true,
                        }}
                       
                        labelProps={{ shrink: true }}
                        inputProps={{
                          required: item.isRequired,
                          name: item.field_name,
                          options:option.split(',').map((v)=> {return  {key:v, value: v} }),
                          type:  type,
                          value:value,
                         
                          onChange: handleChange,
                        }}
                      />
                              </>

                            ) : type == 'multiselect' ? (
                             <>

                          <CustomInput
                              labelText={item.display_Name}
                              id={item.field_name}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            
                              labelProps={{ shrink: true }}
                              inputProps={{
                                required: item.isRequired,
                                name: item.field_name,
                                options:option.split(',').map((v)=> {return  {key:v, value: v} }),
                                type:  'checkbox_list',
                                value: value ? value.split(',') : [],
                                defaultValue: value ? value.split(',') : [],
                              
                                onChange: (e) => handleChange(e,true) ,
                              }}
                            />
                      
                             </>
                            ): (
                              <CustomInput
                              labelText={item.display_Name}
                              id={item.field_name}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              labelProps={{
                                shrink:
                                  ["radio", "checkbox"].indexOf(item.field_type) >
                                  -1
                                    ? false
                                    : true,
                              }}
                              customOptions={item.field_options}
                              inputProps={{
                                required: item.isRequired,
                                name: item.field_name,
                                type: item.field_type == 'datetime'? 'date':item.field_type ,
                                defaultValue:
                                  formValues && formValues[item.field_name],
                                onChange: handleChange,
                                onBlur: handleBlur,
                                // onBlur={handleBlur.bind(this, index, element)}
                              }}
                            />
                            )
                          }


                          
                          </>


                        )}



                      </GridItem>
                    );
                  })}
                </GridContainer> 
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="white">
                <h3 />
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Source Tag"
                      id="source"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      labelProps={{ shrink: true }}
                      //customOptions={item.field_options}
                      inputProps={{
                        required: true,
                        name: "source",
                        type: "text",
                        value: "Manual",
                        defaultValue: formValues && formValues.source,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>
                  {["manager", "owner"].indexOf(user && user.role) > -1 &&
                    !IsShowField && (
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Assign To"
                          id="assignTo"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          labelProps={{ shrink: true }}
                          inputProps={{
                            required: true,
                            name: "assignTo",
                            type: "select",
                            defaultValue: formValues && formValues.assignTo,
                            onChange: handleChange,
                            options: campaignMembers,
                          }}
                        />
                      </GridItem>
                    )}

                  {/* {IsShowField && ( */}
                    <GridItem xs={12} sm={12} md={12}>
                      <CountryMaster setCallCountry={setCallCountry} />
                    </GridItem>
                  {/* )} */}

                  {IsShowField && (
                    <GridItem xs={12} sm={12} md={12}>
                      <Autocomplete
                        onChange={(event, value) => setInputCampaign(value)}
                        id="combo-box-demo"
                        size="small"
                        options={Campaigns}
                        labelText="FieldType"
                        name="autoComplete"
                        value={InputCampaign || null}
                        getOptionLabel={(Campaigns) => Campaigns.name || ""}
                        defaultValue="Select"
                        renderTags={(value) =>
                          value.map(() => (
                            <Chip variant="outlined" label="Enter Field" />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Campaign "
                            // variant="outlined"
                          />
                        )}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
              <CardFooter>
                {IsShowSave &&  (
                  <Button color="success" type="submit" disabled={btnDissable}>
                    Save
                  </Button>
                )}
                <Button onClick={handleCancelProspect}>Cancel</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
}

AddProspect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(AddProspect);
