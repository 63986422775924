import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// import ReactPaginate from 'react-paginate';
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.jsx";

import Avatar from "@material-ui/core/Avatar";

import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";

import DataTableMobileStyle from "assets/jss/material-dashboard-react/components/DataTableMobileStyle.jsx";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutline from '@material-ui/icons/MailOutline';
import SmsIcon from '@material-ui/icons/Sms';
import CallIcon from '@material-ui/icons/Call';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { maskNumber } from "../../services/Common";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {

    name = name.toUpperCase().replace('  ', ' ').trim();
    let firstChar = name.split(' ')[0][0];
    let secondChar = '';

    if (name.split(' ').length > 1) {
        secondChar = name.split(' ')[1][0];
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${firstChar}${secondChar}`,
    };
}

function formatDate(date) {

    if (date) {
        let mdate = window.moment(date)



        return mdate.fromNow()
    }
    else {
        return 'not yet';
    }
}
function callbackdate(date) {
    if (date) {
        let mdate = window.moment(date)


        return 'at ' + mdate.format('DD-MMM-YYYY HH:mm')
    }
    else {
        return 'and callback not set';
    }
}

function showdisposition(value) {
    let arr = [];
    if (value.dispositionArray && value.dispositionArray.length > 0) {
        for (
            let index = 0;
            index < value.dispositionArray.length;
            index++
        ) {
            const element = value.dispositionArray[index];
            arr.push(element.disposition_value);
        }
    }

    return arr.join(" > ");
}




class DataTableProspectRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            expand: false,
            //selected: false
        };
        this.onHandleChecked = this.onHandleChecked.bind(this);
    }
    onHandleChecked(e) {
        debugger;
        console.log(e)
        //this.setState({selected: e.target.checked})
        this.props.onSelectedRowsChange(this.props.row, e.target.checked)
    }

    render() {
        const {

            columns,
            row,
        } = this.props;


        return (
            <Card className="datatableresponsive">

                <CardBody className="cardbody">
                    <GridContainer className="datatableresponsive">
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={1} sm={1} md={1}>

                                    <Checkbox
                                        color="primary"
                                        checked={row && row.checked}
                                        onChange={this.onHandleChecked}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                        style={{ padding: 0 }}
                                    />


                                    {/* <Avatar {...stringAvatar(row && (row.firstName || row.phone))} /> */}

                                </GridItem>
                                <GridItem xs={8} sm={8} md={8}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {
                                            columns && columns.map((col, key) => {
                                                if (col.inHeader) {
                                                    if (col.cell) {
                                                        return <span className="customerName">{col.cell(row)}</span>
                                                    }
                                                    return null
                                                }
                                            })
                                        }
                                        <span className="custPhone">

                                            {row.CampainData &&
                                                row.CampainData.length > 0 &&
                                                row.CampainData[0].phoneNumberMasking
                                                ? maskNumber(row.phone)
                                                : row.phone}
                                        </span>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <span className="lastUpdated">updated on: {formatDate(row.updatedOn)}</span>
                                    </GridItem>
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <span className="lastUpdated">
                                        {formatDate(row.createdOn)}
                                    </span>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <span className="lastDisposition">{showdisposition(row)} {callbackdate(row.callback)}</span>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <span className="lastDisposition">Prospect in  {row.CampainData && row.CampainData.length > 0
                                        ? row.CampainData[0].name
                                        : "-"}&nbsp;with {row.assignTo ? row.assignTo : "-"}
                                    </span>
                                </GridItem>

                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter className="cardfooter">
                
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <hr></hr>
                    </GridItem>
                        <GridItem xs={10} sm={10} md={10}>
                            <ButtonGroup
                                color="info"
                                aria-label="small outlined button group"
                            >

                                {
                                    columns && columns.map((col, key) => {
                                        if (col.inAction) {
                                            if (col.cell) {
                                                return col.cell(row)
                                            }

                                        }
                                    })
                                }

                                {/* <Button
                                    size="sm"
                                    color="transparent"
                                    onClick={(e) => {
                                        this.setState({ expand: !this.state.expand })
                                    }}
                                >
                                    <CallIcon />
                                </Button>
                                <Button
                                    size="sm"
                                    color="transparent"
                                    onClick={(e) => {
                                        this.setState({ expand: !this.state.expand })
                                    }}
                                >
                                    <WhatsAppIcon />
                                </Button>
                                <Button
                                    size="sm"
                                    color="transparent"
                                    onClick={(e) => {
                                        this.setState({ expand: !this.state.expand })
                                    }}
                                >
                                    <MailOutline />
                                </Button>
                                <Button
                                    size="sm"
                                    color="transparent"
                                    onClick={(e) => {
                                        this.setState({ expand: !this.state.expand })
                                    }}
                                >
                                    <SmsIcon />
                                </Button> */}

                            </ButtonGroup>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <ButtonGroup
                                color="info"
                                aria-label="small outlined button group"
                            >
                                <Button
                                    size="sm"
                                    color="transparent"
                                    onClick={(e) => {
                                        this.setState({ expand: !this.state.expand })
                                    }}
                                >
                                    {this.state.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                                </Button>
                            </ButtonGroup>
                        </GridItem>
                    </GridContainer>


                </CardFooter>
                <Collapse in={this.state.expand} >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <ul>
                                {
                                    columns && columns.map((col, key) => {
                                        if (!col.inHeader && !col.inAction) {
                                            if (col.cell) {
                                                return <li><label>{col.name}</label>  <span>{col.cell(row)}</span></li>
                                            }
                                            else {
                                                return <li><label>{col.name}</label> <span>{row[col.selector]}</span></li>
                                            }
                                        }
                                    })
                                }
                            </ul>
                        </GridItem>
                    </GridContainer>
                </Collapse>
            </Card>
        );
    }
}

DataTableProspectRow.propTypes = {
    // checkboxes: PropTypes.bool,
    // selectableRows: PropTypes.bool,
    columns: PropTypes.array,
    row: PropTypes.object,

    // onSelectedRowsChange: PropTypes.func,
    // clearSelectedRows: PropTypes.func
}

export default withStyles(DataTableMobileStyle)(DataTableProspectRow);
