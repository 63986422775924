import React, { useRef, useEffect, useState } from 'react';
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_POST } from "../../services/api.service";

import SettingsIcon from "@material-ui/icons/Settings";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "components/Snackbar/Snackbar.jsx";

function  BasicSettings(props) {
  const { classes, user } = props;

  let frmVal = {
    enableTimeTracking:false,
    diallingSettingEnableIvr : false,
    diallingSettingIvr : null,
    configName: "basic",
    admin: user && user.admin,
  };


  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(frmVal);
  const [customFields, setCustomFields] = useState([]);
  const [count, setCount] = useState(0);
  const [callingOptions,setCallingOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [callingsetupPresent, setCallingsetupPresent] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });

  const handleChange = async (e) => {
    let formValue = formValues;

    if (e.target && e.target.type === "checkbox") {
      formValue[e.target.id] = e.target.checked;
    } else if (e._isAMomentObject) {
      formValue[props] = e.format();
    } else {
      formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
    }

    setFormValues(formValue);
    setCount(count + 1);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const loadCalling = async () =>{

    
    if (!user || (user && !user.admin)) {
      return;
  }

  let registerRequest = await API_POST("action/getdata", {
      root: "CallingSetup",
      con: { admin: user && user.admin, type: 'CallingAPI' },
      cols: {},
  });

  let options = registerRequest.data.length ? registerRequest.data.map((v)=>{
    return {key:v._id,value:v.name}
  })  : null;


  if(options && options.length){
    setCallingsetupPresent(true)
  }
  setCallingOptions(options || []);
  //setIsRefresh(true);

  }

  const pageLoad = async () => {
    let registerRequest;
    try {
      if (user && user.admin) {
        registerRequest = await API_POST("action/getdata", {
          root: "Configuration",
          con: { configName: "basic", admin: user && user.admin },
          cols: {},
        });
        if (
          registerRequest &&
          registerRequest.status == 200 &&
          registerRequest.data &&
          registerRequest.data.length > 0
        ) {
          setFormValues(registerRequest.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };
  const handleAddField = async (e) => {
    debugger;
    e.preventDefault();
    let formValue = formValues;
    formValue["admin"] = user && user.username;
    formValue["updatedOn"] = new Date();
    delete formValue["_id"];
    try {
      let registerRequest = await API_POST("action/upsert", {
        root: "Configuration",
        body: formValue,
        querydata: { configName: "basic", admin: user && user.admin },
      });

      console.log(registerRequest);

      if (registerRequest && registerRequest.status == 200) {
        setFormValues(formValue);
        //loadCustomFields(e);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    if (user) {
      pageLoad();
      loadCalling();
    }
  }, [user]);

  return (
    <>
      <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() => setNotify({ open: false })}
        close
      />
     

          <GridContainer>
           
           
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Enable Time Tracking"
                id="enableTimeTracking"
                error={errors && errors.enableTimeTracking}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type:'checkbox',
                  required: true,
                  value: formValues && formValues.enableTimeTracking,
                  checked: formValues && formValues.enableTimeTracking,
                  name: "enableTimeTracking",
                  onChange: handleChange,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Enable Ivr Calling for Dialling"
                id="diallingSettingEnableIvr"
                error={errors && errors.diallingSettingEnableIvr}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type:'checkbox',
          
                  disabled:false,
                  checked: formValues && formValues.diallingSettingEnableIvr,
                  name: "diallingSettingEnableIvr",
                  onChange: handleChange,
                }}
              />
            </GridItem>


            {
              callingOptions && callingOptions.length ? <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Select IVR Calling for Dialling"
                id="diallingSettingIvr"
                error={errors && errors.diallingSettingIvr}
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type:'select',
                  required: true,
                  options : callingOptions,
                  value: formValues && formValues.diallingSettingIvr,
                  name: "diallingSettingIvr",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            : null
            }



          </GridContainer>

         
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddField} color="primary">
            Save
          </Button>
         

    </>
  );
}

export default BasicSettings;
