  const CommonJson = {
    ProspectValidKeys:  [
        "assignTo",
        "Assign To",
        "callback",
        "appointment",
        "priority",
        "handler",
        "duration",
        "bill_sec",
        "LastCalled",
        ""
      ]
      
  }
  export default CommonJson;