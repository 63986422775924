import React, { useEffect } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import HighchartsReact from 'highcharts-react-official';


import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Loader from "components/Loader/Loader.jsx";
import { API_POST } from "../../services/api.service";
import _ from "underscore";
import { Button } from "@material-ui/core";

drilldown(Highcharts);

let Option = {
  "prospect_wise": false,
  "plotOptions": {
    "series": {
      "cursor": "pointer",
      "dataLabels": {
        "color": "#428bca",
        "enabled": true,
        "backgroundColor": "white"
      },
      "showInLegend": true,
      "allowPointSelect": true
    }
  },
  "xAxis": {
    "type": "category"
  },
  "title": {
    "text": "Campaign-wise Responses"
  }, "yAxis": {
    "title": {
      "text": "Count"
    }
  },
  "chart": {
    "type": "column",
    "events": {
      drilldown: function (e) {
        if (!e.seriesOptions) {
          let dashboardData = JSON.parse(localStorage.getItem("campaign_chart"));
          let _CWData = _.where(dashboardData, { _id: e.point.id });

          if (_CWData && _CWData.length > 0) {
            _CWData = _CWData[0];

            let obj = [];
            _CWData.dispData.forEach(function (item) {
              if (item.count > 0) {
                obj.push([item.disposition_value, item.count])
              }
            });
            var chart = e.target;
            chart.addSingleSeriesAsDrilldown(e.point, {
              name: e.point.name,
              color: 'green',
              data: obj
            });
            chart.applyDrilldown();
          }
        }
      }
    }
  },
  "tooltip": {
    "pointFormat": "<span style=\"color:{point.color}\">{series.name}</span>: <b>{point.y}</b>"
  },
  "legend": {
    "enabled": false
  },
  "series": [
    {
      "colorByPoint": true,
      "data": null,
      "name": "Campaigns",
      "id": "Campaigns"
    }
  ],

}



function CRChart(props) {
  const { classes, user, query } = props
  const [data, setData] = React.useState(null);
  
  let chartOption = { ...Option };
  
  const loadData = async (e) => {
    let registerRequest;
    try {
      let con = { ...query, admin: user && user.admin, username: user && user.username, role: user && user.role, dispositionQuery: { admin: user && user.admin } };
      registerRequest = await API_POST("action/getChartInfo",
        {
          root: 'campaign',
          con: con
        });
      console.log(registerRequest);


      if (registerRequest && registerRequest.status == 200) {
        debugger;
        localStorage.setItem('campaign_chart', JSON.stringify(registerRequest.data))
        setData(registerRequest && registerRequest.data);
      }


    } catch (error) {
      console.log(error)
      //registerRequest = response;
    }

  }



  useEffect(() => {
    if (user && user.admin) {
      loadData();
    }
  }, [user, query]);
  

  const bindChart = () => {
    let _chartOption = { ...chartOption };
    _chartOption.series[0]['data'] = null
    let obj = [];
    
    data.forEach(function (item) {
      if (item.count > 0) {
        obj.push({
          "y": item.count,
          "drilldown": true,
          "name": item.name,
          "id": item._id,
        })
      }
    });
    _chartOption.series[0]['data'] = obj

    return _chartOption;
  }

  return (
    <>
      <Card chart>
        <CardHeader color="primary">
          {/* <Button onClick={() => loadData()}>Reload</Button> */}
          {data == null && <div style={{height:'400px', background: '#ffffff'}}> <Loader></Loader></div>}
          {data != null && <HighchartsReact
            highcharts={Highcharts}
            options={bindChart()}
            allowChartUpdate={true}
          />}
        </CardHeader>
        <CardBody />
        <CardFooter chart />
      </Card>
    </>
  );
}



export default withStyles(dashboardStyle)(CRChart);
