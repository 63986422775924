/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";

import { API_GET, API_POST } from "../../services/api.service";


import Loader from "components/Loader/Loader.jsx";
import DataTable from "react-data-table-component";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

class AddNotification extends React.Component {
    constructor(props) { 
        super(props);
        const { user } = this.props;

        this.state = {
            teamuser: [{ key: 'Select', value: 0 }],
            showLoader: true,
            _id: null,
            mode: "Add",
            records: null
        };
        const defaultData = {
            type: 'onetoone',
            user: [],
            read: 0,
            message: '',
            prospectId: null,
            IsActive: true,
            createdOn: null,
            updatedOn: null,
            admin: user && user.admin,
        };

        this.state.notificationDetails = defaultData;


    }




    // to stop the warning of calling setState of unmounted component
    componentDidMount() {
        const { user } = this.props;
        let id = this.props.match.params.id;
        this.setState({ showLoader: false });
        if (user) {
            this.loadUsers(user.id);
            this.loadRecords(user);
        }
    }



    loadRecords = async (user) => {
        if (!user || (user && !user.admin)) {
            return;
        }

        let registerRequest = await API_POST("action/getdata", {
            root: "notifications",
            con: { admin: user && user.admin },
            cols: {},
        });

        this.setState({
            records: registerRequest.data,
        });

        // debugger;
        //setData(registerRequest.data || []);
        // setIsRefresh(true);

    }

    loadUsers = async (userid) => {
        if (userid) {
            let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid}`);
            if (userInfo.success) {

                if (userInfo.data.ActiveMembers) {

                    var teamuUserData = [];
                    userInfo.data.ActiveMembers.map((val, key) => {
                        if (val.is_active && val.role != "owner") {
                            var tempobj = {};
                            tempobj.key = val.email;
                            tempobj.value = val.email;
                            teamuUserData.push(tempobj);
                        }
                    })

                    // console.log(teamuUserData);



                    this.setState({
                        teamuser: teamuUserData,
                    });
                }
            } else {
                this.setState({ userInfo: null });
            }
        }

    }


    handleChange = async (e) => {

        let formValue = this.state.notificationDetails;

        if (e.target && e.target.type === "checkbox") {
            formValue[e.target.id] = e.target.checked;
        } else if (e._isAMomentObject) {
            formValue[props] = e.format();
        } else {
            formValue[e.target.name] = e.target.value === "" ? null : e.target.value;
        }

        console.log(formValue);
        this.setState({ notificationDetails: formValue });
    };


    handleCancel = async (e) => {
        const { user, history } = this.props;
        history.push("/admin/addnotification");
    };

    handleSave = async (e) => {

        this.handleAddNotification(e);

    };

    handleAddNotification = async (e) => {

        const { user, history } = this.props;
        let formValue = this.state.notificationDetails;
        formValue["type"] = 'message';
        formValue["admin"] = user && user.username;
        formValue["createdBy"] = user && user.username;
        formValue["createdOn"] = new Date();

        let registerRequest;
        try {

            const result = await formValue.user.map(async (val, key) => {

                formValue.user = val;
                registerRequest = await API_POST("action/addRecord", {
                    root: "notifications",
                    body: formValue,
                });
                var registerRequest2 = await API_POST("action/PushNotification", {
                    body: formValue,
                });

            });

            if (result) {
                history.push("/admin/addnotification");

            }


        } catch (error) {
            console.log(error);
        }
    };



    render() {

        const columns = [
            {
                name: "User",
                selector: "user",
            },
            {
                name: "Message",
                selector: "message",
            },

            {
                name: "Created On",
                cell: (row) => (
                    <span>
                        {row.createdOn &&
                            window.moment(row.createdOn).format("DD-MMM-YYYY HH:mm")}
                    </span>
                ),
            },


        ];
        const countPerPage = 10;
        const { classes, user } = this.props;
        const {
            records,
            notificationDetails,
            dispositions,
            customFormFields,
            mode,
            teamuser,
            showLoader,
        } = this.state;

        return (
            <>
                <>
                    {showLoader ? <Loader /> : null}
                    {showLoader ? null : (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="white">
                                        <h3>Manage Notification</h3>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GenerateField
                                                value={
                                                    notificationDetails && notificationDetails.user
                                                }
                                                onChange={this.handleChange}
                                                id={"user"}
                                                label={"Select User *"}
                                                type="checkbox_list"
                                                keyValuePair={teamuser}
                                            />

                                            <GridItem xs={12} sm={12} md={12}>

                                                <GenerateField
                                                    value={
                                                        notificationDetails && notificationDetails.message
                                                    }
                                                    onChange={this.handleChange}
                                                    id={"message"}
                                                    label={"Message *"}
                                                    type="textarea"
                                                    rows={20}
                                                    rowsMax={30}

                                                />

                                            </GridItem>



                                        </GridContainer>
                                    </CardBody>

                                    <CardFooter>
                                        <Button color="primary"
                                            size="sm"
                                            onClick={this.handleCancel}>
                                            Cancel
                                        </Button>
                                        <Button color="primary"
                                            size="sm"
                                            onClick={this.handleSave}>
                                            Save
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>

                        </GridContainer>


                    )}
                </>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="white">
                                <h3>View Notification </h3>

                            </CardHeader>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <DataTable
                                        columns={columns}
                                        data={records || []}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={countPerPage}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                        </Card>
                    </GridItem>
                </GridContainer>



            </>
        );
    }
}

export default withStyles(styles)(AddNotification);

class GenerateField extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            value,
            onChange,
            id,
            options,
            label,
            keyValuePair,
            type = "select",
            disabled = false,
        } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                    labelText={label}
                    id
                    labelProps={{ shrink: true }}
                    formControlProps={{
                        fullWidth: true,
                        multiline: true,
                    }}
                    customOptions={options}
                    inputProps={{
                        required: false,
                        value,
                        defaultValue: value,
                        checked: value,
                        name: id,
                        id: id,
                        type: type,
                        onChange,
                        disabled,
                        options: keyValuePair,
                    }}
                />
            </GridItem>
        );
    }
}
